define('presenter/instance-initializers/translated-properties', ['exports', 'presenter/helpers/translated-properties'], function (exports, _translatedProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'translatedProperties',
    initialize: function initialize(instance) {
      return _translatedProperties.default.reopen({
        presenterResponseController: instance.container.lookup('controller:presenter/response')
      });
    }
  };
});
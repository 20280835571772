define('presenter/mixins/grid-component-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /* global Modernizr */
  var GridComponentHelper;

  GridComponentHelper = Ember.Mixin.create({
    _showNextItem: function () {
      var $item, dir;
      if (($item = this.$('.js-gridItem')) && (dir = this.get('component.currentDirection'))) {
        $item.css({
          x: $item.parent().outerWidth() * dir
        });
        return $item.transition({
          x: 0
        }, GridComponentHelper.TRANSITION_DURATION);
      }
    }.observes('component.currentItem')
  });

  GridComponentHelper.TRANSITION_DURATION = 200;

  exports.default = GridComponentHelper;
});
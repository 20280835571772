define('presenter/mixins/form-field-support', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    autofocusInput: false,
    onFocus: function onFocus() {
      if ((0, _globals.presenterFlag)('isReadOnly') || _globals.isIframed && this.get('component.position') === 0) {
        return;
      }
      if (this.get('autofocusInput') && this.get('component.isCurrent')) {
        this._focused = true;
        if (!(Modernizr.touch || Modernizr.ie10)) {
          return Ember.run.scheduleOnce('afterRender', function (_this) {
            return function () {
              var $input;
              if (!_this.$()) {
                return;
              }
              $input = _this.$("input[type='text'],textarea").first();
              if ($input.length) {
                if (!$input.val().trim().length) {
                  return $input.focus();
                }
              }
            };
          }(this));
        }
      }
    }
  });
});
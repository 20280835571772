define('presenter/helpers/format-for-html-raw', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var noop, text;
    text = arg[0];
    noop = function noop(x) {
      return x;
    };
    return (0, _utils.autolink)(text, noop, Ember.Handlebars.Utils.escapeExpression);
  });
});
define('presenter/models/survey-share', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SurveyShare;

  SurveyShare = _data.Model.extend({
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    user: (0, _data.belongsTo)('user', {
      serialize: 'id'
    })
  });

  SurveyShare.reopenClass({
    resourceName: 'survey_share'
  });

  exports.default = SurveyShare;
});
define("presenter/templates/presenter/layouts/question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 413
            },
            "end": {
              "line": 1,
              "column": 495
            }
          },
          "moduleName": "presenter/templates/presenter/layouts/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "presenter-component-read-only-mask");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 526
          }
        },
        "moduleName": "presenter/templates/presenter/layouts/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("div");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "presenter-component-content js-componentContent");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "presenter-component-input-wrapper");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createMorphAt(element3, 0, 0);
        morphs[4] = dom.createMorphAt(element3, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["presenter-component-wrapper js-componentWrapper ", ["subexpr", "if", [["get", "component.components.disableInput", ["loc", [null, [1, 65], [1, 98]]]], "disable-input"], [], ["loc", [null, [1, 60], [1, 116]]]]]]], ["attribute", "class", ["concat", ["presenter-component ", ["subexpr", "if", [["get", "component.model.isCentered", ["loc", [null, [1, 155], [1, 181]]]], "Component-centered", "Component-alignedToEdge"], [], ["loc", [null, [1, 150], [1, 230]]]]]]], ["inline", "partial", ["survey/questionHeader"], [], ["loc", [null, [1, 293], [1, 328]]]], ["inline", "partial", [["subexpr", "component-view", [["get", "component", ["loc", [null, [1, 401], [1, 410]]]]], [], ["loc", [null, [1, 385], [1, 411]]]]], [], ["loc", [null, [1, 375], [1, 413]]]], ["block", "if", [["get", "component.isReadOnly", ["loc", [null, [1, 419], [1, 439]]]]], [], 0, null, ["loc", [null, [1, 413], [1, 502]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define('presenter/controllers/presenter/scale', ['exports', 'presenter/controllers/presenter/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    hasAnswer: Ember.computed.notEmpty('answer.number'),
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.number'),
    pipingAnswer: function pipingAnswer() {
      return this.answerText(this.get('answer.number'));
    },
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      return this.set('answer.number', null);
    },
    answerText: function answerText(value) {
      var ref, ref1;
      switch (this.get('type')) {
        case 'CustomerSatisfaction':
          return ((ref = this.get('csatRatings').findBy('number', value)) != null ? ref.text : void 0) || value;
        case 'CustomerEffort':
          return ((ref1 = this.get('cesRatings').findBy('number', value)) != null ? ref1.text : void 0) || value;
        default:
          return value;
      }
    }
  });
});
define("presenter/templates/svg/dashboard/positive_keyword", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/positive_keyword.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M3.8 27.6c.3 2 1.9 3.4 3.9 3.4H30V14h-6.3l-4-14H18c-2.2 0-4 1.8-4 4v9H6c-2.2 0-4 1.8-4 4v1l1.8 9.6zM28 29h-4V16h4v13zM4 17c0-1.1.9-2 2-2h10V4c0-1.1.9-2 2-2h.2L22 15.1V29H7.7c-1 0-1.8-.7-1.9-1.7L4 17.9V17z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
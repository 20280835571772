define('presenter/models/credential', ['exports', 'presenter/data', 'presenter/globals', 'presenter/utils/bitfields'], function (exports, _data, _globals, _bitfields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Credential;

  Credential = _data.Model.extend({
    type: (0, _data.attr)('string'),
    description: (0, _data.attr)('string'),
    instanceUrl: (0, _data.attr)('string'),
    orgType: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    userId: (0, _data.attr)('number'),
    organizationId: (0, _data.attr)('string'),
    permissions: (0, _data.attr)('number'),
    slackTeamId: (0, _data.attr)('string'),
    pardotVersion: (0, _data.attr)('string'),
    numUsers: Ember.computed.oneWay('users.length')
  });

  _bitfields.default.addProperties(Credential, 'permissions', _globals.codeSettings.credentialPermissionsFields);

  Credential.reopenClass({
    resourceName: 'credential'
  });

  exports.default = Credential;
});
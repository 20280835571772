define('presenter/mixins/aria', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['aria-label', 'tabindex', 'aria-disabled', 'role', 'aria-checked', 'aria-describedby'],
    'tabindex': 0,
    'aria-label': '',
    'aria-disabled': false,
    'role': ''
  });
});
define('presenter/initializers/browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'browserFeatureDetection',
    initialize: function initialize(container, application) {
      container.register('modernizr:main', Modernizr, {
        instantiate: false
      });
      container.injection('controller', 'browser', 'modernizr:main');
      return container.injection('component', 'browser', 'modernizr:main');
    }
  };
});
define('presenter/mixins/change-confirmation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _confirmations: function () {
      return [];
    }.property(),
    saveRecord: function saveRecord(options) {
      if (options == null) {
        options = {};
      }
      this.get('_confirmations').pushObjects(options.confirm || []);
      return this._super.apply(this, arguments);
    },
    deleteRecord: function deleteRecord(confirm) {
      if (confirm) {
        this.set('_confirmations', ['destroy']);
      }
      return this._super.apply(this, arguments)["finally"](function (_this) {
        return function () {
          return _this.set('_confirmations', []);
        };
      }(this));
    },
    serialize: function serialize() {
      var json, obj, root;
      json = this._super.apply(this, arguments);
      root = RL.get('client.adapter.serializer')._keyForResource(this);
      if (Ember.isArray(this.get('_confirmations'))) {
        obj = json[root] || json;
        this.get('_confirmations').forEach(function (flag) {
          return obj["confirm_" + flag.underscore()] = true;
        });
        this.set('_confirmations', []);
      }
      return json;
    }
  });
});
define('presenter/mixins/presenter/rules-evaluator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    rulesController: null,
    _initRulesController: function () {
      return this.set('rulesController', Ember.ArrayController.create({
        itemController: 'presenter/rule',
        contentBinding: 'parentController.content.rules',
        parentController: this,
        container: this.get('container')
      }));
    }.on('init'),
    willDestroy: function willDestroy() {
      this.get('rulesController').destroy();
      return this._super();
    },
    _evaluateRules: function _evaluateRules(preconditionsOnly) {
      var actions;
      return actions = this.get('rulesController').reduce(function (accumulator, ruleController) {
        if (preconditionsOnly && !ruleController.get('isPrecondition')) {
          return accumulator;
        } else {
          return accumulator.concat(ruleController.evaluate());
        }
      }, []);
    }
  });
});
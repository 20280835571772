define('presenter/components/alert-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['alert'],
    classNameBindings: ['position'],
    toggle: null,
    duration: 3000,
    position: 'top',
    hideOnToggle: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      return this._showAlert();
    },
    _showAlert: function () {
      var _flashObserver;
      if (!this.get('toggle')) {
        return;
      }
      this.$().slideDown(100);
      if (this.get('hideOnToggle')) {
        return this.addObserver('toggle', _flashObserver = function (_this) {
          return function () {
            _this.removeObserver('toggle', _flashObserver);
            return _this.$().slideUp(100);
          };
        }(this));
      } else {
        this._timer = Ember.run.later(function (_this) {
          return function () {
            return _this.$().slideUp(200, function () {
              return _this.toggleProperty('toggle');
            });
          };
        }(this), this.get('duration'));
        return this.addObserver('toggle', _flashObserver = function flashObserver() {
          Ember.run.cancel(this._timer);
          this.$().hide();
          return this.removeObserver('toggle', _flashObserver);
        });
      }
    }.observes('toggle'),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      return Ember.run.cancel(this._timer);
    }
  });
});
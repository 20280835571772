define('presenter/instance-initializers/raven', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'raven',
    initialize: function initialize(instance) {
      var user, userObj;
      Raven.config(_globals.jsSettings.sentry.frontend_dsn, _globals.ravenOptions).addPlugin(Raven.Plugins.Ember).install();
      if (user = instance.container.lookup('controller:currentUser')) {
        userObj = user.getProperties('model.email', 'model.id');
        return Raven.setUser({
          id: user.get('model.id'),
          email: user.get('model.email')
        });
      }
    }
  };
});
define('presenter/models/recipe-menu', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RecipeMenu;

  RecipeMenu = _data.Model.extend({
    name: (0, _data.attr)('string'),
    orderedRecipeMenuItems: (0, _data.hasMany)('recipe_menu_item', {
      serialize: true
    })
  });

  RecipeMenu.reopenClass({
    resourceName: 'recipe_menu'
  });

  exports.default = RecipeMenu;
});
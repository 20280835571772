define('presenter/models/dashboard-share', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DashboardShare;

  DashboardShare = _data.Model.extend({
    dashboard: (0, _data.belongsTo)('dashboard', {
      serialize: 'id'
    }),
    user: (0, _data.belongsTo)('user', {
      serialize: 'id'
    })
  });

  DashboardShare.reopenClass({
    resourceName: 'dashboard_share'
  });

  exports.default = DashboardShare;
});
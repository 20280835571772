define("presenter/templates/svg/_salesforce_sync_error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_salesforce_sync_error.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M17.3 20.2l-.9-.3-.7 1.9.9.3c.8.3 1.3 1 1.3 1.9 0 1.1-.9 2-2 2-.8 0-1.6-.5-1.9-1.3l-.3-.9-1.9.7.3.9c.6 1.6 2.1 2.7 3.8 2.7 2.2 0 4-1.8 4-4 .1-1.8-1-3.3-2.6-3.9z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M30.3 9.9c-1.2-1.2-2.5-2.2-3.8-3L30.4 3 29 1.6 24.6 6C22 4.7 19 4 16 4 10.6 4 5.5 6.1 1.7 9.9l-.7.7L2.4 12l.7-.7C6.5 7.9 11.1 6 16 6c2.5 0 4.9.5 7.1 1.5l-4.8 4.8c-.7-.2-1.5-.3-2.3-.3-3.2 0-6.2 1.2-8.5 3.5l-.7.7 1.4 1.4.7-.7C10.8 15 13.3 14 16 14h.6l-13 13L5 28.4l14-14c1.5.5 2.9 1.3 4.1 2.5l.7.7 1.4-1.4-.7-.7c-1.1-1.2-2.5-2-4-2.6L25 8.4c1.4.8 2.7 1.8 3.9 2.9l.7.7 1.4-1.4-.7-.7z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/components/presenter/net-promoter-rating', ['exports', 'i18n-js', 'presenter/mixins/aria'], function (exports, _i18nJs, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ratingLabelKeys;

  ratingLabelKeys = {
    0: function _() {
      return 'not_likely';
    },
    10: function _() {
      return 'extremely_likely';
    }
  };

  exports.default = Ember.Component.extend(_aria.default, {
    tagName: 'button',
    classNames: ['NetPromoter-rating', 'theme-border-20', 'theme-answer'],
    classNameBindings: ['isSelected:theme-selected:theme-background-screen', 'isSelected:is-selected'],
    layoutName: ['presenter/net-promoter-rating'],
    answer: null,
    value: null,
    hideLabels: false,
    labelKey: function () {
      var name;
      return typeof ratingLabelKeys[name = this.get('value')] === "function" ? ratingLabelKeys[name]() : void 0;
    }.property('value'),
    isSelected: function () {
      return this.get('value') === this.get('answer.number');
    }.property('value', 'answer.number'),
    click: function click() {
      return this.send('select', this.get('value'));
    }
  });
});
define('presenter/instance-initializers/register-controller-utility', ['exports', 'presenter/utils/register-controller'], function (exports, _registerController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'registerControllerUtility',
    initialize: function initialize(instance) {
      return (0, _registerController.init)(instance.container);
    }
  };
});
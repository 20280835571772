define("presenter/templates/presenter/thank-you-page-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 135
            }
          },
          "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-for-html-raw", [["subexpr", "merge-response-values", [["get", "component.model.markup", ["loc", [null, [1, 104], [1, 126]]]]], [], ["loc", [null, [1, 81], [1, 127]]]]], [], ["loc", [null, [1, 59], [1, 129]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 352
              },
              "end": {
                "line": 1,
                "column": 564
              }
            },
            "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "component-description theme-question");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 522], [1, 540]]]], "description"], [], ["loc", [null, [1, 486], [1, 555]]]]], [], ["loc", [null, [1, 463], [1, 556]]]]], [], ["loc", [null, [1, 445], [1, 558]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 135
            },
            "end": {
              "line": 1,
              "column": 571
            }
          },
          "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h1");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "component-title component-title-presenter theme-question js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 317], [1, 335]]]], "title"], [], ["loc", [null, [1, 281], [1, 344]]]]], [], ["loc", [null, [1, 258], [1, 345]]]]], [], ["loc", [null, [1, 240], [1, 347]]]], ["block", "if", [["get", "component.hasDescription", ["loc", [null, [1, 358], [1, 382]]]]], [], 0, null, ["loc", [null, [1, 352], [1, 571]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 604
              },
              "end": {
                "line": 1,
                "column": 725
              }
            },
            "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 696], [1, 723]]]]], [], []]], ["loc", [null, [1, 647], [1, 725]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 732
              },
              "end": {
                "line": 1,
                "column": 853
              }
            },
            "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-video", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 824], [1, 851]]]]], [], []]], ["loc", [null, [1, 775], [1, 853]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 578
            },
            "end": {
              "line": 1,
              "column": 860
            }
          },
          "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.mediaObject.isImage", ["loc", [null, [1, 610], [1, 645]]]]], [], 0, null, ["loc", [null, [1, 604], [1, 732]]]], ["block", "if", [["get", "component.model.mediaObject.isVideo", ["loc", [null, [1, 738], [1, 773]]]]], [], 1, null, ["loc", [null, [1, 732], [1, 860]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 946
              },
              "end": {
                "line": 1,
                "column": 1085
              }
            },
            "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "data-action", "close");
            dom.setAttribute(el1, "class", "thank-you-page-button theme-button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "component.model.buttonText", ["loc", [null, [1, 1046], [1, 1076]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1093
                },
                "end": {
                  "line": 1,
                  "column": 1389
                }
              },
              "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "tabindex", "0");
              dom.setAttribute(el1, "class", "thank-you-page-button theme-button");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["redirect", ["subexpr", "merge-response-values", [["get", "component.model.buttonUrl", ["loc", [null, [1, 1188], [1, 1213]]]]], ["urlEncode", true], ["loc", [null, [1, 1165], [1, 1229]]]]], ["on", "click"], ["loc", [null, [1, 1145], [1, 1242]]]], ["inline", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 1346], [1, 1364]]]], "buttonText"], [], ["loc", [null, [1, 1310], [1, 1378]]]]], [], ["loc", [null, [1, 1286], [1, 1380]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1085
              },
              "end": {
                "line": 1,
                "column": 1396
              }
            },
            "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "component.model.buttonUrl", ["loc", [null, [1, 1099], [1, 1124]]]]], [], 0, null, ["loc", [null, [1, 1093], [1, 1396]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 867
            },
            "end": {
              "line": 1,
              "column": 1409
            }
          },
          "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "thank-you-page-button-container");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isCustomer", ["loc", [null, [1, 952], [1, 962]]]]], [], 0, 1, ["loc", [null, [1, 946], [1, 1403]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1416
          }
        },
        "moduleName": "presenter/templates/presenter/thank-you-page-component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.useRichText", ["loc", [null, [1, 6], [1, 27]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 578]]]], ["block", "if", [["get", "component.hasMedia", ["loc", [null, [1, 584], [1, 602]]]]], [], 2, null, ["loc", [null, [1, 578], [1, 867]]]], ["block", "if", [["get", "component.model.buttonText", ["loc", [null, [1, 873], [1, 899]]]]], [], 3, null, ["loc", [null, [1, 867], [1, 1416]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});
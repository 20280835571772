define('presenter/components/presenter/text-field', ['exports', 'presenter/components/text-field', 'presenter/mixins/placeholder', 'presenter/mixins/text-control-common', 'presenter/mixins/input-common', 'presenter/mixins/aria'], function (exports, _textField, _placeholder, _textControlCommon, _inputCommon, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _textField.default.extend(_placeholder.default, _textControlCommon.default, _inputCommon.default, _aria.default, {
    classNames: ['theme-text'],
    _removeWhitespaceFromEmail: Ember.observer('value', function () {
      var ref;
      if (this.get('validate') !== 'email') {
        return;
      }
      return this.set('value', (ref = this.get('value')) != null ? ref.replace(' ', '') : void 0);
    }).on('init'),
    keyDown: function keyDown(event) {
      this._super(event);
      if (this.get('validate') !== 'email') {
        return;
      }
      if (event.keyCode === 32) {
        return event.preventDefault();
      }
    }
  });
});
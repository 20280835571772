define('presenter/mixins/field-focus-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FOCUS_CLEAR_DELAY;

  FOCUS_CLEAR_DELAY = 200;

  exports.default = Ember.Mixin.create({
    focusedObject: null,
    focusIdentifier: null,
    focusContext: null,
    init: function init() {
      this._super();
      this.on('didInsertElement', this, this._focusedObjectChanged);
      this.on('focusIn', this, this._gainedFocus);
      return this.on('focusOut', this, this._lostFocus);
    },
    _focusedObjectChanged: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var identifier;
        identifier = this.get('focusIdentifier');
        if (identifier && this.get('focusedObject') === identifier && this.$() && !this.$().is(':focus')) {
          this.$().focus();
          return this.$().val(this.$().val());
        }
      });
    }.observes('focusedObject', 'focusIdentifier'),
    _gainedFocus: function _gainedFocus() {
      var identifier, ref;
      if (identifier = this.get('focusIdentifier')) {
        this.set('focusedObject', identifier);
      }
      return (ref = this.get('focusContext')) != null ? ref.set('focusedComponent', this) : void 0;
    },
    _lostFocus: function _lostFocus() {
      return Ember.run.later(function (_this) {
        return function () {
          if (_this.get('focusContext.focusedComponent') === _this) {
            return _this.set('focusContext.focusedComponent', null);
          }
        };
      }(this), FOCUS_CLEAR_DELAY);
    }
  });
});
define('presenter/models/sluricane-keyword', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SluricaneKeyword;

  SluricaneKeyword = _data.Model.extend({
    type: (0, _data.attr)('string', {
      readOnly: true
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    response: (0, _data.belongsTo)('response', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    responseRethinkId: (0, _data.attr)('string', {
      readOnly: true
    }),
    term: (0, _data.attr)('string', {
      readOnly: true
    }),
    financialAmount: (0, _data.attr)('number', {
      readOnly: true
    }),
    negativeCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    negativeScore: (0, _data.attr)('number', {
      readOnly: true
    }),
    neutralCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    positiveCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    positiveScore: (0, _data.attr)('number', {
      readOnly: true
    }),
    totalCount: (0, _data.attr)('number', {
      readOnly: true
    })
  });

  SluricaneKeyword.reopenClass({
    resourceName: 'sluricane_keyword',
    baseUrl: _globals.jsSettings.sluricane.url,
    endpoint: 'terms'
  });

  exports.default = SluricaneKeyword;
});
define('presenter/utils', ['exports', 'core-js/library/fn/set', 'presenter/globals'], function (exports, _set, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isRebranded = exports.isGsmAlphabet = exports.fileIsValidSize = exports.isImageExtension = exports.preventOverscroll = exports.emberSet = exports.autolink = exports.escapeAndFormatWhitespace = exports.cursorPosition = exports.daysInMonth = exports.openWindow = exports.isMissingAnyPipedAnswer = exports.pipeAnswersInString = exports.mergeResponseValues = exports.mergeFieldsInString = exports.delimiters = exports.isSalesforceID = exports.SALESFORCE_ID_REGEXP_GLOBAL = exports.SALESFORCE_ID_REGEXP = exports.userLanguage = exports.isValidEmail = exports.getVimeoId = exports.getYouTubeId = exports.VIMEO_URL_REGEX = exports.YOUTUBE_SHORTENED_URL_REGEX = exports.YOUTUBE_URL_REGEX = exports.PHONE_NUMBER_REGEX = exports.URL_REGEX_GLOBAL = exports.URL_REGEX = exports.resizeImage = exports.dataToBlob = exports.loadImage = exports.readFile = exports.interpolateRGBA = exports.getDominantColor = exports.lightenColor = exports.darkenColor = exports.isDark = exports.contrastColor = exports.DOMColorToRGB = exports.colorToRGBA = exports.RGBToColor = exports.HSLToRGB = exports.RGBToHSL = exports.colorToRGB = exports.perceivedLuminosity = exports.colorToDOMHex = undefined;
  var gsmAlphabet, hueToRGB, pipingPattern;

  var colorToDOMHex = exports.colorToDOMHex = function colorToDOMHex(color, alpha) {
    var hex;
    if (alpha == null) {
      alpha = null;
    }
    if (color == null) {
      return "#000000";
    }
    hex = Number(color).toString(16);
    while (hex.length < 6) {
      hex = '0' + hex;
    }
    if (typeof alpha === 'number') {
      return "#" + (Number(Math.floor(255 * alpha)).toString(16) + hex);
    } else {
      return "#" + hex;
    }
  };

  var perceivedLuminosity = exports.perceivedLuminosity = function perceivedLuminosity(color) {
    var rgb;
    if (!color) {
      return 0;
    }
    rgb = colorToRGB(color);
    return (0.299 * rgb.red + 0.587 * rgb.green + 0.114 * rgb.blue) / 256;
  };

  var colorToRGB = exports.colorToRGB = function colorToRGB(color) {
    var rgb;
    rgb = {};
    rgb.red = (color & 0xFF0000) >> 16;
    rgb.green = (color & 0x00FF00) >> 8;
    rgb.blue = color & 0x0000FF;
    return rgb;
  };

  var RGBToHSL = exports.RGBToHSL = function RGBToHSL(rgb) {
    var blue, diff, green, hsl, hue, lightness, max, min, red, saturation;
    red = rgb.red / 255;
    green = rgb.green / 255;
    blue = rgb.blue / 255;
    max = Math.max(red, green, blue);
    min = Math.min(red, green, blue);
    diff = max - min;
    hue = function () {
      switch (max) {
        case min:
          return 0;
        case red:
          return 60 * (green - blue) / diff;
        case green:
          return 60 * (blue - red) / diff + 120;
        case blue:
          return 60 * (red - green) / diff + 240;
      }
    }();
    lightness = (max + min) / 2;
    saturation = max === min ? 0 : lightness < 0.5 ? diff / (2 * lightness) : diff / (2 - 2 * lightness);
    hsl = {};
    hsl.hue = hue % 360;
    hsl.saturation = saturation * 100;
    hsl.lightness = lightness * 100;
    return hsl;
  };

  hueToRGB = function hueToRGB(m1, m2, h) {
    if (h < 0) {
      h += 1;
    }
    if (h > 1) {
      h -= 1;
    }
    if (h * 6 < 1) {
      return m1 + (m2 - m1) * h * 6;
    } else if (h * 2 < 1) {
      return m2;
    } else if (h * 3 < 2) {
      return m1 + (m2 - m1) * (2 / 3 - h) * 6;
    } else {
      return m1;
    }
  };

  var HSLToRGB = exports.HSLToRGB = function HSLToRGB(hsl) {
    var hue, lightness, m1, m2, rgb, saturation;
    hue = hsl.hue / 360;
    saturation = hsl.saturation / 100;
    lightness = hsl.lightness / 100;
    m2 = lightness <= 0.5 ? lightness * (saturation + 1) : lightness + saturation - lightness * saturation;
    m1 = lightness * 2 - m2;
    rgb = {};
    rgb.red = Math.round(hueToRGB(m1, m2, hue + 1 / 3) * 0xFF);
    rgb.green = Math.round(hueToRGB(m1, m2, hue) * 0xFF);
    rgb.blue = Math.round(hueToRGB(m1, m2, hue - 1 / 3) * 0xFF);
    return rgb;
  };

  var RGBToColor = exports.RGBToColor = function RGBToColor(rgb) {
    return (rgb.red << 16) + (rgb.green << 8) + rgb.blue;
  };

  var colorToRGBA = exports.colorToRGBA = function colorToRGBA(color, alpha) {
    var rgb;
    rgb = colorToRGB(color);
    return "rgba(" + rgb.red + ", " + rgb.green + ", " + rgb.blue + ", " + (alpha || '1.0') + ")";
  };

  var DOMColorToRGB = exports.DOMColorToRGB = function DOMColorToRGB(domColor) {
    var base, match;
    if (match = domColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/)) {
      base = 10;
    } else if (match = domColor.match(/#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/i)) {
      base = 16;
    }
    return {
      red: parseInt(match[1], base),
      green: parseInt(match[2], base),
      blue: parseInt(match[3], base)
    };
  };

  var contrastColor = exports.contrastColor = function contrastColor(color) {
    if (perceivedLuminosity(color) < _globals.codeSettings.perceivedLuminosityCutoff) {
      return 0xFFFFFF;
    } else {
      return 0;
    }
  };

  var isDark = exports.isDark = function isDark(dependentKey) {
    return Ember.computed(dependentKey, function () {
      return perceivedLuminosity(this.get(dependentKey)) < _globals.codeSettings.perceivedLuminosityCutoff;
    });
  };

  var darkenColor = exports.darkenColor = function darkenColor(color, percentage) {
    var hsl;
    hsl = RGBToHSL(colorToRGB(color));
    hsl.lightness = hsl.lightness * (1 - percentage / 100);
    return RGBToColor(HSLToRGB(hsl));
  };

  var lightenColor = exports.lightenColor = function lightenColor(color, percentage) {
    var hsl;
    hsl = RGBToHSL(colorToRGB(color));
    hsl.lightness = (100 - hsl.lightness) * (percentage / 100) + hsl.lightness;
    return RGBToColor(HSLToRGB(hsl));
  };

  var getDominantColor = exports.getDominantColor = function getDominantColor(image) {
    var colorThief, rgb;
    colorThief = new ColorThief();
    rgb = function () {
      try {
        return colorThief.getColor(image);
      } catch (error) {
        return [0, 0, 0];
      }
    }();
    return {
      red: rgb[0],
      green: rgb[1],
      blue: rgb[2]
    };
  };

  var interpolateRGBA = exports.interpolateRGBA = function interpolateRGBA(domColor1, domColor2, alpha) {
    var i, rgb1, rgb2;
    rgb1 = DOMColorToRGB(domColor1);
    rgb2 = DOMColorToRGB(domColor2);
    i = d3.interpolateHsl("rgb(" + rgb1.red + ", " + rgb1.green + ", " + rgb1.blue + ")", "rgb(" + rgb2.red + ", " + rgb2.green + ", " + rgb2.blue + ")");
    return function (t) {
      var rgb;
      rgb = DOMColorToRGB(i(t));
      return "rgba(" + rgb.red + ", " + rgb.green + ", " + rgb.blue + ", " + alpha + ")";
    };
  };

  var readFile = exports.readFile = function readFile(file) {
    var promise;
    promise = new Ember.RSVP.Promise(function (resolve, reject) {
      var reader;
      reader = new FileReader();
      reader.onload = function (event) {
        return resolve(reader.result);
      };
      reader.onerror = function () {
        return reject(this);
      };
      return reader.readAsDataURL(file);
    });
    return promise;
  };

  var loadImage = exports.loadImage = function loadImage(data) {
    var promise;
    promise = new Ember.RSVP.Promise(function (resolve, reject) {
      var image;
      image = new Image();
      image.onload = function () {
        return resolve(this);
      };
      image.onerror = function () {
        return reject(new Error('Invalid image file'));
      };
      return image.src = data;
    });
    return promise;
  };

  var dataToBlob = exports.dataToBlob = function dataToBlob(data) {
    var buffer, bytes, content, i, j, mimetype, ref, segments;
    segments = data.split(',');
    bytes = segments[0].indexOf('base64') !== -1 ? atob(segments[1]) : decodeURI(segments[1]);
    buffer = new ArrayBuffer(bytes.length);
    content = new Uint8Array(buffer);
    for (i = j = 0, ref = bytes.length; j < ref; i = j += 1) {
      content[i] = bytes.charCodeAt(i);
    }
    mimetype = segments[0].split(':')[1].split(';')[0];
    return new Blob([buffer], {
      type: mimetype
    });
  };

  var resizeImage = exports.resizeImage = function resizeImage(file, maxWidth, maxHeight) {
    return readFile(file).then(loadImage).then(function (_this) {
      return function (image) {
        var canvas, context, height, scale, width;
        if (image.height / image.width > maxHeight / maxWidth) {
          scale = image.height / maxHeight;
        } else {
          scale = image.width / maxWidth;
        }
        width = image.width / scale * 2;
        height = image.height / scale * 2;
        if (width < image.width) {
          canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, width, height);
          if (/^image\/jpe?g/.test(file.type)) {
            return canvas.toDataURL('image/jpeg', 0.75);
          } else {
            return canvas.toDataURL('image/png');
          }
        } else {
          return image.src;
        }
      };
    }(this));
  };

  var URL_REGEX = exports.URL_REGEX = /((((?:https?|ftp):(?:\/\/))[A-Za-z0-9\.\-]+|(?:www\.)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

  var URL_REGEX_GLOBAL = exports.URL_REGEX_GLOBAL = new RegExp(URL_REGEX.source, 'g');

  var PHONE_NUMBER_REGEX = exports.PHONE_NUMBER_REGEX = /^([\+]?[0-9]{1,3}([\s\.\-])?)?([\(][0-9]{1,5}[\)])?([0-9A-Z\s\.\-]{1,32})([\s]?(x|ext|extension)[\s]?[0-9]{1,5}?)?$/i;

  var YOUTUBE_URL_REGEX = exports.YOUTUBE_URL_REGEX = /https?:\/\/.*\.?youtube.com\/(v\/|.*(\?|\&)v=|embed\/)+([a-zA-Z0-9\-_]+)/i;

  var YOUTUBE_SHORTENED_URL_REGEX = exports.YOUTUBE_SHORTENED_URL_REGEX = /https?:\/\/youtu.be\/([a-zA-Z0-9\-_]+)/i;

  var VIMEO_URL_REGEX = exports.VIMEO_URL_REGEX = /https?:\/\/.*\.?vimeo\.com.*\/([0-9]+)/i;

  var getYouTubeId = exports.getYouTubeId = function getYouTubeId(url) {
    var match;
    if (match = url.match(YOUTUBE_URL_REGEX) || url.match(YOUTUBE_SHORTENED_URL_REGEX)) {
      return match[match.length - 1];
    }
  };

  var getVimeoId = exports.getVimeoId = function getVimeoId(url) {
    var match;
    if (match = url.match(VIMEO_URL_REGEX)) {
      return match[1];
    }
  };

  var isValidEmail = exports.isValidEmail = function isValidEmail(email) {
    return (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    );
  };

  var userLanguage = exports.userLanguage = function userLanguage() {
    return window.navigator.userLanguage || window.navigator.language || '';
  };

  var SALESFORCE_ID_REGEXP = exports.SALESFORCE_ID_REGEXP = /^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{18}$/;

  var SALESFORCE_ID_REGEXP_GLOBAL = exports.SALESFORCE_ID_REGEXP_GLOBAL = new RegExp(SALESFORCE_ID_REGEXP.source, 'g');

  var isSalesforceID = exports.isSalesforceID = function isSalesforceID(id) {
    return SALESFORCE_ID_REGEXP.test(id);
  };

  var delimiters = exports.delimiters = /\[(.+?)\]/g;

  var mergeFieldsInString = exports.mergeFieldsInString = function mergeFieldsInString(template, data, urlEncode) {
    if (urlEncode == null) {
      urlEncode = false;
    }
    return template != null ? template.replace(delimiters, function (match, field) {
      var lfield, value;
      lfield = field != null ? field.toLowerCase() : void 0;
      if (!!(value = (data != null ? typeof data.get === "function" ? data.get(lfield) : void 0 : void 0) || (data != null ? data[lfield] : void 0))) {
        if (urlEncode) {
          return encodeURIComponent(value);
        } else {
          return value;
        }
      } else {
        return "[" + field + "]";
      }
    }) : void 0;
  };

  var mergeResponseValues = exports.mergeResponseValues = function mergeResponseValues(value, responseController, componentsController, urlEncode) {
    var mergeMap;
    if (urlEncode == null) {
      urlEncode = false;
    }
    mergeMap = responseController.get('model.mergeMap');
    return mergeFieldsInString(pipeAnswersInString(value, componentsController), mergeMap, urlEncode);
  };

  pipingPattern = /\[gf_q[0-9]+([:][0-9]+)?]/gi;

  var pipeAnswersInString = exports.pipeAnswersInString = function pipeAnswersInString(template, componentsController) {
    var replacedTemplate;
    return replacedTemplate = template != null ? template.replace(pipingPattern, function (match) {
      var itemIndex, pipedAnswer, questionAndItem, questionIndex;
      questionAndItem = match.match(/\d+/g);
      questionIndex = parseInt(questionAndItem[0]) - 1;
      if (Number.isFinite(parseInt(questionAndItem[1]))) {
        itemIndex = parseInt(questionAndItem[1]) - 1;
      }
      pipedAnswer = componentsController.pipingAnswer(questionIndex, itemIndex);
      if (Ember.isPresent(pipedAnswer)) {
        return pipedAnswer;
      } else {
        return match;
      }
    }) : void 0;
  };

  var isMissingAnyPipedAnswer = exports.isMissingAnyPipedAnswer = function isMissingAnyPipedAnswer(template, componentsController) {
    var matches;
    if (!template) {
      return false;
    }
    matches = template.match(pipingPattern);
    if (!matches) {
      return false;
    }
    return matches.some(function (match) {
      var itemIndex, questionAndItem, questionIndex;
      questionAndItem = match.match(/\d+/g);
      questionIndex = parseInt(questionAndItem[0]) - 1;
      if (Number.isFinite(parseInt(questionAndItem[1]))) {
        itemIndex = parseInt(questionAndItem[1]) - 1;
      }
      return componentsController.isMissingPipingAnswer(questionIndex, itemIndex);
    });
  };

  var openWindow = exports.openWindow = function openWindow(url, title, w, h, usePostForm) {
    var dualScreenLeft, dualScreenTop, form, height, input, left, newWindow, ref, ref1, ref2, ref3, ref4, ref5, top, width, windowOptions;
    if (usePostForm == null) {
      usePostForm = false;
    }
    dualScreenLeft = (ref = window.screenLeft) != null ? ref : screen.left;
    dualScreenTop = (ref1 = window.screenTop) != null ? ref1 : screen.top;
    width = (ref2 = (ref3 = window.innerWidth) != null ? ref3 : document.documentElement.clientWidth) != null ? ref2 : screen.width;
    height = (ref4 = (ref5 = window.innerHeight) != null ? ref5 : document.documentElement.clientHeight) != null ? ref4 : screen.height;
    left = width / 2 - w / 2 + dualScreenLeft;
    top = height / 2 - h / 2 + dualScreenTop;
    windowOptions = 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left;
    newWindow = null;
    if (usePostForm) {
      form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', url);
      form.setAttribute('target', title);
      input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', $.rails.csrfParam());
      input.setAttribute('value', $.rails.csrfToken());
      form.appendChild(input);
      document.body.appendChild(form);
      newWindow = window.open('', title, windowOptions);
      form.submit();
      document.body.removeChild(form);
    } else {
      newWindow = window.open(url, title, windowOptions);
    }
    if (window.focus) {
      newWindow.focus();
    }
    return newWindow;
  };

  var daysInMonth = exports.daysInMonth = function daysInMonth(month, year) {
    switch (month) {
      case 1:
        if (year != null && (year % 4 === 0 && year % 100 || year % 400 === 0)) {
          return 29;
        } else {
          return 28;
        }
        break;
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
      default:
        return 31;
    }
  };

  var cursorPosition = exports.cursorPosition = function cursorPosition(el) {
    var input, length, selection;
    if (el.selectionStart != null) {
      return el.selectionStart;
    } else if (input = el.querySelector('input')) {
      return input.selectionStart;
    } else if (document.selection != null) {
      selection = document.selection.createRange();
      length = document.selection.createRange().text.length;
      selection.moveStart('character', -el.value.length);
      return selection.text.length - length;
    } else {
      return 0;
    }
  };

  var escapeAndFormatWhitespace = exports.escapeAndFormatWhitespace = function escapeAndFormatWhitespace(text) {
    text = Ember.Handlebars.Utils.escapeExpression(text);
    text = text.replace(/\n/g, '<br/>');
    return text;
  };

  var autolink = exports.autolink = function autolink(text, textEscaper, expressionEscaper) {
    var href, match, nextIndex, regexp, result;
    if (expressionEscaper == null) {
      expressionEscaper = Ember.Handlebars.Utils.escapeExpression;
    }
    if (!text) {
      return "";
    }
    regexp = new RegExp(URL_REGEX_GLOBAL.source, 'g');
    result = "";
    nextIndex = 0;
    while (match = regexp.exec(text)) {
      href = expressionEscaper(match[3] ? match[0] : "http://" + match[0]);
      result += textEscaper(text.substring(nextIndex, match.index)) + '<a class="u-linkExternal" target="_blank" href="' + href + '">' + expressionEscaper(match[0]) + '</a>';
      nextIndex = match.index + match[0].length;
    }
    result += textEscaper(text.substring(nextIndex));
    return new Ember.Handlebars.SafeString(result);
  };

  var emberSet = exports.emberSet = Ember.CoreObject.extend(Ember.MutableEnumerable, {
    _content: new _set.default(),
    init: function init(items) {
      this._content = new _set.default();
      this._super();
      if (items) {
        return this.addObjects(items);
      }
    },
    nextObject: function nextObject(i, prevObj, ctx) {
      if (i === 0) {
        ctx.setIter = this._content.values();
      }
      return ctx.setIter.next().value;
    },
    add: Ember.aliasMethod('addObject'),
    push: Ember.aliasMethod('addObject'),
    remove: Ember.aliasMethod('removeObject'),
    addObject: function addObject(obj) {
      var added;
      if (Ember.isNone(obj)) {
        return this;
      }
      if (this._content.has(obj)) {
        return this;
      }
      added = [obj];
      this.enumerableContentWillChange(null, added);
      this._content.add(obj);
      this.enumerableContentDidChange(null, added);
      return this;
    },
    removeObject: function removeObject(obj) {
      var removed;
      if (Ember.isNone(obj)) {
        return this;
      }
      if (!this._content.has(obj)) {
        return this;
      }
      removed = [obj];
      this.enumerableContentWillChange(removed, null);
      this._content["delete"](obj);
      this.enumerableContentDidChange(removed, null);
      return this;
    },
    clear: function clear() {
      if (this.length === 0) {
        return this;
      }
      this.enumerableContentWillChange(len, 0);
      this._content.clear();
      this.enumerableContentDidChange(len, 0);
      return this;
    },
    contains: function contains(obj) {
      return this._content.has(obj);
    }
  });

  Object.defineProperty(emberSet.prototype, 'length', {
    get: function get() {
      return this._content.size;
    }
  });

  var preventOverscroll = exports.preventOverscroll = function preventOverscroll(scrollElement) {
    var allowScrollDown, allowScrollUp, lastY;
    allowScrollUp = true;
    allowScrollDown = true;
    lastY = 0;
    scrollElement.addEventListener('touchstart', function (e) {
      allowScrollUp = this.scrollTop > 0;
      allowScrollDown = this.scrollTop < this.scrollHeight - this.clientHeight;
      return lastY = e.pageY;
    }, true);
    return scrollElement.addEventListener('touchmove', function (e) {
      var down, up;
      up = e.pageY > lastY;
      down = !up;
      lastY = e.pageY;
      if (up && allowScrollUp || down && allowScrollDown) {} else {
        return e.preventDefault();
      }
    }, true);
  };

  var isImageExtension = exports.isImageExtension = function isImageExtension(filename) {
    return (/(jpg|jpeg|png|bmp|gif)$/i.test(filename.split('.').pop())
    );
  };

  var fileIsValidSize = exports.fileIsValidSize = function fileIsValidSize(filesize) {
    return filesize < _globals.jsSettings.storage.aws.size_limit;
  };

  gsmAlphabet = new RegExp('^[A-Za-z0-9 \\r\\n@\xA3$\xA5\xE8\xE9\xF9\xEC\xF2\xC7\xD8\xF8\xC5\xE5\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039E\xC6\xE6\xDF\xC9!"#$%&\'()*+,\\-./:;<=>?\xA1\xC4\xD6\xD1\xDC\xA7\xBF\xE4\xF6\xF1\xFC\xE0^{}\\\\\\[~\\]|\u20AC]*$');

  var isGsmAlphabet = exports.isGsmAlphabet = function isGsmAlphabet(text) {
    return gsmAlphabet.test(text);
  };

  var isRebranded = exports.isRebranded = window.localStorage.getItem('hasRebranding') === '1';
});
define("presenter/templates/svg/dashboard/answer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/answer.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "data-name", "Layer 1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 31.5 24.7");
        var el2 = dom.createElement("defs");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("shortanswer");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "id", "shortanswer");
        dom.setAttribute(el2, "class", "cls-1");
        dom.setAttribute(el2, "d", "M2.2,28.35a22.85,22.85,0,0,0,8.6-6.5c2.4-2.9,3.6-5.7,3.6-8.5a10.92,10.92,0,0,0-2.1-7.1,7.36,7.36,0,0,0-6-2.6A6.31,6.31,0,0,0,2,5.35,5.13,5.13,0,0,0,.2,9.25a7.61,7.61,0,0,0,.3,2.1,5.24,5.24,0,0,0,1.1,1.9,6.92,6.92,0,0,0,2.2,1.4,11.52,11.52,0,0,0,3.6.5h2a10.53,10.53,0,0,1-2.6,6.2,20.27,20.27,0,0,1-6.2,4.5Zm17.3,0a22.85,22.85,0,0,0,8.6-6.5c2.4-2.9,3.6-5.7,3.6-8.5a10.92,10.92,0,0,0-2.1-7.1,7.36,7.36,0,0,0-6-2.6,6.31,6.31,0,0,0-4.3,1.7,5.12,5.12,0,0,0-1.8,3.9,7.61,7.61,0,0,0,.3,2.1,5.24,5.24,0,0,0,1.1,1.9,6.92,6.92,0,0,0,2.2,1.4,11.52,11.52,0,0,0,3.6.5h2a10.53,10.53,0,0,1-2.6,6.2,20.27,20.27,0,0,1-6.2,4.5Z");
        dom.setAttribute(el2, "transform", "translate(-0.2 -3.65)");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
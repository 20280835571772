define('presenter/components/presenter/rank-question', ['exports', 'i18n-js', 'presenter/components/presenter/question', 'presenter/mixins/touchable', 'presenter/mixins/resize-handler'], function (exports, _i18nJs, _question, _touchable, _resizeHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend(_touchable.default, _resizeHandler.default, {
    touchable: '[data-role="rank-choices"]',
    touchStartDelay: 50,
    scrollable: '',
    ariaLabelPath: Ember.computed('browser.touch', function () {
      if (this.get('browser.touch')) {
        return 'press_and_drag_to_reorder';
      } else {
        return 'drag_to_reorder';
      }
    }),
    didInsertElement: function didInsertElement() {
      var $choices, options;
      this._super();
      $choices = this.$('[data-role="rank-choices"]');
      options = {
        scrollSpeed: 10,
        axis: 'y',
        distance: 0,
        update: function (_this) {
          return function (event, ui) {
            return _this.set('component.rankedChoices', _this._getRankedChoices());
          };
        }(this),
        start: function (_this) {
          return function (event, ui) {
            ui.placeholder.height(ui.item.height());
            return _this.send('disableUserSelection');
          };
        }(this),
        stop: function (_this) {
          return function () {
            return _this.send('enableUserSelection');
          };
        }(this)
      };
      $choices.sortable(options);
      return Ember.run.schedule('afterRender', this, this.onResize);
    },
    onResize: function onResize() {
      var $choices;
      this._super();
      if (!this.$()) {
        return;
      }
      $choices = this.$('[data-role="rank-choices"]');
      return $choices.css('height', $choices.height());
    },
    triggerResize: function () {
      var $choices;
      if (!this.$()) {
        return;
      }
      $choices = this.$('[data-role="rank-choices"]');
      return $choices.css('height', 'auto');
    }.observes('component.rankedChoices.length'),
    _initAnswer: function () {
      if (!this.$()) {
        return;
      }
      if (this.get('component.answer') && !this.get('component.rankedChoices.length')) {
        this.set('component.rankedChoices', this._getRankedChoices());
        return this.set('component.isChanged', false);
      }
    }.observes('component.answer').on('didInsertElement'),
    _getRankedChoices: function _getRankedChoices() {
      var choices, ref;
      choices = (ref = this.$('.js-rankChoice')) != null ? ref.map(function (_this) {
        return function (i, choice) {
          return _this.get('component.choicesById')[$(choice).data('id')];
        };
      }(this)).toArray() : void 0;
      return choices;
    },
    keyDown: function keyDown(e) {
      var $el, $handle;
      $el = $(e.target);
      $handle = $(e.target).closest('.js-rankChoice');
      if ($el.is(':focus') || $handle.is(':focus')) {
        this.mouseDown(e);
      }
      if (e.keyCode === 9) {
        this.mouseUp(e);
        return $el.focus();
      } else if (e.keyCode === 38) {
        $handle.insertBefore($handle.prev());
        this.set('component.rankedChoices', this._getRankedChoices());
        return $el.focus();
      } else if (e.keyCode === 40) {
        $handle.insertAfter($handle.next());
        this.set('component.rankedChoices', this._getRankedChoices());
        return $el.focus();
      }
    },
    mouseDown: function mouseDown(e) {
      var $handle;
      if ($handle = this.$(e.target).closest('.js-rankChoice')) {
        $handle.addClass('dragging');
        $handle.find('.presenter-rank-choice').addClass('theme-button');
        $handle.find('.choice-leading-icon').removeClass('theme-90');
        return $handle.find('.choice-text').removeClass('theme-answer');
      }
    },
    mouseUp: function mouseUp(e) {
      var $choice;
      if ($choice = this.$(e.target).closest('.js-rankChoice')) {
        $choice.removeClass('dragging');
        $choice.find('.presenter-rank-choice').removeClass('theme-button');
        $choice.find('.choice-leading-icon').addClass('theme-90');
        return $choice.find('.choice-text').addClass('theme-answer');
      }
    }
  });
});
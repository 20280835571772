define("presenter/templates/svg/_getfeedback_logo_2021", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_getfeedback_logo_2021.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "160");
        dom.setAttribute(el1, "height", "27");
        dom.setAttribute(el1, "viewBox", "0 0 160 27");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M17.9044 0.759375C16.5037 0.27 15.0019 0 13.4494 0C12.2175 0 11.0363 0.16875 9.90562 0.4725C6.36188 1.43438 2.80125 4.33687 1.85625 7.99875C1.43438 9.63562 1.485 11.3231 2.32875 12.8081C2.48062 13.0781 2.66625 13.3481 2.85188 13.6013C3.07125 13.905 3.3075 14.175 3.5775 14.4281C3.96562 14.7994 4.3875 15.12 4.84312 15.4069C6.44625 16.3687 8.33625 16.6556 10.1587 16.2C12.3525 15.66 14.1075 14.1075 14.9512 12.0487H11.3062C11.1544 12.0487 11.0194 11.9137 11.0194 11.7619V9.34875C11.0194 9.19687 11.1544 9.06187 11.3062 9.06187H19.9294C20.0813 9.06187 20.2162 9.19687 20.2162 9.34875V18.4612C20.2162 18.6131 20.0813 18.7481 19.9294 18.7481H17.5163C17.3644 18.7481 17.2294 18.6131 17.2294 18.4612V14.2594C15.8962 16.6388 13.635 18.4106 10.9012 19.1025C10.0744 19.305 9.2475 19.4062 8.4375 19.4062C6.64875 19.4062 4.87687 18.9169 3.3075 17.9719C2.6325 17.5669 2.00813 17.0944 1.45125 16.5544C1.38375 16.4869 1.31625 16.4194 1.24875 16.335C0.77625 15.8625 0.354375 15.3225 0 14.7656C0.64125 21.6169 6.4125 27 13.4494 27C20.9081 27 26.9494 20.9587 26.9494 13.5C26.9494 7.61062 23.1694 2.59875 17.9044 0.759375Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M39.5887 15.0694H43.3349V17.82C42.8962 18.1575 42.373 18.4275 41.7655 18.63C41.158 18.8325 40.4999 18.9337 39.808 18.9337C39.0655 18.9337 38.3737 18.7987 37.783 18.5287C37.1755 18.2587 36.6524 17.9044 36.2137 17.4319C35.7749 16.9762 35.4374 16.4194 35.2012 15.7781C34.9649 15.1369 34.8468 14.4281 34.8468 13.6856V13.6687C34.8468 12.9769 34.9649 12.3187 35.218 11.6775C35.4543 11.0531 35.7918 10.4962 36.2137 10.0406C36.6355 9.56811 37.1418 9.19686 37.7155 8.92686C38.2893 8.63999 38.9137 8.50499 39.5887 8.50499C40.0443 8.50499 40.4662 8.53874 40.8543 8.62311C41.2424 8.70749 41.5968 8.80874 41.9174 8.94374C42.238 9.07874 42.5418 9.24749 42.8287 9.43311C43.0649 9.58499 43.2843 9.75374 43.5205 9.93936L45.2924 8.16749L45.3093 8.13374C44.938 7.81311 44.5499 7.52624 44.1449 7.28999C43.7399 7.03686 43.318 6.83436 42.8793 6.66561C42.4237 6.49686 41.9512 6.36186 41.428 6.27749C40.9049 6.19311 40.3312 6.14249 39.673 6.14249C38.5762 6.14249 37.5637 6.34499 36.6355 6.74999C35.7074 7.15499 34.9143 7.69499 34.2393 8.38686C33.5643 9.07874 33.0412 9.88874 32.6699 10.8C32.2987 11.7112 32.113 12.69 32.113 13.7025V13.7362C32.113 14.8162 32.2987 15.795 32.653 16.7231C33.0074 17.6344 33.5305 18.4275 34.1887 19.1025C34.8468 19.7775 35.6399 20.3006 36.5849 20.6887C37.513 21.0769 38.5593 21.2625 39.7068 21.2625C40.348 21.2625 40.9555 21.195 41.5462 21.0769C42.1368 20.9587 42.6768 20.79 43.183 20.5875C43.6893 20.385 44.1618 20.1319 44.6005 19.8619C45.0393 19.575 45.4443 19.2881 45.7987 18.9844V12.8419H39.5718V15.0694H39.5887Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M56.5482 11.5256C56.1095 10.9856 55.5526 10.5469 54.8945 10.2263C54.2364 9.90563 53.4601 9.75375 52.5826 9.75375C51.7895 9.75375 51.0639 9.90563 50.4057 10.2094C49.7476 10.5131 49.1907 10.9181 48.7014 11.4413C48.2289 11.9644 47.8407 12.5719 47.5876 13.2638C47.3176 13.9556 47.1826 14.6981 47.1826 15.5081V15.5419C47.1826 16.3856 47.3345 17.1619 47.6214 17.8706C47.9082 18.5794 48.3132 19.17 48.8195 19.6763C49.3257 20.1825 49.9332 20.5706 50.6251 20.8406C51.317 21.1275 52.0595 21.2625 52.8526 21.2625C53.8651 21.2625 54.7426 21.0769 55.4682 20.7056C56.1939 20.3344 56.8351 19.8281 57.3582 19.2038L55.8732 17.8875C55.4345 18.3263 54.9789 18.6469 54.5064 18.8663C54.0507 19.0856 53.5107 19.2038 52.8695 19.2038C52.0426 19.2038 51.3507 18.9675 50.777 18.4781C50.2032 18.0056 49.832 17.2969 49.697 16.4025H57.8139C57.8307 16.2844 57.8307 16.1494 57.8476 16.0313C57.8476 15.9131 57.8645 15.795 57.8645 15.6938C57.8645 14.9006 57.7464 14.1413 57.527 13.4325C57.3076 12.69 56.987 12.0656 56.5482 11.5256ZM49.697 14.715C49.8151 13.8544 50.1189 13.1625 50.6251 12.6225C51.1314 12.0825 51.7895 11.8125 52.5657 11.8125C52.9876 11.8125 53.3589 11.8969 53.6795 12.0488C54.0001 12.2006 54.287 12.4031 54.5232 12.6731C54.7595 12.9263 54.9451 13.23 55.0801 13.6013C55.2151 13.9556 55.3164 14.3438 55.3501 14.7488H49.697V14.715Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M62.7411 6.93561H60.193V9.97311H58.4211V12.15H60.193V17.955C60.193 18.5625 60.2774 19.0856 60.4293 19.4906C60.5811 19.8956 60.8005 20.2331 61.0874 20.4862C61.3743 20.7394 61.7118 20.925 62.1168 21.0262C62.5049 21.1444 62.9436 21.195 63.3993 21.195C63.8718 21.195 64.2937 21.1444 64.648 21.0431C65.0024 20.9419 65.3568 20.8069 65.6774 20.6212V18.5625C65.1711 18.8156 64.648 18.9337 64.108 18.9337C63.6693 18.9337 63.3487 18.8325 63.1124 18.6131C62.8762 18.3937 62.758 18.0394 62.758 17.55V12.15H65.728V9.97311H62.758V6.93561H62.7411Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M70.1321 8.69063H78.4515V6.37875H67.5671V21.0094H70.1321V15.0525H76.5615V12.7406H70.1321V8.69063Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M87.0917 11.5256C86.6529 10.9856 86.0961 10.5469 85.4379 10.2263C84.7798 9.90563 84.0036 9.75375 83.1261 9.75375C82.3329 9.75375 81.6073 9.90563 80.9492 10.2094C80.2911 10.5131 79.7342 10.9181 79.2448 11.4413C78.7723 11.9644 78.3842 12.5719 78.1311 13.2638C77.8611 13.9556 77.7261 14.6981 77.7261 15.5081V15.5419C77.7261 16.3856 77.8779 17.1619 78.1648 17.8706C78.4517 18.5794 78.8567 19.17 79.3629 19.6763C79.8692 20.1825 80.4767 20.5706 81.1686 20.8406C81.8604 21.1275 82.6029 21.2625 83.3961 21.2625C84.4086 21.2625 85.2861 21.0769 86.0117 20.7056C86.7373 20.3344 87.3786 19.8281 87.9017 19.2038L86.4167 17.8875C85.9779 18.3263 85.5223 18.6469 85.0498 18.8663C84.5942 19.0856 84.0542 19.2038 83.4129 19.2038C82.5861 19.2038 81.8942 18.9675 81.3204 18.4781C80.7467 18.0056 80.3754 17.2969 80.2404 16.4025H88.3573C88.3742 16.2844 88.3742 16.1494 88.3911 16.0313C88.3911 15.9131 88.4079 15.795 88.4079 15.6938C88.4079 14.9006 88.2898 14.1413 88.0704 13.4325C87.8511 12.69 87.5304 12.0656 87.0917 11.5256ZM80.2404 14.715C80.3586 13.8544 80.6623 13.1456 81.1686 12.6225C81.6748 12.0825 82.3329 11.8125 83.1092 11.8125C83.5311 11.8125 83.9023 11.8969 84.2229 12.0488C84.5436 12.2006 84.8304 12.4031 85.0667 12.6731C85.3029 12.9263 85.4886 13.23 85.6236 13.6013C85.7586 13.9556 85.8598 14.3438 85.8936 14.7488H80.2404V14.715Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M98.7863 11.5256C98.3475 10.9856 97.7906 10.5469 97.1325 10.2263C96.4744 9.90563 95.6981 9.75375 94.8206 9.75375C94.0275 9.75375 93.3019 9.90563 92.6438 10.2094C91.9856 10.5131 91.4287 10.9181 90.9394 11.4413C90.4669 11.9644 90.0787 12.5719 89.8256 13.2638C89.5556 13.9556 89.4375 14.6981 89.4375 15.4913V15.525C89.4375 16.3688 89.5894 17.145 89.8762 17.8538C90.1631 18.5625 90.5681 19.1531 91.0744 19.6594C91.5806 20.1656 92.1881 20.5538 92.88 20.8238C93.5719 21.1106 94.3144 21.2456 95.1075 21.2456C96.12 21.2456 96.9975 21.06 97.7231 20.6888C98.4487 20.3175 99.09 19.8113 99.6131 19.1869L98.1281 17.8706C97.6894 18.3094 97.2337 18.63 96.7612 18.8494C96.3056 19.0688 95.7656 19.1869 95.1244 19.1869C94.2975 19.1869 93.6056 18.9506 93.0319 18.4613C92.4581 17.9888 92.0869 17.28 91.9519 16.3856H100.069C100.086 16.2675 100.086 16.1325 100.102 16.0144C100.102 15.8963 100.119 15.7781 100.119 15.6769C100.119 14.8838 100.001 14.1244 99.7819 13.4156C99.5625 12.69 99.225 12.0656 98.7863 11.5256ZM91.935 14.715C92.0531 13.8544 92.3569 13.1456 92.8631 12.6225C93.3862 12.0825 94.0275 11.8125 94.8038 11.8125C95.2256 11.8125 95.5969 11.8969 95.9175 12.0488C96.2381 12.2006 96.525 12.4031 96.7612 12.6731C96.9975 12.9263 97.1831 13.23 97.3181 13.6013C97.4531 13.9556 97.5544 14.3438 97.5881 14.7488H91.935V14.715Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M109.991 11.6944C109.586 11.1712 109.08 10.7325 108.489 10.3275C107.899 9.93936 107.139 9.73686 106.245 9.73686C105.587 9.73686 104.962 9.85499 104.338 10.1081C103.714 10.3612 103.174 10.7325 102.701 11.2219C102.229 11.7112 101.857 12.3019 101.571 13.0275C101.284 13.7362 101.149 14.5462 101.149 15.4744V15.5081C101.149 16.4362 101.301 17.2462 101.587 17.955C101.874 18.6637 102.262 19.2712 102.735 19.7606C103.207 20.25 103.747 20.6212 104.372 20.8744C104.979 21.1275 105.621 21.2456 106.279 21.2456C107.173 21.2456 107.916 21.0431 108.523 20.6381C109.131 20.2331 109.637 19.7437 110.042 19.17L110.025 21.0094H112.573V5.75436H110.025V11.6944H109.991ZM110.025 15.5081C110.025 16.0312 109.941 16.5206 109.772 16.9425C109.603 17.3812 109.367 17.7525 109.08 18.0562C108.793 18.3769 108.456 18.6131 108.067 18.7819C107.679 18.9506 107.274 19.035 106.852 19.035C106.414 19.035 106.009 18.9506 105.637 18.7819C105.249 18.6131 104.912 18.3769 104.625 18.0731C104.338 17.7694 104.102 17.3981 103.933 16.9594C103.764 16.5206 103.68 16.0312 103.68 15.5081V15.4744C103.68 14.9344 103.764 14.445 103.933 14.0062C104.102 13.5675 104.321 13.1962 104.625 12.8925C104.912 12.5887 105.249 12.3525 105.637 12.2006C106.026 12.0487 106.431 11.9644 106.852 11.9644C107.274 11.9644 107.679 12.0487 108.067 12.2175C108.456 12.3862 108.793 12.6225 109.08 12.9262C109.367 13.23 109.603 13.6012 109.772 14.04C109.941 14.4787 110.025 14.9681 110.025 15.4912V15.5081Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M123.981 11.2219C123.491 10.7325 122.951 10.3781 122.344 10.125C121.736 9.87186 121.095 9.75374 120.437 9.75374C119.543 9.75374 118.8 9.95624 118.193 10.3444C117.602 10.7325 117.096 11.2219 116.691 11.8125L116.708 5.75436H114.159V21.0094H116.708V19.2881C117.096 19.8112 117.585 20.25 118.193 20.655C118.783 21.0431 119.543 21.2456 120.437 21.2456C121.095 21.2456 121.719 21.1275 122.344 20.8744C122.968 20.6212 123.508 20.25 123.998 19.7606C124.47 19.2712 124.858 18.6806 125.145 17.955C125.432 17.2462 125.567 16.4362 125.567 15.5081V15.4744C125.567 14.5462 125.415 13.7362 125.128 13.0275C124.841 12.3187 124.453 11.7112 123.981 11.2219ZM123.036 15.5081C123.036 16.0481 122.951 16.5375 122.783 16.9762C122.614 17.415 122.394 17.7862 122.108 18.09C121.821 18.3937 121.483 18.63 121.112 18.7987C120.724 18.9675 120.319 19.0519 119.897 19.0519C119.458 19.0519 119.053 18.9675 118.665 18.7987C118.277 18.63 117.939 18.3937 117.636 18.09C117.349 17.7862 117.113 17.415 116.944 16.9762C116.775 16.5375 116.691 16.0481 116.691 15.525V15.4744C116.691 14.9512 116.775 14.4619 116.944 14.04C117.113 13.6012 117.349 13.23 117.636 12.9262C117.923 12.6056 118.277 12.3694 118.665 12.2006C119.053 12.0319 119.458 11.9475 119.897 11.9475C120.336 11.9475 120.741 12.0319 121.112 12.2006C121.5 12.3694 121.838 12.6056 122.108 12.9094C122.394 13.2131 122.614 13.5844 122.783 14.0231C122.951 14.4619 123.036 14.9512 123.036 15.4744V15.5081Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M131.507 9.78748C130.595 9.78748 129.819 9.87185 129.178 10.0406C128.537 10.2093 127.912 10.4287 127.305 10.7156L127.997 12.7406C128.503 12.5381 128.992 12.3693 129.482 12.2343C129.971 12.1162 130.528 12.0487 131.152 12.0487C132.03 12.0487 132.705 12.2512 133.177 12.6562C133.65 13.0612 133.886 13.6518 133.886 14.4112V14.6137C133.447 14.4787 133.009 14.3606 132.536 14.2762C132.064 14.1918 131.507 14.1581 130.832 14.1581C130.19 14.1581 129.6 14.2256 129.043 14.3775C128.486 14.5294 128.014 14.7487 127.625 15.0525C127.22 15.3562 126.917 15.7275 126.697 16.1831C126.478 16.6387 126.36 17.1618 126.36 17.7862V17.82C126.36 18.3769 126.461 18.8662 126.68 19.2881C126.9 19.71 127.187 20.0643 127.541 20.3512C127.895 20.6381 128.317 20.8575 128.79 20.9925C129.262 21.1443 129.769 21.2118 130.292 21.2118C131.135 21.2118 131.844 21.06 132.418 20.7562C133.009 20.4525 133.481 20.0812 133.852 19.6256V20.9925H136.367V14.4618C136.367 12.9768 135.962 11.8462 135.169 11.0194C134.375 10.2094 133.16 9.78748 131.507 9.78748ZM133.92 17.01C133.92 17.3643 133.852 17.6681 133.7 17.955C133.549 18.2418 133.346 18.4781 133.076 18.6806C132.806 18.8831 132.502 19.035 132.148 19.1531C131.794 19.2712 131.405 19.3218 130.984 19.3218C130.376 19.3218 129.887 19.1868 129.465 18.9C129.06 18.6131 128.84 18.225 128.84 17.685V17.6512C128.84 17.1281 129.06 16.7062 129.515 16.4025C129.954 16.0987 130.579 15.9468 131.372 15.9468C131.861 15.9468 132.317 15.9806 132.755 16.065C133.194 16.1494 133.565 16.2506 133.886 16.3856V17.01H133.92Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M145.024 18.6637C144.568 18.9168 144.045 19.0518 143.437 19.0518C142.948 19.0518 142.509 18.9506 142.104 18.765C141.699 18.5793 141.345 18.3262 141.058 18.0056C140.771 17.685 140.535 17.3137 140.383 16.875C140.214 16.4362 140.147 15.9806 140.147 15.4912V15.4575C140.147 14.985 140.231 14.5293 140.383 14.0906C140.535 13.6518 140.754 13.2806 141.041 12.9768C141.328 12.6562 141.649 12.42 142.037 12.2175C142.425 12.0318 142.847 11.9306 143.319 11.9306C143.961 11.9306 144.501 12.0656 144.939 12.3187C145.378 12.5718 145.8 12.9093 146.188 13.3143L147.757 11.6268C147.234 11.0531 146.644 10.5975 145.952 10.2431C145.26 9.88872 144.399 9.71997 143.353 9.71997C142.526 9.71997 141.767 9.87185 141.058 10.1756C140.349 10.4793 139.742 10.9012 139.236 11.4243C138.729 11.9475 138.324 12.555 138.037 13.2637C137.751 13.9725 137.599 14.715 137.599 15.4912V15.525C137.599 16.3012 137.751 17.0437 138.037 17.7356C138.324 18.4275 138.729 19.035 139.236 19.5581C139.742 20.0812 140.349 20.4862 141.058 20.7731C141.75 21.0768 142.509 21.2287 143.336 21.2287C144.416 21.2287 145.294 21.0431 145.986 20.655C146.677 20.2668 147.302 19.7775 147.842 19.1531L146.323 17.6512C145.901 18.0731 145.462 18.4106 145.024 18.6637Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M159.215 9.95624H156.144L151.571 14.85V5.73749H149.023V20.9925H151.571V17.8369L153.124 16.2337L156.448 20.9925H159.367L154.828 14.4619L159.215 9.95624Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/presenter/multiple-choice-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 283
              },
              "end": {
                "line": 1,
                "column": 508
              }
            },
            "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "presenter/other-choice-text-field", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [1, 348], [1, 354]]]]], [], []], "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 362], [1, 378]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 386], [1, 395]]]]], [], []], "placeholder", ["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["choices", ["get", "choice.id", ["loc", [null, [1, 464], [1, 473]]]], "text"], [], ["loc", [null, [1, 431], [1, 481]]]]], [], ["loc", [null, [1, 408], [1, 482]]]], "rightToLeft", ["subexpr", "@mut", [["get", "rightToLeft", ["loc", [null, [1, 495], [1, 506]]]]], [], []]], ["loc", [null, [1, 305], [1, 508]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 508
              },
              "end": {
                "line": 1,
                "column": 701
              }
            },
            "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "presenter/multiple-choice-choice", [], ["tagName", "", "choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [1, 569], [1, 575]]]]], [], []], "role", ["subexpr", "if", [["get", "component.model.multipleAnswers", ["loc", [null, [1, 585], [1, 616]]]], "button", "radio"], [], ["loc", [null, [1, 581], [1, 634]]]], "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 642], [1, 658]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 666], [1, 675]]]]], [], []], "rightToLeft", ["subexpr", "@mut", [["get", "rightToLeft", ["loc", [null, [1, 688], [1, 699]]]]], [], []]], ["loc", [null, [1, 516], [1, 701]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 196
            },
            "end": {
              "line": 1,
              "column": 708
            }
          },
          "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "choice.isOther", ["loc", [null, [1, 289], [1, 303]]]]], [], 0, 1, ["loc", [null, [1, 283], [1, 708]]]]],
        locals: ["choice"],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 723
            },
            "end": {
              "line": 1,
              "column": 918
            }
          },
          "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "custom-icon-circled-info");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["presenter-question-tip theme-answer ", ["subexpr", "if", [["get", "component.showTips", ["loc", [null, [1, 809], [1, 827]]]], "show-tips"], [], ["loc", [null, [1, 804], [1, 841]]]]]]], ["content", "component.minMaxQuestionTip", ["loc", [null, [1, 883], [1, 914]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 925
            },
            "end": {
              "line": 1,
              "column": 1459
            }
          },
          "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "theme-question presenter-comment-header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'id');
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          morphs[3] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["presenter-comment-wrapper ", ["subexpr", "if", [["get", "component.showComment", ["loc", [null, [1, 1006], [1, 1027]]]], "show-comment", "js-excludeHeight"], [], ["loc", [null, [1, 1001], [1, 1063]]]]]]], ["attribute", "id", ["concat", ["question-comment-", ["get", "component.id", ["loc", [null, [1, 1091], [1, 1103]]]]]]], ["inline", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.id", ["loc", [null, [1, 1215], [1, 1227]]]], "commentHeader"], [], ["loc", [null, [1, 1179], [1, 1244]]]]], [], ["loc", [null, [1, 1155], [1, 1246]]]], ["inline", "presenter/text-area", [], ["classNames", "presenter-comment autogrowing answer-text", "value", ["subexpr", "@mut", [["get", "component.answer.comment", ["loc", [null, [1, 1333], [1, 1357]]]]], [], []], "rows", "2", "disabled", ["subexpr", "@mut", [["get", "disabledTextArea", ["loc", [null, [1, 1376], [1, 1392]]]]], [], []], "aria-describedby", ["subexpr", "concat", ["question-comment-", ["get", "component.id", ["loc", [null, [1, 1438], [1, 1450]]]]], [], ["loc", [null, [1, 1410], [1, 1451]]]]], ["loc", [null, [1, 1250], [1, 1453]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1472
          }
        },
        "moduleName": "presenter/templates/presenter/multiple-choice-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "multiple-choice-choices-wrapper");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "multiple-choice-choices");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element4, 'aria-labelledby');
        morphs[1] = dom.createAttrMorph(element4, 'role');
        morphs[2] = dom.createMorphAt(element4, 0, 0);
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createMorphAt(element3, 2, 2);
        return morphs;
      },
      statements: [["attribute", "aria-labelledby", ["concat", ["question-title-", ["get", "component.id", ["loc", [null, [1, 84], [1, 96]]]]]]], ["attribute", "role", ["subexpr", "if", [["get", "component.model.multipleAnswers", ["loc", [null, [1, 110], [1, 141]]]], "list", "radiogroup"], [], ["loc", [null, [1, 105], [1, 163]]]]], ["block", "each", [["subexpr", "filter-missing-piped-answers", [["get", "component.displayChoices", ["loc", [null, [1, 234], [1, 258]]]], ["get", "component", ["loc", [null, [1, 259], [1, 268]]]]], [], ["loc", [null, [1, 204], [1, 269]]]]], [], 0, null, ["loc", [null, [1, 196], [1, 717]]]], ["block", "if", [["get", "component.minMaxQuestionTip", ["loc", [null, [1, 729], [1, 756]]]]], [], 1, null, ["loc", [null, [1, 723], [1, 925]]]], ["block", "if", [["get", "component.model.commentEnabled", ["loc", [null, [1, 931], [1, 961]]]]], [], 2, null, ["loc", [null, [1, 925], [1, 1466]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});
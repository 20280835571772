define('presenter/mixins/theme-properties', ['exports', 'presenter/utils', 'presenter/mixins/themeable', 'presenter/utils/fonts', 'presenter/utils/layout', 'presenter/models/image'], function (exports, _utils, _themeable, _fonts, _layout, _image) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ThemeProperties, makeBackgroundStyleProperty, makeButtonColorDarkenedProperty;

  makeBackgroundStyleProperty = function makeBackgroundStyleProperty(urlAttr, overrideDisplay) {
    if (overrideDisplay == null) {
      overrideDisplay = '';
    }
    return function () {
      var backgroundColor, backgroundImage, buffer;
      backgroundColor = this._cssColor(this.get('themeable.backgroundColor'));
      buffer = "background-color: " + backgroundColor + " !important;";
      backgroundImage = this.get("themeable.backgroundImage." + urlAttr);
      if (!backgroundImage) {
        backgroundImage = this.get('themeable.backgroundImage.url');
      }
      if (!Ember.isEmpty(backgroundImage) && this.get('themeable.backgroundType') === 'image') {
        buffer += "background: url(" + backgroundImage + ") ";
        buffer += function () {
          switch (overrideDisplay || this.get('themeable.backgroundDisplay')) {
            case 'centered':
              return "no-repeat center " + backgroundColor + " !important;";
            case 'tiled':
              return "repeat " + backgroundColor + " !important;";
            case 'fit':
              return "no-repeat center " + backgroundColor + " !important; background-size: contain !important;";
            default:
              return "no-repeat center " + backgroundColor + " !important; background-size: cover !important;";
          }
        }.call(this);
      }
      return buffer;
    }.property('themeable.{backgroundImage,backgroundDisplay,backgroundColor,backgroundType}').readOnly();
  };

  makeButtonColorDarkenedProperty = function makeButtonColorDarkenedProperty(darkenPercentage) {
    return function () {
      return _utils.darkenColor(this.get('buttonColor'), darkenPercentage);
    }.property('buttonColor').readOnly();
  };

  ThemeProperties = Ember.Mixin.create({
    themeable: Ember.computed.oneWay('model'),
    backgroundStyle: makeBackgroundStyleProperty('url'),
    background256Style: makeBackgroundStyleProperty('url256'),
    background512Style: makeBackgroundStyleProperty('url512'),
    background768Style: makeBackgroundStyleProperty('url768'),
    backgroundPreviewStyle: makeBackgroundStyleProperty('url256', 'fill'),
    answerColor: Ember.computed.oneWay('themeable.answerColor'),
    buttonColor: Ember.computed.oneWay('themeable.buttonColor'),
    buttonColorContrastTextColor: Ember.computed.oneWay('themeable.buttonColorContrastTextColor'),
    questionColor: Ember.computed.oneWay('themeable.questionColor'),
    backgroundColor: Ember.computed.oneWay('themeable.backgroundColor'),
    backgroundImage: Ember.computed.oneWay('themeable.backgroundImage'),
    backgroundType: Ember.computed.oneWay('themeable.backgroundType'),
    font: Ember.computed.oneWay('themeable.font'),
    backgroundAdjustmentStyle: function () {
      var brightness, style;
      style = (brightness = this.get('themeable.backgroundImageBrightness')) && brightness !== 0 ? brightness > 0 ? "background-color: rgba(255, 255, 255, " + brightness / 100 + ");" : "background-color: rgba(0, 0, 0, " + -brightness / 100 + ");" : "";
      return style.htmlSafe();
    }.property('themeable.backgroundImageBrightness').readOnly(),
    buttonBorderColor: makeButtonColorDarkenedProperty(25),
    buttonBorderBottomColor: makeButtonColorDarkenedProperty(35),
    progressColor: Ember.computed.alias('themeable.buttonColor'),
    progressContrastColor: Ember.computed.alias('themeable.buttonColorContrastTextColor'),
    selectionColor: Ember.computed.alias('themeable.buttonColorContrastTextColor'),
    selectionBackgroundColor: Ember.computed.alias('themeable.buttonColor'),
    selectionBorderColor: Ember.computed.alias('themeable.buttonBorderColor'),
    fontStyle: function () {
      var fallbacks, fontIndex, font_css;
      if (!(font_css = _fonts.FONT_STACKS[this.get('themeable.font')])) {
        if ((fontIndex = _fonts.GOOGLE_FONTS.map(function (font) {
          return font.family;
        }).indexOf(this.get('themeable.font'))) !== -1) {
          fallbacks = _fonts.GOOGLE_FONTS[fontIndex].category === 'serif' ? "'Times New Roman', serif" : 'Arial, Helvetica, sans-serif';
          font_css = this.get('themeable.font') + ", " + fallbacks;
        }
      }
      return ("font-family: " + font_css).htmlSafe();
    }.property('themeable.font').readOnly(),
    previewStyle: function () {
      var buffer;
      buffer = this.get('themeable.backgroundDisplay') === 'tiled' ? this.get('background256Style') : this.get('backgroundPreviewStyle');
      buffer += "color: " + this._cssColor(this.get('themeable.questionColor')) + ";";
      return buffer.htmlSafe();
    }.property('themeable.{backgroundDisplay,questionColor}', 'background256Style', 'backgroundPreviewStyle').readOnly(),
    setDominantColor: function setDominantColor(color) {
      var textColor;
      textColor = _utils.contrastColor(color);
      this.set('model.backgroundColor', color);
      this.set('model.questionColor', textColor);
      return this.set('model.answerColor', textColor);
    },
    backgroundScaledStyle: function () {
      var size, width;
      if ((width = _layout.default.get('viewportWidthMax')) && this.get('themeable.backgroundDisplay') !== 'tiled') {
        size = _image.default.WIDTHS.find(function (findWidth) {
          return width <= findWidth;
        }) || '';
      } else {
        size = '';
      }
      return this.get("background" + size + "Style");
    }.property('themeable.backgroundDisplay', 'backgroundStyle', 'background256Style', 'background512Style', 'background768Style'),
    grays: function () {
      var color, grays, i, len, percent, ref;
      grays = [];
      ref = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];
      for (i = 0, len = ref.length; i < len; i++) {
        percent = ref[i];
        color = this._cssColor(this.get("themeable.backgroundColorContrastGray" + percent));
        grays.push(".theme-" + percent + "               { color:               " + color + "; !important}\n.theme-" + percent + "               { fill:                " + color + "; !important}\n.theme-background-" + percent + "    { background-color:    " + color + "; }\n.theme-border-" + percent + "        { border-color:        " + color + "; }\n.theme-border-top-" + percent + "    { border-top-color:    " + color + "; }\n.theme-border-right-" + percent + "  { border-right-color:  " + color + "; }\n.theme-border-bottom-" + percent + " { border-bottom-color: " + color + "; }\n.theme-border-left-" + percent + "   { border-left-color:   " + color + "; }");
      }
      return grays.join("\n");
    }.property('themeable.backgroundColorContrastGray100', 'themeable.backgroundColorContrastGray90', 'themeable.backgroundColorContrastGray80', 'themeable.backgroundColorContrastGray70', 'themeable.backgroundColorContrastGray60', 'themeable.backgroundColorContrastGray50', 'themeable.backgroundColorContrastGray40', 'themeable.backgroundColorContrastGray30', 'themeable.backgroundColorContrastGray20', 'themeable.backgroundColorContrastGray10'),
    _cssColor: _utils.colorToDOMHex
  });

  ThemeProperties.reopenForEach(_themeable.default.COLOR_PROPERTIES, function (property, ThemeableComponent) {
    return ThemeProperties[property + "Style"] = function () {
      var color, textColor;
      color = _utils.colorToDOMHex(this.get("model." + property));
      textColor = _utils.colorToDOMHex(this.get("model." + property + "ContrastTextColor"));
      return "background: " + color + "; color: " + textColor;
    }.property("model." + property);
  });

  exports.default = ThemeProperties;
});
define('presenter/components/presenter/form-question', ['exports', 'presenter/components/presenter/question', 'presenter/mixins/form-field-support', 'i18n-js'], function (exports, _question, _formFieldSupport, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend(_formFieldSupport.default, {
    legend: null,
    _skipHiddenForm: Ember.observer('component.isCurrent', 'component.formFieldsAllHiddenByMergeFields', function () {
      var disableHistory, disableNavigation, forceNavigagtion;
      if (this.get('component.isCurrent') && this.get('component.formFieldsAllHiddenByMergeFields')) {
        disableNavigation = false;
        disableHistory = true;
        forceNavigagtion = true;
        return this.send('next', disableNavigation, disableHistory, forceNavigagtion);
      }
    })
  });
});
define('presenter/components/presenter/date-input-field', ['exports', 'presenter/mixins/input-common'], function (exports, _inputCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(Ember.TextSupport, _inputCommon.default, {
    attributeBindings: ['type', 'value', 'min', 'max'],
    tagName: 'input',
    classNames: ['form-field', 'theme-text']
  });
});
define('presenter/controllers/application-tailwind', ['exports', 'presenter/tailwind/config/colors', 'presenter/tailwind/config/screens', 'presenter/tailwind/config/fonts', 'presenter/tailwind/config/text-sizes', 'presenter/tailwind/config/font-weights', 'presenter/tailwind/config/line-height', 'presenter/tailwind/config/letter-spacing', 'presenter/tailwind/config/border-widths', 'presenter/tailwind/config/border-radius', 'presenter/tailwind/config/width', 'presenter/tailwind/config/height', 'presenter/tailwind/config/min-width', 'presenter/tailwind/config/min-height', 'presenter/tailwind/config/max-width', 'presenter/tailwind/config/max-height', 'presenter/tailwind/config/padding', 'presenter/tailwind/config/margin', 'presenter/tailwind/config/negative-margin', 'presenter/tailwind/config/shadows', 'presenter/tailwind/config/z-index', 'presenter/tailwind/config/opacity', 'presenter/tailwind/config/svg-fill', 'presenter/tailwind/config/svg-stroke'], function (exports, _colors, _screens, _fonts, _textSizes, _fontWeights, _lineHeight, _letterSpacing, _borderWidths, _borderRadius, _width, _height, _minWidth, _minHeight, _maxWidth, _maxHeight, _padding, _margin, _negativeMargin, _shadows, _zIndex, _opacity, _svgFill, _svgStroke) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var modules = {
    colors: _colors.default,
    screens: _screens.default,
    fonts: _fonts.default,
    textSizes: _textSizes.default,
    fontWeights: _fontWeights.default,
    leading: _lineHeight.default,
    tracking: _letterSpacing.default,
    borderWidths: _borderWidths.default,
    borderRadius: _borderRadius.default,
    width: _width.default,
    height: _height.default,
    minWidth: _minWidth.default,
    minHeight: _minHeight.default,
    maxWidth: _maxWidth.default,
    maxHeight: _maxHeight.default,
    padding: _padding.default,
    margin: _margin.default,
    negativeMargin: _negativeMargin.default,
    shadows: _shadows.default,
    zIndex: _zIndex.default,
    opacity: _opacity.default,
    svgFill: _svgFill.default,
    svgStroke: _svgStroke.default
  };

  exports.default = Ember.Controller.extend({

    /*
      A module style is an object that looks like
       {
        module: 'border-radius',
        name: 'lg',
        value: '.5rem'
      }
    */
    moduleStyles: Ember.computed(function () {
      return Object.keys(modules).reduce(function (allModules, moduleName) {
        var hash = modules[moduleName];
        allModules[moduleName] = Object.keys(hash).map(function (key) {
          return {
            module: Ember.String.dasherize(moduleName),
            name: key,
            value: hash[key]
          };
        });

        return allModules;
      }, {});
    })

  });
});
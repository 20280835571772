define('presenter/models/salesforce-push', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforcePush;

  SalesforcePush = _data.Model.extend({
    status: (0, _data.attr)('string'),
    salesforceRecordIds: (0, _data.attr)('raw'),
    errorCode: (0, _data.attr)('string'),
    errorMessage: (0, _data.attr)('string'),
    createdRecordId: (0, _data.attr)('string'),
    failedAnswers: (0, _data.attr)('raw', {
      readOnly: true,
      serialize: false
    }),
    successfulAnswers: (0, _data.attr)('raw', {
      readOnly: true,
      serialize: false
    }),
    response: (0, _data.belongsTo)('response', {
      serialize: false
    }),
    isSalesforcePush: true,
    failedAnswerCount: function () {
      var failedAnswers, length;
      if ((failedAnswers = this.get('failedAnswers')) && (length = failedAnswers.length) && length > 0) {
        return failedAnswers[0];
      }
    }.property('failedAnswers'),
    successfulAnswerCount: function () {
      var length, successfulAnswers;
      if ((successfulAnswers = this.get('successfulAnswers')) && (length = successfulAnswers.length) && length > 0) {
        return successfulAnswers[0];
      }
    }.property('successfulAnswers')
  });

  SalesforcePush.reopenClass({
    resourceName: 'salesforce_push'
  });

  exports.default = SalesforcePush;
});
define("presenter/templates/bourbon-messaging-svg", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/bourbon-messaging-svg.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "257");
        dom.setAttribute(el1, "height", "232");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M56 111l-31 31v-32.204C10.59 105.851 0 92.662 0 77V34C0 15.222 15.222 0 34 0h92c18.778 0 34 15.222 34 34v43c0 18.778-15.222 34-34 34H56z");
        dom.setAttribute(el3, "fill", "#9B9B9B");
        dom.setAttribute(el3, "opacity", ".596");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M191.343 185L228 221.657v-38.939c14.805-5.38 25-19.54 25-35.718v-43c0-20.987-17.013-38-38-38h-92c-20.987 0-38 17.013-38 38v43c0 20.987 17.013 38 38 38h68.343zm12.157-53a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm-35 0a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm-34 0a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13z");
        dom.setAttribute(el3, "stroke", "#FFF");
        dom.setAttribute(el3, "stroke-width", "8");
        dom.setAttribute(el3, "fill", "#4A90E2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/template-category', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TemplateCategory;

  TemplateCategory = _data.Model.extend({
    name: (0, _data.attr)('string'),
    position: (0, _data.attr)('integer'),
    publishedTemplatesCount: (0, _data.attr)('integer'),
    templates: (0, _data.hasMany)('template')
  });

  TemplateCategory.reopenClass({
    resourceName: 'template_category'
  });

  exports.default = TemplateCategory;
});
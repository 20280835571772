define('presenter/models/component', ['exports', 'presenter/globals', 'presenter/data', 'presenter/mixins/change-confirmation', 'presenter/models/stats'], function (exports, _globals, _data, _changeConfirmation, _stats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Component, types;

  Component = _data.Model.extend(_changeConfirmation.default, (_Model$extend = {
    type: (0, _data.attr)('string'),
    title: (0, _data.attr)('string'),
    markup: (0, _data.attr)('string'),
    showMedia: (0, _data.attr)('boolean'),
    showTopMedia: (0, _data.attr)('boolean'),
    isQuestion: (0, _data.attr)('boolean'),
    description: (0, _data.attr)('string'),
    showDescription: (0, _data.attr)('boolean'),
    hasGridItems: (0, _data.attr)('boolean'),
    randomizeGridItems: (0, _data.attr)('boolean'),
    reverse: (0, _data.attr)('boolean', {
      defaultValue: false
    }),
    position: (0, _data.attr)('number', {
      positionIn: 'survey.components'
    }),
    alignment: (0, _data.attr)('string', {
      defaultValue: 'center'
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    choices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    answers: (0, _data.hasMany)('sluricane_answer', {
      serialize: false
    }),
    mediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    topMediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    stats: (0, _data.belongsTo)('stats', {
      readOnly: true,
      defaultValue: function defaultValue() {
        return _stats.default.create();
      }
    }),
    choicesStats: (0, _data.hasMany)('choice_stats', {
      readOnly: true
    }),
    gridItems: (0, _data.hasMany)('grid_item', {
      serialize: 'id'
    }),
    existingComponent: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    orderedRules: (0, _data.hasMany)('rule', {
      serialize: 'id'
    }),
    lhsConditions: (0, _data.hasMany)('logic_condition', {
      serialize: false
    }),
    translations: (0, _data.hasMany)('translation', {
      serialize: true
    }),
    targetActions: (0, _data.hasMany)('action', {
      serialize: false
    }),
    mappableFieldTypes: (0, _data.attr)('raw', {
      readOnly: true
    }),
    required: (0, _data.attr)('boolean'),
    randomize: (0, _data.attr)('boolean'),
    multiline: (0, _data.attr)('boolean'),
    multipleAnswers: (0, _data.attr)('boolean'),
    minAnswers: (0, _data.attr)('number'),
    maxAnswers: (0, _data.attr)('number'),
    commentable: (0, _data.attr)('boolean'),
    commentEnabled: (0, _data.attr)('boolean'),
    commentHeader: (0, _data.attr)('string'),
    canHideMergeFields: (0, _data.attr)('boolean'),
    shouldHideMergeFields: (0, _data.attr)('boolean'),
    hideImageLabels: (0, _data.attr)('boolean')
  }, _defineProperty(_Model$extend, 'reverse', (0, _data.attr)('boolean')), _defineProperty(_Model$extend, 'minimum', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'maximum', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'number', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'shape', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'startAt', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'includeNotApplicable', (0, _data.attr)('boolean')), _defineProperty(_Model$extend, 'minimumLabel', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'maximumLabel', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'middleLabel', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'startPosition', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'minimumValue', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'maximumValue', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'hideLabels', (0, _data.attr)('boolean')), _defineProperty(_Model$extend, 'salesforceObjectName', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'salesforceFields', (0, _data.attr)('raw')), _defineProperty(_Model$extend, 'salesforceObjectMapping', (0, _data.belongsTo)('salesforce_object_mapping', {
    serialize: false
  })), _defineProperty(_Model$extend, 'cmListId', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'buttonText', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'buttonUrl', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'redirectUrl', (0, _data.attr)('string')), _defineProperty(_Model$extend, 'previewUrl', (0, _data.attr)('string', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'embeddedAnswerKey', (0, _data.attr)('string', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'isEmbeddedAnswerable', (0, _data.attr)('boolean', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'isEmbeddedAuto', (0, _data.attr)('boolean', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'embeddedType', (0, _data.attr)('string', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'isEmailEmbeddable', (0, _data.attr)('boolean', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'rules', Ember.computed.alias('orderedRules')), _defineProperty(_Model$extend, 'choicesByCmListKey', Ember.computed.indexBy('choices', 'cmListKey')), _defineProperty(_Model$extend, 'friendlyType', function () {
    var friendlyType, type;
    if ((type = this.get('type')) && (friendlyType = Component.FRIENDLY_MAPPING[type])) {
      if (typeof friendlyType === 'function') {
        return friendlyType.apply(this);
      } else {
        return friendlyType;
      }
    } else {
      return '';
    }
  }.property('type', 'hasGridItems', 'salesforceObjectName')), _defineProperty(_Model$extend, 'iconClassBase', function () {
    var fileName, partial;
    if ((this.get('isQuestion') || this.get('isSectionBreak') || this.get('isExit')) && this.get('type')) {
      partial = function () {
        switch (_typeof(fileName = Component.ICON_MAPPING[this.get('type')])) {
          case 'function':
            return fileName.apply(this);
          case 'string':
            return fileName;
          default:
            return this.get('type').camelize();
        }
      }.call(this);
      return "svg/questionTypes/" + partial;
    }
  }.property('type', 'isQuestion', 'isSectionBreak', 'isExit', 'salesforceObjectName')), _defineProperty(_Model$extend, 'iconClass', function () {
    if (this.get('type') && this.get('hasGridItems')) {
      return this.get('iconClassBase') + 'Grid';
    } else {
      return this.get('iconClassBase');
    }
  }.property('iconClassBase', 'hasGridItems')), _defineProperty(_Model$extend, 'isUntyped', function () {
    return !this.get('type') || this.get('type') === 'Exit';
  }.property('type')), _defineProperty(_Model$extend, 'displayAsQuestion', function () {
    return !this.get('type') || this.get('isQuestion');
  }.property('isQuestion', 'type')), _defineProperty(_Model$extend, 'otherChoice', function () {
    return this.get('choices').findBy('isOther');
  }.property('choices.@each.isOther')), _defineProperty(_Model$extend, 'hasOther', Ember.computed.bool('otherChoice')), _defineProperty(_Model$extend, 'nextComponentNullOrExit', function () {
    return this === this.get('survey.orderedTypedQuestions').get('lastObject');
  }.property('survey.orderedTypedQuestions.[]')), _defineProperty(_Model$extend, 'questionNumber', function () {
    if (this.get('survey.hasCoverPage')) {
      return this.get('position');
    } else {
      return this.get('position') + 1;
    }
  }.property('position', 'survey.hasCoverPage')), _defineProperty(_Model$extend, 'formattedTitle', function () {
    var title, url;
    if (this.get('type') === 'Redirect') {
      return (url = this.get('redirectUrl')) && "Redirect to " + url || this.get('friendlyType');
    } else {
      title = this.get('survey.useRichText') && typeof Trix !== "undefined" && Trix !== null ? this.get('markup') ? Trix.Document.fromHTML(this.get('markup')).toString().trim() : void 0 : this.get('title');
      title || (title = this.get('friendlyType'));
      if (this.get('displayAsQuestion') || this.get('isSectionBreak')) {
        return this.get('questionNumber') + ". " + title;
      } else {
        return title;
      }
    }
  }.property('type', 'questionNumber', 'title', 'friendlyType', 'markup', 'survey.useRichText', 'displayAsQuestion', 'redirectUrl')), _defineProperty(_Model$extend, 'isValidConditionLhs', function () {
    return this.get('isQuestion') && !(this.get('isRank') || this.get('hasGridItems') || this.get('isSectionBreak'));
  }.property('isQuestion', 'isRank', 'isForm', 'hasGridItems', 'isSectionBreak')), _defineProperty(_Model$extend, 'commentableEnabled', Ember.computed.and('commentable', 'commentEnabled')), _defineProperty(_Model$extend, 'isExit', function () {
    return Component.EXIT_TYPES.contains(this.get('type'));
  }.property('type')), _defineProperty(_Model$extend, 'isText', function () {
    return this.get('type') === 'ShortAnswer';
  }.property('type')), _defineProperty(_Model$extend, 'isCentered', function () {
    return this.get('alignment') === 'center';
  }.property('alignment')), _defineProperty(_Model$extend, 'isFormType', Ember.computed.or('isForm', 'isSalesforceForm', 'isCampaignMonitorForm')), _defineProperty(_Model$extend, 'hasCustomValues', function () {
    return (this.get('isMultipleChoice') || this.get('isPictureChoice')) && this.get('choices').any(function (choice) {
      return Ember.isPresent(choice.get('value'));
    });
  }.property('isMultipleChoice', 'isPictureChoice', 'choices.@each.value')), _defineProperty(_Model$extend, 'hasGetEmbedLink', Ember.computed('type', function () {
    return Component.COMPONENT_TYPES_WITH_EMBED_LINK.indexOf(this.get('type')) !== -1 && !this.get('hasGridItems');
  })), _Model$extend));

  Component.TYPES = types = _globals.codeSettings.componentTypes;

  Component.EXIT_TYPES = _globals.codeSettings.componentExitTypes;

  Component.reopenForEach(types, function (type, Component) {
    return Component["is" + type] = Ember.computed.equal('type', type);
  });

  Component.FRIENDLY_MAPPING = _defineProperty({
    CoverPage: 'Title Page',
    ShortAnswer: 'Short Answer',
    Form: 'Form',
    List: 'List',
    PictureChoice: 'Picture Choice',
    Rank: 'Rank',
    Slider: 'Slider',
    NetPromoter: 'Net Promoter',
    FileUpload: 'Image Upload',
    Exit: 'Exit Page',
    ThankYouPage: 'Thank You Page',
    Redirect: 'URL Redirect',
    SectionBreak: 'Section Break',
    LikeDislike: 'Like/Dislike',
    CustomerEffort: 'Customer Effort',
    CustomerSatisfaction: 'Customer Satisfaction',
    CampaignMonitorForm: 'CM Form',
    KeyDriver: 'Key Driver',
    SalesforceForm: function SalesforceForm() {
      return this.get('salesforceObjectName') + " Form";
    },
    MultipleChoice: function MultipleChoice() {
      if (this.get('hasGridItems')) {
        return 'Multiple Choice Grid';
      } else {
        return 'Multiple Choice';
      }
    },
    Rating: function Rating() {
      if (this.get('hasGridItems')) {
        return 'Rating Grid';
      } else {
        return 'Rating';
      }
    }
  }, 'KeyDriver', function KeyDriver() {
    return 'Key Driver';
  });

  Component.ICON_MAPPING = {
    SalesforceForm: function SalesforceForm() {
      return "salesforce" + this.get('salesforceObjectName') + "Form";
    }
  };

  Component.RATING_SHAPES = _globals.codeSettings.componentRatingShapes;

  Component.SLURICANE_TYPE_MAP = {
    ShortAnswer: 'sa',
    Form: 'fo',
    SalesforceForm: 'fo',
    CampaignMonitorForm: 'fo',
    MultipleChoice: 'mc',
    PictureChoice: 'mc',
    Rank: 'ra',
    Rating: 'rt',
    Slider: 'sl',
    NetPromoter: 'np',
    FileUpload: 'fu',
    LikeDislike: 'ld',
    CustomerEffort: 'ce',
    CustomerSatisfaction: 'cs',
    KeyDriver: 'kd'
  };

  Component.LIKE_DISLIKE_SHAPES = _globals.codeSettings.componentLikeDislikeShapes;

  Component.CES_VALUES = _globals.codeSettings.componentCesValues;

  Component.CSAT_VALUES = _globals.codeSettings.componentCsatValues;

  Component.COMPONENT_TYPES_WITH_EMBED_LINK = ['NetPromoter', 'CustomerSatisfaction', 'CustomerEffort', 'MultipleChoice', 'LikeDislike', 'Rating', 'Form', 'CampaignMonitorForm', 'SalesforceForm', 'KeyDriver'];

  Component.reopenClass({
    resourceName: 'component'
  });

  exports.default = Component;
});
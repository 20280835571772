define('presenter/models/placeholder-exit-component', ['exports', 'presenter/models/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PlaceholderExitComponent;

  PlaceholderExitComponent = _component.default.extend({
    formattedTitle: 'END OF SURVEY',
    position: 999
  });

  PlaceholderExitComponent.reopenClass({
    resourceName: 'placeholder_exit_component'
  });

  exports.default = PlaceholderExitComponent;
});
define('presenter/models/form-field', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FormField;

  FormField = _data.Model.extend({
    text: (0, _data.attr)('string'),
    displayText: (0, _data.attr)('string'),
    choice: (0, _data.belongsTo)('choice', {
      serialize: 'id'
    }),
    valid: true
  });

  FormField.reopenClass({
    resourceName: 'form_field'
  });

  exports.default = FormField;
});
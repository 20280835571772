define('presenter/models/recipe-step', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RecipeStep;

  RecipeStep = _data.Model.extend({
    recipeStepTemplate: (0, _data.belongsTo)('recipe_step_template'),
    childRecipes: (0, _data.hasMany)('recipe'),
    completedAt: (0, _data.attr)('date'),
    objectId: (0, _data.attr)('number'),
    isCompleted: Ember.computed('completedAt', function () {
      return Ember.isPresent(this.get('completedAt'));
    })
  });

  RecipeStep.reopenClass({
    resourceName: 'recipe_step'
  });

  exports.default = RecipeStep;
});
define('presenter/initializers/development', ['exports', 'presenter/utils/development'], function (exports, _development) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'development',
    after: 'RESTless.Client',
    initialize: function initialize(container, app) {
      return (0, _development.init)(app);
    }
  };
});
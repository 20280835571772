define("presenter/templates/presenter/default-page-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 33
              },
              "end": {
                "line": 1,
                "column": 100
              }
            },
            "moduleName": "presenter/templates/presenter/default-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("iframe");
            dom.setAttribute(el1, "class", "DefaultPage-fullFrame");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 139
                },
                "end": {
                  "line": 1,
                  "column": 248
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "partial", ["svg/getfeedbackLogo_2021"], [], ["loc", [null, [1, 210], [1, 248]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 307
                },
                "end": {
                  "line": 1,
                  "column": 403
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "component.model.title", ["loc", [null, [1, 378], [1, 403]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 501
                  },
                  "end": {
                    "line": 1,
                    "column": 603
                  }
                },
                "moduleName": "presenter/templates/presenter/default-page-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "component.model.description", ["loc", [null, [1, 572], [1, 603]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 431
                },
                "end": {
                  "line": 1,
                  "column": 630
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "DefaultPage-description");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["block", "presenter/ref-link", [], ["refCode", ["subexpr", "@mut", [["get", "component.model.ref", ["loc", [null, [1, 531], [1, 550]]]]], [], []], "newWindow", ["subexpr", "@mut", [["get", "isIframed", ["loc", [null, [1, 561], [1, 570]]]]], [], []]], 0, null, ["loc", [null, [1, 501], [1, 626]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 637
                },
                "end": {
                  "line": 1,
                  "column": 760
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 717], [1, 736]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.video", ["loc", [null, [1, 743], [1, 758]]]]], [], []]], ["loc", [null, [1, 659], [1, 760]]]]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 791
                  },
                  "end": {
                    "line": 1,
                    "column": 921
                  }
                },
                "moduleName": "presenter/templates/presenter/default-page-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Sign up for GetFeedback");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 973
                    },
                    "end": {
                      "line": 1,
                      "column": 1100
                    }
                  },
                  "moduleName": "presenter/templates/presenter/default-page-component.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("See More examples");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 948
                  },
                  "end": {
                    "line": 1,
                    "column": 1127
                  }
                },
                "moduleName": "presenter/templates/presenter/default-page-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                return morphs;
              },
              statements: [["block", "presenter/ref-link", [], ["classNames", "DefaultPage-examplesLink ", "refCode", ["subexpr", "@mut", [["get", "component.model.ref", ["loc", [null, [1, 1042], [1, 1061]]]]], [], []], "newWindow", ["subexpr", "@mut", [["get", "isIframed", ["loc", [null, [1, 1072], [1, 1081]]]]], [], []]], 0, null, ["loc", [null, [1, 973], [1, 1123]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 771
                },
                "end": {
                  "line": 1,
                  "column": 1138
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "presenter/ref-link", [], ["classNames", "button DefaultPage-cta", "refCode", ["subexpr", "@mut", [["get", "component.model.ref", ["loc", [null, [1, 857], [1, 876]]]]], [], []], "newWindow", ["subexpr", "@mut", [["get", "isIframed", ["loc", [null, [1, 887], [1, 896]]]]], [], []]], 0, null, ["loc", [null, [1, 791], [1, 944]]]], ["block", "unless", [["get", "isEmbedded", ["loc", [null, [1, 958], [1, 968]]]]], [], 1, null, ["loc", [null, [1, 948], [1, 1138]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 100
              },
              "end": {
                "line": 1,
                "column": 1149
              }
            },
            "moduleName": "presenter/templates/presenter/default-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1, "class", "DefaultPage-header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1, "class", "DefaultPage-header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "presenter/ref-link", [], ["refCode", ["subexpr", "@mut", [["get", "component.model.ref", ["loc", [null, [1, 169], [1, 188]]]]], [], []], "newWindow", ["subexpr", "@mut", [["get", "isIframed", ["loc", [null, [1, 199], [1, 208]]]]], [], []]], 0, null, ["loc", [null, [1, 139], [1, 271]]]], ["block", "presenter/ref-link", [], ["refCode", ["subexpr", "@mut", [["get", "component.model.ref", ["loc", [null, [1, 337], [1, 356]]]]], [], []], "newWindow", ["subexpr", "@mut", [["get", "isIframed", ["loc", [null, [1, 367], [1, 376]]]]], [], []]], 1, null, ["loc", [null, [1, 307], [1, 426]]]], ["block", "if", [["get", "component.model.description", ["loc", [null, [1, 437], [1, 464]]]]], [], 2, null, ["loc", [null, [1, 431], [1, 637]]]], ["block", "unless", [["get", "isEmbedded", ["loc", [null, [1, 647], [1, 657]]]]], [], 3, null, ["loc", [null, [1, 637], [1, 771]]]], ["block", "unless", [["get", "isIE9", ["loc", [null, [1, 781], [1, 786]]]]], [], 4, null, ["loc", [null, [1, 771], [1, 1149]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 1156
            }
          },
          "moduleName": "presenter/templates/presenter/default-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "iframeUrlSet", ["loc", [null, [1, 39], [1, 51]]]]], [], 0, 1, ["loc", [null, [1, 33], [1, 1156]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1164
              },
              "end": {
                "line": 1,
                "column": 1269
              }
            },
            "moduleName": "presenter/templates/presenter/default-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["DefaultPage-icon theme-100 ", ["get", "component.model.iconClass", ["loc", [null, [1, 1236], [1, 1261]]]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1276
              },
              "end": {
                "line": 1,
                "column": 1369
              }
            },
            "moduleName": "presenter/templates/presenter/default-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "js-questionTitle");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "component.model.markup", ["loc", [null, [1, 1335], [1, 1363]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1493
                },
                "end": {
                  "line": 1,
                  "column": 1615
                }
              },
              "moduleName": "presenter/templates/presenter/default-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "component-description theme-question");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "component.model.description", ["loc", [null, [1, 1578], [1, 1609]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1369
              },
              "end": {
                "line": 1,
                "column": 1622
              }
            },
            "moduleName": "presenter/templates/presenter/default-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h1");
            dom.setAttribute(el1, "class", "component-title component-title-presenter theme-question js-questionTitle");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "component.model.title", ["loc", [null, [1, 1463], [1, 1488]]]], ["block", "if", [["get", "component.model.description", ["loc", [null, [1, 1499], [1, 1526]]]]], [], 0, null, ["loc", [null, [1, 1493], [1, 1622]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1156
            },
            "end": {
              "line": 1,
              "column": 1629
            }
          },
          "moduleName": "presenter/templates/presenter/default-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.iconClass", ["loc", [null, [1, 1170], [1, 1195]]]]], [], 0, null, ["loc", [null, [1, 1164], [1, 1276]]]], ["block", "if", [["get", "component.useRichText", ["loc", [null, [1, 1282], [1, 1303]]]]], [], 1, 2, ["loc", [null, [1, 1276], [1, 1629]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1636
          }
        },
        "moduleName": "presenter/templates/presenter/default-page-component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.model.isBranded", ["loc", [null, [1, 6], [1, 31]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 1636]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
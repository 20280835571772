define('presenter/models/campaign-url', ['exports', 'presenter/globals', 'presenter/data'], function (exports, _globals, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CampaignURL;

  CampaignURL = _data.Model.extend({
    string: (0, _data.attr)('string'),
    type: (0, _data.attr)('string'),
    previewUrl: Ember.computed('string', {
      get: function get(key) {
        return this.get('string');
      },
      set: function set(key, value) {
        return value;
      }
    })
  });

  CampaignURL.reopenForEach(_globals.codeSettings.campaignUrlMatchTypes, function (matchType, CampaignURL) {
    return CampaignURL["is" + matchType.camelize().titleize() + "Match"] = Ember.computed.equal('type', matchType);
  });

  CampaignURL.reopenClass({
    resourceName: 'campaign_url'
  });

  exports.default = CampaignURL;
});
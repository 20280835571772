define('presenter/models/image', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Image;

  Image = _data.Model.extend({
    url: (0, _data.attr)('string', {
      readOnly: true
    }),
    url256: (0, _data.attr)('string', {
      readOnly: true
    }),
    url512: (0, _data.attr)('string', {
      readOnly: true
    }),
    url768: (0, _data.attr)('string', {
      readOnly: true
    }),
    uploaded: (0, _data.attr)('boolean'),
    bucketType: (0, _data.attr)('string', {
      readOnly: true
    }),
    contentType: (0, _data.attr)('string'),
    ticket: (0, _data.attr)('raw', {
      readOnly: true
    }),
    sourceUrl: (0, _data.attr)('string')
  });

  Image.reopenClass({
    attach: function attach(record, attr, file) {
      var image;
      image = this.create();
      image.set('contentType', file.type);
      image.set('parentObject', record);
      return image.saveRecord().then(function (_this) {
        return function (image) {
          var formData, methodType, ticket;
          ticket = image.get('ticket');
          formData = new FormData();
          methodType = 'POST';
          if (ticket.provider === 'google') {
            formData.append('key', ticket.key);
            formData.append('AWSAccessKeyId', ticket.AWSAccessKeyId);
            formData.append('acl', ticket.acl);
            formData.append('policy', ticket.policy);
            formData.append('signature', ticket.signature);
            formData.append('Cache-Control', ticket['Cache-Control']);
            formData.append('Content-Type', image.get('contentType'));
            formData.append('file', file);
          } else {
            methodType = 'PUT';
            formData = file;
          }
          return Ember.$.ajax({
            type: methodType,
            url: ticket.url,
            data: formData,
            processData: false,
            contentType: false
          }).then(function () {
            image.set('uploaded', true);
            return image.saveRecord();
          }).then(function () {
            record.set(attr, image);
            return record;
          });
        };
      }(this));
    }
  });

  Image.WIDTHS = _globals.codeSettings.imageWidths;

  Image.reopenClass({
    resourceName: 'image'
  });

  exports.default = Image;
});
define('presenter/models/default-closed-page', ['exports', 'i18n-js', 'presenter/models/default-page'], function (exports, _i18nJs, _defaultPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _defaultPage.default.extend({
    type: 'ClosedPage',
    title: function () {
      return _i18nJs.default.t('presenter.default_pages.closed');
    }.property(),
    iconClass: 'icon-clock',
    ref: 'sc'
  });
});
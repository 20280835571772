define('presenter/components/select-field', ['exports', 'ember-group-by', 'i18n-js'], function (exports, _emberGroupBy, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'components/controls/select-field',
    classNames: ['Select'],
    classNameBindings: ['hasValue'],
    content: null,
    optionValuePath: null,
    optionLabelPath: null,
    optionEnabledPath: null,
    groupByPath: null,
    useOptGroup: Ember.computed.bool('groupByPath'),
    disabled: false,
    initGroupedContent: function () {
      var groupByPath;
      if (groupByPath = this.get('groupByPath')) {
        return Ember.defineProperty(this, 'groupedContent', (0, _emberGroupBy.default)('content', groupByPath));
      }
    }.on('init'),
    prompt: null,
    hasPrompt: Ember.computed.notEmpty('prompt'),
    value: null,
    hasValue: Ember.computed.notEmpty('value'),
    ariaLabel: Ember.computed('required', 'language', function () {
      if (this.get('required')) {
        if (this.get('prompt')) {
          return this.get('prompt') + ' ' + _i18nJs.default.t('presenter.alerts.required_field');
        } else {
          return _i18nJs.default.t('presenter.alerts.required_field');
        }
      } else {
        return this.get('prompt');
      }
    }),
    selection: Ember.computed('content.[]', 'value', 'optionValuePath', {
      get: function get() {
        var path;
        if ((path = this.get('_valuePath')) && this.get('value') && this.get('content')) {
          return this.get('content').findBy(path, this.get('value'));
        } else {
          return this.get('value');
        }
      },
      set: function set(key, value) {
        var path;
        if (Ember.isPresent(value)) {
          this.set('value', (path = this.get('_valuePath')) ? (typeof value.get === "function" ? value.get(path) : void 0) || value[path] : value);
        } else {
          this.set('value', null);
        }
        return value;
      }
    }),
    action: null,
    _sendAction: function () {
      return this.sendAction('action', this.get('selection'));
    }.observes('selection'),
    _valuePath: function () {
      var ref;
      return (ref = this.get('optionValuePath')) != null ? ref.replace(/^content\.?/, '') : void 0;
    }.property('optionValuePath'),
    _initSelection: function () {
      return Ember.run.scheduleOnce('afterRender', function (_this) {
        return function () {
          if (_this.get('content')) {
            return _this.send('updateSelection', true);
          }
        };
      }(this));
    }.on('didInsertElement').observes('content'),
    actions: {
      updateSelection: function updateSelection(initalRun) {
        var selectedIndex;
        if (initalRun == null) {
          initalRun = false;
        }
        selectedIndex = this.$('select')[0].selectedIndex;
        if (this.get('hasPrompt')) {
          selectedIndex -= 1;
        }
        return this.set('selection', this.get('content').objectAt(selectedIndex));
      }
    }
  });
});
define('presenter/mixins/touchable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    touchable: null,
    touchStartDelay: 0,
    scrollable: null,
    disableTouch: false,
    didInsertElement: function didInsertElement() {
      var enableTouch;
      this._super();
      this._touchStart = function (_this) {
        return function (event) {
          var delay, mousedown;
          mousedown = function mousedown() {
            if (_this.get('disableTouch')) {
              return;
            }
            _this._simulateMouseEvent(event, 'mousedown');
            _this._mouseDown = true;
            return _this._delayTimer = null;
          };
          if (delay = _this.get('touchStartDelay')) {
            return _this._delayTimer = setTimeout(mousedown, delay);
          } else {
            return mousedown();
          }
        };
      }(this);
      this._touchMove = function (_this) {
        return function (event) {
          if (_this.get('disableTouch')) {
            return;
          }
          if (_this._delayTimer) {
            clearTimeout(_this._delayTimer);
          }
          if (_this._mouseDown) {
            return _this._simulateMouseEvent(event, 'mousemove');
          }
        };
      }(this);
      this._touchEnd = function (_this) {
        return function (event) {
          if (_this.get('disableTouch')) {
            return;
          }
          if (_this._delayTimer) {
            return clearTimeout(_this._delayTimer);
          } else if (_this._mouseDown) {
            _this._simulateMouseEvent(event, 'mouseup');
            return _this._mouseDown = false;
          }
        };
      }(this);
      this.$(this.get('touchable')).on('touchstart', this._touchStart).on('touchmove', this._touchMove).on('touchend', this._touchEnd).on('touchcancel', this._touchEnd);
      if (Modernizr.ios && !Ember.isNone(this.get('scrollable'))) {
        if (!this.get('touchStartDelay')) {
          this.set('touchStartDelay', 10);
        }
        enableTouch = debounce(function (_this) {
          return function () {
            return _this.set('disableTouch', false);
          };
        }(this), 100);
        return this.$(this.get('scrollable')).on('scroll', this._scrollHandler = function (_this) {
          return function () {
            _this.set('disableTouch', true);
            return enableTouch();
          };
        }(this));
      }
    },
    willRemoveElement: function willRemoveElement() {
      this._super();
      this.$(this.get('touchable')).off('touchstart', this._touchStart).off('touchmove', this._touchMove).off('touchend', this._touchEnd).off('touchcancel', this._touchEnd);
      return this.$(this.get('scrollable')).off('scroll', this._scrollHandler);
    },
    _simulateMouseEvent: function _simulateMouseEvent(event, type) {
      var simulatedEvent, touch;
      if (event.originalEvent.touches.length > 1) {
        return;
      }
      touch = event.originalEvent.changedTouches[0];
      simulatedEvent = document.createEvent('MouseEvent');
      simulatedEvent.initMouseEvent(type, true, true, window, 1, touch.screenX, touch.screenY, touch.clientX, touch.clientY, false, false, false, false, 0, null);
      touch.target.dispatchEvent(simulatedEvent);
      return event.preventDefault();
    }
  });
});
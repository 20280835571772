define("presenter/templates/presenter/rank-choice", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 389
          }
        },
        "moduleName": "presenter/templates/presenter/rank-choice.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "presenter-rank-choice theme-border-20 theme-background-screen needsclick");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "custom-icon-arrow-combo choice-leading-icon theme-90 needsclick");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "choice-text theme-answer needsclick");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(". ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'aria-labelledby');
        morphs[1] = dom.createMorphAt(element1, 0, 0);
        morphs[2] = dom.createMorphAt(element1, 2, 2);
        return morphs;
      },
      statements: [["attribute", "aria-labelledby", ["concat", [["subexpr", "i18n", [["get", "presenter.labels.rank.instructions", ["loc", [null, [1, 40], [1, 74]]]]], [], ["loc", [null, [1, 33], [1, 76]]]]]]], ["content", "position", ["loc", [null, [1, 287], [1, 299]]]], ["inline", "merge-response-values", [["subexpr", "translated-properties", ["choices", ["get", "choice.id", ["loc", [null, [1, 358], [1, 367]]]], "text"], [], ["loc", [null, [1, 325], [1, 375]]]]], [], ["loc", [null, [1, 301], [1, 377]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/mixins/rating-component-helper', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['scaleClass', 'notApplicableClass'],
    notApplicableClass: function () {
      if (this.get('component.includeNotApplicable') || this.get('component.hasNotApplicableAnswer')) {
        return 'include-not-applicable';
      } else {
        return '';
      }
    }.property('component.{includeNotApplicable,hasNotApplicableAnswer}'),
    scaleClass: function () {
      var baseScale, i, ref, ref1, results, scale, showingNotApplicable;
      showingNotApplicable = this.get('component.includeNotApplicable') || this.get('component.hasNotApplicableAnswer');
      baseScale = this.get('component.scale.length');
      if (baseScale === void 0) {
        baseScale = function () {
          results = [];
          for (var i = ref = this.get('component.number'), ref1 = this.get('startAt'); ref <= ref1 ? i <= ref1 : i >= ref1; ref <= ref1 ? i++ : i--) {
            results.push(i);
          }
          return results;
        }.apply(this).length;
      }
      scale = baseScale + (showingNotApplicable ? 1 : 0);
      return "with-scale" + scale;
    }.property('component.{scale.length,number,startAt,includeNotApplicable,hasNotApplicableAnswer}'),
    positionLabels: function positionLabels() {
      var $labels, width;
      if (!($labels = this.$('.js-labels'))) {
        return;
      }
      width = this.ratingScaleWidth() + $labels.cssFloat('padding-left') + $labels.cssFloat('padding-right');
      $labels.outerWidth(width);
      return $labels.addClass('is-positioned');
    },
    ratingScaleWidth: function ratingScaleWidth() {
      var $firstButton, $lastButton;
      $firstButton = this.$('.js-rating-button:last');
      $lastButton = this.$('.js-rating-button:first');
      return $lastButton.offset().left + $lastButton.outerWidth() - $firstButton.offset().left;
    },
    notApplicable: Ember.computed('response.model.language', function () {
      this.get('response.model.language');
      return _i18nJs.default.t('shared.labels.not_applicable');
    }),
    onResize: function onResize() {
      this._super();
      return this.positionLabels();
    }
  });
});
define('presenter/models/media-object', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MediaObject;

  MediaObject = _data.Model.extend({
    type: (0, _data.attr)('string'),
    videoUrl: (0, _data.attr)('string'),
    image: (0, _data.belongsTo)('image', {
      serialize: 'id'
    }),
    frame: (0, _data.attr)('boolean'),
    nativeWidth: (0, _data.attr)('number'),
    nativeHeight: (0, _data.attr)('number'),
    canvasWidth: (0, _data.attr)('number'),
    canvasHeight: (0, _data.attr)('number'),
    transform: (0, _data.attr)('raw'),
    isVideo: Ember.computed.equal('type', 'MediaVideo'),
    isImage: Ember.computed.equal('type', 'MediaImage'),
    scale: Ember.computed('transform.[]', {
      get: function get(key) {
        return this.get('transform.firstObject');
      },
      set: function set(key, value) {
        var transform;
        this.set('transform', transform = this.get('transform').copy());
        transform.replaceAt(0, value);
        transform.replaceAt(3, value);
        return value;
      }
    }),
    offset: Ember.computed('transform.[]', {
      get: function get(key) {
        var transform;
        transform = this.get('transform');
        return {
          x: transform.objectAt(4),
          y: transform.objectAt(5)
        };
      },
      set: function set(key, value) {
        var transform;
        transform = this.get('transform');
        this.set('transform', transform = this.get('transform').copy());
        transform.replaceAt(4, value.x);
        transform.replaceAt(5, value.y);
        return value;
      }
    })
  });

  MediaObject.reopenClass({
    resourceName: 'media_object'
  });

  exports.default = MediaObject;
});
define("presenter/templates/presenter/rating-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 409
              },
              "end": {
                "line": 1,
                "column": 825
              }
            },
            "moduleName": "presenter/templates/presenter/rating-question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "Rating-labels js-labels");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "Rating-minLabel js-label theme-question");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "Rating-maxLabel js-label theme-question");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 603], [1, 621]]]], "minimumLabel"], [], ["loc", [null, [1, 567], [1, 637]]]]], [], ["loc", [null, [1, 544], [1, 638]]]]], [], ["loc", [null, [1, 526], [1, 640]]]], ["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 776], [1, 794]]]], "maximumLabel"], [], ["loc", [null, [1, 740], [1, 810]]]]], [], ["loc", [null, [1, 717], [1, 811]]]]], [], ["loc", [null, [1, 699], [1, 813]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 381
            },
            "end": {
              "line": 1,
              "column": 832
            }
          },
          "moduleName": "presenter/templates/presenter/rating-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.hasLabels", ["loc", [null, [1, 415], [1, 434]]]]], [], 0, null, ["loc", [null, [1, 409], [1, 832]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 849
          }
        },
        "moduleName": "presenter/templates/presenter/rating-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "ratingWrapper");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "Rating-wrapper");
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "role", "heading");
        dom.setAttribute(el2, "aria-level", "1");
        dom.setAttribute(el2, "for", "ratingWrapper");
        dom.setAttribute(el2, "id", "ratingWrapperLabel");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [0]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element1, 'min');
        morphs[1] = dom.createAttrMorph(element1, 'max');
        morphs[2] = dom.createAttrMorph(element1, 'value');
        morphs[3] = dom.createAttrMorph(element1, 'aria-valuemin');
        morphs[4] = dom.createAttrMorph(element1, 'aria-valuemax');
        morphs[5] = dom.createAttrMorph(element2, 'aria-label');
        morphs[6] = dom.createMorphAt(element1, 1, 1);
        morphs[7] = dom.createMorphAt(element1, 2, 2);
        return morphs;
      },
      statements: [["attribute", "min", ["get", "component.model.startAt", ["loc", [null, [1, 45], [1, 68]]]]], ["attribute", "max", ["get", "component.model.number", ["loc", [null, [1, 77], [1, 99]]]]], ["attribute", "value", ["get", "value", ["loc", [null, [1, 110], [1, 115]]]]], ["attribute", "aria-valuemin", ["get", "component.model.startAt", ["loc", [null, [1, 134], [1, 157]]]]], ["attribute", "aria-valuemax", ["get", "component.model.number", ["loc", [null, [1, 176], [1, 198]]]]], ["attribute", "aria-label", ["get", "component.ariaLabel", ["loc", [null, [1, 274], [1, 293]]]]], ["inline", "partial", ["survey/rating_scale"], [], ["loc", [null, [1, 348], [1, 381]]]], ["block", "if", [["get", "component.showLabels", ["loc", [null, [1, 387], [1, 407]]]]], [], 0, null, ["loc", [null, [1, 381], [1, 839]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
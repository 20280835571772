define("presenter/templates/svg/_live_agent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_live_agent.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createElement("defs");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "a");
        dom.setAttribute(el3, "d", "M0 0h20v19.992H0z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3, "cx", "50");
        dom.setAttribute(el3, "cy", "50");
        dom.setAttribute(el3, "r", "40");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M68.823 52.316a12.86 12.86 0 0 1-2.311 7.345l3.178 7.755-.292.12a.385.385 0 0 1-.506.5l-8.366-3.541a14.409 14.409 0 0 1-5.615 1.138c-3.348 0-6.42-1.132-8.82-3.019 9.469-.422 17.074-7.969 17.074-17.298 0-1.397-.17-2.754-.492-4.053 3.71 2.391 6.15 6.45 6.15 11.053zM45.253 32c7.684 0 13.912 5.96 13.912 13.316 0 7.353-6.228 13.317-13.912 13.317-2 0-3.895-.413-5.614-1.138l-8.074 3.417a.7.7 0 0 1-.92-.91l3.008-7.34a12.86 12.86 0 0 1-2.311-7.346C31.342 37.961 37.569 32 45.253 32z");
        dom.setAttribute(el3, "fill", "#FFF");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/default-completed-page', ['exports', 'i18n-js', 'presenter/models/default-page'], function (exports, _i18nJs, _defaultPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _defaultPage.default.extend({
    type: 'CompletedPage',
    title: function () {
      return _i18nJs.default.t('presenter.default_pages.completed');
    }.property(),
    iconClass: 'icon-fa icon-ok-circle',
    ref: 'su'
  });
});
define("presenter/templates/components/application-modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 237
              },
              "end": {
                "line": 1,
                "column": 359
              }
            },
            "moduleName": "presenter/templates/components/application-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "modal-fullscreen-dismiss");
            var el2 = dom.createTextNode("×");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["hideModal"], ["on", "click"], ["loc", [null, [1, 276], [1, 309]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 359
              },
              "end": {
                "line": 1,
                "column": 459
              }
            },
            "moduleName": "presenter/templates/components/application-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "icon-circled-cross modal-dismiss");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["hideModal"], ["on", "click"], ["loc", [null, [1, 375], [1, 408]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 205
            },
            "end": {
              "line": 1,
              "column": 466
            }
          },
          "moduleName": "presenter/templates/components/application-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "modalOptions.fullscreen", ["loc", [null, [1, 243], [1, 266]]]]], [], 0, 1, ["loc", [null, [1, 237], [1, 466]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 479
          }
        },
        "moduleName": "presenter/templates/components/application-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "fas fa-times icon-fa modal-close");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createElementMorph(element3);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["using-new-app modal-content js-modalContent ", ["get", "contentClassName", ["loc", [null, [1, 58], [1, 74]]]], " ", ["get", "modalOptions.className", ["loc", [null, [1, 79], [1, 101]]]]]]], ["element", "action", ["hideModal"], ["on", "click"], ["loc", [null, [1, 108], [1, 141]]]], ["inline", "outlet", ["modal"], [], ["loc", [null, [1, 187], [1, 205]]]], ["block", "if", [["get", "modalOptions.dismissable", ["loc", [null, [1, 211], [1, 235]]]]], [], 0, null, ["loc", [null, [1, 205], [1, 473]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define("presenter/templates/svg/_getfeedback_logo", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_getfeedback_logo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 1400 560");
        dom.setAttribute(el1, "enable-background", "new 0 0 1400 560");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M266.6,373.6c23.2,0,41.7-10.9,41.7-37.4v-10.9c0-4.5,0.5-8.5,0.5-8.5h-0.5c-7.1,13.3-19.2,20.8-37.6,20.8\n		c-32,0-53-25.3-53-61.1c0-35.7,19.6-60.1,51.6-60.1c31.5,0,39.3,19.9,39.3,19.9h0.5c0,0-0.2-1.4-0.2-4v-13h15.6v116.2\n		c0,37.9-27.9,52.5-57,52.5c-13.7,0-27.5-3.5-39.1-9.5l6.2-13.3C234.5,365.4,249.1,373.6,266.6,373.6z M308.5,276.6\n		c0-34.8-16.6-45.4-37.6-45.4c-23.2,0-36.7,16.8-36.7,45.2c0,28.4,15.4,46.9,39.1,46.9C292,323.2,308.5,311.6,308.5,276.6z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M391.8,216.5c32.9,0,49.7,25.3,49.7,54.9c0,2.8-0.5,8.3-0.5,8.3h-91.4c0.7,30.5,21.5,47.1,46.2,47.1\n		c21.8,0,36.2-14.4,36.2-14.4l7.3,12.3c0,0-17.3,16.6-44,16.6c-35.5,0-62.2-25.6-62.2-62.2C333.2,239.9,359.7,216.5,391.8,216.5z\n		 M425.2,266.4c-0.9-24.9-16.1-36.4-33.8-36.4c-19.9,0-37.2,12.5-40.9,36.4H425.2z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M455,234.2h-15.6v-13.5h15.9v-34.3h15.9v34.3H501v13.5h-29.8v59.2c0,27.9,17.8,31.5,26.7,31.5c3.3,0,5.4-0.5,5.4-0.5v14.4\n		c0,0-2.6,0.5-6.9,0.5c-13.7,0-41.4-4.5-41.4-44.3V234.2z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M519.3,244.7h-14.9v-23.9h14.9v-5.4c0-39.5,32.4-44.7,49.2-44.7c6.2,0,10.2,0.7,10.2,0.7v25.6c0,0-2.6-0.5-6.2-0.5\n		c-8.5,0-23.2,2.1-23.2,19.6v4.7h25.8v23.9h-25.8v94.9h-30.1V244.7z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M635.6,216c34.8,0,53.5,25.3,53.5,57.3c0,3.6-0.7,11.6-0.7,11.6h-83.3c1.9,21.1,18.2,32,36.2,32\n		c19.4,0,34.8-13.3,34.8-13.3l11.6,21.5c0,0-18.5,17.3-48.5,17.3c-40,0-64.9-28.9-64.9-63.2C574.3,242.1,599.6,216,635.6,216z\n		 M658.8,264.3c-0.5-16.3-10.7-25.1-23.9-25.1c-15.4,0-26,9.5-28.9,25.1H658.8z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M753.8,216c34.8,0,53.5,25.3,53.5,57.3c0,3.6-0.7,11.6-0.7,11.6h-83.3c1.9,21.1,18.2,32,36.2,32\n		c19.4,0,34.8-13.3,34.8-13.3l11.6,21.5c0,0-18.5,17.3-48.5,17.3c-40,0-64.9-28.9-64.9-63.2C692.5,242.1,717.8,216,753.8,216z\n		 M777,264.3c-0.5-16.3-10.7-25.1-23.9-25.1c-15.4,0-26,9.5-28.9,25.1H777z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M865.6,216c26.5,0,34.8,15.4,34.8,15.4h0.5c0,0-0.2-3.1-0.2-6.9v-53h30.1v168h-28.4v-9.9c0-4.5,0.5-7.8,0.5-7.8h-0.5\n		c0,0-10.2,20.6-37.9,20.6c-32.7,0-53.5-25.8-53.5-63.2C811,240.9,833.7,216,865.6,216z M871.3,317.1c15.6,0,30.3-11.4,30.3-38.1\n		c0-18.7-9.9-37.2-30.1-37.2c-16.6,0-30.3,13.5-30.3,37.4C841.3,302.2,853.6,317.1,871.3,317.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M937.8,171.5h30.1v51.8c0,5.9-0.5,10.2-0.5,10.2h0.5c0,0,10.7-17.5,36.2-17.5c32.7,0,53.5,25.8,53.5,63.2\n		c0,38.3-23.4,63.2-55.4,63.2c-25.1,0-36-18-36-18h-0.5c0,0,0.5,3.5,0.5,8.3v6.9h-28.4V171.5z M997,317.1\n		c16.6,0,30.3-13.5,30.3-37.4c0-23-12.3-37.9-30.1-37.9c-15.6,0-30.3,11.4-30.3,38.1C967,298.6,976.9,317.1,997,317.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M1129.8,263.8h4v-1.7c0-15.9-9.9-21.3-23.2-21.3c-17.3,0-33.1,11.6-33.1,11.6l-11.4-21.5c0,0,18.7-14.9,47.3-14.9\n		c31.7,0,50.2,17.5,50.2,48v75.5h-27.7v-9.9c0-4.7,0.5-8.5,0.5-8.5h-0.5c0.2,0-9.2,21.3-36.7,21.3c-21.5,0-40.9-13.5-40.9-37.6\n		C1058.3,266.4,1110.1,263.8,1129.8,263.8z M1106.8,319c15.9,0,27.2-17,27.2-31.7v-2.8h-5.2c-15.4,0-40.2,2.1-40.2,18.9\n		C1088.6,311.4,1094.5,319,1106.8,319z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M1233,214.8c31.5,0,46.4,18,46.4,18l-13.3,20.6c0,0-12.5-13-31.5-13c-22.2,0-36.7,16.8-36.7,37.4\n		c0,20.4,14.7,37.9,37.6,37.9c20.4,0,35.5-15.4,35.5-15.4l11.6,21.3c0,0-17.3,19.6-49.7,19.6c-40,0-65.6-28.2-65.6-63.2\n		C1167.4,243.7,1193,214.8,1233,214.8z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "enable-background", "new    ");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M1287.8,170.3h30.1v89.9h14.9l29.1-42.6h33.4l-38.1,54v0.5l42.8,66.3h-34.6l-32-52.8h-15.6v52.8h-30.1V170.3z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("polygon");
        dom.setAttribute(el2, "points", "103.2,202.9 119.9,254.6 174.2,254.6 130.3,286.5 147.1,338.2 103.2,306.2 59,338.2 75.8,286.5 31.9,254.6\n	86.2,254.6 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M103.1,188.5c-49.7,0-90,40.3-90,90s40.3,90,90,90s90-40.3,90-90S152.8,188.5,103.1,188.5z M103.1,359\n	c-44.5,0-80.5-36-80.5-80.5c0-44.5,36-80.5,80.5-80.5c44.5,0,80.5,36,80.5,80.5C183.6,323,147.5,359,103.1,359z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/controllers/presenter/like-dislike', ['exports', 'presenter/controllers/presenter/scale', 'presenter/mixins/like-dislike-helper'], function (exports, _scale, _likeDislikeHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scale.default.extend(_likeDislikeHelper.default, {
    actions: {
      select: function select(value) {
        this.get('answer').set('number', value);
        this.send('autoAdvance');
        return false;
      }
    }
  });
});
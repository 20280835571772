define("presenter/templates/svg/recipes/support", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/recipes/support.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "width", "24");
        dom.setAttribute(el1, "height", "24");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        var el2 = dom.createElement("g");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M12,0C5.935,0,1,4.935,1,11v6c0,1.654,1.346,3,3,3h3c0.552,0,1-0.448,1-1v-7c0-0.552-0.448-1-1-1H3 c0-4.962,4.038-9,9-9s9,4.038,9,9h-4c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h3c0.352,0,0.686-0.072,1-0.184 C21,21.261,20.004,22,19,22h-5c-0.552,0-1,0.448-1,1s0.448,1,1,1h5c2.206,0,4-1.794,4-4v-9C23,4.935,18.065,0,12,0z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
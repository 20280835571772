define("presenter/templates/components/controls/image-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 56
            },
            "end": {
              "line": 1,
              "column": 153
            }
          },
          "moduleName": "presenter/templates/components/controls/image-editor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "image-canvas", [], ["classNames", "ImageEditor-canvas js-canvas", "media", ["subexpr", "@mut", [["get", "media", ["loc", [null, [1, 146], [1, 151]]]]], [], []]], ["loc", [null, [1, 83], [1, 153]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 153
            },
            "end": {
              "line": 1,
              "column": 248
            }
          },
          "moduleName": "presenter/templates/components/controls/image-editor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "ImageEditor-image");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'src');
          morphs[1] = dom.createAttrMorph(element3, 'style');
          return morphs;
        },
        statements: [["attribute", "src", ["get", "media.image.url", ["loc", [null, [1, 172], [1, 187]]]]], ["attribute", "style", ["get", "fixedConstraintsStyle", ["loc", [null, [1, 198], [1, 219]]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 274
              },
              "end": {
                "line": 1,
                "column": 465
              }
            },
            "moduleName": "presenter/templates/components/controls/image-editor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ImageCropper-dragArea");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ImageCropper-cropHandle");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 255
            },
            "end": {
              "line": 1,
              "column": 483
            }
          },
          "moduleName": "presenter/templates/components/controls/image-editor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "image-cropper", [], ["canvasHeight", ["subexpr", "@mut", [["get", "media.canvasHeight", ["loc", [null, [1, 304], [1, 322]]]]], [], []], "maxHeight", ["subexpr", "@mut", [["get", "media.canvasWidth", ["loc", [null, [1, 333], [1, 350]]]]], [], []], "pixelHeightRatio", ["subexpr", "@mut", [["get", "canvasScale", ["loc", [null, [1, 368], [1, 379]]]]], [], []]], 0, null, ["loc", [null, [1, 274], [1, 483]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 960
                },
                "end": {
                  "line": 1,
                  "column": 1018
                }
              },
              "moduleName": "presenter/templates/components/controls/image-editor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon-fa icon-ban-circle");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1018
                },
                "end": {
                  "line": 1,
                  "column": 1051
                }
              },
              "moduleName": "presenter/templates/components/controls/image-editor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon-plus");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 897
              },
              "end": {
                "line": 1,
                "column": 1068
              }
            },
            "moduleName": "presenter/templates/components/controls/image-editor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("frame");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["toggleFrame"], ["on", "click"], ["loc", [null, [1, 924], [1, 959]]]], ["block", "if", [["get", "media.frame", ["loc", [null, [1, 966], [1, 977]]]]], [], 0, 1, ["loc", [null, [1, 960], [1, 1058]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 496
            },
            "end": {
              "line": 1,
              "column": 1086
            }
          },
          "moduleName": "presenter/templates/components/controls/image-editor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ImageEditor-controls");
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "ImageEditor-layoutOptions");
          var el3 = dom.createElement("li");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "icon-fa icon-bullseye");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("center");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ImageEditor-scaleControl");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "ImageEditor-toggleOptions");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var element2 = dom.childAt(element1, [0, 0]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["centerImage"], ["on", "click"], ["loc", [null, [1, 599], [1, 634]]]], ["inline", "slider-input", [], ["classNames", "ImageEditor-slider", "value", ["subexpr", "@mut", [["get", "scalePercentage", ["loc", [null, [1, 779], [1, 794]]]]], [], []], "minimum", 5, "maximum", ["subexpr", "@mut", [["get", "maxScalePercentage", ["loc", [null, [1, 813], [1, 831]]]]], [], []], "animate", true, "step", 5], ["loc", [null, [1, 726], [1, 853]]]], ["block", "if", [["get", "showFrameOption", ["loc", [null, [1, 903], [1, 918]]]]], [], 0, null, ["loc", [null, [1, 897], [1, 1075]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1093
          }
        },
        "moduleName": "presenter/templates/components/controls/image-editor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ImageEditor-canvasWrapper js-canvasWrapper");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element4, 0, 0);
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "canResizeReposition", ["loc", [null, [1, 62], [1, 81]]]]], [], 0, 1, ["loc", [null, [1, 56], [1, 255]]]], ["block", "if", [["get", "showCropper", ["loc", [null, [1, 261], [1, 272]]]]], [], 2, null, ["loc", [null, [1, 255], [1, 490]]]], ["block", "if", [["get", "canResizeReposition", ["loc", [null, [1, 502], [1, 521]]]]], [], 3, null, ["loc", [null, [1, 496], [1, 1093]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});
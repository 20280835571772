define("presenter/templates/svg/dashboard/one_tile", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/one_tile.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 79 79");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "d", "M76 79H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h73c1.657 0 3 1.343 3 3v73c0 1.657-1.343 3-3 3zm1-75c0-1.105-.895-2-2-2H4c-1.105 0-2 .895-2 2v71c0 1.105.895 2 2 2h71c1.105 0 2-.895 2-2V4z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/mixins/sluricane-query-properties', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    startsAt: (0, _data.attr)('date'),
    endsAt: (0, _data.attr)('date'),
    window: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'infinite';
      }
    }),
    conditions: (0, _data.hasMany)('reporting_condition', {
      serialize: true
    }),
    rangeType: Ember.computed('window', {
      get: function get() {
        if (this.get('window')) {
          return this.get('window').split('_').splice(0, 2).join('_');
        }
      },
      set: function set(_, value) {
        return value;
      }
    }),
    period: Ember.computed('window', 'rangeType', {
      get: function get() {
        if (this.get('window') && this.get('rangeType') === 'rolling_period') {
          return this.get('window').split('_')[2];
        }
      },
      set: function set(_, value) {
        return value;
      }
    }),
    days: Ember.computed('window', 'rangeType', {
      get: function get() {
        if (this.get('window') && this.get('rangeType') === 'rolling_days') {
          return this.get('window').split('_')[2];
        }
      },
      set: function set(_, value) {
        return value;
      }
    }),
    hasPreviousData: Ember.computed.match('rangeType', /rolling_(period|days)/),
    _updateWindow: function () {
      return this.set('window', function () {
        switch (this.get('rangeType')) {
          case 'infinite':
          case 'fixed':
            return this.get('rangeType');
          case 'rolling_period':
            return this.get('rangeType') + '_' + this.get('period');
          case 'rolling_days':
            return this.get('rangeType') + '_' + this.get('days');
        }
      }.call(this));
    }.observes('rangeType', 'period', 'days')
  });
});
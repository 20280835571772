define("presenter/templates/components/controls/media-video", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 78
            }
          },
          "moduleName": "presenter/templates/components/controls/media-video.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "you-tube-player", [], ["media", ["subexpr", "@mut", [["get", "media", ["loc", [null, [1, 41], [1, 46]]]]], [], []], "playbackSwitch", ["subexpr", "@mut", [["get", "playbackSwitch", ["loc", [null, [1, 62], [1, 76]]]]], [], []]], ["loc", [null, [1, 17], [1, 78]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 85
            },
            "end": {
              "line": 1,
              "column": 158
            }
          },
          "moduleName": "presenter/templates/components/controls/media-video.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "vimeo-player", [], ["media", ["subexpr", "@mut", [["get", "media", ["loc", [null, [1, 121], [1, 126]]]]], [], []], "playbackSwitch", ["subexpr", "@mut", [["get", "playbackSwitch", ["loc", [null, [1, 142], [1, 156]]]]], [], []]], ["loc", [null, [1, 100], [1, 158]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 165
          }
        },
        "moduleName": "presenter/templates/components/controls/media-video.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isYouTube", ["loc", [null, [1, 6], [1, 15]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 85]]]], ["block", "if", [["get", "isVimeo", ["loc", [null, [1, 91], [1, 98]]]]], [], 1, null, ["loc", [null, [1, 85], [1, 165]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
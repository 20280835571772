define('presenter/utils/sluricane-change-feed', ['exports', 'presenter/mixins/sluricane-url-helper'], function (exports, _sluricaneUrlHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_sluricaneUrlHelper.default, {
    source: null,
    error: null,
    pausedModels: [],
    models: function () {
      return Ember.Map.create();
    }.property(),
    throttleIntervals: Ember.Map.create(),
    register: function register(model, identifier) {
      if (identifier == null) {
        identifier = 'id';
      }
      if (model.get(identifier) && !this.get('models').has(model.get(identifier))) {
        return this.get('models').set(model.get(identifier).toString(), model);
      }
    },
    unregister: function unregister(model, identifier) {
      if (identifier == null) {
        identifier = 'id';
      }
      return this._unregister(model.get(identifier));
    },
    close: function close() {
      this.get('models').clear();
      return this._closeEventSource();
    },
    pause: function pause(model, identifier) {
      if (identifier == null) {
        identifier = 'id';
      }
      if (this.get('models').has(model.get(identifier))) {
        return this.get('pausedModels').pushObject(model);
      }
    },
    resume: function resume(model) {
      return this.get('pausedModels').removeObject(model);
    },
    start: function start() {
      return this.refresh();
    },
    refresh: function refresh() {
      return this._initEventSource();
    },
    _unregister: function _unregister(id) {
      this.get('models')["delete"](id);
      return this._initEventSource();
    },
    _closeEventSource: function _closeEventSource() {
      if (this.get('source')) {
        this.get('source').close();
        return this.set('source', void 0);
      }
    },
    _initEventSource: function _initEventSource() {
      var source, token;
      this._closeEventSource();
      this.set('error', null);
      if (!(token = this.get('model.changeFeedToken'))) {
        return;
      }
      source = new EventSource(this.feedUrl(token));
      this.set('source', source);
      source.addEventListener('data', function (_this) {
        return function (event) {
          var data, interval, refreshEvent, throttleInterval;
          data = JSON.parse(event.data);
          switch (data.type) {
            case 'info':
            case 'time':
              return null;
            case 'action':
              switch (data.action) {
                case 'reconnect':
                  return _this.refresh();
              }
              break;
            case 'batch':
              return data.events.forEach(function (event) {
                return _this._triggerChangeFeedEventOnModel(event);
              });
            case 'force_refresh':
              return _this.get('models').forEach(function (model) {
                return Ember.run.later(function () {
                  return model.trigger('changeFeedEvent', {
                    type: 'refresh'
                  });
                }, Math.floor(Math.random() * 1e3 * 60 * 5));
              });
            case 'throttle_started':
              refreshEvent = {
                identifier: data.identifier,
                type: 'refresh'
              };
              _this._triggerChangeFeedEventOnModel(refreshEvent);
              interval = setInterval(function () {
                return _this._triggerChangeFeedEventOnModel(refreshEvent);
              }, 1e3 * 5);
              return _this.get('throttleIntervals').set(data.identifier, interval);
            case 'throttle_finished':
              _this._triggerChangeFeedEventOnModel({
                identifier: data.identifier,
                type: 'refresh'
              });
              if (throttleInterval = _this.get('throttleIntervals').get(data.identifier)) {
                clearInterval(throttleInterval);
                return _this.get('throttleIntervals')["delete"](data.identifier);
              }
              break;
            default:
              return _this._triggerChangeFeedEventOnModel(data);
          }
        };
      }(this));
      source.onerror = function (_this) {
        return function (event) {
          if (event.target.readyState === EventSource.CLOSED) {
            Ember.run.later(_this, 'refresh', 5 + Math.ceil(Math.random() * 1e3 * 25));
          }
          if (!_this.get('error')) {
            return _this.set('error', event);
          }
        };
      }(this);
      return source.onopen = function (_this) {
        return function () {
          return _this.set('error', null);
        };
      }(this);
    },
    _triggerChangeFeedEventOnModel: function _triggerChangeFeedEventOnModel(data) {
      var model;
      if (model = this.get('models').get(data.identifier)) {
        if (this.get('pausedModels').contains(model)) {
          return;
        }
        return model.trigger('changeFeedEvent', data);
      }
    }
  });
});
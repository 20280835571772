define('presenter/components/vimeo-player', ['exports', 'presenter/components/video-player', 'presenter/utils'], function (exports, _videoPlayer, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _videoPlayer.default.extend({
    tagName: 'iframe',
    attributeBindings: ['src', 'frameborder'],
    frameborder: 0,
    src: function () {
      var id;
      if (!this.get('media.videoUrl')) {
        return;
      }
      id = (0, _utils.getVimeoId)(this.get('media.videoUrl'));
      return "//player.vimeo.com/video/" + id + "?api=1&title=0&byline=0&portrait=0";
    }.property('media.videoUrl'),
    didInsertElement: function didInsertElement() {
      return this._player = $f(this.$()[0]);
    },
    stopPlayback: function stopPlayback() {
      return this._player.api('pause');
    }
  });
});
define('presenter/helpers/survey-event-bus', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var messageHandler;

  if (_globals.isIframed) {
    messageHandler = window.parent;
  } else {
    Ember.$(document).ready(function () {
      var ref, ref1;
      return messageHandler || (messageHandler = (ref = window.webkit) != null ? (ref1 = ref.messageHandlers) != null ? ref1.callback : void 0 : void 0);
    });
  }

  exports.default = Ember.Object.extend(Ember.Evented, {
    init: function init() {
      if (_globals.isEmbedded) {
        Ember.$(window).on('message', function (_this) {
          return function (event) {
            return _this.incomingMessage(event.originalEvent);
          };
        }(this));
      }
      return this._anyOrigin = _globals.isPreview && _globals.isEmbedded;
    },
    incomingMessage: function incomingMessage(event) {
      var data, name, parts;
      if (!(_globals.embedTarget && event.origin === _globals.embedTarget)) {
        return;
      }
      if (typeof event.data === 'string') {
        parts = event.data.split(':');
        name = parts.shift();
        data = parts.join(':');
        return this.trigger(name, data);
      }
    },
    didStartInput: function (token) {
      return this._sendMessageOut('inputStarted', token);
    }.on('inputStarted'),
    didEndInput: function (token) {
      return this._sendMessageOut('inputEnded', token);
    }.on('inputEnded'),
    didLoadSurvey: function (token) {
      return this._sendMessageOut('loaded', token);
    }.on('loaded'),
    didSubmitResponse: function (token) {
      return this._sendMessageOut('submittedResponse', token, true);
    }.on('submittedResponse'),
    didRequestRedirect: function (url) {
      return this._sendMessageOut('requestRedirect', url, true);
    }.on('requestRedirect'),
    didHeightChanged: function (height) {
      return this._sendMessageOut('heightChanged', height, true);
    }.on('heightChanged'),
    _sendMessageOut: function _sendMessageOut(event, data, anyOrigin) {
      var message;
      if (anyOrigin == null) {
        anyOrigin = this._anyOrigin;
      }
      messageHandler || (messageHandler = window.messageHandler);
      if (messageHandler && (anyOrigin || _globals.embedTarget)) {
        message = data ? event + ":" + data : event;
        return messageHandler.postMessage(message, anyOrigin && '*' || _globals.embedTarget);
      }
    }
  });
});
define('presenter/models/insight-segment', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var InsightSegment;

  InsightSegment = _data.Model.extend({
    insight: (0, _data.belongsTo)('insight', {
      serialize: false
    }),
    choiceText: (0, _data.attr)('string'),
    kpiScore: (0, _data.attr)('number'),
    kpiAverage: (0, _data.attr)('number'),
    kpiDifference: (0, _data.attr)('number'),
    responseCount: (0, _data.attr)('number')
  });

  InsightSegment.reopenClass({
    resourceName: 'insight_segment'
  });

  exports.default = InsightSegment;
});
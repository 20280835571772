define("presenter/templates/svg/dashboard/sun", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/sun.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        var el2 = dom.createElement("style");
        var el3 = dom.createTextNode(".Dashboards-sun{fill:url(#sun-gradient);};}");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3, "id", "sun-gradient");
        dom.setAttribute(el3, "gradientUnits", "userSpaceOnUse");
        dom.setAttribute(el3, "x1", "-13");
        dom.setAttribute(el3, "y1", "14");
        dom.setAttribute(el3, "x2", "53.3702");
        dom.setAttribute(el3, "y2", "80.3701");
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "0");
        dom.setAttribute(el4, "stop-color", "#FBC559");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "1");
        dom.setAttribute(el4, "stop-color", "#DE4743");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M8 33.5H2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h6c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M15 16.5c-.4 0-.8-.1-1.1-.4l-4.2-4.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0L16 14c.6.6.6 1.5 0 2.1-.2.3-.6.4-1 .4z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M32 9.5c-.8 0-1.5-.7-1.5-1.5V2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v6c0 .8-.7 1.5-1.5 1.5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M49 16.5c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l4.2-4.2c.6-.6 1.5-.6 2.1 0s.6 1.5 0 2.1L50 16.1c-.3.3-.6.4-1 .4z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M62 33.5h-6c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h6c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M53.2 54.7c-.4 0-.8-.1-1.1-.4L47.9 50c-.6-.6-.6-1.5 0-2.1s1.5-.6 2.1 0l4.2 4.2c.6.6.6 1.5 0 2.1-.2.4-.6.5-1 .5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M32 63.5c-.8 0-1.5-.7-1.5-1.5v-6c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v6c0 .8-.7 1.5-1.5 1.5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M10.8 54.7c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l4.2-4.2c.6-.6 1.5-.6 2.1 0s.6 1.5 0 2.1l-4.2 4.2c-.2.3-.6.4-1 .4z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-sun");
        dom.setAttribute(el3, "d", "M32 49.5c-9.6 0-17.5-7.9-17.5-17.5S22.4 14.5 32 14.5 49.5 22.4 49.5 32 41.6 49.5 32 49.5zm0-32c-8 0-14.5 6.5-14.5 14.5S24 46.5 32 46.5 46.5 40 46.5 32 40 17.5 32 17.5z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/folder', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Folder;

  Folder = _data.Model.extend(Ember.Evented, {
    name: (0, _data.attr)('string'),
    surveysCount: (0, _data.attr)('number'),
    surveys: (0, _data.hasMany)('survey')
  });

  Folder.reopenClass({
    resourceName: 'folder'
  });

  exports.default = Folder;
});
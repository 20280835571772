define("presenter/templates/presenter/components/controls/date-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 236
            }
          },
          "moduleName": "presenter/templates/presenter/components/controls/date-field.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/mobile-date-input-field", [], ["datetimeformat", ["subexpr", "@mut", [["get", "format", ["loc", [null, [1, 73], [1, 79]]]]], [], []], "placeholder", ["subexpr", "merge-response-values", [["get", "labelTextWithDefault", ["loc", [null, [1, 115], [1, 135]]]]], [], ["loc", [null, [1, 92], [1, 136]]]], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 143], [1, 148]]]]], [], []], "ariaLabel", ["subexpr", "if", [["get", "required", ["loc", [null, [1, 163], [1, 171]]]], ["subexpr", "translated-properties", ["presenter", "alerts", "required_field"], [], ["loc", [null, [1, 172], [1, 233]]]]], [], ["loc", [null, [1, 159], [1, 234]]]]], ["loc", [null, [1, 22], [1, 236]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 267
              },
              "end": {
                "line": 1,
                "column": 499
              }
            },
            "moduleName": "presenter/templates/presenter/components/controls/date-field.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "merge-response-values", [["get", "labelText", ["loc", [null, [1, 488], [1, 497]]]]], [], ["loc", [null, [1, 464], [1, 499]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 565
              },
              "end": {
                "line": 1,
                "column": 771
              }
            },
            "moduleName": "presenter/templates/presenter/components/controls/date-field.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "component", [["subexpr", "date-field-component", [["get", "field", ["loc", [null, [1, 630], [1, 635]]]]], [], ["loc", [null, [1, 608], [1, 636]]]]], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 644], [1, 648]]]]], [], []], "dateField", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 659], [1, 663]]]]], [], []], "aria-label", ["subexpr", "concat", [["subexpr", "merge-response-values", [["get", "labelText", ["loc", [null, [1, 706], [1, 715]]]]], [], ["loc", [null, [1, 683], [1, 716]]]], " ", ["get", "field", ["loc", [null, [1, 721], [1, 726]]]]], [], ["loc", [null, [1, 675], [1, 727]]]], "updateFieldProperty", "updateFieldProperty"], ["loc", [null, [1, 596], [1, 771]]]]],
          locals: ["field"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 236
            },
            "end": {
              "line": 1,
              "column": 792
            }
          },
          "moduleName": "presenter/templates/presenter/components/controls/date-field.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "DateField");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "DateField-inputFields");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          return morphs;
        },
        statements: [["block", "presenter/date-field-label", [], ["hidden", ["subexpr", "@mut", [["get", "labelIsHidden", ["loc", [null, [1, 304], [1, 317]]]]], [], []], "tabindex", 0, "aria-label", ["subexpr", "concat", [["subexpr", "merge-response-values", [["get", "labelText", ["loc", [null, [1, 371], [1, 380]]]]], [], ["loc", [null, [1, 348], [1, 381]]]], " ", ["subexpr", "if", [["get", "required", ["loc", [null, [1, 390], [1, 398]]]], ["subexpr", "translated-properties", ["presenter", "alerts", "required_field"], [], ["loc", [null, [1, 399], [1, 460]]]]], [], ["loc", [null, [1, 386], [1, 461]]]]], [], ["loc", [null, [1, 340], [1, 462]]]]], 0, null, ["loc", [null, [1, 267], [1, 530]]]], ["block", "each", [["get", "fieldOrder", ["loc", [null, [1, 573], [1, 583]]]]], [], 1, null, ["loc", [null, [1, 565], [1, 780]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 799
          }
        },
        "moduleName": "presenter/templates/presenter/components/controls/date-field.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "browser.mobile", ["loc", [null, [1, 6], [1, 20]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 799]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define("presenter/templates/svg/recipes/_crown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/recipes/_crown.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "viewBox", "0 0 22 24");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "d", "M21.000,24.000 L1.000,24.000 C0.448,24.000 -0.000,23.552 -0.000,23.000 L-0.000,21.000 C-0.000,20.448 0.448,20.000 1.000,20.000 L21.000,20.000 C21.552,20.000 22.000,20.448 22.000,21.000 L22.000,23.000 C22.000,23.552 21.552,24.000 21.000,24.000 ZM21.392,18.000 L11.759,18.000 L10.241,18.000 L0.608,18.000 C0.272,18.000 -0.000,17.456 -0.000,16.785 L-0.000,3.778 C-0.000,3.107 0.272,2.563 0.608,2.563 L6.240,9.753 L11.034,0.335 C11.271,-0.139 11.656,-0.139 11.893,0.335 L15.894,9.582 L21.392,2.563 C21.728,2.563 22.000,3.107 22.000,3.778 L22.000,16.785 C22.000,17.456 21.728,18.000 21.392,18.000 Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/helpers/ordinalize-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var num, x, y;
    num = arg[0];
    if (num === null || num === void 0 || isNaN(num)) {
      return;
    }
    x = num % 10;
    y = num % 100;
    if (x === 1 && y !== 11) {
      return num + 'st';
    } else if (x === 2 && y !== 12) {
      return num + 'nd';
    } else if (x === 3 && y !== 13) {
      return num + 'rd';
    } else {
      return num + 'th';
    }
  });
});
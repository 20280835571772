define("presenter/templates/svg/dashboard/percentage", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/percentage.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "24");
        dom.setAttribute(el1, "height", "24");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "id", "percentage");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "%");
        dom.setAttribute(el3, "d", "M4 6.52734C4 5.49609 4.33203 4.65234 4.99609 3.99609C5.66016 3.33203 6.53125 3 7.60938 3C8.70312 3 9.57812 3.33203 10.2344 3.99609C10.8984 4.65234 11.2305 5.51562 11.2305 6.58594V7.42969C11.2305 8.45312 10.8984 9.29297 10.2344 9.94922C9.57812 10.6055 8.71094 10.9336 7.63281 10.9336C6.57812 10.9336 5.70703 10.6133 5.01953 9.97266C4.33984 9.32422 4 8.45312 4 7.35938V6.52734ZM5.99219 7.42969C5.99219 7.96875 6.14062 8.40625 6.4375 8.74219C6.74219 9.07031 7.14062 9.23438 7.63281 9.23438C8.11719 9.23438 8.50781 9.07031 8.80469 8.74219C9.10156 8.40625 9.25 7.95312 9.25 7.38281V6.52734C9.25 5.98828 9.09766 5.55078 8.79297 5.21484C8.49609 4.87891 8.10156 4.71094 7.60938 4.71094C7.11719 4.71094 6.72266 4.88281 6.42578 5.22656C6.13672 5.5625 5.99219 6.00781 5.99219 6.5625V7.42969ZM12.2031 16.125C12.2031 15.0781 12.543 14.2305 13.2227 13.582C13.9023 12.9336 14.7695 12.6094 15.8242 12.6094C16.8867 12.6094 17.7539 12.9336 18.4258 13.582C19.1055 14.2305 19.4453 15.1055 19.4453 16.207V17.0391C19.4453 18.0547 19.1172 18.8945 18.4609 19.5586C17.8125 20.2148 16.9414 20.543 15.8477 20.543C14.7539 20.543 13.8711 20.2188 13.1992 19.5703C12.5352 18.9141 12.2031 18.0508 12.2031 16.9805V16.125ZM14.1953 17.0391C14.1953 17.5703 14.3477 18.0078 14.6523 18.3516C14.9648 18.6875 15.3633 18.8555 15.8477 18.8555C16.3477 18.8555 16.7383 18.6953 17.0195 18.375C17.3086 18.0469 17.4531 17.5898 17.4531 17.0039V16.125C17.4531 15.5781 17.3008 15.1406 16.9961 14.8125C16.6914 14.4844 16.3008 14.3203 15.8242 14.3203C15.3398 14.3203 14.9453 14.4844 14.6406 14.8125C14.3438 15.1406 14.1953 15.5938 14.1953 16.1719V17.0391ZM8.21875 19.0312L6.75391 18.1875L15.0859 4.85156L16.5508 5.69531L8.21875 19.0312Z");
        dom.setAttribute(el3, "fill", "#9096A7");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
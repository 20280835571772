define('presenter/models/grid-item', ['exports', 'presenter/data', 'presenter/mixins/change-confirmation', 'presenter/models/stats'], function (exports, _data, _changeConfirmation, _stats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GridItem;

  GridItem = _data.Model.extend(_changeConfirmation.default, {
    text: (0, _data.attr)('string'),
    position: (0, _data.attr)('number'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    answer: (0, _data.belongsTo)('answer', {
      serialize: 'id'
    }),
    stats: (0, _data.belongsTo)('stats', {
      readOnly: true,
      defaultValue: function defaultValue() {
        return _stats.default.create();
      }
    }),
    choicesStats: (0, _data.hasMany)('choice_stats', {
      readOnly: true
    }),
    translations: (0, _data.hasMany)('translation', {
      serialize: true
    }),
    positionPlusOne: function () {
      return this.get('position') + 1;
    }.property('position')
  });

  GridItem.reopenClass({
    resourceName: 'grid_item'
  });

  exports.default = GridItem;
});
define('presenter/utils/sluricane-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ref, sluricaneParamNames;

  sluricaneParamNames = ['dateInterval', 'limit', 'direction', 'last', 'utcOffset', 't'];

  exports.default = Ember.Object.extend({
    model: void 0,
    queryParams: (ref = function ref() {
      return sluricaneParamNames.reduce(function (_this) {
        return function (params, p) {
          var value;
          if (value = _this.get(p)) {
            params[p.decamelize()] = value;
          }
          return params;
        };
      }(this), {});
    }).property.apply(ref, sluricaneParamNames),
    dateInterval: Ember.computed.oneWay('model.dateInterval'),
    limit: Ember.computed.oneWay('model.limit'),
    direction: Ember.computed.oneWay('model.direction'),
    last: Ember.computed.oneWay('model.last'),
    utcOffset: function () {
      return moment().format('Z');
    }.property(),
    t: Ember.computed(function () {
      return Date.now();
    }).volatile()
  });
});
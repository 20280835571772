define('presenter/initializers/layout', ['exports', 'presenter/utils/layout'], function (exports, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'layout',
    initialize: function initialize() {
      _layout.default.setViewportScale();
      if (typeof Modernizr !== "undefined" && Modernizr !== null ? Modernizr.deviceorientation : void 0) {
        return Ember.$(window).bind('orientationchange', function (_this) {
          return function () {
            return _layout.default.setViewportScale();
          };
        }(this));
      }
    }
  };
});
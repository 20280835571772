define("presenter/templates/components/etw/module-style-example", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-border\n          etw-border-solid etw-border-grey\n          etw-bg-grey-lighter\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [11, 12], [11, 37]]]], "\n        "]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 6
              },
              "end": {
                "line": 21,
                "column": 6
              }
            },
            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element18, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24\n          etw-border-red etw-bg-grey-lighter etw-border-solid\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [18, 12], [18, 37]]]], "\n        "]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 6
                },
                "end": {
                  "line": 24,
                  "column": 6
                }
              },
              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element17, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["etw-marginx-auto etw-w-full etw-h-24 bg-", ["get", "moduleStyle.name", ["loc", [null, [22, 62], [22, 78]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 6
                  },
                  "end": {
                    "line": 29,
                    "column": 6
                  }
                },
                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element16, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["font-", ["get", "moduleStyle.name", ["loc", [null, [25, 25], [25, 41]]]]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 6
                    },
                    "end": {
                      "line": 37,
                      "column": 6
                    }
                  },
                  "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element15 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element15, 'class');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24\n          etw-border etw-border-solid etw-border-grey\n          etw-bg-grey-lighter\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [34, 12], [34, 37]]]], "\n        "]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 6
                      },
                      "end": {
                        "line": 42,
                        "column": 6
                      }
                    },
                    "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createTextNode("\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element14 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element14, 'class');
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["text-left tracking-", ["get", "moduleStyle.name", ["loc", [null, [38, 39], [38, 55]]]]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 42,
                          "column": 6
                        },
                        "end": {
                          "line": 47,
                          "column": 6
                        }
                      },
                      "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("p");
                      var el2 = dom.createTextNode("\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element13 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element13, 'class');
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["text-left leading-", ["get", "moduleStyle.name", ["loc", [null, [43, 38], [43, 54]]]]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  var child0 = function () {
                    return {
                      meta: {
                        "revision": "Ember@1.13.13",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 47,
                            "column": 6
                          },
                          "end": {
                            "line": 56,
                            "column": 6
                          }
                        },
                        "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        var el2 = dom.createTextNode("\n          ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n        ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element11 = dom.childAt(fragment, [1]);
                        var element12 = dom.childAt(element11, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createAttrMorph(element11, 'class');
                        morphs[1] = dom.createAttrMorph(element12, 'class');
                        return morphs;
                      },
                      statements: [["attribute", "class", ["concat", ["etw-bg-red etw-w-24 etw-mx-auto etw-border-t etw-border-solid ", ["subexpr", "if", [["subexpr", "eq", [["get", "moduleStyle.name", ["loc", [null, [48, 91], [48, 107]]]], "auto"], [], ["loc", [null, [48, 87], [48, 115]]]], "", "etw-border-transparent"], [], ["loc", [null, [48, 82], [48, 145]]]]]]], ["attribute", "class", ["concat", ["\n            etw-mx-auto etw-w-24 etw-h-24 etw-border\n             etw-bg-grey-lighter\n            mt-", ["get", "moduleStyle.name", ["loc", [null, [52, 17], [52, 33]]]], "\n          "]]]],
                      locals: [],
                      templates: []
                    };
                  }();
                  var child1 = function () {
                    var child0 = function () {
                      return {
                        meta: {
                          "revision": "Ember@1.13.13",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 56,
                              "column": 6
                            },
                            "end": {
                              "line": 63,
                              "column": 6
                            }
                          },
                          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("        ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(1);
                          morphs[0] = dom.createAttrMorph(element10, 'class');
                          return morphs;
                        },
                        statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-bg-grey-lighter\n          etw-border etw-border-solid etw-border-grey\n          max-h-", ["get", "moduleStyle.name", ["loc", [null, [60, 18], [60, 34]]]], "\n        "]]]],
                        locals: [],
                        templates: []
                      };
                    }();
                    var child1 = function () {
                      var child0 = function () {
                        return {
                          meta: {
                            "revision": "Ember@1.13.13",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 63,
                                "column": 6
                              },
                              "end": {
                                "line": 70,
                                "column": 6
                              }
                            },
                            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("        ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element9 = dom.childAt(fragment, [1]);
                            var morphs = new Array(1);
                            morphs[0] = dom.createAttrMorph(element9, 'class');
                            return morphs;
                          },
                          statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-bg-grey-lighter\n          etw-border etw-border-solid etw-border-grey\n          max-w-", ["get", "moduleStyle.name", ["loc", [null, [67, 18], [67, 34]]]], "\n        "]]]],
                          locals: [],
                          templates: []
                        };
                      }();
                      var child1 = function () {
                        var child0 = function () {
                          return {
                            meta: {
                              "revision": "Ember@1.13.13",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 70,
                                  "column": 6
                                },
                                "end": {
                                  "line": 77,
                                  "column": 6
                                }
                              },
                              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("        ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("div");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var element8 = dom.childAt(fragment, [1]);
                              var morphs = new Array(1);
                              morphs[0] = dom.createAttrMorph(element8, 'class');
                              return morphs;
                            },
                            statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-bg-grey-lighter\n          etw-border etw-border-solid etw-border-grey\n          min-h-", ["get", "moduleStyle.name", ["loc", [null, [74, 18], [74, 34]]]], "\n        "]]]],
                            locals: [],
                            templates: []
                          };
                        }();
                        var child1 = function () {
                          var child0 = function () {
                            return {
                              meta: {
                                "revision": "Ember@1.13.13",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 77,
                                    "column": 6
                                  },
                                  "end": {
                                    "line": 84,
                                    "column": 6
                                  }
                                },
                                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("        ");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("div");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var element7 = dom.childAt(fragment, [1]);
                                var morphs = new Array(1);
                                morphs[0] = dom.createAttrMorph(element7, 'class');
                                return morphs;
                              },
                              statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-bg-grey-lighter\n          etw-border etw-border-solid etw-border-grey\n          min-w-", ["get", "moduleStyle.name", ["loc", [null, [81, 18], [81, 34]]]], "\n        "]]]],
                              locals: [],
                              templates: []
                            };
                          }();
                          var child1 = function () {
                            var child0 = function () {
                              return {
                                meta: {
                                  "revision": "Ember@1.13.13",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 84,
                                      "column": 6
                                    },
                                    "end": {
                                      "line": 95,
                                      "column": 6
                                    }
                                  },
                                  "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("        ");
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createElement("div");
                                  dom.setAttribute(el1, "class", "etw-mb-8 etw-bg-red etw-px-4 etw-pb-4 etw-mx-auto etw-h-32 etw-relative");
                                  var el2 = dom.createTextNode("\n          ");
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createElement("div");
                                  dom.setAttribute(el2, "class", "etw-absolute etw-pin-x");
                                  var el3 = dom.createTextNode("\n            ");
                                  dom.appendChild(el2, el3);
                                  var el3 = dom.createElement("div");
                                  dom.appendChild(el2, el3);
                                  var el3 = dom.createTextNode("\n          ");
                                  dom.appendChild(el2, el3);
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createTextNode("\n        ");
                                  dom.appendChild(el1, el2);
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createTextNode("\n\n");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                  var element6 = dom.childAt(fragment, [1, 1, 1]);
                                  var morphs = new Array(1);
                                  morphs[0] = dom.createAttrMorph(element6, 'class');
                                  return morphs;
                                },
                                statements: [["attribute", "class", ["concat", ["\n            etw-mx-auto etw-w-24 etw-h-24 etw-border\n            etw-bg-grey-lighter etw-shadow-lg\n            -mt-", ["get", "moduleStyle.name", ["loc", [null, [90, 18], [90, 34]]]], "\n            "]]]],
                                locals: [],
                                templates: []
                              };
                            }();
                            var child1 = function () {
                              var child0 = function () {
                                return {
                                  meta: {
                                    "revision": "Ember@1.13.13",
                                    "loc": {
                                      "source": null,
                                      "start": {
                                        "line": 95,
                                        "column": 6
                                      },
                                      "end": {
                                        "line": 102,
                                        "column": 6
                                      }
                                    },
                                    "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                  },
                                  arity: 0,
                                  cachedFragment: null,
                                  hasRendered: false,
                                  buildFragment: function buildFragment(dom) {
                                    var el0 = dom.createDocumentFragment();
                                    var el1 = dom.createTextNode("        ");
                                    dom.appendChild(el0, el1);
                                    var el1 = dom.createElement("div");
                                    dom.appendChild(el0, el1);
                                    var el1 = dom.createTextNode("\n\n");
                                    dom.appendChild(el0, el1);
                                    return el0;
                                  },
                                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                    var element5 = dom.childAt(fragment, [1]);
                                    var morphs = new Array(1);
                                    morphs[0] = dom.createAttrMorph(element5, 'class');
                                    return morphs;
                                  },
                                  statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-border\n          etw-border-grey etw-bg-grey-lighter\n          opacity-", ["get", "moduleStyle.name", ["loc", [null, [99, 20], [99, 36]]]], "\n        "]]]],
                                  locals: [],
                                  templates: []
                                };
                              }();
                              var child1 = function () {
                                var child0 = function () {
                                  return {
                                    meta: {
                                      "revision": "Ember@1.13.13",
                                      "loc": {
                                        "source": null,
                                        "start": {
                                          "line": 102,
                                          "column": 6
                                        },
                                        "end": {
                                          "line": 113,
                                          "column": 6
                                        }
                                      },
                                      "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                    },
                                    arity: 0,
                                    cachedFragment: null,
                                    hasRendered: false,
                                    buildFragment: function buildFragment(dom) {
                                      var el0 = dom.createDocumentFragment();
                                      var el1 = dom.createTextNode("        ");
                                      dom.appendChild(el0, el1);
                                      var el1 = dom.createElement("div");
                                      dom.setAttribute(el1, "class", "etw-bg-red etw-w-24 etw-mx-auto");
                                      var el2 = dom.createTextNode("\n          ");
                                      dom.appendChild(el1, el2);
                                      var el2 = dom.createElement("div");
                                      var el3 = dom.createTextNode("\n            ");
                                      dom.appendChild(el2, el3);
                                      var el3 = dom.createElement("p");
                                      dom.setAttribute(el3, "class", "etw-text-grey-darker");
                                      var el4 = dom.createTextNode("Lorem");
                                      dom.appendChild(el3, el4);
                                      dom.appendChild(el2, el3);
                                      var el3 = dom.createTextNode("\n          ");
                                      dom.appendChild(el2, el3);
                                      dom.appendChild(el1, el2);
                                      var el2 = dom.createTextNode("\n        ");
                                      dom.appendChild(el1, el2);
                                      dom.appendChild(el0, el1);
                                      var el1 = dom.createTextNode("\n\n");
                                      dom.appendChild(el0, el1);
                                      return el0;
                                    },
                                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                      var element4 = dom.childAt(fragment, [1, 1]);
                                      var morphs = new Array(1);
                                      morphs[0] = dom.createAttrMorph(element4, 'class');
                                      return morphs;
                                    },
                                    statements: [["attribute", "class", ["concat", ["\n            etw-mx-auto etw-w-24 etw-h-24 etw-border\n             etw-bg-grey-lighter\n            pt-", ["get", "moduleStyle.name", ["loc", [null, [107, 17], [107, 33]]]], "\n          "]]]],
                                    locals: [],
                                    templates: []
                                  };
                                }();
                                var child1 = function () {
                                  var child0 = function () {
                                    return {
                                      meta: {
                                        "revision": "Ember@1.13.13",
                                        "loc": {
                                          "source": null,
                                          "start": {
                                            "line": 113,
                                            "column": 6
                                          },
                                          "end": {
                                            "line": 120,
                                            "column": 6
                                          }
                                        },
                                        "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                      },
                                      arity: 0,
                                      cachedFragment: null,
                                      hasRendered: false,
                                      buildFragment: function buildFragment(dom) {
                                        var el0 = dom.createDocumentFragment();
                                        var el1 = dom.createTextNode("        ");
                                        dom.appendChild(el0, el1);
                                        var el1 = dom.createElement("div");
                                        var el2 = dom.createTextNode("\n        ");
                                        dom.appendChild(el1, el2);
                                        dom.appendChild(el0, el1);
                                        var el1 = dom.createTextNode("\n\n");
                                        dom.appendChild(el0, el1);
                                        return el0;
                                      },
                                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                        var element3 = dom.childAt(fragment, [1]);
                                        var morphs = new Array(1);
                                        morphs[0] = dom.createAttrMorph(element3, 'class');
                                        return morphs;
                                      },
                                      statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-bg-white\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [116, 12], [116, 37]]]], "\n        "]]]],
                                      locals: [],
                                      templates: []
                                    };
                                  }();
                                  var child1 = function () {
                                    var child0 = function () {
                                      return {
                                        meta: {
                                          "revision": "Ember@1.13.13",
                                          "loc": {
                                            "source": null,
                                            "start": {
                                              "line": 120,
                                              "column": 6
                                            },
                                            "end": {
                                              "line": 127,
                                              "column": 6
                                            }
                                          },
                                          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                        },
                                        arity: 0,
                                        cachedFragment: null,
                                        hasRendered: false,
                                        buildFragment: function buildFragment(dom) {
                                          var el0 = dom.createDocumentFragment();
                                          var el1 = dom.createTextNode("        ");
                                          dom.appendChild(el0, el1);
                                          var el1 = dom.createElement("div");
                                          dom.setAttribute(el1, "class", "etw-bg-grey-lighter etw-text-red etw-py-4");
                                          var el2 = dom.createTextNode("\n          ");
                                          dom.appendChild(el1, el2);
                                          dom.setNamespace("http://www.w3.org/2000/svg");
                                          var el2 = dom.createElement("svg");
                                          dom.setAttribute(el2, "class", "fill-current inline-block h-12 w-12");
                                          dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
                                          dom.setAttribute(el2, "viewBox", "0 0 20 20");
                                          var el3 = dom.createTextNode("\n            ");
                                          dom.appendChild(el2, el3);
                                          var el3 = dom.createElement("path");
                                          dom.setAttribute(el3, "d", "M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z");
                                          dom.appendChild(el2, el3);
                                          var el3 = dom.createTextNode("\n          ");
                                          dom.appendChild(el2, el3);
                                          dom.appendChild(el1, el2);
                                          var el2 = dom.createTextNode("\n        ");
                                          dom.appendChild(el1, el2);
                                          dom.appendChild(el0, el1);
                                          var el1 = dom.createTextNode("\n\n");
                                          dom.appendChild(el0, el1);
                                          return el0;
                                        },
                                        buildRenderNodes: function buildRenderNodes() {
                                          return [];
                                        },
                                        statements: [],
                                        locals: [],
                                        templates: []
                                      };
                                    }();
                                    var child1 = function () {
                                      var child0 = function () {
                                        return {
                                          meta: {
                                            "revision": "Ember@1.13.13",
                                            "loc": {
                                              "source": null,
                                              "start": {
                                                "line": 127,
                                                "column": 6
                                              },
                                              "end": {
                                                "line": 136,
                                                "column": 6
                                              }
                                            },
                                            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                          },
                                          arity: 0,
                                          cachedFragment: null,
                                          hasRendered: false,
                                          buildFragment: function buildFragment(dom) {
                                            var el0 = dom.createDocumentFragment();
                                            var el1 = dom.createTextNode("        ");
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createElement("div");
                                            dom.setAttribute(el1, "class", "etw-bg-grey-lighter etw-text-red etw-py-4");
                                            var el2 = dom.createTextNode("\n          ");
                                            dom.appendChild(el1, el2);
                                            dom.setNamespace("http://www.w3.org/2000/svg");
                                            var el2 = dom.createElement("svg");
                                            dom.setAttribute(el2, "class", "stroke-current inline-block h-12 w-12");
                                            dom.setAttribute(el2, "viewBox", "0 0 24 24");
                                            dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
                                            dom.setAttribute(el2, "fill", "none");
                                            dom.setAttribute(el2, "stroke-width", "2");
                                            dom.setAttribute(el2, "stroke-linecap", "round");
                                            dom.setAttribute(el2, "stroke-linejoin", "round");
                                            var el3 = dom.createTextNode("\n              ");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createElement("circle");
                                            dom.setAttribute(el3, "cx", "8");
                                            dom.setAttribute(el3, "cy", "21");
                                            dom.setAttribute(el3, "r", "2");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createTextNode("\n              ");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createElement("circle");
                                            dom.setAttribute(el3, "cx", "20");
                                            dom.setAttribute(el3, "cy", "21");
                                            dom.setAttribute(el3, "r", "2");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createTextNode("\n              ");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createElement("path");
                                            dom.setAttribute(el3, "d", "M5.67 6H23l-1.68 8.39a2 2 0 0 1-2 1.61H8.75a2 2 0 0 1-2-1.74L5.23 2.74A2 2 0 0 0 3.25 1H1");
                                            dom.appendChild(el2, el3);
                                            var el3 = dom.createTextNode("\n          ");
                                            dom.appendChild(el2, el3);
                                            dom.appendChild(el1, el2);
                                            var el2 = dom.createTextNode("\n        ");
                                            dom.appendChild(el1, el2);
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createTextNode("\n\n");
                                            dom.appendChild(el0, el1);
                                            return el0;
                                          },
                                          buildRenderNodes: function buildRenderNodes() {
                                            return [];
                                          },
                                          statements: [],
                                          locals: [],
                                          templates: []
                                        };
                                      }();
                                      var child1 = function () {
                                        var child0 = function () {
                                          return {
                                            meta: {
                                              "revision": "Ember@1.13.13",
                                              "loc": {
                                                "source": null,
                                                "start": {
                                                  "line": 136,
                                                  "column": 6
                                                },
                                                "end": {
                                                  "line": 141,
                                                  "column": 6
                                                }
                                              },
                                              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                            },
                                            arity: 0,
                                            cachedFragment: null,
                                            hasRendered: false,
                                            buildFragment: function buildFragment(dom) {
                                              var el0 = dom.createDocumentFragment();
                                              var el1 = dom.createTextNode("        ");
                                              dom.appendChild(el0, el1);
                                              var el1 = dom.createElement("p");
                                              var el2 = dom.createTextNode("\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n        ");
                                              dom.appendChild(el1, el2);
                                              dom.appendChild(el0, el1);
                                              var el1 = dom.createTextNode("\n\n");
                                              dom.appendChild(el0, el1);
                                              return el0;
                                            },
                                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                              var element2 = dom.childAt(fragment, [1]);
                                              var morphs = new Array(1);
                                              morphs[0] = dom.createAttrMorph(element2, 'class');
                                              return morphs;
                                            },
                                            statements: [["attribute", "class", ["concat", ["text-left text-", ["get", "moduleStyle.name", ["loc", [null, [137, 35], [137, 51]]]]]]]],
                                            locals: [],
                                            templates: []
                                          };
                                        }();
                                        var child1 = function () {
                                          var child0 = function () {
                                            return {
                                              meta: {
                                                "revision": "Ember@1.13.13",
                                                "loc": {
                                                  "source": null,
                                                  "start": {
                                                    "line": 141,
                                                    "column": 6
                                                  },
                                                  "end": {
                                                    "line": 148,
                                                    "column": 6
                                                  }
                                                },
                                                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                              },
                                              arity: 0,
                                              cachedFragment: null,
                                              hasRendered: false,
                                              buildFragment: function buildFragment(dom) {
                                                var el0 = dom.createDocumentFragment();
                                                var el1 = dom.createTextNode("        ");
                                                dom.appendChild(el0, el1);
                                                var el1 = dom.createElement("div");
                                                dom.appendChild(el0, el1);
                                                var el1 = dom.createTextNode("\n\n");
                                                dom.appendChild(el0, el1);
                                                return el0;
                                              },
                                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                                var element1 = dom.childAt(fragment, [1]);
                                                var morphs = new Array(1);
                                                morphs[0] = dom.createAttrMorph(element1, 'class');
                                                return morphs;
                                              },
                                              statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-border\n          etw-border-grey etw-bg-grey-lighter\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [145, 12], [145, 37]]]], "\n        "]]]],
                                              locals: [],
                                              templates: []
                                            };
                                          }();
                                          var child1 = function () {
                                            var child0 = function () {
                                              return {
                                                meta: {
                                                  "revision": "Ember@1.13.13",
                                                  "loc": {
                                                    "source": null,
                                                    "start": {
                                                      "line": 148,
                                                      "column": 6
                                                    },
                                                    "end": {
                                                      "line": 155,
                                                      "column": 6
                                                    }
                                                  },
                                                  "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                                },
                                                arity: 0,
                                                cachedFragment: null,
                                                hasRendered: false,
                                                buildFragment: function buildFragment(dom) {
                                                  var el0 = dom.createDocumentFragment();
                                                  var el1 = dom.createTextNode("        ");
                                                  dom.appendChild(el0, el1);
                                                  var el1 = dom.createElement("div");
                                                  dom.appendChild(el0, el1);
                                                  var el1 = dom.createTextNode("\n\n      ");
                                                  dom.appendChild(el0, el1);
                                                  return el0;
                                                },
                                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                                  var element0 = dom.childAt(fragment, [1]);
                                                  var morphs = new Array(1);
                                                  morphs[0] = dom.createAttrMorph(element0, 'class');
                                                  return morphs;
                                                },
                                                statements: [["attribute", "class", ["concat", ["\n          etw-mx-auto etw-w-24 etw-h-24 etw-border\n          etw-border-grey etw-bg-grey-lighter\n          ", ["get", "classesForModuleStyle.0", ["loc", [null, [152, 12], [152, 37]]]], "\n        "]]]],
                                                locals: [],
                                                templates: []
                                              };
                                            }();
                                            return {
                                              meta: {
                                                "revision": "Ember@1.13.13",
                                                "loc": {
                                                  "source": null,
                                                  "start": {
                                                    "line": 148,
                                                    "column": 6
                                                  },
                                                  "end": {
                                                    "line": 155,
                                                    "column": 6
                                                  }
                                                },
                                                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                              },
                                              arity: 0,
                                              cachedFragment: null,
                                              hasRendered: false,
                                              buildFragment: function buildFragment(dom) {
                                                var el0 = dom.createDocumentFragment();
                                                var el1 = dom.createComment("");
                                                dom.appendChild(el0, el1);
                                                return el0;
                                              },
                                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                                var morphs = new Array(1);
                                                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                                dom.insertBoundary(fragment, 0);
                                                dom.insertBoundary(fragment, null);
                                                return morphs;
                                              },
                                              statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [148, 20], [148, 38]]]], "z-index"], [], ["loc", [null, [148, 16], [148, 49]]]]], [], 0, null, ["loc", [null, [148, 6], [155, 6]]]]],
                                              locals: [],
                                              templates: [child0]
                                            };
                                          }();
                                          return {
                                            meta: {
                                              "revision": "Ember@1.13.13",
                                              "loc": {
                                                "source": null,
                                                "start": {
                                                  "line": 141,
                                                  "column": 6
                                                },
                                                "end": {
                                                  "line": 155,
                                                  "column": 6
                                                }
                                              },
                                              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                            },
                                            arity: 0,
                                            cachedFragment: null,
                                            hasRendered: false,
                                            buildFragment: function buildFragment(dom) {
                                              var el0 = dom.createDocumentFragment();
                                              var el1 = dom.createComment("");
                                              dom.appendChild(el0, el1);
                                              return el0;
                                            },
                                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                              var morphs = new Array(1);
                                              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                              dom.insertBoundary(fragment, 0);
                                              dom.insertBoundary(fragment, null);
                                              return morphs;
                                            },
                                            statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [141, 20], [141, 38]]]], "width"], [], ["loc", [null, [141, 16], [141, 47]]]]], [], 0, 1, ["loc", [null, [141, 6], [155, 6]]]]],
                                            locals: [],
                                            templates: [child0, child1]
                                          };
                                        }();
                                        return {
                                          meta: {
                                            "revision": "Ember@1.13.13",
                                            "loc": {
                                              "source": null,
                                              "start": {
                                                "line": 136,
                                                "column": 6
                                              },
                                              "end": {
                                                "line": 155,
                                                "column": 6
                                              }
                                            },
                                            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                          },
                                          arity: 0,
                                          cachedFragment: null,
                                          hasRendered: false,
                                          buildFragment: function buildFragment(dom) {
                                            var el0 = dom.createDocumentFragment();
                                            var el1 = dom.createComment("");
                                            dom.appendChild(el0, el1);
                                            return el0;
                                          },
                                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                            var morphs = new Array(1);
                                            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                            dom.insertBoundary(fragment, 0);
                                            dom.insertBoundary(fragment, null);
                                            return morphs;
                                          },
                                          statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [136, 20], [136, 38]]]], "text-sizes"], [], ["loc", [null, [136, 16], [136, 52]]]]], [], 0, 1, ["loc", [null, [136, 6], [155, 6]]]]],
                                          locals: [],
                                          templates: [child0, child1]
                                        };
                                      }();
                                      return {
                                        meta: {
                                          "revision": "Ember@1.13.13",
                                          "loc": {
                                            "source": null,
                                            "start": {
                                              "line": 127,
                                              "column": 6
                                            },
                                            "end": {
                                              "line": 155,
                                              "column": 6
                                            }
                                          },
                                          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                        },
                                        arity: 0,
                                        cachedFragment: null,
                                        hasRendered: false,
                                        buildFragment: function buildFragment(dom) {
                                          var el0 = dom.createDocumentFragment();
                                          var el1 = dom.createComment("");
                                          dom.appendChild(el0, el1);
                                          return el0;
                                        },
                                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                          var morphs = new Array(1);
                                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                          dom.insertBoundary(fragment, 0);
                                          dom.insertBoundary(fragment, null);
                                          return morphs;
                                        },
                                        statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [127, 20], [127, 38]]]], "svg-stroke"], [], ["loc", [null, [127, 16], [127, 52]]]]], [], 0, 1, ["loc", [null, [127, 6], [155, 6]]]]],
                                        locals: [],
                                        templates: [child0, child1]
                                      };
                                    }();
                                    return {
                                      meta: {
                                        "revision": "Ember@1.13.13",
                                        "loc": {
                                          "source": null,
                                          "start": {
                                            "line": 120,
                                            "column": 6
                                          },
                                          "end": {
                                            "line": 155,
                                            "column": 6
                                          }
                                        },
                                        "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                      },
                                      arity: 0,
                                      cachedFragment: null,
                                      hasRendered: false,
                                      buildFragment: function buildFragment(dom) {
                                        var el0 = dom.createDocumentFragment();
                                        var el1 = dom.createComment("");
                                        dom.appendChild(el0, el1);
                                        return el0;
                                      },
                                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                        var morphs = new Array(1);
                                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                        dom.insertBoundary(fragment, 0);
                                        dom.insertBoundary(fragment, null);
                                        return morphs;
                                      },
                                      statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [120, 20], [120, 38]]]], "svg-fill"], [], ["loc", [null, [120, 16], [120, 50]]]]], [], 0, 1, ["loc", [null, [120, 6], [155, 6]]]]],
                                      locals: [],
                                      templates: [child0, child1]
                                    };
                                  }();
                                  return {
                                    meta: {
                                      "revision": "Ember@1.13.13",
                                      "loc": {
                                        "source": null,
                                        "start": {
                                          "line": 113,
                                          "column": 6
                                        },
                                        "end": {
                                          "line": 155,
                                          "column": 6
                                        }
                                      },
                                      "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                    },
                                    arity: 0,
                                    cachedFragment: null,
                                    hasRendered: false,
                                    buildFragment: function buildFragment(dom) {
                                      var el0 = dom.createDocumentFragment();
                                      var el1 = dom.createComment("");
                                      dom.appendChild(el0, el1);
                                      return el0;
                                    },
                                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                      var morphs = new Array(1);
                                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                      dom.insertBoundary(fragment, 0);
                                      dom.insertBoundary(fragment, null);
                                      return morphs;
                                    },
                                    statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [113, 20], [113, 38]]]], "shadows"], [], ["loc", [null, [113, 16], [113, 49]]]]], [], 0, 1, ["loc", [null, [113, 6], [155, 6]]]]],
                                    locals: [],
                                    templates: [child0, child1]
                                  };
                                }();
                                return {
                                  meta: {
                                    "revision": "Ember@1.13.13",
                                    "loc": {
                                      "source": null,
                                      "start": {
                                        "line": 102,
                                        "column": 6
                                      },
                                      "end": {
                                        "line": 155,
                                        "column": 6
                                      }
                                    },
                                    "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                  },
                                  arity: 0,
                                  cachedFragment: null,
                                  hasRendered: false,
                                  buildFragment: function buildFragment(dom) {
                                    var el0 = dom.createDocumentFragment();
                                    var el1 = dom.createComment("");
                                    dom.appendChild(el0, el1);
                                    return el0;
                                  },
                                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                    var morphs = new Array(1);
                                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                    dom.insertBoundary(fragment, 0);
                                    dom.insertBoundary(fragment, null);
                                    return morphs;
                                  },
                                  statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [102, 20], [102, 38]]]], "padding"], [], ["loc", [null, [102, 16], [102, 49]]]]], [], 0, 1, ["loc", [null, [102, 6], [155, 6]]]]],
                                  locals: [],
                                  templates: [child0, child1]
                                };
                              }();
                              return {
                                meta: {
                                  "revision": "Ember@1.13.13",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 95,
                                      "column": 6
                                    },
                                    "end": {
                                      "line": 155,
                                      "column": 6
                                    }
                                  },
                                  "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createComment("");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                  var morphs = new Array(1);
                                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                  dom.insertBoundary(fragment, 0);
                                  dom.insertBoundary(fragment, null);
                                  return morphs;
                                },
                                statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [95, 20], [95, 38]]]], "opacity"], [], ["loc", [null, [95, 16], [95, 49]]]]], [], 0, 1, ["loc", [null, [95, 6], [155, 6]]]]],
                                locals: [],
                                templates: [child0, child1]
                              };
                            }();
                            return {
                              meta: {
                                "revision": "Ember@1.13.13",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 84,
                                    "column": 6
                                  },
                                  "end": {
                                    "line": 155,
                                    "column": 6
                                  }
                                },
                                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                                dom.insertBoundary(fragment, 0);
                                dom.insertBoundary(fragment, null);
                                return morphs;
                              },
                              statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [84, 20], [84, 38]]]], "negative-margin"], [], ["loc", [null, [84, 16], [84, 57]]]]], [], 0, 1, ["loc", [null, [84, 6], [155, 6]]]]],
                              locals: [],
                              templates: [child0, child1]
                            };
                          }();
                          return {
                            meta: {
                              "revision": "Ember@1.13.13",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 77,
                                  "column": 6
                                },
                                "end": {
                                  "line": 155,
                                  "column": 6
                                }
                              },
                              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [77, 20], [77, 38]]]], "min-width"], [], ["loc", [null, [77, 16], [77, 51]]]]], [], 0, 1, ["loc", [null, [77, 6], [155, 6]]]]],
                            locals: [],
                            templates: [child0, child1]
                          };
                        }();
                        return {
                          meta: {
                            "revision": "Ember@1.13.13",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 70,
                                "column": 6
                              },
                              "end": {
                                "line": 155,
                                "column": 6
                              }
                            },
                            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [70, 20], [70, 38]]]], "min-height"], [], ["loc", [null, [70, 16], [70, 52]]]]], [], 0, 1, ["loc", [null, [70, 6], [155, 6]]]]],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }();
                      return {
                        meta: {
                          "revision": "Ember@1.13.13",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 63,
                              "column": 6
                            },
                            "end": {
                              "line": 155,
                              "column": 6
                            }
                          },
                          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [63, 20], [63, 38]]]], "max-width"], [], ["loc", [null, [63, 16], [63, 51]]]]], [], 0, 1, ["loc", [null, [63, 6], [155, 6]]]]],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }();
                    return {
                      meta: {
                        "revision": "Ember@1.13.13",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 56,
                            "column": 6
                          },
                          "end": {
                            "line": 155,
                            "column": 6
                          }
                        },
                        "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [56, 20], [56, 38]]]], "max-height"], [], ["loc", [null, [56, 16], [56, 52]]]]], [], 0, 1, ["loc", [null, [56, 6], [155, 6]]]]],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }();
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 47,
                          "column": 6
                        },
                        "end": {
                          "line": 155,
                          "column": 6
                        }
                      },
                      "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [47, 20], [47, 38]]]], "margin"], [], ["loc", [null, [47, 16], [47, 48]]]]], [], 0, 1, ["loc", [null, [47, 6], [155, 6]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 42,
                        "column": 6
                      },
                      "end": {
                        "line": 155,
                        "column": 6
                      }
                    },
                    "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [42, 20], [42, 38]]]], "line-height"], [], ["loc", [null, [42, 16], [42, 53]]]]], [], 0, 1, ["loc", [null, [42, 6], [155, 6]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 6
                    },
                    "end": {
                      "line": 155,
                      "column": 6
                    }
                  },
                  "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [37, 20], [37, 38]]]], "letter-spacing"], [], ["loc", [null, [37, 16], [37, 56]]]]], [], 0, 1, ["loc", [null, [37, 6], [155, 6]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 6
                  },
                  "end": {
                    "line": 155,
                    "column": 6
                  }
                },
                "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [29, 20], [29, 38]]]], "height"], [], ["loc", [null, [29, 16], [29, 48]]]]], [], 0, 1, ["loc", [null, [29, 6], [155, 6]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 6
                },
                "end": {
                  "line": 155,
                  "column": 6
                }
              },
              "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [24, 20], [24, 38]]]], "font-weights"], [], ["loc", [null, [24, 16], [24, 54]]]]], [], 0, 1, ["loc", [null, [24, 6], [155, 6]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 6
              },
              "end": {
                "line": 155,
                "column": 6
              }
            },
            "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [21, 20], [21, 38]]]], "colors"], [], ["loc", [null, [21, 16], [21, 48]]]]], [], 0, 1, ["loc", [null, [21, 6], [155, 6]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 155,
              "column": 6
            }
          },
          "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [14, 20], [14, 38]]]], "border-widths"], [], ["loc", [null, [14, 16], [14, 55]]]]], [], 0, 1, ["loc", [null, [14, 6], [155, 6]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 164,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/components/etw/module-style-example.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "class", "etw-mb-8 etw-w-1/5 etw-p-2");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "etw-text-center etw-m-4 etw-text-sm ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "etw-text-center etw-m-4 etw-text-sm ");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "etw-mt-3 etw-leading-normal");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "etw-opacity-50");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0]);
        var element21 = dom.childAt(element20, [1, 1]);
        var element22 = dom.childAt(element21, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element20);
        morphs[1] = dom.createMorphAt(element21, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element22, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element22, [3]), 0, 0);
        return morphs;
      },
      statements: [["element", "action", ["selectModuleStyle"], [], ["loc", [null, [1, 3], [1, 33]]]], ["block", "if", [["subexpr", "eq", [["get", "moduleStyle.module", ["loc", [null, [5, 16], [5, 34]]]], "border-radius"], [], ["loc", [null, [5, 12], [5, 51]]]]], [], 0, 1, ["loc", [null, [5, 6], [155, 13]]]], ["content", "moduleStyle.name", ["loc", [null, [158, 11], [158, 31]]]], ["content", "moduleStyle.value", ["loc", [null, [159, 34], [159, 55]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define("presenter/templates/svg/dashboard/pie_chart", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/pie_chart.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "22");
        dom.setAttribute(el1, "height", "22");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M8.058 15.612l5.952 5.94a10.955 10.955 0 0 0 2.149-.856l-6.59-6.553-1.51 1.47zm12.493.888A10.9 10.9 0 0 0 22 12.14h-5.841l4.392 4.36zm-8.328-4.353h-.693l-.575.574 6.896 6.883c.572-.438 1.099-.93 1.575-1.47l-6.023-5.987h-1.18zM9.86 9.79V0A11.019 11.019 0 0 0 .836 6.737a10.983 10.983 0 0 0 1.625 11.13l7.4-7.386V9.79zM12.223 0v9.79H22A11.01 11.01 0 0 0 12.223 0zM4.13 19.533A10.98 10.98 0 0 0 11.042 22h.574l-4.951-4.997-2.535 2.53z");
        dom.setAttribute(el2, "fill-rule", "nonzero");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/survey-compact', ['exports', 'presenter/data', 'presenter/mixins/themeable', 'presenter/mixins/change-confirmation'], function (exports, _data, _themeable, _changeConfirmation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SurveyCompact;

  SurveyCompact = _data.Model.extend(_themeable.default.ContrastProperties, _changeConfirmation.default, {
    name: (0, _data.attr)('string'),
    folderId: (0, _data.attr)('number'),
    isShared: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    questionsCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    completedResponsesCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    createdAt: (0, _data.attr)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    user: (0, _data.belongsTo)('user'),
    newOwner: (0, _data.belongsTo)('user', {
      serialize: 'id'
    }),
    theme: (0, _data.belongsTo)('theme', {
      serialize: 'id'
    }),
    existingSurvey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    publishTo: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    draft: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    surveyShares: (0, _data.hasMany)('survey_share', {
      serialize: false
    }),
    draftDiffers: (0, _data.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    draftName: (0, _data.attr)('string'),
    fontOverride: (0, _data.attr)('string'),
    questionColorOverride: (0, _data.attr)('color'),
    backgroundColorOverride: (0, _data.attr)('color'),
    backgroundImageBrightness: (0, _data.attr)('number'),
    backgroundImageOverride: (0, _data.belongsTo)('image', {
      serialize: 'id'
    }),
    backgroundTypeOverride: (0, _data.attr)('string'),
    touched: (0, _data.attr)('number'),
    storeIpdata: (0, _data.attr)('boolean'),
    shared: Ember.computed.gt('surveyShares.length', 0),
    isDraft: Ember.computed.notEmpty('publishTo'),
    hasDraft: Ember.computed.notEmpty('draft'),
    touch: function touch() {
      return this.set('touched', new Date().getTime());
    }
  });

  SurveyCompact.reopenForEach(_themeable.default.ATTRIBUTES, function (field, SurveyCompact) {
    var override, themeField;
    override = field + "Override";
    themeField = "theme." + field;
    return SurveyCompact[field] = Ember.computed(override, themeField, {
      get: function get(key) {
        var val;
        if (Ember.isEmpty(val = this.get(override))) {
          return this.get(themeField);
        } else {
          return val;
        }
      },
      set: function set(key, value) {
        if (!Ember.isEmpty(value)) {
          if (value === this.get(themeField)) {
            this.set(override, null);
          } else {
            this.set(override, value);
          }
        }
        return value;
      }
    });
  });

  SurveyCompact.reopenClass({
    resourceName: 'survey'
  });

  exports.default = SurveyCompact;
});
define('presenter/helpers/merge-response-values', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    presenterResponseController: Ember.K,
    presenterComponentsController: Ember.K,
    destroy: function destroy() {
      var response;
      this._super(arguments);
      response = this.presenterResponseController.get('model');
      response.off('answerChange', this, this.recompute);
      return this.get('container').lookup('eventBus:survey').off('updateMergeMap', this, this.recompute);
    },
    __onInit: function () {
      var response;
      response = this.presenterResponseController.get('model');
      response.on('answerChange', this, this.recompute);
      return this.get('container').lookup('eventBus:survey').on('updateMergeMap', this, this.recompute);
    }.on('init'),
    compute: function compute(params) {
      return (0, _utils.mergeResponseValues)(params[0], this.presenterResponseController, this.presenterComponentsController);
    }
  });
});
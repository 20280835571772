define('presenter/mixins/input-common', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /* global Modernizr */
  var timerId;

  timerId = null;

  exports.default = Ember.Mixin.create({
    inputStartedAction: 'inputStarted',
    inputEndedAction: 'inputEnded',
    _handleInputStarted: function _handleInputStarted() {
      if (!(_globals.isEmbedded && Modernizr.touch)) {
        return;
      }
      if (timerId) {
        Ember.run.cancel(timerId);
        return timerId = null;
      } else {
        return this.sendAction('inputStartedAction');
      }
    },
    _handleInputEnded: function _handleInputEnded() {
      if (!(_globals.isEmbedded && Modernizr.touch)) {
        return;
      }
      return timerId = Ember.run.later(function (_this) {
        return function () {
          _this.sendAction('inputEndedAction');
          return timerId = null;
        };
      }(this), 50);
    },
    focusIn: function focusIn() {
      this._super.apply(this, arguments);
      return this._handleInputStarted();
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      return this._handleInputEnded();
    }
  });
});
define('presenter/models/custom-dropdown-list', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CustomDomainList;

  CustomDomainList = _data.Model.extend({
    name: (0, _data.attr)('string'),
    list: (0, _data.attr)('raw')
  });

  CustomDomainList.reopenClass({
    resourceName: 'custom_dropdown_list'
  });

  exports.default = CustomDomainList;
});
define('presenter/controllers/modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modalOptions;

  modalOptions = {
    dismissable: false,
    className: null,
    anchor: null,
    fullscreen: false
  };

  exports.default = Ember.Mixin.create({
    modalOptions: null,
    showModal: false,
    actions: {
      setModalOptions: function setModalOptions(options) {
        return this.set('modalOptions', Ember.merge(Ember.copy(modalOptions), options));
      },
      resetModalOptions: function resetModalOptions() {
        return this.set('modalOptions', Ember.copy(modalOptions));
      },
      toggleModal: function toggleModal(show) {
        return this.set('showModal', show);
      }
    }
  });
});
define("presenter/templates/bourbon-new-tab-svg", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/bourbon-new-tab-svg.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 14 14");
        dom.setAttribute(el1, "width", "14");
        dom.setAttribute(el1, "height", "14");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M1.713 1.273a.44.44 0 0 0-.44.44v10.574c0 .243.197.44.44.44h10.574a.44.44 0 0 0 .44-.44V1.713a.44.44 0 0 0-.44-.44H1.713zM14 7.636v4.404A1.96 1.96 0 0 1 12.04 14H1.96A1.96 1.96 0 0 1 0 12.04V1.96A1.96 1.96 0 0 1 1.96 0h4.404v1.273h2.545V0H14v5.09h-1.273v2.546H14zM12.802 0L14 1.198 8.835 6.364 7.636 5.165 12.802 0z");
        dom.setAttribute(el2, "fill-rule", "nonzero");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/custom-domain', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CustomDomain;

  CustomDomain = _data.Model.extend({
    domain: (0, _data.attr)('string')
  });

  CustomDomain.reopenClass({
    resourceName: 'custom_domain'
  });

  exports.default = CustomDomain;
});
define("presenter/templates/presenter/file-upload-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 370
            }
          },
          "moduleName": "presenter/templates/presenter/file-upload-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "FileUpload-imageWrapper");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "alt", "");
          dom.setAttribute(el2, "class", "FileUpload-image");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "FileUpload-removeButton theme-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var element3 = dom.childAt(element2, [0]);
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'aria-label');
          morphs[1] = dom.createAttrMorph(element3, 'src');
          morphs[2] = dom.createElementMorph(element4);
          morphs[3] = dom.createMorphAt(element4, 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter", "alerts", "successful_upload"], [], ["loc", [null, [1, 43], [1, 110]]]]]]], ["attribute", "src", ["get", "component.imageUrl", ["loc", [null, [1, 166], [1, 184]]]]], ["element", "action", ["removeFile"], ["on", "click"], ["loc", [null, [1, 233], [1, 267]]]], ["inline", "i18n", ["presenter.file_upload.remove"], [], ["loc", [null, [1, 322], [1, 361]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 370
            },
            "end": {
              "line": 1,
              "column": 928
            }
          },
          "moduleName": "presenter/templates/presenter/file-upload-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "file-upload-button-mobile theme-button theme-text");
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "FileUpload-fileLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "file-upload-button theme-button-transparent theme-question");
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "FileUpload-fileLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0, 0]);
          var element1 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          morphs[3] = dom.createMorphAt(element1, 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "image-file-field", [], ["classNames", "FileUpload-fileField", "action", "setImage"], ["loc", [null, [1, 480], [1, 552]]]], ["inline", "i18n", ["presenter.file_upload.upload_image"], [], ["loc", [null, [1, 552], [1, 597]]]], ["inline", "image-file-field", [], ["classNames", "FileUpload-fileField", "action", "setImage"], ["loc", [null, [1, 733], [1, 805]]]], ["inline", "i18n", ["presenter.file_upload.drag_drop_or"], [], ["loc", [null, [1, 805], [1, 850]]]], ["inline", "i18n", ["presenter.file_upload.select_image"], [], ["loc", [null, [1, 862], [1, 907]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 935
            },
            "end": {
              "line": 1,
              "column": 1044
            }
          },
          "moduleName": "presenter/templates/presenter/file-upload-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "FileUpload-invalidImage");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["content", "component.errorMessage", ["loc", [null, [1, 1012], [1, 1038]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1055
            },
            "end": {
              "line": 1,
              "column": 1173
            }
          },
          "moduleName": "presenter/templates/presenter/file-upload-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "FileUpload-draggingMask theme-text");
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Drag and drop your file here.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1180
          }
        },
        "moduleName": "presenter/templates/presenter/file-upload-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.imageUrl", ["loc", [null, [1, 6], [1, 24]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 935]]]], ["block", "unless", [["get", "component.isValid", ["loc", [null, [1, 945], [1, 962]]]]], [], 2, null, ["loc", [null, [1, 935], [1, 1055]]]], ["block", "if", [["get", "component.isDragging", ["loc", [null, [1, 1061], [1, 1081]]]]], [], 3, null, ["loc", [null, [1, 1055], [1, 1180]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});
define('presenter/models/recipe-template', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RecipeTemplate;

  RecipeTemplate = _data.Model.extend({
    name: (0, _data.attr)('string'),
    description: (0, _data.attr)('string'),
    category: (0, _data.attr)('string', {
      readOnly: true
    }),
    features: (0, _data.attr)('array'),
    isSaved: (0, _data.attr)('boolean'),
    orderedChildRecipeStepTemplates: (0, _data.hasMany)('recipe_step_template')
  });

  RecipeTemplate.reopenClass({
    resourceName: 'recipe_template'
  });

  exports.default = RecipeTemplate;
});
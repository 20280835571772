define('presenter/models/team', ['exports', 'presenter/globals', 'presenter/data', 'presenter/models/user', 'presenter/mixins/feature-flaggable', 'presenter/mixins/change-confirmation'], function (exports, _globals, _data, _user, _featureFlaggable, _changeConfirmation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Team;

  Team = _data.Model.extend(_changeConfirmation.default, _featureFlaggable.default, {
    completedResponsesLimit: (0, _data.attr)('number', {
      readOnly: true
    }),
    completedResponsesCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    completedResponsesInterval: (0, _data.attr)('string', {
      readOnly: true
    }),
    nextUsageResetAt: (0, _data.attr)('date', {
      readOnly: true
    }),
    cardDescription: (0, _data.attr)('string', {
      readOnly: true
    }),
    whitelabeled: (0, _data.attr)('boolean'),
    subdomain: (0, _data.attr)('string'),
    domain: (0, _data.attr)('string'),
    features: (0, _data.attr)('raw', {
      readOnly: true
    }),
    switchedOnFeatures: (0, _data.attr)('raw', {
      readOnly: true
    }),
    membersLimit: (0, _data.attr)('number'),
    token: (0, _data.attr)('string', {
      readOnly: true
    }),
    customizations: (0, _data.attr)('raw', {
      readOnly: true
    }),
    plan: (0, _data.belongsTo)('plan', {
      serialize: 'id'
    }),
    logoMediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    billingContactAddress: (0, _data.belongsTo)('address', {
      serialize: true
    }),
    users: (0, _data.hasMany)('team_user', {
      serialize: true
    }),
    customDropdowns: (0, _data.hasMany)('custom_dropdown_list', {
      serialize: false
    }),
    customDomains: (0, _data.hasMany)('custom_domain', {
      serialize: false
    }),
    cmSentCount: (0, _data.attr)('number'),
    defaultFromEmail: (0, _data.attr)('string', {
      readOnly: true
    }),
    upgradePlanTag: (0, _data.attr)('string'),
    freeSalesforceExpired: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    encryptionKeys: (0, _data.attr)('raw', {
      readOnly: true
    }),
    generateNewEncryptionKey: (0, _data.attr)('boolean'),
    removedEncryptionKey: (0, _data.attr)('string'),
    credentials: (0, _data.hasMany)('credential'),
    salesforceConnectionsLimit: (0, _data.attr)('number', {
      readOnly: true
    }),
    pardotConnectionsLimit: (0, _data.attr)('number', {
      readOnly: true
    }),
    crmLeadId: (0, _data.attr)('string', {
      readOnly: true
    }),
    activeSparkpostSendingDomains: (0, _data.attr)('raw', {
      readOnly: true
    }),
    activeCustomSparkpostSendingDomains: (0, _data.attr)('raw', {
      readOnly: true
    }),
    physicalAddress: (0, _data.attr)('string'),
    minimumPasswordLength: (0, _data.attr)('number'),
    surveySalesforceConnectionsLimit: (0, _data.attr)('number', {
      readOnly: true
    }),
    upcomingTrialExpirationAt: (0, _data.attr)('date', {
      readOnly: true
    }),
    currentlyInTrial: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    trialExpired: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    salesforceTrialExpired: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    nonSalesforceTrialExpired: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    currentlyInSalesforceTrial: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    currentlyInNonSalesforceTrial: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    enhancedTrial: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    owner: function () {
      return this.get('users').findBy('permissionsOwner');
    }.property('users.@each.permissionsOwner'),
    members: Ember.computed.filterBy('users', 'permissionsOwner', false),
    invitedOrActiveUsers: function () {
      return this.get('users').filter(function (u) {
        return u.get('isActive') || u.get('isInvited');
      });
    }.property('users.@each.{isActive,isInvited}'),
    invitedOrActiveMembers: function () {
      return this.get('members').filter(function (u) {
        return u.get('isActive') || u.get('isInvited');
      });
    }.property('members.@each.{isActive,isInvited}'),
    embedScript: function () {
      return Team.JS_SNIPPET.replace('*TEAM_TOKEN*', this.get('token'));
    }.property('token'),
    salesforceCredentials: function () {
      return this.get('credentials').filterBy('type', 'salesforce');
    }.property('credentials'),
    slackCredentials: Ember.computed('credentials', function () {
      return this.get('credentials').filterBy('type', 'slack');
    }),
    pardotCredentials: Ember.computed('credentials', function () {
      return this.get('credentials').filterBy('type', 'pardot');
    }),
    cannotUseOpenQuestions: Ember.computed('currentlyInTrial', 'enhancedTrial', function () {
      return this.get('currentlyInTrial') && !this.get('enhancedTrial');
    })
  });

  Team.reopenForEach(_user.default.STATUSES, function (status, Team) {
    var filterProp;
    filterProp = "is" + status.classify();
    return Team[status + "Members"] = function () {
      return this.get('members').filterBy(filterProp).sortBy('id');
    }.property("members.@each." + filterProp);
  });

  Team.JS_SNIPPET = _globals.codeSettings.campaignsEmbedJsSnippet;

  Team.reopenClass({
    resourceName: 'team'
  });

  exports.default = Team;
});
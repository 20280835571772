define('presenter/components/presenter/default-page-component', ['exports', 'presenter/components/presenter/component', 'presenter/globals'], function (exports, _component, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['DefaultPage'],
    isIframed: _globals.isIframed,
    isEmbedded: _globals.isEmbedded,
    iframeUrl: null,
    iframeUrlSet: Ember.computed.bool('component.model.iframe'),
    isIE9: Modernizr.ie9,
    _setIframeUrl: function () {
      var $iframe, autoresolveTimer, deferred, setIframeSource;
      if (!this.get('iframeUrlSet')) {
        return;
      }
      if (this.get('component.response.submitting')) {
        deferred = Ember.RSVP.defer();
        this.set('component.iframeLoadedPromise', deferred.promise);
        autoresolveTimer = Ember.run.later(function () {
          if (deferred.promise._state !== 1) {
            deferred.resolve();
            return Raven.captureMessage('Presenter iframe promise not resolved in time.');
          }
        }, 10000);
        deferred.promise.then(function () {
          return Ember.run.cancel(autoresolveTimer);
        });
        setIframeSource = function (_this) {
          return function () {
            $iframe.attr('src', _this.get('component.model.iframe'));
            return $iframe[0].onload = function () {
              return deferred.resolve(true);
            };
          };
        }(this);
        if ($iframe = this.$('iframe')) {
          return setIframeSource();
        } else {
          return Ember.run.schedule('afterRender', function (_this) {
            return function () {
              if ($iframe = _this.$('iframe')) {
                return setIframeSource();
              }
            };
          }(this));
        }
      }
    }.observes('component.response.submitting').on('init')
  });
});
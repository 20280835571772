define("presenter/templates/svg/dashboard/gauge", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/gauge.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M32 15C14.4 15 0 29.4 0 47v1h12.1v-1c0-6.9 3.5-13 8.9-16.6L32 48h2.4L22.7 29.4c2.8-1.5 5.9-2.3 9.3-2.3 11 0 19.9 8.9 19.9 19.9v1H64v-1c0-17.6-14.4-32-32-32zM10.1 46H2c.3-10.1 5.7-18.9 13.6-24.1l4.3 6.9c-5.6 3.7-9.5 10-9.8 17.2zm43.8 0C53.4 34.4 43.7 25.1 32 25.1c-3.8 0-7.3 1-10.4 2.6l-4.3-6.9C21.6 18.4 26.7 17 32 17c16.2 0 29.5 12.9 30 29h-8.1z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/question_types/_redirect", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_redirect.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M52 42c-.6 0-1 .4-1 1v17H3V16h13c.6 0 1-.4 1-1s-.4-1-1-1H2c-.6 0-1 .4-1 1v46c0 .6.4 1 1 1h50c.6 0 1-.4 1-1V43c0-.6-.4-1-1-1z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M39.7 3.2c-.3-.3-.7-.3-1.1-.2-.4.3-.6.6-.6 1v11c-12.8.2-27 4-27 29 0 .4.3.8.7 1h.3c.3 0 .6-.2.8-.4C18 37.1 23.4 33.2 38 33v11c0 .4.2.7.6.9.4.2.8.1 1.1-.2l23-20c.2-.2.3-.5.3-.8s-.1-.6-.3-.8l-23-19.9z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
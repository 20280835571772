define("presenter/templates/presenter/multiple-choice-choice", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 528
          }
        },
        "moduleName": "presenter/templates/presenter/multiple-choice-choice.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'role');
        morphs[1] = dom.createAttrMorph(element0, 'aria-checked');
        morphs[2] = dom.createAttrMorph(element0, 'aria-describedby');
        morphs[3] = dom.createAttrMorph(element0, 'class');
        morphs[4] = dom.createElementMorph(element0);
        morphs[5] = dom.createAttrMorph(element1, 'class');
        morphs[6] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["attribute", "role", ["get", "role", ["loc", [null, [1, 15], [1, 19]]]]], ["attribute", "aria-checked", ["get", "isSelected", ["loc", [null, [1, 37], [1, 47]]]]], ["attribute", "aria-describedby", ["concat", ["question-title-", ["get", "target.id", ["loc", [null, [1, 123], [1, 132]]]]]]], ["attribute", "class", ["concat", ["choice-wrapper presenter-multiple-choice-choice theme-answer ", ["subexpr", "if", [["get", "isSelected", ["loc", [null, [1, 209], [1, 219]]]], "theme-selected", "theme-background-screen"], [], ["loc", [null, [1, 204], [1, 264]]]], " ", ["subexpr", "if", [["get", "choice.isSelected", ["loc", [null, [1, 270], [1, 287]]]], "", "theme-border-20"], [], ["loc", [null, [1, 265], [1, 310]]]], " ", ["subexpr", "if", [["get", "choice.isOther", ["loc", [null, [1, 316], [1, 330]]]], "is-other"], [], ["loc", [null, [1, 311], [1, 343]]]], " ", ["subexpr", "if", [["get", "isSelected", ["loc", [null, [1, 349], [1, 359]]]], "is-selected"], [], ["loc", [null, [1, 344], [1, 375]]]]]]], ["element", "action", ["toggle", ["get", "choice", ["loc", [null, [1, 68], [1, 74]]]]], ["on", "click"], ["loc", [null, [1, 50], [1, 87]]]], ["attribute", "class", ["concat", ["choice-text ", ["subexpr", "if", [["get", "rightToLeft", ["loc", [null, [1, 406], [1, 417]]]], "right-to-left"], [], ["loc", [null, [1, 401], [1, 435]]]]]]], ["inline", "merge-response-values", [["subexpr", "translated-properties", ["choices", ["get", "choice.id", ["loc", [null, [1, 494], [1, 503]]]], "text"], [], ["loc", [null, [1, 461], [1, 511]]]]], [], ["loc", [null, [1, 437], [1, 513]]]]],
      locals: [],
      templates: []
    };
  }());
});
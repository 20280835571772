define("presenter/templates/components/controls/inline-select", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 139
              },
              "end": {
                "line": 1,
                "column": 176
              }
            },
            "moduleName": "presenter/templates/components/controls/inline-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "selectedLabel", ["loc", [null, [1, 159], [1, 176]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 184
                },
                "end": {
                  "line": 1,
                  "column": 218
                }
              },
              "moduleName": "presenter/templates/components/controls/inline-select.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "placeholder", ["loc", [null, [1, 203], [1, 218]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 218
                },
                "end": {
                  "line": 1,
                  "column": 232
                }
              },
              "moduleName": "presenter/templates/components/controls/inline-select.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 176
              },
              "end": {
                "line": 1,
                "column": 239
              }
            },
            "moduleName": "presenter/templates/components/controls/inline-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "placeholder", ["loc", [null, [1, 190], [1, 201]]]]], [], 0, 1, ["loc", [null, [1, 184], [1, 239]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 296
              },
              "end": {
                "line": 1,
                "column": 517
              }
            },
            "moduleName": "presenter/templates/components/controls/inline-select.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "inline-select-option", [], ["model", ["subexpr", "@mut", [["get", "option", ["loc", [null, [1, 354], [1, 360]]]]], [], []], "selectedOption", ["subexpr", "@mut", [["get", "selectedOption", ["loc", [null, [1, 376], [1, 390]]]]], [], []], "optionLabelPath", ["subexpr", "@mut", [["get", "optionLabelPath", ["loc", [null, [1, 407], [1, 422]]]]], [], []], "optionValuePath", ["subexpr", "@mut", [["get", "optionValuePath", ["loc", [null, [1, 439], [1, 454]]]]], [], []], "optionEnabledPath", ["subexpr", "@mut", [["get", "optionEnabledPath", ["loc", [null, [1, 473], [1, 490]]]]], [], []], "select", ["subexpr", "action", ["select"], [], ["loc", [null, [1, 498], [1, 515]]]]], ["loc", [null, [1, 325], [1, 517]]]]],
          locals: ["option"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 531
            }
          },
          "moduleName": "presenter/templates/components/controls/inline-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "data-role", "trigger");
          dom.setAttribute(el1, "class", "InlineSelect-selection");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "InlineSelect-dropdownMenu js-menu");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasSelection", ["loc", [null, [1, 145], [1, 157]]]]], [], 0, 1, ["loc", [null, [1, 139], [1, 246]]]], ["block", "each", [["get", "options", ["loc", [null, [1, 304], [1, 311]]]]], [], 2, null, ["loc", [null, [1, 296], [1, 526]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 545
          }
        },
        "moduleName": "presenter/templates/components/controls/inline-select.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "drop-down", [], ["classNames", "InlineSelect-dropdown", "readOnly", ["subexpr", "@mut", [["get", "readOnly", ["loc", [null, [1, 57], [1, 65]]]]], [], []], "setFixed", ["subexpr", "@mut", [["get", "setFixed", ["loc", [null, [1, 75], [1, 83]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [1, 545]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define('presenter/controllers/presenter/form', ['exports', 'presenter/utils', 'presenter/controllers/presenter/component', 'presenter/models/form-field'], function (exports, _utils, _component, _formField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    response: Ember.inject.controller('presenter/response'),
    surveyController: Ember.inject.controller('presenter/survey'),
    _initAnswerFormFields: function () {
      var len;
      if (!(this.get('answer') && this.get('isCurrent'))) {
        return;
      }
      if (len = this.get('answer.formFields.length')) {
        return this.get('answer.formFields').replaceContent(0, len, this.get('formFields'));
      } else {
        return this.get('answer.formFields').pushObjects(this.get('formFields'));
      }
    }.observes('answer', 'isCurrent'),
    formFields: function () {
      return this.get('choices').map(function (_this) {
        return function (choice) {
          return _this.findFormFieldBy(choice) || _this.createFormFieldFor(choice);
        };
      }(this));
    }.property(),
    formFieldsNotHiddenByPiping: null,
    formFieldsAllHiddenByMergeFields: Ember.computed('formFields.@each.isHidden', function () {
      return this.get('formFields.length') > 0 && this.get('formFields').isEvery('isHidden', true);
    }),
    findFormFieldBy: function findFormFieldBy(choice) {
      var formField, ref;
      formField = (ref = this.get('answer.formFields')) != null ? ref.findBy('choice.id', choice.get('id')) : void 0;
      if (formField != null && formField.get('choice.formFieldType') == null) {
        if (choice = this.get('choices').findBy('id', choice.get('id'))) {
          formField.setProperties({
            choice: choice,
            valid: true
          });
        }
      }
      if (formField) {
        formField.setProperties({
          isPrepopulated: true,
          shouldClearPrepopulatedValue: true,
          passedInitialValidationCheck: false
        });
      }
      return formField;
    },
    createFormFieldFor: function createFormFieldFor(choice) {
      return _formField.default.create({
        choice: choice,
        answer: Ember.computed.oneWay('choice.component.answer')
      });
    },
    required: function () {
      return this.get('content.required') && !Ember.isEmpty(this.get('choices'));
    }.property('content.required', 'choices.[]'),
    choicesRequired: Ember.computed('model.choices.[]', function () {
      return this.get('model.choices').any(function (f) {
        return f.get('required');
      });
    }),
    noChoicesRequired: Ember.computed.not('choicesRequired'),
    valid: function () {
      return this.get('formFields').isEvery('valid');
    }.property('formFields.@each.valid'),
    completed: function () {
      return (!this.get('answer') || this.get('hasAnswer') || !this.get('required') && this.get('noChoicesRequired')) && this.get('valid');
    }.property('answer', 'hasAnswer', 'required', 'valid'),
    pipingAnswer: function pipingAnswer(itemIndex) {
      var ref, ref1;
      if (this.get('answer') && Number.isFinite(itemIndex)) {
        return (ref = this.get('answer').get('formFields')) != null ? (ref1 = ref.objectAt(itemIndex)) != null ? ref1.get('text') : void 0 : void 0;
      }
    },
    hasAnswer: function () {
      var checkField, checkFieldIfRequired;
      if (!this.get('answer.formFields.length')) {
        return;
      }
      checkField = function checkField(field) {
        return field.get('text') && !Ember.isEmpty(field.get('text').trim());
      };
      checkFieldIfRequired = function checkFieldIfRequired(field) {
        if (field.get('choice.required')) {
          return checkField(field);
        }
        return true;
      };
      return this.get('valid') && (this.get('required') ? this.get('answer.formFields').every(checkField) : this.get('answer.formFields').every(checkFieldIfRequired));
    }.property('answer.formFields.@each.text', 'required', 'valid'),
    skipQuestionIfMissingTitle: function skipQuestionIfMissingTitle() {
      return false;
    },
    skipQuestionIfEmpty: function skipQuestionIfEmpty() {
      this.computeFormFieldsFilteredByPiping(this.get('formFields'));
      if (this.get('formFieldsNotHiddenByPiping') !== null && this.get('formFieldsNotHiddenByPiping.length') === 0) {
        if (this.get('components.lastNavigationDirection') === 'next') {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      return false;
    },
    computeFormFieldsFilteredByPiping: function computeFormFieldsFilteredByPiping(formFields) {
      var componentCanHideMergeFields, componentsController, controller, filtered;
      controller = this;
      if (this.get('required')) {
        this.set('formFieldsNotHiddenByPiping', formFields);
        return;
      }
      componentsController = this.get('components');
      componentCanHideMergeFields = controller.get('canHideMergeFields');
      filtered = formFields.filter(function (formField) {
        var label, missing;
        if (formField.get('choice.required') || formField.get('isPrepopulated') || componentCanHideMergeFields && formField.get('choice.shouldHideMergeField')) {
          return true;
        }
        label = formField.get('choice.text');
        missing = (0, _utils.isMissingAnyPipedAnswer)(label, componentsController);
        if (missing) {
          controller.clearAnswer(formField);
        }
        return !missing;
      });
      return this.set('formFieldsNotHiddenByPiping', filtered);
    },
    clearAnswer: function clearAnswer(formField) {
      if (!this.get('answer')) {
        return;
      }
      return formField.setProperties({
        text: null,
        valid: true,
        shouldClearDateField: true
      });
    },
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.formFields.@each.text'),
    showLegend: Ember.computed('formFields.@each.choice.required', function () {
      var choiceRequired;
      choiceRequired = function choiceRequired(field) {
        return field.get('choice.required');
      };
      return this.get('formFields').any(choiceRequired);
    }),
    showPrivacyNotice: Ember.computed.oneWay('surveyController.showPrivacyNotice')
  });
});
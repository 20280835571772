define('presenter/components/progress-indicator', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'ProgressIndicator',
    classNameBindings: ['isComplete', 'showFilterPanel:with-filterPanel'],
    layoutName: 'components/controls/progress-indicator',
    current: 0,
    total: 0,
    percentComplete: function () {
      return this.get('current') / this.get('total') * 100;
    }.property('current', 'total'),
    percentDisplay: function () {
      return _i18nJs.default.toPercentage(this.get('percentComplete'), {
        precision: 0
      });
    }.property('percentComplete'),
    isComplete: Ember.computed.equal('percentComplete', 100),
    didInsertElement: function didInsertElement() {
      this._super();
      return this._updateBar();
    },
    _updateBar: function () {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          var ref;
          return (ref = _this.$('.js-bar')) != null ? ref.width(_this.get('percentComplete') + "%") : void 0;
        };
      }(this));
    }.observes('percentComplete')
  });
});
define('presenter/components/media-image', ['exports', 'presenter/mixins/resize-handler', 'presenter/models/image', 'presenter/utils/layout'], function (exports, _resizeHandler, _image, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resizeHandler.default, {
    layoutName: 'components/controls/media-image',
    classNames: ['MediaImage'],
    classNameBindings: ['media.frame'],
    attributeBindings: ['alt'],
    media: null,
    canvasScale: null,
    fixedConstraints: null,
    aspectRatio: function () {
      return this.get('media.canvasWidth') / this.get('media.canvasHeight');
    }.property('media.canvasWidth', 'media.canvasHeight'),
    adjustedScale: function () {
      return this.get('media.scale') * this.get('canvasScale');
    }.property('media.scale', 'canvasScale'),
    scaledWidth: function () {
      return this.get('media.nativeWidth') * this.get('adjustedScale');
    }.property('media.nativeWidth', 'adjustedScale'),
    scaledHeight: function () {
      return this.get('media.nativeHeight') * this.get('adjustedScale');
    }.property('media.nativeHeight', 'adjustedScale'),
    scaledOffset: function () {
      return {
        x: this.get('media.offset.x') * this.get('canvasScale'),
        y: this.get('media.offset.y') * this.get('canvasScale')
      };
    }.property('media.offset.x', 'media.offset.y', 'canvasScale'),
    wrapperStyle: function () {
      if (this.get('fixedConstraints')) {
        return "text-align: center;";
      } else {
        return ("padding-bottom: " + 1 / this.get('aspectRatio') * 100 + "%;").htmlSafe();
      }
    }.property('aspectRatio'),
    imageStyle: function () {
      if (this.get('fixedConstraints')) {
        return ("max-width:  " + this.get('fixedConstraints').split('x')[0] + "px;\nmax-height: " + this.get('fixedConstraints').split('x')[1] + "px;\nposition:   relative;").htmlSafe();
      } else {
        return ("margin-left: " + this.get('scaledWidth') / -2 + "px;\nmargin-top:  " + this.get('scaledHeight') / -2 + "px;\nwidth:       " + this.get('scaledWidth') + "px;\nheight:      " + this.get('scaledHeight') + "px;\nleft:        " + this.get('scaledOffset.x') + "px;\ntop:         " + this.get('scaledOffset.y') + "px;").htmlSafe();
      }
    }.property('scaledWidth', 'scaledHeight', 'scaledOffset.x', 'scaledOffset.y'),
    responsiveUrl: function () {
      var height, imageSize, maxSize, scale, width;
      scale = this.get('media.scale');
      width = this.get('media.nativeWidth');
      height = this.get('media.nativeHeight');
      maxSize = Math.max(width, height) * _layout.default.get('viewportWidthScale') * scale;
      imageSize = _image.default.WIDTHS.find(function (size) {
        return maxSize <= size;
      });
      return imageSize && this.get("media.image.url" + imageSize) || this.get('media.image.url');
    }.property('media.scale', 'media.nativeWidth', 'media.nativeHeight'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      return this.onResize();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      return this.$('img').attr('src', null);
    },
    onResize: function onResize() {
      return Ember.run.next(function (_this) {
        return function () {
          return _this.set('canvasScale', _this.$().width() / _this.get('media.canvasWidth'));
        };
      }(this));
    }
  });
});
define('presenter/initializers/restless', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'restless',
    before: 'RESTless.Client',
    initialize: function initialize(instance, app) {
      return app.set('Client', _data.Client);
    }
  };
});
define('presenter/initializers/adapter', ['exports', 'presenter/models/answer'], function (exports, _answer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'adapter',
    initialize: function initialize() {
      return _answer.default.reopenClass({
        endpoint: 'answers'
      });
    }
  };
});
define('presenter/mixins/head-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    headData: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      return this.set('headData.controller', controller);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      return this.set('headData.controller', null);
    }
  });
});
define("presenter/utils/register-controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Container;

  Container = null;

  var init = exports.init = function init(container) {
    return Container = container;
  };

  var register = exports.register = function register(name, obj) {
    if (!!lookup(name)) {
      return;
    }
    return Container._registry.register("controller:" + name, obj);
  };

  var lookup = exports.lookup = function lookup(name) {
    return Container.lookup("controller:" + name);
  };
});
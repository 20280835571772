define('presenter/models/abstract-condition', ['exports', 'presenter/globals', 'presenter/data'], function (exports, _globals, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AbstractCondition,
      operators,
      operatorsByType,
      operatorsForMergeValue,
      ref,
      type,
      validOperators,
      hasProp = {}.hasOwnProperty;

  AbstractCondition = _data.Model.extend({
    lhsComponent: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    lhsGridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    lhsChoice: (0, _data.belongsTo)('choice', {
      serialize: 'id'
    }),
    lhsMergeKey: (0, _data.attr)('string'),
    operator: (0, _data.attr)('string'),
    rhsChoices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    rhs: (0, _data.attr)('string'),
    operators: Ember.computed('lhsComponent.type', 'lhsMergeKey', 'lhsLanguage', function () {
      var lhsComponent;
      if (lhsComponent = this.get('lhsComponent')) {
        if (this.get('lhsChoice.isNumber')) {
          return AbstractCondition.VALID_OPERATORS_BY_COMPONENT_TYPE.get('Number').filter(function (op) {
            return Ember.isEmpty(op.enabled);
          });
        } else {
          return AbstractCondition.VALID_OPERATORS_BY_COMPONENT_TYPE.get(this.get('lhsComponent.type')).filter(function (op) {
            return (Ember.isEmpty(op.enabled) || op.enabled(lhsComponent)) && (Ember.isEmpty(op.disabled) || !op.disabled(lhsComponent));
          });
        }
      } else if (this.get('lhsMergeKey')) {
        if (this.get('lhsLanguage')) {
          return AbstractCondition.VALID_OPERATORS_FOR_LANGUAGE;
        } else {
          return AbstractCondition.VALID_OPERATORS_FOR_MERGE_VALUE;
        }
      }
    }),
    requireRhs: Ember.computed('operator', function () {
      if (this.get('operator')) {
        return AbstractCondition.OPERATORS.get(this.get('operator')).requireRhs;
      }
    }),
    lhsLanguage: function () {
      return this.get('lhsMergeKey') === _globals.codeSettings.languageKeyForCondition;
    }.property('lhsMergeKey'),
    validRhs: function () {
      return !AbstractCondition.OPERATORS.get(this.get('operator')).requireRhs || !!(this.get('rhs') || this.get('rhsChoices.length'));
    }.property('operator', 'rhs', 'rhsChoices.length', 'lhsChoice'),
    validRhsRange: function () {
      var rangeOperator, rhsArray;
      if (this.get('lhsChoice.isNumber')) {
        rangeOperator = this.get('operator') === 'between';
        if (!rangeOperator && Number.isFinite(Number(this.get('rhs')))) {
          return true;
        } else if (rangeOperator && this.get('rhs')) {
          rhsArray = Ember.A(this.get('rhs').split(',').map(function (i) {
            return Number(i);
          }).concat([null]).slice(0, 2));
          if (Number.isFinite(rhsArray.get('firstObject')) && Number.isFinite(rhsArray.get('lastObject'))) {
            return true;
          }
        }
      } else {
        return true;
      }
    }.property('rhs', 'lhsComponent', 'lhsChoice'),
    textRepresentation: Ember.computed('lhsComponent', 'lhsMergeKey', 'operator', 'rhs', 'rhsChoices.@each', 'lhsChoice.text', 'lhsComponent.{formattedTitle,isFormType,id}', function () {
      var text;
      text = '';
      if (this.get('lhsComponent')) {
        text += 'the answer to ';
        text += this.get('lhsComponent.formattedTitle') + ' ';
        if (this.get('lhsComponent.isFormType')) {
          text += '- ' + this.get('lhsChoice.text') + ' ';
        }
      } else if (this.get('lhsMergeKey')) {
        if (this.get('lhsLanguage')) {
          text += 'the response language ';
        } else {
          text += "the " + this.get('lhsMergeKey') + " merge field ";
        }
      }
      if (this.get('operator')) {
        text += AbstractCondition.OPERATORS.get(this.get('operator')).label;
      }
      if (this.get('rhs')) {
        text += ' ' + this.get('rhs');
      } else if (this.get('rhsChoices')) {
        this.get('rhsChoices').forEach(function (choice) {
          return text += ' ' + choice.get('text');
        });
      }
      return text;
    })
  });

  operators = [{
    value: 'is_any_of',
    label: 'includes',
    requireRhs: true,
    enabled: function enabled(component) {
      return component.get('multipleAnswers');
    }
  }, {
    value: 'is_none_of',
    label: 'does not include',
    requireRhs: true,
    enabled: function enabled(component) {
      return component.get('multipleAnswers');
    }
  }, {
    value: 'equal',
    label: 'is equal to',
    requireRhs: true,
    disabled: function disabled(component) {
      return component.get('multipleAnswers');
    }
  }, {
    value: 'not_equal',
    label: 'is not equal to',
    requireRhs: true,
    disabled: function disabled(component) {
      return component.get('multipleAnswers');
    }
  }, {
    value: 'contains',
    label: 'contains',
    requireRhs: true
  }, {
    value: 'does_not_contain',
    label: 'doesn\'t contain',
    requireRhs: true
  }, {
    value: 'greater_than',
    label: 'is greater than',
    requireRhs: true
  }, {
    value: 'greater_than_or_equal',
    label: 'is greater than or equal to',
    requireRhs: true
  }, {
    value: 'less_than',
    label: 'is less than',
    requireRhs: true
  }, {
    value: 'less_than_or_equal',
    label: 'is less than or equal to',
    requireRhs: true
  }, {
    value: 'between',
    label: 'is between',
    requireRhs: true
  }, {
    value: 'is_blank',
    label: 'is blank',
    requireRhs: false
  }, {
    value: 'is_present',
    label: 'is not blank',
    requireRhs: false
  }, {
    value: 'is_promoter',
    label: 'is promoter',
    requireRhs: false
  }, {
    value: 'is_detractor',
    label: 'is detractor',
    requireRhs: false
  }, {
    value: 'is_passive',
    label: 'is passive',
    requireRhs: false
  }];

  AbstractCondition.OPERATORS = Ember.Map.create();

  operators.forEach(function (operator) {
    return AbstractCondition.OPERATORS.set(operator.value, operator);
  });

  AbstractCondition.VALID_OPERATORS_BY_COMPONENT_TYPE = operatorsByType = Ember.Map.create();

  ref = _globals.codeSettings.conditionValidOperatorsByComponentType;
  for (type in ref) {
    if (!hasProp.call(ref, type)) continue;
    validOperators = ref[type];
    operatorsByType.set(type, operators.filter(function (operator) {
      return validOperators.contains(operator.value);
    }));
  }

  operatorsForMergeValue = _globals.codeSettings.conditionValidOperatorsForMergeValue;

  AbstractCondition.VALID_OPERATORS_FOR_MERGE_VALUE = operators.filter(function (operator) {
    return operatorsForMergeValue.contains(operator.value);
  });

  AbstractCondition.VALID_OPERATORS_FOR_LANGUAGE = operators.filter(function (operator) {
    return ['equal', 'not_equal'].contains(operator.value);
  });

  AbstractCondition.LANGUAGE_KEY_FOR_CONDITION = _globals.codeSettings.languageKeyForCondition;

  exports.default = AbstractCondition;
});
define('presenter/mixins/modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      showModal: function showModal(options) {
        this.container.lookup("route:" + this.router.currentRouteName).render(options.templateName, {
          into: 'application',
          outlet: 'modal',
          controller: options.controller,
          model: options.model
        });
        if (typeof options.setupController === "function") {
          options.setupController(this.controllerFor(options.controller));
        }
        this.controllerFor('application').send('setModalOptions', Ember.Object.create(options).getProperties('dismissable', 'className', 'anchor', 'fullscreen'));
        return this.controllerFor('application').send('toggleModal', true);
      },
      hideModal: function hideModal() {
        return this.controllerFor('application').send('toggleModal', false);
      },
      disconnectModal: function disconnectModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
        return this.controllerFor('application').send('resetModalOptions');
      }
    }
  });
});
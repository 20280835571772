define('presenter/utils/development', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.init = undefined;
  var App, Dev;

  App = null;

  Dev = {
    lookup: function lookup(str) {
      if (!App) {
        return;
      }
      return App.__container__.lookup(str);
    },
    controller: function controller(str) {
      return Dev.lookup("controller:" + str);
    },
    route: function route(str) {
      return Dev.lookup('router:main').router.getHandler(str);
    },
    view: function view(id) {
      var views;
      views = Dev.lookup('-view-registry:main');
      return views[id] || views["ember" + id];
    }
  };

  var init = exports.init = function init(app) {
    var currentUser;
    if (!_globals.debug) {
      return;
    }
    $(document).on('ajaxError', function (event, jqXHR) {
      if (jqXHR.status === 500) {
        return window.open('/__better_errors', 'ajaxError');
      }
    });
    App = app;
    if (currentUser = Dev.controller('currentUser')) {
      Dev.currentUser = currentUser;
      Dev.userId = currentUser.get('model.id');
    }
    return window.Dev = Dev;
  };

  exports.default = Dev;
});
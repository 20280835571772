define('presenter/components/presenter/date-field', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['withNoLabel'],
    layoutName: 'presenter/components/controls/date-field',
    iso8601Format: 'YYYY-MM-DD',
    updateFieldProperty: null,
    monthValue: null,
    dayValue: null,
    yearValue: null,
    hasChildValue: Ember.computed.or('monthValue', 'dayValue', 'yearValue'),
    monthFocused: false,
    dayFocused: false,
    yearFocused: false,
    monthPlaceholder: function () {
      return this._placeholderFor('month');
    }.property(),
    dayPlaceholder: function () {
      return this._placeholderFor('day');
    }.property(),
    yearPlaceholder: function () {
      return this._placeholderFor('year');
    }.property(),
    focusedIn: false,
    labelText: null,
    labelTextWithDefault: function () {
      return this.get('labelText') || this.get('dateFormat');
    }.property('labelText', 'dateFormat'),
    labelIsHidden: false,
    withNoLabel: Ember.computed.empty('labelText'),
    firstElementFocused: false,
    inputStartedAction: 'inputStarted',
    inputEndedAction: 'inputEnded',
    mobile: function () {
      return Modernizr.mobile;
    }.property(),
    valid: true,
    value: Ember.computed('dateString', {
      get: function get(key) {
        return this.get('dateString');
      },
      set: function set(key, value) {
        var dateParts;
        if (!this.get('hasChildValue') && value && !this.get('mobile')) {
          dateParts = value.split('-');
          this.setProperties({
            monthValue: dateParts.objectAt(1),
            dayValue: dateParts.objectAt(2),
            yearValue: dateParts.objectAt(0)
          });
        }
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super();
      this._handleLabelVisibility();
      return this._handleMobileStyle();
    },
    handleWasFocused: function () {
      if (this.get('monthFocused') || this.get('dayFocused') || this.get('yearFocused')) {
        this.set('focusedIn', true);
      } else {
        this.set('focusedIn', false);
      }
      return this._handleLabelVisibility();
    }.observes('monthFocused', 'dayFocused', 'yearFocused'),
    dateFormat: null,
    fieldOrder: function () {
      return this.get('dateFormat').split('-').map(function (datePart) {
        switch (datePart) {
          case 'yyyy':
            return 'year';
          case 'mm':
            return 'month';
          case 'dd':
            return 'day';
        }
      });
    }.property('dateFormat'),
    format: Ember.computed.oneWay('iso8601Format'),
    currentElementName: function () {
      return this.get('fieldOrder').find(function (_this) {
        return function (name) {
          return _this.get(name + "Focused");
        };
      }(this));
    }.property('monthFocused', 'dayFocused', 'yearFocused', 'fieldOrder.[]'),
    nextElementName: function () {
      var currentElementName, fieldOrder, index;
      currentElementName = this.get('currentElementName');
      fieldOrder = this.get('fieldOrder');
      index = fieldOrder.indexOf(currentElementName) + 1;
      return fieldOrder[index];
    }.property('currentElementName', 'fieldOrder.[]'),
    isLastElement: function () {
      var currentElementName, fieldOrder, lastElementName, length;
      currentElementName = this.get('currentElementName');
      fieldOrder = this.get('fieldOrder');
      length = fieldOrder.length;
      lastElementName = this.get('fieldOrder.lastObject');
      return currentElementName === lastElementName;
    }.property('currentElementName', 'fieldOrder.[]'),
    firstElementFocusedChanged: function () {
      var firstElementName;
      if (!this.get('firstElementFocused')) {
        return;
      }
      firstElementName = this.get('fieldOrder')[0];
      return this.set(firstElementName + "Focused", true);
    }.observes('firstElementFocused'),
    dateString: function () {
      var dateString, day, month, year;
      month = this.get('monthValue');
      day = this.get('dayValue');
      year = this.get('yearValue');
      dateString = year + "-" + month + "-" + day;
      if (!this._isValidDateString(dateString)) {
        return;
      }
      return dateString;
    }.property('monthValue', 'dayValue', 'yearValue'),
    checkInitialFieldValidity: function () {
      if (!this.get('mobile') && !this.get('dateString') || this.get('mobile') && !this._isValidDateString(this.get('value'))) {
        this.setProperties({
          monthValue: null,
          dayValue: null,
          yearValue: null
        });
        this.sendAction('updateFieldProperty', 'valid', true);
        return this.sendAction('updateFieldProperty', 'text', '');
      } else {
        return this.sendAction('updateFieldProperty', 'passedInitialValidationCheck', true);
      }
    }.on('init'),
    clearDateFields: function () {
      if (this.get('shouldClearDateField')) {
        this.setProperties({
          monthValue: null,
          dayValue: null,
          yearValue: null
        });
        return this.sendAction('updateFieldProperty', 'shouldClearDateField', false);
      }
    }.observes('shouldClearDateField'),
    labelIsHiddenChanged: function () {
      if (this.get('labelIsHidden') && !this.get('focusedIn')) {
        return this.set('firstElementFocused', true);
      }
    }.observes('labelIsHidden'),
    valueChanged: function () {
      this._setValid || (this._setValid = function () {
        return this.sendAction('updateFieldProperty', 'valid', !!this.get('dateString') || !this.get('hasChildValue'));
      });
      return Ember.run.scheduleOnce('actions', this, this._setValid);
    }.observes('dateString', 'hasChildValue'),
    _isValidDateString: function _isValidDateString(dateString) {
      var format, strict;
      format = this.get('iso8601Format');
      strict = true;
      return moment(dateString, format, strict).isValid();
    },
    _handleLabelVisibility: function _handleLabelVisibility() {
      var focusedIn, isEmpty;
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      focusedIn = this.get('focusedIn');
      isEmpty = !this.get('hasChildValue');
      if (focusedIn) {
        return this.set('labelIsHidden', true);
      } else {
        if (isEmpty) {
          this.set('labelIsHidden', false);
          return this.set('firstElementFocused', false);
        } else {
          return this.set('labelIsHidden', true);
        }
      }
    },
    _handleMobileStyle: function _handleMobileStyle() {
      if (this.get('mobile')) {
        return this.$().css({
          'display': 'block'
        });
      }
    },
    _placeholderFor: function _placeholderFor(unit) {
      return _i18nJs.default.t("presenter.labels.date_field." + unit + "_format");
    },
    actions: {
      autoAdvance: function autoAdvance() {
        var nextElementFocused, nextElementName;
        if (this.get('mobile')) {
          return;
        }
        nextElementName = this.get('nextElementName');
        if (!this.get('isLastElement')) {
          nextElementFocused = nextElementName + "Focused";
          this.set(nextElementFocused, true);
          return this.set('firstElementFocused', false);
        }
      },
      inputStarted: function inputStarted() {
        return this.sendAction('inputStartedAction');
      },
      inputEnded: function inputEnded() {
        return this.sendAction('inputEndedAction');
      }
    }
  });
});
define('presenter/data', ['exports', 'presenter/utils', 'presenter/globals'], function (exports, _utils, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Client = exports.belongsTo = exports.hasMany = exports.attr = exports.Model = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /* global RL */
  var ALWAYS_MERGE_FIELDS,
      IdentityMap,
      JSONSerializer,
      RESTAdapter,
      RecordArray,
      adapter,
      isScalar,
      makeComputedAttribute,
      indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }return -1;
  };

  RL.Model.reopen({
    didDefineProperty: function didDefineProperty(proto, key, value) {
      var meta;
      if (meta = value != null ? typeof value.meta === "function" ? value.meta() : void 0 : void 0) {
        if (meta.isRelationship && !meta.readOnly && meta.serialize !== false) {
          if (meta.hasMany && meta.serialize === 'id') {
            return Ember.addObserver(proto, key + '.isChanged', null, '_onRelationshipChange');
          } else if (meta.belongsTo && meta.serialize === 'id') {
            return Ember.addObserver(proto, key + '.isSaved', null, '_onRelationshipChange');
          } else if (meta.serialize === true) {
            return Ember.addObserver(proto, key + '.isDirty', null, '_onRelationshipChange');
          }
        }
      }
    }
  });

  var Model = exports.Model = RL.Model.extend({
    isSaved: Ember.computed.not('isNew'),
    _onRelationshipChange: function _onRelationshipChange(sender, key) {
      if (sender.get(key)) {
        return this._onPropertyChange(key, false);
      } else {
        this.get('dirtyAttrs')["delete"](key);
        if (!this.get('dirtyAttrs.size')) {
          return this.set('isDirty', false);
        }
      }
    },
    _onPropertyChange: function _onPropertyChange(key, prevValue) {
      this._super.apply(this, arguments);
      this.propertyDidChange('isDirty');
      if (this.get('isLoaded') && this.get('isSaved') && !this.get('dirtyAttrs').has(key)) {
        return this.get('dirtyAttrs').set(key, prevValue);
      }
    },
    currentSave: null,
    isStub: false,
    identityMap: null,
    dirtyAttrs: function () {
      return Ember.Map.create();
    }.property(),
    previewRecord: function previewRecord(options) {
      if (options == null) {
        options = {};
      }
      return Ember.get(this.constructor, 'adapter').previewRecord(this, options);
    },
    saveRecord: function saveRecord(options) {
      if (options == null) {
        options = {};
      }
      return this._queueingRequest('save', function () {
        return Ember.get(this.constructor, 'adapter').saveRecord(this, options);
      });
    },
    deleteRecord: function deleteRecord() {
      this._super;
      return this._queueingRequest('delete', this.__nextSuper);
    },
    reloadRecord: function reloadRecord(params) {
      return this._queueingRequest('reload', function () {
        return Ember.get(this.constructor, 'adapter').reloadRecord(this, params);
      });
    },
    rollback: function rollback() {
      var dirtyAttrs;
      if (!this.get('isDirty')) {
        return;
      }
      this.setProperties({
        isLoaded: false,
        isDirty: false
      });
      dirtyAttrs = this.get('dirtyAttrs');
      Ember.beginPropertyChanges(this);
      this.constructor.eachComputedProperty(function (_this) {
        return function (prop) {
          var meta;
          meta = _this.constructor.metaForProperty(prop);
          if (dirtyAttrs.has(prop)) {
            _this.set(prop, dirtyAttrs.get(prop));
          }
          if (meta.isRelationship && _this.get(prop + ".isDirty")) {
            if (_this.get(prop).rollback) {
              return _this.get(prop).rollback();
            }
          }
        };
      }(this));
      Ember.endPropertyChanges(this);
      return this.onLoaded();
    },
    deserialize: function deserialize() {
      this._super.apply(this, arguments);
      this.set('dirtyAttrs', Ember.Map.create());
      this.set('isStub', false);
      return this.set('isNew', Ember.isEmpty(this.get(Ember.get(this.constructor, 'primaryKey'))));
    },
    _queueingRequest: function _queueingRequest(type, originalFunction) {
      var currentSave, newSave;
      currentSave = this.get('currentSave');
      if (currentSave && currentSave._state !== 1) {
        if (currentSave.destroying) {
          return currentSave;
        }
        newSave = currentSave.then(function (_this) {
          return function () {
            return originalFunction.apply(_this);
          };
        }(this));
      } else {
        newSave = originalFunction.apply(this);
      }
      if (type === 'delete') {
        newSave.destroying = true;
      }
      this.set('currentSave', newSave);
      newSave["catch"](function (_this) {
        return function (error) {
          newSave.destroying = false;
          if (!_this.get('_disableRaven') && error.status !== 409) {
            try {
              return Raven.captureException(error.errorThrown, {
                tags: {
                  promise: 'queuesavefailure'
                },
                extra: {
                  fullErrors: error,
                  status: error.status,
                  message: error.textStatus,
                  "class": _this.constructor.toString(),
                  id: _this.get(Ember.get(_this.constructor, 'primaryKey'))
                }
              });
            } catch (error1) {}
          }
        };
      }(this))["finally"](function (_this) {
        return function () {
          return _this.set('currentSave', null);
        };
      }(this));
      return newSave;
    },
    copy: function copy(deep, copyIdentityMap, as) {
      var clone, copiedValue, field, fieldOpts, fields, value;
      if (copyIdentityMap == null) {
        copyIdentityMap = false;
      }
      if (as == null) {
        as = void 0;
      }
      clone = (as != null ? as : this.constructor).create();
      fields = Ember.get(this.constructor, 'fields');
      Ember.beginPropertyChanges(clone);
      if (copyIdentityMap) {
        clone.set('identityMap', this.get('identityMap'));
      }
      for (field in fields) {
        if (!fields.hasOwnProperty(field)) {
          continue;
        }
        fieldOpts = fields[field];
        if (value = this.get(field)) {
          clone.set(field, copiedValue = (typeof value.copy === "function" ? value.copy(deep) : void 0) || value);
        }
        if (fieldOpts.hasMany) {
          copiedValue.set('parent', clone);
        }
      }
      Ember.endPropertyChanges(clone);
      return clone;
    },
    setWithoutDirtying: function setWithoutDirtying(keyName, value) {
      var dirtyState;
      dirtyState = this.get('isDirty');
      this.set(keyName, value);
      this.set('isDirty', dirtyState);
      return value;
    },
    onSaved: function onSaved() {
      this._super.apply(this, arguments);
      this.constructor.eachComputedProperty(function (_this) {
        return function (prop) {
          var meta, relation;
          meta = _this.constructor.metaForProperty(prop);
          if (meta.isRelationship && meta.serialize !== false) {
            relation = _this.get(prop);
            if (meta.hasMany) {
              relation.set('isChanged', false);
              if (meta.serialize === true) {
                return relation.forEach(function (childRecord) {
                  childRecord.set('isDirty', false);
                  return childRecord.onSaved();
                });
              }
            } else if (meta.serialize === true) {
              if (relation) {
                relation.set('isDirty', false);
                return relation.onSaved();
              }
            }
          }
        };
      }(this));
      return this.set('dirtyAttrs', Ember.Map.create());
    },
    onLoaded: function onLoaded() {
      this._super.apply(this, arguments);
      return this.set('dirtyAttrs', Ember.Map.create());
    },
    sideloadRelationships: function sideloadRelationships(identityMap, visited) {
      if (visited == null) {
        visited = _utils.emberSet.create();
      }
      visited.add(this);
      this.set('isLoaded', false);
      this.constructor.eachComputedProperty(function (_this) {
        return function (prop) {
          var assoc, idRecord, klass, meta, primaryKey;
          if (!((meta = _this.constructor.metaForProperty(prop)).isRelationship && (assoc = _this.get(prop)))) {
            return;
          }
          klass = RL.lookupFactory("model:" + meta.type);
          primaryKey = Ember.get(klass, 'primaryKey');
          if (meta.belongsTo) {
            if (assoc.get('isStub')) {
              if ((idRecord = identityMap.find(klass, assoc.get(primaryKey))) && !idRecord.get('isStub')) {
                return _this.set(prop, idRecord);
              }
            } else {
              if (!visited.contains(assoc)) {
                return assoc.sideloadRelationships(identityMap, visited);
              }
            }
          } else if (meta.hasMany) {
            if (assoc.get('firstObject.isStub')) {
              assoc.set('isLoaded', false);
              assoc.replaceContent(0, assoc.get('length'), assoc.map(function (record) {
                if ((idRecord = identityMap.find(klass, record.get(primaryKey))) && !idRecord.get('isStub')) {
                  return idRecord;
                } else {
                  return record;
                }
              }));
              return assoc.set('isLoaded', true);
            } else {
              return assoc.forEach(function (record) {
                if (!visited.contains(record)) {
                  return record.sideloadRelationships(identityMap, visited);
                }
              });
            }
          }
        };
      }(this));
      return this.set('isLoaded', true);
    },
    calculateFilth: function calculateFilth() {
      var filth;
      filth = this.get('isDirty');
      if (!filth) {
        this.constructor.eachComputedProperty(function (_this) {
          return function (prop) {
            var meta, relation;
            if (filth) {
              return;
            }
            meta = _this.constructor.metaForProperty(prop);
            if (meta.isRelationship && meta.serialize !== false) {
              relation = _this.get(prop);
              if (!relation) {
                return;
              }
              if (!meta.belongsTo) {
                return filth = relation.calculateFilth();
              }
            }
          };
        }(this));
      }
      return filth;
    }
  });

  Model.reopenClass({
    propertyForEmbeddedKey: function propertyForEmbeddedKey(key) {
      this._embeddedKeys || (this._embeddedKeys = function (_this) {
        return function () {
          var map;
          map = {};
          _this.eachComputedProperty(function (prop) {
            var embeddedKey, klass, meta, primaryKey;
            if ((meta = this.metaForProperty(prop)).isRelationship) {
              klass = RL.lookupFactory("model:" + meta.type);
              primaryKey = Ember.get(klass, 'primaryKey');
              embeddedKey = meta.hasMany ? prop.singularize().underscore() + "_" + primaryKey + "s" : prop.underscore() + "_" + primaryKey;
              return map[embeddedKey] = prop;
            }
          });
          return map;
        };
      }(this)());
      return this._embeddedKeys[key];
    },
    fetchFromParent: function fetchFromParent(parent, parentId, objectId) {
      this.endpoint = parent + "/" + parentId + "/" + Ember.get(this, 'resourceNamePlural');
      return this.fetch(objectId);
    }
  });

  RecordArray = RL.RecordArray.extend({
    isChanged: Ember.computed('cleanContent', {
      get: function get(key) {
        return !!this.get('cleanContent');
      },
      set: function set(key, value) {
        if (!value) {
          this.set('cleanContent', null);
        }
        return value;
      }
    }),
    cleanContent: null,
    klass: null,
    parent: null,
    inverseOf: null,
    _init: function () {
      var parent;
      if ((parent = this.get('parent')) && !this.get('inverseOf')) {
        return this.set('inverseOf', parent.constructor.resourceName);
      }
    }.observes('parent').on('init'),
    rollback: function rollback() {
      if (!this.get('isDirty')) {
        return;
      }
      this.set('isLoaded', false);
      if (this.get('isChanged')) {
        this.replaceContent(0, this.get('length'), this.get('cleanContent'));
      }
      this.forEach(function (model) {
        return model.rollback();
      });
      return this.set('isLoaded', true);
    },
    replaceContent: function replaceContent(idx, amt, objects) {
      var i, identityMap, len1, object;
      if (identityMap = this.get('parent.identityMap')) {
        for (i = 0, len1 = objects.length; i < len1; i++) {
          object = objects[i];
          if (object.get('isNew')) {
            object.set('identityMap', identityMap);
          }
        }
      }
      if (this.get('isLoaded') && !this.get('cleanContent')) {
        this.set('cleanContent', this.get('content').copy());
      }
      this.propertyDidChange('isChanged');
      return this.get('content').replace(idx, amt, objects);
    },
    saveRecords: function saveRecords() {
      var deleted, savePromise;
      savePromise = this.reduce(function (promise, record) {
        return promise.then(function () {
          return record.saveRecord();
        });
      }, Ember.RSVP.resolve());
      if (this.get('cleanContent') && (deleted = this.get('cleanContent').copy().removeObjects(this.get('content'))).get('length')) {
        savePromise = deleted.reduce(function (promise, deletedRecord) {
          return promise.then(function () {
            return deletedRecord.deleteRecord();
          });
        }, savePromise);
      }
      return savePromise;
    },
    create: function create(attrs, options) {
      var child;
      if (attrs == null) {
        attrs = {};
      }
      if (options == null) {
        options = {};
      }
      attrs[this.get('inverseOf')] = this.get('parent');
      child = this.get('klass').create(attrs);
      if (typeof options.position === 'number') {
        this.insertAt(options.position, child);
      } else {
        this.pushObject(child);
      }
      return child;
    },
    createAt: function createAt(position, attrs) {
      if (attrs == null) {
        attrs = {};
      }
      return this.create(attrs, {
        position: position
      });
    },
    copy: function copy(deep) {
      return RecordArray.create({
        klass: this.get('klass'),
        content: deep ? this.map(function (record) {
          return record.copy(deep);
        }) : this.get('content').copy()
      });
    },
    clear: function clear() {
      var len;
      if (len = this.get('content').length) {
        this.replace(0, len, []);
      }
      return this;
    },
    calculateFilth: function calculateFilth() {
      var filth;
      filth = this.get('isDirty');
      if (!filth) {
        filth = this.get('content').filter(function (item) {
          return item.calculateFilth();
        }).length > 0;
      }
      return filth;
    },
    _onItemDirtyChange: function () {
      var clean;
      if (!this.get('isLoaded')) {
        return;
      }
      clean = !this.get('isChanged') && this.get('content').isEvery('isDirty', false);
      this.set('isDirty', !clean);
      return this.propertyDidChange('isDirty');
    }.observes('@each.isDirty', 'isLoaded', 'isChanged'),
    _onLoadedChange: function () {
      this._super();
      if (this.get('isLoaded')) {
        return this.set('isChanged', false);
      }
    }.observes('isLoaded')
  });

  var attr = exports.attr = function attr(type, opts) {
    var meta;
    meta = Ember.merge({
      type: type,
      isAttribute: true
    }, opts);
    if (meta.positionIn) {
      return Ember.computed(function () {
        var ref;
        return (ref = this.get(meta.positionIn)) != null ? ref.indexOf(this) : void 0;
      }).property(meta.positionIn + ".[]").meta(meta);
    } else {
      return makeComputedAttribute(meta);
    }
  };

  var hasMany = exports.hasMany = function hasMany(type, opts) {
    var defaultArray, meta;
    defaultArray = function defaultArray(attrs) {
      return RecordArray.createWithContent(attrs);
    };
    meta = Ember.merge({
      type: type,
      isRelationship: true,
      hasMany: true,
      defaultValue: defaultArray
    }, opts);
    return makeComputedAttribute(meta);
  };

  var belongsTo = exports.belongsTo = function belongsTo(type, opts) {
    var meta;
    meta = Ember.merge({
      type: type,
      isRelationship: true,
      belongsTo: true
    }, opts);
    return makeComputedAttribute(meta);
  };

  makeComputedAttribute = function makeComputedAttribute(meta) {
    return Ember.computed('_data', {
      get: function get(key) {
        var data, value;
        data = this.get('_data');
        if (!(value = data[key])) {
          if (typeof meta.defaultValue === 'function') {
            if (meta.hasMany) {
              value = meta.defaultValue({
                parent: this,
                klass: RL.lookupFactory("model:" + meta.type),
                inverseOf: meta.inverseOf
              });
            } else {
              value = meta.defaultValue();
            }
          } else {
            value = meta.defaultValue;
          }
          return data[key] = value;
        }
      },
      set: function set(key, value) {
        var data;
        data = this.get('_data');
        if (value !== data[key]) {
          if (meta.isRelationship && value && !Ember.get(value, 'identityMap')) {
            Ember.set(value, 'identityMap', this.get('identityMap'));
          }
          if (!meta.readOnly && !RESTless.ReadOnlyModel.detectInstance(this) && (meta.isAttribute || meta.isRelationship && (meta.serialize === 'id' || meta.serialize === true))) {
            this._onPropertyChange(key, this.get(key));
          }
          data[key] = value;
        }
        return value;
      }
    }).meta(meta);
  };

  IdentityMap = Ember.Object.extend({
    init: function init() {
      this._map = Ember.Map.create();
      return this._unclassified = _utils.emberSet.create();
    },
    find: function find(modelClass, primaryKey) {
      var entityMap;
      if (!primaryKey) {
        return;
      }
      entityMap = this._map.get(modelClass.resourceName);
      if (entityMap && entityMap[primaryKey]) {
        return entityMap[primaryKey];
      }
      return void 0;
    },
    findOrStub: function findOrStub(modelClass, primaryKey) {
      var resource;
      if (resource = this.find(modelClass, primaryKey)) {
        return resource;
      } else {
        this.add(resource = modelClass.create({
          id: primaryKey,
          isNew: false,
          isStub: true,
          isLoaded: false,
          identityMap: this
        }));
        return resource;
      }
    },
    add: function add(model) {
      var entityMap, modelClass, primaryKey, primaryKeyField;
      if (!model) {
        return;
      }
      model.set('identityMap', this);
      modelClass = model.constructor;
      entityMap = this._map.get(modelClass.resourceName);
      if (!entityMap) {
        entityMap = {};
        this._map.set(modelClass.resourceName, entityMap);
      }
      primaryKeyField = Ember.get(modelClass, 'primaryKey');
      primaryKey = model.get(primaryKeyField);
      if (primaryKey) {
        return entityMap[primaryKey] = model;
      } else {
        return this._unclassified.add(model);
      }
    },
    addMany: function addMany(models) {
      return models != null ? models.forEach(function (_this) {
        return function (model) {
          return _this.add(model);
        };
      }(this)) : void 0;
    }
  });

  ALWAYS_MERGE_FIELDS = ['id', 'url', 'ticket', 'timeZone', 'createdAt'];

  isScalar = function isScalar(value) {
    var valueType;
    valueType = Ember.typeOf(value);
    return valueType === 'number' || valueType === 'string';
  };

  JSONSerializer = RL.JSONSerializer.extend({
    deserialize: function deserialize(resource, data) {
      var identityMap, key, meta, prop;
      if (!(identityMap = resource.get('identityMap'))) {
        identityMap = IdentityMap.create();
      }
      identityMap.add(resource);
      if (data) {
        key = this._keyForResource(resource);
        data = data[key] || data;
        meta = this.extractMeta(data);
        if (meta) {
          resource.set('meta', meta);
        }
        Ember.beginPropertyChanges(resource);
        for (prop in data) {
          if (data.hasOwnProperty(prop)) {
            this.deserializeProperty(resource, prop, data[prop]);
          }
        }
        Ember.endPropertyChanges(resource);
        resource.setProperties({
          isLoaded: true,
          isDirty: false
        });
      }
      return resource;
    },
    deserializeProperty: function deserializeProperty(resource, prop, value) {
      var attrName, belongsToModel, field, fields, hasManyArr, identityMap, klass, primaryKeyField, type, unclassified;
      attrName = this.attributeNameForKey(resource, prop);
      fields = Ember.get(resource.constructor, 'fields');
      field = fields[attrName];
      if (!field) {
        return;
      }
      type = field.type;
      klass = RL.lookupFactory("model:" + type);
      identityMap = resource.get('identityMap');
      if (field.hasMany) {
        if (value && isScalar(value[0])) {
          hasManyArr = resource.get(attrName);
          hasManyArr.set('isLoaded', false);
          hasManyArr.clear();
          hasManyArr.pushObjects(value.compact().map(function (primaryKey) {
            return identityMap.findOrStub(klass, primaryKey);
          }));
        } else {
          hasManyArr = this.deserializeMany(resource.get(attrName), type, value);
        }
        resource.one('didLoad', function () {
          return hasManyArr.set('isLoaded', true);
        });
        resource.set(attrName, hasManyArr);
      } else if (field.belongsTo && klass && value) {
        if (isScalar(value)) {
          belongsToModel = identityMap.findOrStub(klass, value);
        } else {
          if (belongsToModel = identityMap.find(klass, value)) {
            belongsToModel.set('isLoaded', false);
            belongsToModel.deserialize(value);
          } else {
            belongsToModel = klass.create({
              identityMap: identityMap
            }).deserialize(value);
          }
          resource.one('didLoad', function () {
            return belongsToModel.onLoaded();
          });
        }
        resource.set(attrName, belongsToModel);
      } else {
        if (type && RESTless.JSONTransforms[type]) {
          value = RESTless.JSONTransforms[type].deserialize(value);
        }
        if (field.readOnly || resource.get('_allowMerges') === true || Ember.isArray(resource.get('_allowMerges')) && indexOf.call(resource.get('_allowMerges'), attrName) >= 0 || !resource.get('isSaving') || resource.get('isSaving') && ALWAYS_MERGE_FIELDS.contains(attrName)) {
          resource.set(attrName, value);
        }
        primaryKeyField = Ember.get(resource.constructor, 'primaryKey');
        if (attrName === primaryKeyField) {
          unclassified = identityMap._unclassified.find(function (model) {
            return resource.constructor.resourceName === model.constructor.resourceName && model.get(primaryKeyField) === resource.get(primaryKeyField);
          });
          if (unclassified) {
            identityMap.add(unclassified);
            identityMap._unclassified.remove(unclassified);
          }
        }
      }
    },
    deserializeMany: function deserializeMany(recordArray, type, data) {
      var i, identityMap, item, keyPlural, klass, len1, meta, model, results;
      if (data) {
        klass = this.modelFor(type);
        identityMap = recordArray.get('parent.identityMap');
        if (meta = this.extractMeta(data)) {
          recordArray.set('meta', meta);
        }
        if (!Ember.isArray(data)) {
          keyPlural = this._keyPluralForResourceType(type);
          if (data[keyPlural]) {
            data = data[keyPlural];
          } else {
            return recordArray;
          }
        }
        if (recordArray) {
          recordArray.set('isLoaded', false);
          recordArray.clear();
        } else {
          recordArray = RecordArray.createWithContent();
        }
        if (data.length) {
          results = [];
          for (i = 0, len1 = data.length; i < len1; i++) {
            item = data[i];
            if (klass && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
              if (identityMap && (model = identityMap.find(klass, item.id))) {
                model.set('isLoaded', false);
                model.deserialize(item);
              } else {
                model = klass.create({
                  identityMap: identityMap
                }).deserialize(item);
              }
            }
            results.push(model);
          }
          recordArray.pushObjects(results);
        }
      }
      return recordArray;
    },
    serialize: function serialize(resource, options) {
      var field, fieldOpts, fields, json, key, keys, overrides, primaryKey, primaryKeyFieldOpts, record, relation, relationType, serializeOpt, wrapped;
      if (options == null) {
        options = {};
      }
      fields = Ember.get(resource.constructor, 'fields');
      json = {};
      overrides = options.serialize || {};
      for (field in fields) {
        if (!fields.hasOwnProperty(field)) {
          continue;
        }
        fieldOpts = fields[field];
        if (fieldOpts.positionIn && !resource.get('isNew')) {
          continue;
        }
        serializeOpt = field in overrides ? overrides[field] : fieldOpts.serialize;
        if (serializeOpt === false || fieldOpts.readOnly) {
          continue;
        }
        key = this.keyForAttributeName(field);
        if (fieldOpts.belongsTo && (options.includeRelationships || serializeOpt)) {
          relation = resource.get(field);
          if (relation === void 0) {
            continue;
          }
          if (serializeOpt === 'id') {
            key = key + "_id";
          }
          json[key] = relation === null ? null : serializeOpt === 'id' ? this.serializeProperty(relation, Ember.get(relation.constructor, 'primaryKey')) : this.serialize(relation, {
            nonEmbedded: true
          });
        } else if (fieldOpts.hasMany && serializeOpt === 'id') {
          relation = resource.get(field);
          relationType = this.modelFor(fieldOpts.type);
          primaryKey = Ember.get(relationType, 'primaryKey');
          primaryKeyFieldOpts = relationType.metaForProperty(primaryKey);
          keys = function () {
            var i, len1, ref, results1;
            ref = relation.get('content');
            results1 = [];
            for (i = 0, len1 = ref.length; i < len1; i++) {
              record = ref[i];
              results1.push(this.serializeProperty(record, primaryKey, primaryKeyFieldOpts));
            }
            return results1;
          }.call(this);
          json[key.singularize() + "_ids"] = keys;
        } else if (!fieldOpts.belongsTo) {
          json[key] = this.serializeProperty(resource, field, fieldOpts);
        }
      }
      if (options.nonEmbedded) {
        return json;
      } else {
        wrapped = {};
        wrapped[this._keyForResource(resource)] = json;
        return wrapped;
      }
    },
    attributeNameForKey: function attributeNameForKey(klass, key) {
      var directName, prop;
      directName = this._super(klass, key);
      if ((prop = klass.constructor.propertyForEmbeddedKey(key)) && !Ember.get(klass.constructor, 'fields')[directName]) {
        return prop;
      } else {
        return directName;
      }
    }
  });

  RESTAdapter = RL.RESTAdapter.extend({
    host: _globals.BASE_URL + "/",
    headers: {
      'API-Version': window._api_version
    },
    serializer: JSONSerializer.create(),
    buildUrl: function buildUrl(model, key, klass) {
      var endpoint;
      this.set('host', Ember.get(klass, 'baseUrl') || _globals.BASE_URL + "/");
      if (endpoint = Ember.get(klass, 'endpoint')) {
        if (key === 'preview' || Ember.get(klass, 'useModelKey') && key) {
          return this.get('rootPath') + "/" + endpoint + "/" + key;
        } else {
          return this.get('rootPath') + "/" + endpoint;
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    previewRecord: function previewRecord(record, options) {
      var ajaxPromise;
      ajaxPromise = this.request({
        key: 'preview',
        model: record,
        params: {
          type: 'POST',
          data: Ember.merge(record.serialize(options), options.params || {})
        }
      });
      return new Ember.RSVP.Promise(function (_this) {
        return function (resolve, reject) {
          return ajaxPromise.then(function (data) {
            var field, fieldOpts, fields, key, resourceKey;
            resourceKey = _this.serializer.keyForResourceName(Ember.get(record.constructor, 'resourceName'));
            if (data[resourceKey]) {
              data = data[resourceKey];
            }
            fields = Ember.get(record.constructor, 'fields');
            for (field in fields) {
              fieldOpts = fields[field];
              if (fieldOpts.deserializeOnPreview) {
                key = _this.serializer.keyForAttributeName(field);
                record.set(field, data[key]);
              }
            }
            return resolve(record);
          }, function (error) {
            record.onError(error);
            return reject(error);
          });
        };
      }(this));
    },
    saveRecord: function saveRecord(record, options) {
      var ajaxPromise, fields, isDirty, isNew, method, overrides;
      isNew = record.get('isNew');
      isDirty = (overrides = options.serialize) && (fields = Object.keys(options.serialize)).length ? record.get('isDirty') || !!fields.find(function (_this) {
        return function (field) {
          var meta;
          if ((meta = record.constructor.metaForProperty(field)).hasMany) {
            return overrides[field] === 'id' && record.get(field).get('isChanged') || overrides[field] === true && record.get(field).get('isDirty');
          } else {
            throw 'serialization overrides for attributes and belongsTo not yet supported';
          }
        };
      }(this)) : record.get('isDirty');
      if (!isNew && !isDirty) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          return resolve(record);
        });
      }
      record.set('isSaving', true);
      record.set('isDirty', false);
      method = isNew ? 'POST' : 'PUT';
      ajaxPromise = this.request({
        model: record,
        params: {
          type: method,
          data: Ember.merge(record.serialize(options), options.params || {})
        }
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return ajaxPromise.then(function (data) {
          var currentDirty;
          currentDirty = record.get('isDirty');
          if (data) {
            record.deserialize(data);
          }
          record.onSaved(isNew);
          record.set('isDirty', currentDirty);
          return resolve(record);
        }, function (error) {
          record.set('isDirty', true);
          record.onError(error);
          return reject(error);
        });
      });
    },
    ajax: function ajax(params) {
      if (_globals.USE_METHOD_OVERRIDE && !/GET|POST/.test(params.type)) {
        params.headers = Ember.merge({
          'X-HTTP-Method-Override': params.type
        }, params.headers);
        params.type = 'POST';
      }
      return this._super(params);
    },
    request: function request(options) {
      var ajax;
      ajax = this._super(options);
      options.model.set('currentRequest', ajax);
      return ajax;
    },
    reloadRecord: function reloadRecord(record, queryParams) {
      var ajaxPromise, key, klass, primaryKey;
      klass = record.constructor;
      primaryKey = Ember.get(klass, 'primaryKey');
      key = record.get(primaryKey);
      if (Ember.isNone(key)) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          return reject(null);
        });
      }
      record.set('isLoaded', false);
      ajaxPromise = this.request({
        model: record,
        params: {
          type: 'GET',
          data: queryParams
        },
        key: key
      });
      ajaxPromise.then(function (data) {
        record.deserialize(data);
        return record.onLoaded();
      }, function (error) {
        return record.onError(error);
      });
      return ajaxPromise;
    }
  });

  adapter = RESTAdapter.create();

  var Client = exports.Client = RL.Client.create({
    adapter: adapter
  });

  adapter.registerTransform('color', {
    deserialize: function deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return null;
      } else {
        return Number(serialized);
      }
    },
    serialize: function serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return null;
      } else if (typeof deserialized === 'string' && deserialized[0] === '#') {
        return parseInt(deserialized.substring(1), 16);
      } else {
        return Number(deserialized);
      }
    }
  });

  adapter.registerTransform('raw', {
    deserialize: function deserialize(x) {
      return x;
    },
    serialize: function serialize(x) {
      return x;
    }
  });

  adapter.configure('plurals', {
    template_category: 'template_categories',
    stats: 'stats',
    'survey-stats': 'survey-stats'
  });
});
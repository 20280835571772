define('presenter/controllers/presenter/rule', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend({
    conditionsController: null,
    _initConditionsController: function () {
      return this.set('conditionsController', Ember.ArrayController.create({
        itemController: 'presenter/condition',
        contentBinding: 'parentController.content.conditions',
        parentController: this,
        container: this.get('container')
      }));
    }.on('init'),
    actionsController: null,
    _initActionsController: function () {
      return this.set('actionsController', Ember.ArrayController.create({
        itemController: 'presenter/action',
        contentBinding: 'parentController.content.actions',
        parentController: this,
        container: this.get('container')
      }));
    }.on('init'),
    willDestroy: function willDestroy() {
      this.get('conditionsController').destroy();
      this.get('actionsController').destroy();
      return this._super();
    },
    evaluate: function evaluate() {
      var conditions, result;
      if (Ember.isEmpty(this.get('conditions'))) {
        return this.get('actionsController').toArray();
      }
      conditions = this.get('conditionsController');
      result = function () {
        switch (this.get('conditionsOperator')) {
          case 'all':
            return conditions.every(function (conditionController) {
              return conditionController.evaluate();
            });
          case 'any':
            return conditions.any(function (conditionController) {
              return conditionController.evaluate();
            });
          default:
            Raven.captureMessage('Invalid rule condition grouping operator.', {
              extra: {
                ruleId: this.get('id')
              }
            });
            return false;
        }
      }.call(this);
      if (result) {
        return this.get('actionsController').toArray();
      } else {
        return [];
      }
    }
  });
});
define("presenter/templates/components/controls/media-picker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 59
            },
            "end": {
              "line": 1,
              "column": 235
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon-search");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Google Images");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["button-small ", ["subexpr", "if", [["get", "isSearch", ["loc", [null, [1, 159], [1, 167]]]], "active"], [], ["loc", [null, [1, 154], [1, 178]]]]]]], ["element", "action", ["setPickerOption", "search"], ["on", "click"], ["loc", [null, [1, 85], [1, 133]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 395
            },
            "end": {
              "line": 1,
              "column": 567
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon-monitor");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Embed Video");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["button-small ", ["subexpr", "if", [["get", "isVideo", ["loc", [null, [1, 493], [1, 500]]]], "active"], [], ["loc", [null, [1, 488], [1, 511]]]]]]], ["element", "action", ["setPickerOption", "video"], ["on", "click"], ["loc", [null, [1, 420], [1, 467]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 748
              },
              "end": {
                "line": 1,
                "column": 826
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa-spin icon-fa icon-refresh");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Searching");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 826
              },
              "end": {
                "line": 1,
                "column": 840
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Search");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 580
            },
            "end": {
              "line": 1,
              "column": 1048
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "MediaPickerSelector-imageSearch");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "MediaPickerSelector-imageSearchButton button-small");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var element2 = dom.childAt(element1, [0]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["searchWebImages"], ["on", "click"], ["loc", [null, [1, 649], [1, 688]]]], ["block", "if", [["get", "isSearching", ["loc", [null, [1, 754], [1, 765]]]]], [], 0, 1, ["loc", [null, [1, 748], [1, 847]]]], ["inline", "bourbon-text-field", [], ["classNames", "MediaPickerSelector-searchField js-searchField", "placeholder", "Search Google Images", "value", ["subexpr", "@mut", [["get", "imageSearchTerm", ["loc", [null, [1, 978], [1, 993]]]]], [], []], "action", ["subexpr", "action", ["searchWebImages"], [], ["loc", [null, [1, 1001], [1, 1027]]]], "noLabel", true], ["loc", [null, [1, 856], [1, 1042]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1055
            },
            "end": {
              "line": 1,
              "column": 1169
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "image-file-field", [], ["classNames", "MediaPickerSelector-fileField js-fileField", "value", ["subexpr", "@mut", [["get", "selectedImageFile", ["loc", [null, [1, 1150], [1, 1167]]]]], [], []]], ["loc", [null, [1, 1069], [1, 1169]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1191
              },
              "end": {
                "line": 1,
                "column": 1454
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "bourbon-text-field", [], ["classNames", "MediaPickerSelector-urlField", "placeholder", "Insert a link from YouTube or Vimeo", "validated", ["subexpr", "@mut", [["get", "selectedVideoUrl", ["loc", [null, [1, 1381], [1, 1397]]]]], [], []], "validate", "mediaUrl", "validationError", ["subexpr", "@mut", [["get", "urlValidationError", ["loc", [null, [1, 1434], [1, 1452]]]]], [], []]], ["loc", [null, [1, 1258], [1, 1454]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1176
            },
            "end": {
              "line": 1,
              "column": 1486
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "text-field-validation-error", [], ["validationError", ["subexpr", "@mut", [["get", "urlValidationError", ["loc", [null, [1, 1238], [1, 1256]]]]], [], []]], 0, null, ["loc", [null, [1, 1191], [1, 1486]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1531
            },
            "end": {
              "line": 1,
              "column": 1960
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "image-editor", [], ["canResizeReposition", ["subexpr", "@mut", [["get", "canResizeReposition", ["loc", [null, [1, 1593], [1, 1612]]]]], [], []], "fixedConstraints", ["subexpr", "@mut", [["get", "fixedConstraints", ["loc", [null, [1, 1630], [1, 1646]]]]], [], []], "canvasWidth", ["subexpr", "@mut", [["get", "mediaObject.canvasWidth", ["loc", [null, [1, 1659], [1, 1682]]]]], [], []], "canvasHeight", ["subexpr", "@mut", [["get", "mediaObject.canvasHeight", ["loc", [null, [1, 1696], [1, 1720]]]]], [], []], "showFrameOption", ["subexpr", "@mut", [["get", "showFrameOption", ["loc", [null, [1, 1737], [1, 1752]]]]], [], []], "media", ["subexpr", "@mut", [["get", "mediaObject", ["loc", [null, [1, 1759], [1, 1770]]]]], [], []], "defaultImageOrientation", ["subexpr", "@mut", [["get", "defaultImageOrientation", ["loc", [null, [1, 1795], [1, 1818]]]]], [], []], "showCropper", ["subexpr", "@mut", [["get", "showCropper", ["loc", [null, [1, 1831], [1, 1842]]]]], [], []], "defaultCanvasWidth", ["subexpr", "@mut", [["get", "defaultCanvasWidth", ["loc", [null, [1, 1862], [1, 1880]]]]], [], []], "defaultCanvasHeight", ["subexpr", "@mut", [["get", "defaultCanvasHeight", ["loc", [null, [1, 1901], [1, 1920]]]]], [], []], "defaultImageSizing", ["subexpr", "@mut", [["get", "defaultImageSizing", ["loc", [null, [1, 1940], [1, 1958]]]]], [], []]], ["loc", [null, [1, 1558], [1, 1960]]]]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1960
              },
              "end": {
                "line": 1,
                "column": 2024
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-video", [], ["media", ["subexpr", "@mut", [["get", "mediaObject", ["loc", [null, [1, 2011], [1, 2022]]]]], [], []]], ["loc", [null, [1, 1991], [1, 2024]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2024
              },
              "end": {
                "line": 1,
                "column": 2105
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-placeholder", [], ["width", ["subexpr", "@mut", [["get", "defaultCanvasWidth", ["loc", [null, [1, 2058], [1, 2076]]]]], [], []], "height", ["subexpr", "@mut", [["get", "defaultCanvasHeight", ["loc", [null, [1, 2084], [1, 2103]]]]], [], []]], ["loc", [null, [1, 2032], [1, 2105]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1960
            },
            "end": {
              "line": 1,
              "column": 2105
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "mediaObject.isVideo", ["loc", [null, [1, 1970], [1, 1989]]]]], [], 0, 1, ["loc", [null, [1, 1960], [1, 2105]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child7 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2138
              },
              "end": {
                "line": 1,
                "column": 2271
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Sorry, this image is no longer available.");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2383
              },
              "end": {
                "line": 1,
                "column": 2562
              }
            },
            "moduleName": "presenter/templates/components/controls/media-picker.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-picker-web-image-thumbnail", [], ["selected", ["subexpr", "@mut", [["get", "selectedWebImage", ["loc", [null, [1, 2466], [1, 2482]]]]], [], []], "width", ["subexpr", "@mut", [["get", "thumbnailWidth", ["loc", [null, [1, 2489], [1, 2503]]]]], [], []], "doubleWidth", ["subexpr", "@mut", [["get", "thumbnailDoubleWidth", ["loc", [null, [1, 2516], [1, 2536]]]]], [], []], "image", ["subexpr", "@mut", [["get", "image", ["loc", [null, [1, 2543], [1, 2548]]]]], [], []], "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 2556], [1, 2560]]]]], [], []]], ["loc", [null, [1, 2422], [1, 2562]]]]],
          locals: ["image"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2112
            },
            "end": {
              "line": 1,
              "column": 2577
            }
          },
          "moduleName": "presenter/templates/components/controls/media-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "alert-view", [], ["classNames", "MediaPickerImageSearchResults-error", "toggle", ["subexpr", "@mut", [["get", "showImageSearchError", ["loc", [null, [1, 2208], [1, 2228]]]]], [], []]], 0, null, ["loc", [null, [1, 2138], [1, 2286]]]], ["attribute", "class", ["concat", ["MediaPickerImageSearchResults js-searchResults ", ["subexpr", "if", [["get", "showSearchResults", ["loc", [null, [1, 2350], [1, 2367]]]], "", "hidden"], [], ["loc", [null, [1, 2345], [1, 2381]]]]]]], ["block", "each", [["get", "imageSearchResults", ["loc", [null, [1, 2391], [1, 2409]]]]], [], 1, null, ["loc", [null, [1, 2383], [1, 2571]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 2590
          }
        },
        "moduleName": "presenter/templates/components/controls/media-picker.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "MediaPickerSelector");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "button-group");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-upload");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Upload Image");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "MediaPickerPreview");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [0]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(fragment, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element6, 0, 0);
        morphs[1] = dom.createAttrMorph(element7, 'class');
        morphs[2] = dom.createElementMorph(element7);
        morphs[3] = dom.createMorphAt(element6, 2, 2);
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createMorphAt(element5, 2, 2);
        morphs[6] = dom.createMorphAt(element5, 3, 3);
        morphs[7] = dom.createMorphAt(element8, 0, 0);
        morphs[8] = dom.createMorphAt(element8, 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showSearch", ["loc", [null, [1, 65], [1, 75]]]]], [], 0, null, ["loc", [null, [1, 59], [1, 242]]]], ["attribute", "class", ["concat", ["button-small ", ["subexpr", "if", [["get", "isFile", ["loc", [null, [1, 322], [1, 328]]]], "active"], [], ["loc", [null, [1, 317], [1, 339]]]]]]], ["element", "action", ["setPickerOption", "file"], ["on", "click"], ["loc", [null, [1, 250], [1, 296]]]], ["block", "if", [["get", "showVideo", ["loc", [null, [1, 401], [1, 410]]]]], [], 1, null, ["loc", [null, [1, 395], [1, 574]]]], ["block", "if", [["get", "isSearch", ["loc", [null, [1, 586], [1, 594]]]]], [], 2, null, ["loc", [null, [1, 580], [1, 1055]]]], ["block", "if", [["get", "isFile", ["loc", [null, [1, 1061], [1, 1067]]]]], [], 3, null, ["loc", [null, [1, 1055], [1, 1176]]]], ["block", "if", [["get", "isVideo", ["loc", [null, [1, 1182], [1, 1189]]]]], [], 4, null, ["loc", [null, [1, 1176], [1, 1493]]]], ["block", "if", [["get", "mediaObject.isImage", ["loc", [null, [1, 1537], [1, 1556]]]]], [], 5, 6, ["loc", [null, [1, 1531], [1, 2112]]]], ["block", "if", [["get", "imageSearchResults", ["loc", [null, [1, 2118], [1, 2136]]]]], [], 7, null, ["loc", [null, [1, 2112], [1, 2584]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }());
});
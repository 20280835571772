define('presenter/components/presenter/slider-input', ['exports', 'presenter/components/slider-input'], function (exports, _sliderInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _sliderInput.default.extend({
    classNameBindings: ['hasBeenSelected'],
    layoutName: 'presenter/slider',
    dragStartAction: 'disableUserSelection',
    dragStopAction: 'enableUserSelection',
    _draggingStateChanged: function () {
      this.set('hasBeenSelected', true);
      if (this.get('dragging')) {
        return this.sendAction('dragStartAction');
      } else {
        return this.sendAction('dragStopAction');
      }
    }.observes('dragging')
  });
});
define("presenter/templates/svg/like_dislike/face_dislike", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/like_dislike/face_dislike.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 128 128");
        dom.setAttribute(el1, "style", "enable-background:new 0 0 64 64;");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n	 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M64,2C29.8,2,2,29.8,2,64s27.8,62,62,62s62-27.8,62-62S98.2,2,64,2z M43.8,43.3c2.6,0,4.7,2.2,4.7,4.7\n	 	s-2,4.7-4.7,4.7c-2.7,0-4.7-2.2-4.7-4.7S41.2,43.3,43.8,43.3z M86,90c-1.2,0-2-0.8-2-2c0-10-10-14-20-14s-20,4-20,14\n	 	c0,1.2-0.8,2-2,2s-2-0.8-2-2c0-12.2,11.8-18,24-18s24,5.8,24,18C88,89.2,87.2,90,86,90z M84,52.8c-2.7,0-4.8-2.2-4.8-4.8\n	 	s2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8S86.7,52.8,84,52.8z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
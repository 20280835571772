define('presenter/globals', ['exports', 'presenter/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bucketType = exports.imgixHosts = exports.rootPath = exports.USE_METHOD_OVERRIDE = exports.BASE_HOST = exports.BASE_URL = exports.inlineLinkTarget = exports.embedTarget = exports.isEmbedded = exports.isIframed = exports.isPreview = exports.ravenOptions = exports.presenterFlag = exports.rootElement = exports.isAuthenticated = exports.isImpersonating = exports.debug = exports.jsSettings = exports.codeSettings = undefined;
  var codeSettings = exports.codeSettings = window._code_settings;

  var jsSettings = exports.jsSettings = window._js_settings;

  var debug = exports.debug = _environment.default.environment !== 'production' || window._debug;

  var isImpersonating = exports.isImpersonating = window._is_impersonating;

  var isAuthenticated = exports.isAuthenticated = !!window._current_user_json;

  var rootElement = exports.rootElement = '#application';

  var presenterFlag = exports.presenterFlag = function presenterFlag(flag) {
    return (window._flags || {})[flag];
  };

  var ravenOptions = exports.ravenOptions = window._raven_options;

  var isPreview = exports.isPreview = presenterFlag('isPreview');

  var isIframed = exports.isIframed = window !== window.parent;

  var isEmbedded = exports.isEmbedded = presenterFlag('isEmbedded');

  var embedTarget = exports.embedTarget = window._embed_target;

  var inlineLinkTarget = exports.inlineLinkTarget = isIframed ? '_blank' : '_self';

  var BASE_URL = exports.BASE_URL = window._base_url;

  var BASE_HOST = exports.BASE_HOST = window._base_host;

  var USE_METHOD_OVERRIDE = exports.USE_METHOD_OVERRIDE = !debug;

  var rootPath = exports.rootPath = window._root_path;

  var imgixHosts = exports.imgixHosts = window._imgix_hosts;

  var bucketType = exports.bucketType = window._bucket_type;
});
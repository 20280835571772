define("presenter/templates/presenter/layouts/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 292
          }
        },
        "moduleName": "presenter/templates/presenter/layouts/component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "presenter-component-wrapper js-componentWrapper");
        var el2 = dom.createElement("div");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "presenter-component-content js-componentContent");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["presenter-component ", ["subexpr", "if", [["get", "component.model.isCentered", ["loc", [null, [1, 98], [1, 124]]]], "Component-centered", "Component-alignedToEdge"], [], ["loc", [null, [1, 93], [1, 173]]]]]]], ["inline", "partial", [["subexpr", "component-view", [["get", "component", ["loc", [null, [1, 262], [1, 271]]]]], [], ["loc", [null, [1, 246], [1, 272]]]]], [], ["loc", [null, [1, 236], [1, 274]]]]],
      locals: [],
      templates: []
    };
  }());
});
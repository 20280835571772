define('presenter/models/cartile', ['exports', 'presenter/globals', 'presenter/data', 'presenter/mixins/response-graph-helper', 'presenter/mixins/sluricane-query-properties', 'presenter/utils/sluricane-adapter'], function (exports, _globals, _data, _responseGraphHelper, _sluricaneQueryProperties, _sluricaneAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Cartile;

  Cartile = _data.Model.extend(_sluricaneQueryProperties.default, _responseGraphHelper.default, {
    name: (0, _data.attr)('string'),
    type: (0, _data.attr)('type'),
    shareToken: (0, _data.attr)('string'),
    periodOffset: (0, _data.attr)('string'),
    daysOffset: (0, _data.attr)('string'),
    description: (0, _data.attr)('string'),
    sortBy: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'rank';
      }
    }),
    termsMode: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'allterms';
      }
    }),
    blocklist: (0, _data.attr)('raw'),
    allowlist: (0, _data.attr)('raw'),
    lastResponseDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    firstResponseDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    hasAccess: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    sluricaneToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    responseGraphToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    keywordToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    term: (0, _data.attr)('string'),
    conditionOperator: (0, _data.attr)('string', {
      defaultValue: 'and',
      deserializeOnPreview: true
    }),
    window: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'rolling_days_30';
      }
    }),
    thresholdValues: (0, _data.attr)('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    dashboard: (0, _data.belongsTo)('dashboard', {
      serialize: 'id'
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    existingCartile: (0, _data.belongsTo)('cartile', {
      serialize: 'id'
    }),
    crosstabComponent: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    crosstabGridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    crosstabEnabled: (0, _data.attr)('boolean', {
      defaultValue: false
    }),
    crosstabUseMatrix: (0, _data.attr)('boolean', {
      defaultValue: true
    }),
    answers: function () {
      return [];
    }.property(),
    keywords: function () {
      return [];
    }.property(),
    isRestricted: function () {
      return this.get('id') && !this.get('hasAccess');
    }.property('id', 'hasAccess'),
    sluricaneAdapter: function () {
      return _sluricaneAdapter.default.create({
        model: this
      });
    }.property(),
    dimensions: function () {
      switch (this.get('type')) {
        case 'numeric':
        case 'percentage':
        case 'donut':
        case 'gauge':
        case 'keyword_gauge':
        case 'response_count':
          return {
            height: 1,
            width: 1
          };
        case 'trend':
        case 'percentage_trend':
        case 'keyword_trend':
          return {
            height: 1,
            width: 3
          };
        case 'answer':
        case 'keyword':
        case 'positive_keyword':
        case 'negative_keyword':
          return {
            height: 1,
            width: 2
          };
        case 'nps_crosstab':
          return {
            height: 2,
            width: 3
          };
        case 'key_driver':
          return {
            height: 2,
            width: 3
          };
      }
    }.property('type'),
    dateInterval: function () {
      if (this.get('type') === 'trend' || this.get('type') === 'percentage_trend' || this.get('type') === 'keyword_trend') {
        return this._super();
      }
    }.property('type', 'daysInQuery'),
    limit: function () {
      if (this.get('type') === 'answer') {
        return 5;
      }
    }.property('type'),
    direction: function () {
      if (this.get('type') === 'answer') {
        return 'desc';
      }
    }.property('type'),
    last: function () {
      if (this.get('type') === 'answer') {
        return this.get('answers.lastObject.responseRethinkId');
      }
    }.property('type', 'answers.lastObject.responseRethinkId'),
    gaugeScoreRange: Ember.computed('component.type', function () {
      switch (this.get('component.type')) {
        case 'Slider':
          return {
            min: 0,
            max: 100
          };
        case 'Rating':
          return {
            min: 0,
            max: this.get('component.number')
          };
        default:
          return {
            min: -100,
            max: 100
          };
      }
    }),
    includeIncompleteResponses: false,
    globalConditions: function () {
      return [];
    }.property(),
    changeFeed: Ember.computed.oneWay('dashboard.changeFeed'),
    _register: function () {
      var ref;
      return (ref = this.get('changeFeed')) != null ? ref.register(this) : void 0;
    }.on('didCreate').on('didLoad').observes('changeFeed'),
    _unregister: function () {
      return this.get('changeFeed').unregister(this);
    }.on('didDelete'),
    _pauseChangeFeed: function () {
      return this.get('changeFeed').pause(this);
    }.on('pauseChangeFeed'),
    _resumeChangeFeed: function () {
      return this.get('changeFeed').resume(this);
    }.on('resumeChangeFeed'),
    _refreshChangeFeed: function () {
      return this.get('dashboard').previewRecord().then(function (_this) {
        return function () {
          return _this.get('changeFeed').refresh();
        };
      }(this));
    }.on('refreshChangeFeed')
  });

  Cartile.reopenClass({
    TYPES: _globals.codeSettings.cartileTypes,
    QUESTION_TYPE_TO_SUPPORTED_TYPES: _globals.codeSettings.cartileQuestionTypeToSupportedTypes,
    resourceName: 'cartile'
  });

  Cartile.reopenForEach(Cartile.TYPES, function (type, Cartile) {
    return Cartile["is" + type.classify()] = Ember.computed.equal('type', type);
  });

  exports.default = Cartile;
});
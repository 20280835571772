define('presenter/models/campaign-monitor-list-field', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CampaignMonitorListField;

  CampaignMonitorListField = _data.Model.extend({
    key: (0, _data.attr)('string'),
    dataType: (0, _data.attr)('string'),
    fieldName: (0, _data.attr)('string'),
    required: (0, _data.attr)('boolean', {
      defaultValue: false
    })
  });

  CampaignMonitorListField.reopenClass({
    resourceName: 'campaign_monitor_list_field'
  });

  exports.default = CampaignMonitorListField;
});
define("presenter/templates/svg/_surveys_icon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_surveys_icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "width", "32px");
        dom.setAttribute(el1, "height", "32px");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("g");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M13,1H2C1.447,1,1,1.447,1,2v11c0,0.553,0.447,1,1,1h11c0.553,0,1-0.447,1-1V2C14,1.447,13.553,1,13,1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "d", "M30,1H19c-0.553,0-1,0.447-1,1v11c0,0.553,0.447,1,1,1h11c0.553,0,1-0.447,1-1V2\n	C31,1.447,30.553,1,30,1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "d", "M13,18H2c-0.553,0-1,0.447-1,1v11c0,0.553,0.447,1,1,1h11c0.553,0,1-0.447,1-1V19\n	C14,18.447,13.553,18,13,18z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M30,18H19c-0.553,0-1,0.447-1,1v11c0,0.553,0.447,1,1,1h11c0.553,0,1-0.447,1-1V19\n	C31,18.447,30.553,18,30,18z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/helpers/css-color', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg, arg1) {
    var alpha, val;
    val = arg[0];
    alpha = arg1.alpha;
    if (alpha) {
      return (0, _utils.colorToRGBA)(val, alpha);
    } else {
      return (0, _utils.colorToDOMHex)(val);
    }
  });
});
define('presenter/controllers/presenter/exit', ['exports', 'presenter/controllers/presenter/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    createAnswer: function createAnswer() {
      return void 0;
    },
    saveAnswer: function saveAnswer() {
      return Ember.RSVP.resolve();
    }
  });
});
define('presenter/utils/ios', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var fixIos7Webview = exports.fixIos7Webview = function fixIos7Webview() {
    return Ember.$(document).ready(function () {
      var $viewport, _clearViewportHeight, _setViewportHeight, viewportContent;
      if (Modernizr.ios7) {
        if (window.navigator.standalone) {
          Ember.$('body').addClass('ios7-standalone');
        }
        $viewport = Ember.$('meta[name="viewport"]');
        viewportContent = $viewport.prop('content');
        _clearViewportHeight = function _clearViewportHeight() {
          return $viewport.prop('content', viewportContent);
        };
        _setViewportHeight = function _setViewportHeight() {
          return $viewport.prop('content', viewportContent + ",height=" + Ember.$(window).height());
        };
        _setViewportHeight();
        return Ember.$(window).bind('orientationchange', function () {
          _clearViewportHeight();
          return setTimeout(_setViewportHeight, 150);
        });
      }
    });
  };
});
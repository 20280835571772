define('presenter/helpers/translated-properties', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    presenterResponseController: Ember.K,
    destroy: function destroy() {
      var response;
      this._super(arguments);
      response = this.presenterResponseController.get('model');
      return response.off('languageChange', this, this.recompute);
    },
    _onInit: function () {
      var response;
      response = this.presenterResponseController.get('model');
      return response.on('languageChange', this, this.recompute);
    }.on('init'),
    compute: function compute(params) {
      return _i18nJs.default.t(params[0] + "." + params[1] + "." + params[2].underscore());
    }
  });
});
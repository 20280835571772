define('presenter/instance-initializers/merge-response-values', ['exports', 'presenter/helpers/merge-response-values'], function (exports, _mergeResponseValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'mergeResponseValues',
    initialize: function initialize(instance) {
      return _mergeResponseValues.default.reopen({
        presenterResponseController: instance.container.lookup('controller:presenter/response'),
        presenterComponentsController: instance.container.lookup('controller:presenter/components')
      });
    }
  };
});
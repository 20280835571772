define('presenter/mixins/themeable', ['exports', 'presenter/utils', 'presenter/globals'], function (exports, _utils, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Themeable;

  Themeable = {};

  Themeable.ATTRIBUTES = ['font', 'backgroundImage', 'backgroundDisplay', 'backgroundType', 'backgroundColor', 'questionColor', 'answerColor', 'buttonColor'];

  Themeable.COLOR_PROPERTIES = ['backgroundColor', 'questionColor', 'answerColor', 'buttonColor'];

  Themeable.BACKGROUND_DISPLAY_STYLES = ['fill', 'fit', 'tiled', 'centered'];

  Themeable.ContrastProperties = Ember.Mixin.create({
    backgroundColorWithBrightness: function () {
      var brightness, color;
      color = this.get('backgroundColor');
      if (brightness = this.get('backgroundImage') && this.get('backgroundImageBrightness')) {
        if (brightness >= 0) {
          color = _utils.lightenColor(color, brightness);
        } else {
          color = _utils.darkenColor(color, -brightness);
        }
      }
      return color;
    }.property('backgroundColor', 'backgroundImage', 'backgroundImageBrightness').readOnly(),
    isQuestionColorBright: Ember.computed('questionColor', function () {
      if (_utils.perceivedLuminosity(this.get('questionColor')) > _globals.codeSettings.perceivedLuminosityCutoff) {
        return 1;
      } else {
        return 0;
      }
    }),
    backgroundColorContrastGray: function backgroundColorContrastGray(percentage) {
      var component, contrastColor, luminosity;
      luminosity = _utils.perceivedLuminosity(this.get('backgroundColorWithBrightness'));
      contrastColor = luminosity < _globals.codeSettings.perceivedLuminosityCutoff ? 1 : 0;
      component = Math.round(0xFF * ((contrastColor - luminosity) * percentage + luminosity));
      return component << 16 | component << 8 | component;
    },
    backgroundColorContrastGray100: Ember.aliasMethod('backgroundColorContrastTextColor')
  });

  Themeable.ContrastProperties.reopenForEach([90, 80, 70, 60, 50, 40, 30, 20, 10], function (percent, ContrastProperties) {
    return ContrastProperties["backgroundColorContrastGray" + percent] = function () {
      return this.backgroundColorContrastGray(percent / 100);
    }.property('backgroundColorWithBrightness');
  });

  Themeable.ContrastProperties.reopenForEach(Themeable.COLOR_PROPERTIES, function (property, ContrastProperties) {
    return ContrastProperties[property + "ContrastTextColor"] = function () {
      return _utils.contrastColor(this.get(property));
    }.property(property);
  });

  exports.default = Themeable;
});
define('presenter/models/response-filter', ['exports', 'presenter/mixins/change-feed-manager', 'presenter/mixins/sluricane-query-properties', 'presenter/utils/sluricane-adapter', 'presenter/models/report-response', 'presenter/data'], function (exports, _changeFeedManager, _sluricaneQueryProperties, _sluricaneAdapter, _reportResponse, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ResponseFilter;

  ResponseFilter = _data.Model.extend(_changeFeedManager.default, _sluricaneQueryProperties.default, {
    title: (0, _data.attr)('string'),
    changeFeedToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    sluricaneToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    responseGraphToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    conditionOperator: (0, _data.attr)('string', {
      defaultValue: 'and',
      deserializeOnPreview: true
    }),
    sluricaneAdapter: Ember.computed(function () {
      return _sluricaneAdapter.default.create({
        model: this
      });
    }),
    changeFeedId: 'response_filter',
    controller: null,
    register: function register() {
      if (this.get('changeFeedToken')) {
        this.get('changeFeed').register(this, 'changeFeedId');
        return this.get('changeFeed').start();
      }
    },
    _unregister: function () {
      return this.get('changeFeed').unregister(this, 'changeFeedId');
    }.on('didDelete'),
    dataInserted: function dataInserted(data) {
      var insertedData;
      insertedData = _reportResponse.default.load(data);
      insertedData.get('answers').forEach(function (_this) {
        return function (answer) {
          return answer.sideloadRelationships(_this.get('survey.identityMap'));
        };
      }(this));
      return this.get('controller').unshiftObject(insertedData);
    },
    dataUpdated: function dataUpdated(data) {
      var controller, updatedResponse;
      controller = this.get('controller');
      updatedResponse = _reportResponse.default.load(data);
      updatedResponse.get('answers').forEach(function (_this) {
        return function (answer) {
          return answer.sideloadRelationships(_this.get('survey.identityMap'));
        };
      }(this));
      return controller.forEach(function (response) {
        if (response.get('id') === updatedResponse.get('id')) {
          return controller.replaceContent(controller.indexOf(response), 1, [updatedResponse]);
        }
      });
    },
    dataDeleted: function dataDeleted(data) {
      var controller;
      controller = this.get('controller');
      return controller.forEach(function (response) {
        if (response.get('id') === data.id) {
          return controller.removeObject(response);
        }
      });
    }
  });

  ResponseFilter.reopenClass({
    endpoint: 'response_filters',
    resourceName: 'response_filter'
  });

  exports.default = ResponseFilter;
});
define('presenter/components/presenter/date-day', ['exports', 'presenter/components/presenter/date-base', 'presenter/utils'], function (exports, _dateBase, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateBase.default.extend({
    name: 'day',
    classNames: ['DateField-inputField', 'dd'],
    maxlength: 2,
    validValue: Ember.computed.alias('dateField.dayValue'),
    monthValue: Ember.computed.oneWay('dateField.monthValue'),
    yearValue: Ember.computed.oneWay('dateField.yearValue'),
    placeholder: Ember.computed.oneWay('dateField.dayPlaceholder'),
    focused: Ember.computed.alias('dateField.dayFocused'),
    min: 1,
    max: Ember.computed.oneWay('daysInMonth'),
    validations: function () {
      var intMonth;
      intMonth = this.get('intMonth');
      if (!intMonth) {
        return true;
      }
      return this.get('intValue') <= (0, _utils.daysInMonth)(intMonth - 1, this.get('intYear'));
    }.property('intValue', 'intMonth', 'intYear'),
    _isComplete: function _isComplete() {
      var intValue, isSingleDigit, value;
      value = this.get('value');
      if (Ember.isEmpty(value)) {
        return false;
      }
      intValue = this.get('intValue');
      isSingleDigit = value.length === 1;
      if (intValue > 3 && intValue <= 10) {
        return true;
      }
      if (intValue === 1 && !isSingleDigit) {
        return true;
      }
      if (intValue < 4 && isSingleDigit) {
        return false;
      }
      if (intValue < 4 && !isSingleDigit) {
        return true;
      }
      if (intValue > 31) {
        return false;
      }
      if (intValue <= 1) {
        return false;
      }
      return intValue > 10 && intValue <= 31;
    }
  });
});
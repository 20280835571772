define('presenter/models/list-item', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ListItem;

  ListItem = _data.Model.extend({
    text: (0, _data.attr)('string')
  });

  ListItem.reopenClass({
    resourceName: 'list_item'
  });

  exports.default = ListItem;
});
define("presenter/templates/svg/dashboard/key_driver", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/key_driver.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "data-name", "Layer 1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 53 48.1");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "cls-1");
        dom.setAttribute(el2, "d", "M14.05 25.2l-6.4-11.1c-.4-.3-.8-.3-1 0L.25 25.2a.91.91 0 001.1 1.3l2.7-.9v14.5a3 3 0 003 3 2.89 2.89 0 003-3V25.5l2.9 1a.91.91 0 001.1-1.3zM52.82 25.2l-6.4-11.1c-.4-.3-.8-.3-1 0L39 25.2a.91.91 0 001.1 1.3l2.7-.9v14.5a3 3 0 003 3 2.89 2.89 0 003-3V25.5l2.9 1a.91.91 0 001.12-1.3zM32.3 20.6l-2.9 1V7a2.89 2.89 0 00-3-3 3 3 0 00-3 3v14.5l-2.7-.9a.91.91 0 00-1.1 1.3L26 33c.2.3.6.3 1 0l6.4-11.1a.91.91 0 00-1.1-1.3z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/presenter/short-answer-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 197
            }
          },
          "moduleName": "presenter/templates/presenter/short-answer-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/text-area", [], ["classNames", "answer-text", "value", ["subexpr", "@mut", [["get", "component.answer.text", ["loc", [null, [1, 86], [1, 107]]]]], [], []], "actionOnShiftEnter", "advance", "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 144], [1, 153]]]]], [], []], "aria-label", ["subexpr", "@mut", [["get", "component.title", ["loc", [null, [1, 165], [1, 180]]]]], [], []], "role", "textbox"], ["loc", [null, [1, 33], [1, 197]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 197
            },
            "end": {
              "line": 1,
              "column": 343
            }
          },
          "moduleName": "presenter/templates/presenter/short-answer-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/text-field", [], ["classNames", "answer-text", "value", ["subexpr", "@mut", [["get", "component.answer.text", ["loc", [null, [1, 259], [1, 280]]]]], [], []], "action", "advance", "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 305], [1, 314]]]]], [], []], "aria-label", ["subexpr", "@mut", [["get", "component.title", ["loc", [null, [1, 326], [1, 341]]]]], [], []]], ["loc", [null, [1, 205], [1, 343]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 350
          }
        },
        "moduleName": "presenter/templates/presenter/short-answer-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.model.multiline", ["loc", [null, [1, 6], [1, 31]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 350]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
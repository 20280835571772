define('presenter/models/report-response', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ReportResponse;

  ReportResponse = _data.Model.extend({
    aggregateDate: (0, _data.attr)('date'),
    answeredComponentIds: (0, _data.attr)('raw'),
    answers: (0, _data.hasMany)('sluricane_answer'),
    campaignId: (0, _data.attr)('number'),
    completedAt: (0, _data.attr)('date'),
    createdAt: (0, _data.attr)('date'),
    emailSentAt: (0, _data.attr)('date'),
    emailOpenedAt: (0, _data.attr)('date'),
    emailClickedAt: (0, _data.attr)('date'),
    mergeMap: (0, _data.attr)('raw'),
    language: (0, _data.attr)('string'),
    rethinkId: (0, _data.attr)('string'),
    status: (0, _data.attr)('number'),
    surveyId: (0, _data.attr)('number'),
    updatedAt: (0, _data.attr)('date'),
    pushStatus: (0, _data.attr)('string'),
    embeddedAnswerMap: (0, _data.attr)('raw', {
      defaultValue: {}
    }),
    ipAddress: (0, _data.attr)('string'),
    countryCode: (0, _data.attr)('string'),
    countryCodeWithEmoji: (0, _data.attr)('string'),
    regionCode: (0, _data.attr)('string'),
    city: (0, _data.attr)('string'),
    countryName: (0, _data.attr)('string'),
    regionName: (0, _data.attr)('string'),
    postalCode: (0, _data.attr)('string'),
    latitude: (0, _data.attr)('string'),
    longitude: (0, _data.attr)('string'),
    inEu: (0, _data.attr)('string'),
    getAnswers: function getAnswers(component) {
      if (!this._componentAnswerMap) {
        this._componentAnswerMap = {};
        this.get('answers').forEach(function (_this) {
          return function (answer) {
            var base, name;
            (base = _this._componentAnswerMap)[name = answer.get('component.id')] || (base[name] = []);
            return _this._componentAnswerMap[answer.get('component.id')].addObject(answer);
          };
        }(this));
      }
      return this._componentAnswerMap[component.get('id')];
    }
  });

  ReportResponse.reopenClass({
    resourceName: 'report_response',
    baseUrl: _globals.jsSettings.sluricane.url,
    endpoint: 'data'
  });

  exports.default = ReportResponse;
});
define("presenter/templates/svg/question_types/_section_break", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_section_break.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "data-name", "Layer 1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("section_break");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("polygon");
        dom.setAttribute(el2, "id", "sectionbreak");
        dom.setAttribute(el2, "class", "cls-1");
        dom.setAttribute(el2, "points", "46 19 46 22.99 2.03 22.99 2.03 19 0 19 0 28.65 2.03 28.65 2.03 25.01 46 25.01 46 28.65 48.03 28.65 48.03 19 46 19");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/dashboard/gear", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/gear.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("style");
        var el3 = dom.createTextNode(".st0{fill:#343434;}");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M16 21c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M20 32h-8v-4.7c-.4-.1-.8-.3-1.2-.5l-3.3 3.3-5.6-5.6 3.3-3.3c-.2-.4-.4-.8-.5-1.2H0v-8h4.7c.1-.4.3-.8.5-1.2L1.9 7.5l5.6-5.6 3.3 3.3c.4-.2.8-.4 1.2-.5V0h8v4.7c.4.1.8.3 1.2.5l3.3-3.3 5.6 5.6-3.3 3.3c.2.4.4.8.5 1.2H32v8h-4.7c-.1.4-.3.8-.5 1.2l3.3 3.3-5.6 5.6-3.3-3.3c-.4.2-.8.4-1.2.5V32zm-6-2h4v-4.2l.8-.2c.7-.2 1.4-.5 2.2-.9l.6-.3 2.9 2.9 2.8-2.8-2.9-2.9.4-.7c.4-.7.7-1.5.9-2.2l.2-.7H30v-4h-4.2l-.2-.8c-.2-.7-.5-1.4-.9-2.2l-.3-.6 2.9-2.9-2.8-2.8-2.9 2.9-.7-.4c-.7-.4-1.5-.7-2.2-.9l-.7-.2V2h-4v4.2l-.8.2c-.7.2-1.4.5-2.2.9l-.6.3-2.9-2.9-2.8 2.8 2.9 2.9-.4.7c-.4.7-.7 1.5-.9 2.2l-.2.7H2v4h4.2l.2.8c.2.7.5 1.4.9 2.2l.3.6-2.9 2.9 2.8 2.8 2.9-2.9.7.4c.7.4 1.5.7 2.2.9l.7.2V30z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/routes/presenter/components/view', ['exports', 'presenter/globals', 'presenter/utils'], function (exports, _globals, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params, transition) {
      var components, controller, currentIndex, index, model;
      components = this.controllerFor('presenter/components');
      if (!components.get('landedAtStart')) {
        return this.replaceWith('presenter/components.index');
      }
      index = Number(params.component_id);
      controller = params.component_id === 'thanks' ? components.get('defaultExitController') : components.get('hasCoverPage') ? components.findBy('index', index) : components.findBy('index', index - 1);
      if (!controller) {
        return this.replaceWith('presenter/components.index');
      }
      currentIndex = components.get('currentComponentController.index');
      if (index > currentIndex) {
        transition.abort();
        this.send('next');
        return;
      }
      model = controller.get('content');
      if (index < currentIndex) {
        components.clearHistory(model);
      }
      return model;
    },
    afterModel: function afterModel(model, transition) {
      var componentsArray, componentsController, jumpComponent, modelController, prerender, shouldRedirect, validComponent;
      componentsArray = this.modelFor('presenter/components');
      componentsController = this.controllerFor('presenter/components');
      modelController = componentsController.controllerForComponent(model);
      prerender = function (_this) {
        return function () {
          if (componentsController.prerenderComponent(model)) {
            return new Ember.RSVP.Promise(function (resolve) {
              return Ember.run.schedule('afterRender', resolve);
            });
          } else {
            return Ember.RSVP.resolve();
          }
        };
      }(this);
      if (model.get('isExit')) {
        if ((validComponent = componentsArray.findBy('id', model.get('id'))) !== model) {
          this.replaceWith('presenter/components.view', validComponent);
          return;
        }
        this.controllerFor('presenter/survey').send('evaluateRules');
        shouldRedirect = model.get('type') === 'Redirect' && !(_globals.isPreview && _globals.isIframed);
        return (shouldRedirect ? Ember.RSVP.resolve() : prerender()).then(function (_this) {
          return function () {
            var responseController;
            responseController = _this.controllerFor('presenter/response');
            return responseController.submit().then(function () {
              var redirectUrl;
              if (shouldRedirect) {
                responseController.set('submitting', true);
                redirectUrl = (0, _utils.mergeResponseValues)(model.get('redirectUrl'), responseController, modelController, true);
                transition.send('redirect', redirectUrl);
                return transition.abort();
              }
            });
          };
        }(this));
      } else {
        if (model.get('position') > componentsController.get('currentComponent.position') || componentsController.get('currentComponent') === null) {
          if (jumpComponent = modelController.evaluateRules(true)) {
            this.replaceWith('presenter/components.view', jumpComponent);
            return;
          }
          if (!(0, _globals.presenterFlag)('isReadOnly') && modelController.get('hasEmbeddedAnswer') || model.get('isFormType') && modelController.get('formFieldsAllHiddenByMergeFields')) {
            if (!(jumpComponent = modelController.evaluateRules())) {
              jumpComponent = componentsArray.objectAt(model.get('position') + 1);
            }
            componentsController.pushHistory(model);
            this.replaceWith('presenter/components.view', jumpComponent);
            return;
          }
        }
        return prerender();
      }
    },
    setupController: function setupController(controller, model) {
      var components;
      this._super.apply(this, arguments);
      components = this.controllerFor('presenter/components');
      components.set('currentComponent', model);
      components.get('currentComponentController').refreshChangeState();
      if (components.get('currentComponentController').get('answer.skipped')) {
        return components.get('currentComponentController').set('answer.skipped', false);
      }
    },
    serialize: function serialize(model) {
      return {
        component_id: (0, _globals.presenterFlag)('isClosed') ? 'closed' : (0, _globals.presenterFlag)('isDuplicateResponse') ? 'duplicate' : (0, _globals.presenterFlag)('isInvalid') ? 'invalid' : model.get('isExit') ? 'thanks' : model.get('questionNumber')
      };
    },
    actions: {
      error: function error() {
        this.send('enableNavigationAndInput');
        return true;
      },
      willTransition: function willTransition(transition) {
        var components, currIndex, nextIndex;
        components = this.controllerFor('presenter/components');
        if (transition.intent.name === 'presenter/components.view') {
          currIndex = components.get('model').indexOf(this.modelFor('presenter/components.view'));
          nextIndex = components.get('model').indexOf(transition.intent.contexts[0]);
          components.slice(currIndex + 1, nextIndex).forEach(function (component) {
            var answer;
            if (!(answer = component.get('answer'))) {
              return;
            }
            answer.set('skipped', true);
            return answer.saveRecord();
          });
        }
        if (components.get('exiting')) {
          transition.abort();
          location.reload();
          window.history.pushState({
            path: window.location.pathname
          }, document.title, window.location.pathname);
          return;
        }
        return true;
      },
      restartSurvey: function restartSurvey() {
        return window.location.reload();
      }
    }
  });
});
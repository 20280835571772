define("presenter/templates/svg/recipes/delete", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/recipes/delete.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "width", "32px");
        dom.setAttribute(el1, "height", "32px");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "fill", "rgb(199, 205, 210)");
        dom.setAttribute(el2, "d", "M22.648,31.984 L9.404,32.009 L0.020,22.662 L-0.005,9.418 L9.342,0.034 L22.586,0.009 L31.970,9.356 L31.995,22.600 L22.648,31.984 ZM23.071,10.343 L21.657,8.929 L16.000,14.586 L10.343,8.929 L8.929,10.343 L14.586,16.000 L8.929,21.657 L10.343,23.071 L16.000,17.414 L21.657,23.071 L23.071,21.657 L17.414,16.000 L23.071,10.343 Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/stats', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Stats;

  Stats = _data.Model.extend((_Model$extend = {
    componentId: (0, _data.attr)('number'),
    choiceId: (0, _data.attr)('number'),
    gridItemId: (0, _data.attr)('number'),
    name: (0, _data.attr)('string'),
    count: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    totalResponses: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    distribution: (0, _data.hasMany)('distribution_grouping'),
    total: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    totalPercentage: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    financialAmount: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    avg: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    binSize: 4,
    sliderDistribution: function () {
      var distribution, i, results;
      distribution = function () {
        results = [];
        for (i = 0; i <= 25; i++) {
          results.push(i);
        }
        return results;
      }.apply(this).map(function () {
        return 0;
      });
      this.get('distribution').forEach(function (_this) {
        return function (d) {
          return distribution[Math.floor(d.get('number') / _this.get('binSize'))] += d.get('count');
        };
      }(this));
      return distribution;
    }.property('distribution.@each.{number,count}', 'binSize'),
    averages: (0, _data.hasMany)('average'),
    histogram: (0, _data.attr)('raw'),
    financialAmounts: (0, _data.attr)('raw'),
    score: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    passivesPercentage: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    promotersPercentage: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    detractorsPercentage: (0, _data.attr)('number', {
      defaultValue: 0
    })
  }, _defineProperty(_Model$extend, 'passivesPercentage', Ember.computed('promotersPercentage', 'detractorsPercentage', function () {
    return 100 - this.get('promotersPercentage') - this.get('detractorsPercentage');
  })), _defineProperty(_Model$extend, 'npsDistribution', function () {
    var distribution;
    distribution = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(function () {
      return 0;
    });
    this.get('distribution').forEach(function (d) {
      return distribution[d.get('number')] = [d.get('total'), d.get('financialAmount') || 0];
    });
    return distribution;
  }.property('distribution.@each.{number,total}')), _defineProperty(_Model$extend, 'financialAmount', (0, _data.attr)('number', {
    defaultValue: 0
  })), _defineProperty(_Model$extend, 'passivesFinancialAmount', (0, _data.attr)('number', {
    defaultValue: 0
  })), _defineProperty(_Model$extend, 'promotersFinancialAmount', (0, _data.attr)('number', {
    defaultValue: 0
  })), _defineProperty(_Model$extend, 'detractorsFinancialAmount', (0, _data.attr)('number', {
    defaultValue: 0
  })), _defineProperty(_Model$extend, 'crosstabValue', (0, _data.attr)('number')), _defineProperty(_Model$extend, 'crosstab', (0, _data.hasMany)('stats')), _defineProperty(_Model$extend, 'keydriver', (0, _data.attr)('raw')), _defineProperty(_Model$extend, 'date', (0, _data.attr)('date', {
    readOnly: true
  })), _defineProperty(_Model$extend, 'average', function () {
    return this.get('avg').roundTo(2) || 0;
  }.property('avg')), _Model$extend));

  Stats.reopenClass({
    resourceName: 'stats',
    baseUrl: _globals.jsSettings.sluricane.url
  });

  exports.default = Stats;
});
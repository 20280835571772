define('presenter/models/reporting-condition', ['exports', 'presenter/models/component', 'presenter/models/abstract-condition', 'presenter/data'], function (exports, _component, _abstractCondition, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ReportingCondition;

  ReportingCondition = _abstractCondition.default.extend({
    lhsType: '',
    isValid: function () {
      return (!!this.get('lhsComponent') && (this.get('lhsComponent.isForm') || this.get('lhsComponent.isRank') ? this.get('lhsChoice') : true) && (this.get('lhsComponent.hasGridItems') ? !!this.get('lhsGridItem') : true) || !!this.get('lhsMergeKey')) && !!this.get('operator') && this.get('validRhs') && this.get('validRhsRange');
    }.property('lhsComponent.{isForm,isRank,hasGridItems}', 'lhsChoice', 'lhsGridItem', 'lhsMergeKey', 'operator', 'validRhs', 'validRhsRange'),
    isInvalidKeyDriverFilter: function () {
      var componentId, crosstabComponentId, lhsComponentId;
      lhsComponentId = this.get('lhsComponent.id');
      componentId = this.get('cartile.component.id');
      crosstabComponentId = this.get('cartile.crosstabComponent.id');
      return lhsComponentId === componentId || lhsComponentId === crosstabComponentId;
    }.property('lhsMergeKey', 'lhsType', 'lhsComponent'),
    isMergeFieldFilter: function () {
      return this.get('lhsMergeKey') && this.get('lhsMergeKey') !== _abstractCondition.default.LANGUAGE_KEY_FOR_CONDITION || this.get('lhsType') === 'mergeKey';
    }.property('lhsMergeKey', 'lhsType'),
    isLanguageFilter: function () {
      return this.get('lhsMergeKey') && this.get('lhsMergeKey') === _abstractCondition.default.LANGUAGE_KEY_FOR_CONDITION;
    }.property('lhsMergeKey', 'lhsType'),
    operatorText: function () {
      if (this.get('operator')) {
        return _abstractCondition.default.OPERATORS.get(this.get('operator')).label;
      } else {
        return '';
      }
    }.property('operator'),
    rhsText: function () {
      if (this.get('lhsComponent.type') === 'MultipleChoice' || this.get('lhsComponent.type') === 'PictureChoice') {
        return this.get('rhsChoices.firstObject.text');
      } else if (this.get('lhsComponent.type') === 'LikeDislike') {
        switch (this.get('rhs')) {
          case '-1':
            return 'Dislike';
          case '0':
            return 'Neutral';
          case '1':
            return 'Like';
        }
      } else {
        return this.get('rhs') || '';
      }
    }.property('lhsComponent.type', 'rhsChoices.@each.text', 'rhs')
  });

  ReportingCondition.LANGUAGE_OPERATORS = _abstractCondition.default.VALID_OPERATORS_FOR_MERGE_VALUE.filter(function (operator) {
    return ['equal', 'not_equal'].contains(operator.value);
  });

  ReportingCondition.reopenClass({
    resourceName: 'reporting_condition'
  });

  exports.default = ReportingCondition;
});
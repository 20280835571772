define('presenter/mixins/rating-controller-helper', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    showLabels: Ember.computed.gt('model.number', 4),
    hasLabels: Ember.computed.or('model.minimumLabel', 'model.maximumLabel'),
    hasNumberScale: Ember.computed.equal('model.shape', 'number'),
    selection: null,
    scale: function () {
      var i, ref, ref1, results;
      return function () {
        results = [];
        for (var i = ref = this.get('model.number'), ref1 = this.get('startAt'); ref <= ref1 ? i <= ref1 : i >= ref1; ref <= ref1 ? i++ : i--) {
          results.push(i);
        }
        return results;
      }.apply(this);
    }.property('model.number', 'startAt'),
    numberItemController: 'object',
    includeNotApplicable: Ember.computed('model.includeNotApplicable', 'hasNumberScale', {
      get: function get(key) {
        return this.get('hasNumberScale') && this.get('model.includeNotApplicable');
      },
      set: function set(key, value) {
        this.set('model.includeNotApplicable', value);
        return value;
      }
    }),
    startAt: Ember.computed('model.startAt', 'hasNumberScale', {
      get: function get(key) {
        if (this.get('hasNumberScale')) {
          return this.get('model.startAt');
        } else {
          return 1;
        }
      },
      set: function set(key) {
        this.set('model.startAt', value);
        return value;
      }
    }),
    notApplicable: Ember.computed('response.model.language', function () {
      this.get('response.model.language');
      return _i18nJs.default.t('shared.labels.not_applicable');
    }),
    actions: {
      select: Ember.K,
      setNotApplicable: Ember.K
    }
  });
});
define('presenter/components/presenter/file-upload-question', ['exports', 'presenter/components/presenter/question', 'presenter/utils'], function (exports, _question, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend({
    draggingCounter: 0,
    dragOver: function dragOver(e) {
      e.preventDefault();
      return e.dataTransfer.dropEffect = 'copy';
    },
    dragEnter: function dragEnter(e) {
      e.preventDefault();
      this.incrementProperty('draggingCounter');
      return this.set('component.isDragging', true);
    },
    dragLeave: function dragLeave(e) {
      e.preventDefault();
      this.decrementProperty('draggingCounter');
      if (this.get('draggingCounter') === 0) {
        return this.set('component.isDragging', false);
      }
    },
    drop: function drop(e) {
      e.preventDefault();
      this.set('draggingCounter', 0);
      this.set('component.isDragging', false);
      return this.get('component').send('setImage', e.dataTransfer.files[0]);
    }
  });
});
define("presenter/templates/svg/dashboard/drag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/drag.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "class", "nc-icon outline");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "width", "32px");
        dom.setAttribute(el1, "height", "32px");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(0, 0)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3, "data-cap", "butt");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "x1", "16");
        dom.setAttribute(el3, "y1", "12");
        dom.setAttribute(el3, "x2", "16");
        dom.setAttribute(el3, "y2", "2");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.setAttribute(el3, "stroke-linecap", "butt");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3, "data-cap", "butt");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "x1", "16");
        dom.setAttribute(el3, "y1", "30");
        dom.setAttribute(el3, "x2", "16");
        dom.setAttribute(el3, "y2", "20");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.setAttribute(el3, "stroke-linecap", "butt");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3, "data-cap", "butt");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "x1", "30");
        dom.setAttribute(el3, "y1", "16");
        dom.setAttribute(el3, "x2", "20");
        dom.setAttribute(el3, "y2", "16");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.setAttribute(el3, "stroke-linecap", "butt");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3, "data-cap", "butt");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "x1", "12");
        dom.setAttribute(el3, "y1", "16");
        dom.setAttribute(el3, "x2", "2");
        dom.setAttribute(el3, "y2", "16");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.setAttribute(el3, "stroke-linecap", "butt");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "points", "21,7 16,2 11,7 \n  ");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "points", "25,21 30,16 \n  25,11 ");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "points", "11,25 16,30 \n  21,25 ");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#999999");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "points", "7,11 2,16 7,21 \n  ");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/trial_expired/logo_twitter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/trial_expired/logo_twitter.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "43px");
        dom.setAttribute(el1, "height", "35px");
        dom.setAttribute(el1, "viewBox", "0 0 43 35");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "id", "Page-1");
        dom.setAttribute(el2, "stroke", "none");
        dom.setAttribute(el2, "stroke-width", "1");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3, "id", "Artboard-Copy");
        dom.setAttribute(el3, "transform", "translate(-655.000000, -198.000000)");
        dom.setAttribute(el3, "fill", "#2DAAE2");
        dom.setAttribute(el3, "fill-rule", "nonzero");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4, "id", "twitter");
        dom.setAttribute(el4, "transform", "translate(655.000000, 198.000000)");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "d", "M43,4.14315413 C41.4177375,4.84586267 39.7177291,5.32086808 37.9332326,5.53443966 C39.7545142,4.44092624 41.1533552,2.7093898 41.8121273,0.646014083 C40.1073318,1.65869163 38.2195347,2.39395323 36.2099605,2.79014156 C34.600823,1.0729049 32.3079701,0 29.7705551,0 C24.8985471,0 20.9486856,3.95591098 20.9486856,8.83550434 C20.9486856,9.5280348 21.026707,10.2023961 21.1771227,10.8491672 C13.8453011,10.4805691 7.34483917,6.96298878 2.99378517,1.61713812 C2.23439993,2.92212021 1.79927774,4.43991684 1.79927774,6.05924199 C1.79927774,9.1247807 3.35668094,11.8292917 5.72385991,13.4137927 C4.27773579,13.3678651 2.91744352,12.9704151 1.72814311,12.3085871 C1.72730327,12.3454301 1.72730327,12.3824413 1.72730327,12.4197049 C1.72730327,16.7007258 4.76828756,20.2718042 8.80389687,21.0836958 C8.06374402,21.2854911 7.28428655,21.3938331 6.47971781,21.3938331 C5.91122869,21.3938331 5.35861258,21.3383162 4.81985387,21.2350213 C5.9425548,24.7451994 9.20047031,27.3001514 13.0608886,27.3712298 C10.0416562,29.741294 6.2377593,31.1536927 2.10447636,31.1536927 C1.39237423,31.1536927 0.690182246,31.111971 0,31.0299575 C3.90409003,33.5372155 8.54127824,35 13.5232216,35 C29.749895,35 38.6234148,21.536158 38.6234148,9.85995818 C38.6234148,9.47689202 38.6146804,9.09584465 38.5977996,8.71681607 C40.3216595,7.47113605 41.8173343,5.91498234 43,4.14315413 Z");
        dom.setAttribute(el5, "id", "Shape");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
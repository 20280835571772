define("presenter/templates/svg/question_types/_salesforce_case_form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_salesforce_case_form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        dom.setAttribute(el1, "id", "new_case");
        dom.setAttribute(el1, "width", "100%");
        dom.setAttribute(el1, "height", "100%");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M6.9 6h1.9c.2 0 .4-.2.4-.5v-.9h5.6v.9c0 .3.2.5.4.5h1.9c.3 0 .4-.2.4-.5V4.4c0-1.4-1.1-2.6-2.5-2.6H9C7.6 1.8 6.5 3 6.5 4.3v1.2c0 .3.1.5.4.5zm14.3 1.8H2.8c-1 0-1.9.9-1.9 1.9v10.6c0 1 .9 1.9 1.9 1.9h18.4c1 0 1.9-.9 1.9-1.9V9.7c0-1-.9-1.9-1.9-1.9z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/rich-text/bold", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "presenter/templates/svg/rich-text/bold.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "width", "44");
        dom.setAttribute(el1, "height", "44");
        dom.setAttribute(el1, "viewBox", "0 0 44 44");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "id", "bold");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "bold-2");
        dom.setAttribute(el3, "d", "M1398,969.013h6.43a9.454,9.454,0,0,0,2.56-.331,6.611,6.611,0,0,0,2.09-.966,4.645,4.645,0,0,0,1.42-1.563,4.391,4.391,0,0,0,.51-2.149,4.506,4.506,0,0,0-1.17-3.089,4.331,4.331,0,0,0-1.28-.966,4.134,4.134,0,0,0-1.59-.42v-0.051a5.3,5.3,0,0,0,2.33-1.436,3.632,3.632,0,0,0,.89-2.53,4.333,4.333,0,0,0-.43-2,3.912,3.912,0,0,0-1.19-1.4,5.389,5.389,0,0,0-1.8-.826,8.756,8.756,0,0,0-2.26-.28H1398v18Zm2.54-15.866h4a2.87,2.87,0,0,1,2.24.788,2.663,2.663,0,0,1,.72,1.856,2.628,2.628,0,0,1-.28,1.246,2.523,2.523,0,0,1-.75.839,3.116,3.116,0,0,1-1.11.471,6.371,6.371,0,0,1-1.35.14h-3.47v-5.34Zm0,7.475h3.76a4.823,4.823,0,0,1,2.93.8,2.781,2.781,0,0,1,1.09,2.4,2.537,2.537,0,0,1-.43,1.551,3.139,3.139,0,0,1-1.03.928,3.863,3.863,0,0,1-1.31.458,7.919,7.919,0,0,1-1.28.114h-3.73v-6.255Z");
        dom.setAttribute(el3, "transform", "translate(-1382 -938)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
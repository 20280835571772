define('presenter/models/team-user', ['exports', 'presenter/data', 'presenter/models/abstract-user', 'presenter/models/user', 'presenter/mixins/allows-merges'], function (exports, _data, _abstractUser, _user, _allowsMerges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TeamUser;

  TeamUser = _abstractUser.default.extend(_allowsMerges.default, {
    defaultTab: (0, _data.attr)('string'),
    updateStatus: (0, _data.attr)('boolean'),
    credentials: (0, _data.hasMany)('credential', {
      serialize: 'id'
    }),
    salesforceCredentials: Ember.computed('credentials.@each.type', function () {
      return this.get('credentials').filterBy('type', 'salesforce');
    }),
    pardotCredentials: Ember.computed('credentials.@each.type', function () {
      return this.get('credentials').filterBy('type', 'pardot');
    }),
    hasSalesforceCredentials: Ember.computed.notEmpty('salesforceCredentials'),
    hasPardotCredentials: Ember.computed.notEmpty('pardotCredentials')
  });

  TeamUser.reopenForEach(_user.default.STATUSES, function (status, TeamUser) {
    return TeamUser["is" + status.classify()] = Ember.computed.equal('status', status);
  });

  TeamUser.reopenClass({
    resourceName: 'team_user'
  });

  exports.default = TeamUser;
});
define('presenter/helpers/format-for-html', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var text;
    text = arg[0];
    return (0, _utils.autolink)(text, _utils.escapeAndFormatWhitespace, Ember.Handlebars.Utils.escapeExpression);
  });
});
define('presenter/components/presenter/date-month', ['exports', 'presenter/components/presenter/date-base'], function (exports, _dateBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateBase.default.extend({
    name: 'month',
    classNames: ['DateField-inputField', 'mm'],
    maxlength: 2,
    min: 1,
    max: 12,
    validValue: Ember.computed.alias('dateField.monthValue'),
    dayValue: Ember.computed.oneWay('dateField.dayValue'),
    yearValue: Ember.computed.oneWay('dateField.yearValue'),
    placeholder: Ember.computed.oneWay('dateField.monthPlaceholder'),
    focused: Ember.computed.alias('dateField.monthFocused'),
    validations: true,
    _isComplete: function _isComplete() {
      var complete, intValue, value;
      value = this.get('value');
      if (Ember.isEmpty(value)) {
        return false;
      }
      intValue = this.get('intValue');
      if (intValue > this.get('min') && intValue < 10) {
        complete = true;
      } else if (intValue >= 10 && intValue <= this.get('max')) {
        complete = true;
      } else if (value === '01') {
        complete = true;
      }
      return complete;
    }
  });
});
define('presenter/controllers/presenter/condition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend({
    components: Ember.inject.controller('presenter/components'),
    response: Ember.inject.controller('presenter/response'),
    evaluate: function evaluate() {
      var ref;
      if (!!this.get('lhsComponent')) {
        if (this.get('lhsComponent.hasGridItems')) {
          switch (this.get('lhsComponent.type')) {
            case 'MultipleChoice':
              return this._evaluateChoices(this.get('_lhsAnswer.choices'));
            case 'Rating':
            case 'KeyDriver':
              return this._evaluateNumerical(this.get('_lhsAnswer.number'));
            default:
              this._reportError('Invalid condition left-hand-side component type.');
              return false;
          }
        } else {
          switch (this.get('lhsComponent.type')) {
            case 'ShortAnswer':
              return this._evaluateTextual(this.get('_lhsAnswer.text'));
            case 'Form':
            case 'SalesforceForm':
            case 'CampaignMonitorForm':
              return this._evaluateForm(this.get('_lhsAnswer.text'));
            case 'MultipleChoice':
            case 'PictureChoice':
            case 'Rank':
              return this._evaluateChoices(((ref = this.get('_lhsAnswer.choices')) != null ? ref : []).toArray());
            case 'Rating':
            case 'Slider':
            case 'NetPromoter':
            case 'LikeDislike':
            case 'CustomerEffort':
            case 'CustomerSatisfaction':
              return this._evaluateNumerical(this.get('_lhsAnswer.number'));
            case 'FileUpload':
              return this._evaluateCommon(this.get('_lhsAnswer.answerAttachments'));
            default:
              this._reportError('Invalid condition left-hand-side component type.');
              return false;
          }
        }
      } else if (!!this.get('lhsMergeKey')) {
        if (this.get('lhsLanguage')) {
          return this._evaluateLanguage();
        } else {
          return this._evaluateTextual(this.get('_lhsMergeValue'));
        }
      }
    },
    _lhsAnswer: function () {
      var answer, choice, controller, gridItem, ref;
      controller = this.get('components').controllerForComponent(this.get('lhsComponent'));
      if (gridItem = this.get('lhsGridItem')) {
        answer = controller.get('answers').find('gridItem', gridItem);
      } else if (choice = this.get('lhsChoice')) {
        answer = (ref = controller.get('answer.formFields')) != null ? ref.findBy('choice.id', choice.get('id')) : void 0;
      } else {
        answer = controller.get('answer');
      }
      return answer;
    }.property().volatile(),
    _lhsMergeValue: function () {
      var ref;
      return (ref = this.get('response.mergeMap')) != null ? ref[this.get('lhsMergeKey')] : void 0;
    }.property('response.mergeMap', 'lhsMergeKey'),
    _evaluateLanguage: function _evaluateLanguage() {
      var language, rhs;
      if (language = this.get('response.language')) {
        rhs = this.get('rhs');
        switch (this.get('operator')) {
          case 'equal':
            return language === rhs;
          case 'not_equal':
            return language !== rhs;
          default:
            this._reportError('Invalid operator for language condition.');
            return false;
        }
      } else {
        this._reportError('Cannot evaluate language condition when response language is not set.');
        return false;
      }
    },
    _evaluateTextual: function _evaluateTextual(text) {
      var contained, result, rhs;
      if ((result = this._evaluateCommon(text)) !== void 0) {
        return result;
      }
      rhs = this.get('rhs');
      contained = function contained() {
        return (text || "").indexOf(rhs) !== -1;
      };
      switch (this.get('operator')) {
        case 'equal':
          return text === rhs;
        case 'not_equal':
          return text !== rhs;
        case 'contains':
          return contained();
        case 'does_not_contain':
          return !contained();
        default:
          this._reportError('Invalid condition operator for text question.');
          return false;
      }
    },
    _evaluateForm: function _evaluateForm(text) {
      var number, result;
      if ((result = this._evaluateCommon(text)) !== void 0) {
        return result;
      }
      if (this.get('lhsChoice.isNumber')) {
        number = Number(text);
        return this._evaluateNumerical(number);
      } else {
        return this._evaluateTextual(text);
      }
    },
    _evaluateChoices: function _evaluateChoices(selectedChoices) {
      var result;
      if (!selectedChoices) {
        return false;
      }
      if ((result = this._evaluateCommon(selectedChoices)) !== void 0) {
        return result;
      }
      switch (this.get('operator')) {
        case 'equal':
          return selectedChoices.get('length') === 1 && selectedChoices.get('firstObject') === this.get('rhsChoices.firstObject');
        case 'not_equal':
          return !selectedChoices.contains(this.get('rhsChoices.firstObject'));
        case 'is_any_of':
          return this.get('rhsChoices').any(function (choice) {
            return selectedChoices.contains(choice);
          });
        case 'is_none_of':
          return !this.get('rhsChoices').any(function (choice) {
            return selectedChoices.contains(choice);
          });
        case 'is_all_of':
          return this.get('rhsChoices').every(function (choice) {
            return selectedChoices.contains(choice);
          });
        default:
          this._reportError('Invalid condition operator for question with choices');
          return false;
      }
    },
    _evaluateNumerical: function _evaluateNumerical(number) {
      var lower, ref, result, rhs, upper;
      if ((result = this._evaluateCommon(number)) !== void 0) {
        return result;
      }
      if (this.get('operator') === 'between') {
        ref = this.get('rhs').split(','), lower = ref[0], upper = ref[1];
        lower = Number(lower);
        upper = Number(upper);
        if (isNaN(lower) || isNaN(upper)) {
          this._reportError('Invalid condition with between operator.', {
            lower: lower,
            upper: upper
          });
          return false;
        }
        return number >= lower && number <= upper;
      } else if (this.get('operator') === 'is_promoter') {
        return number >= 9;
      } else if (this.get('operator') === 'is_passive') {
        return number === 7 || number === 8;
      } else if (this.get('operator') === 'is_detractor') {
        return number <= 6;
      } else {
        rhs = Number(this.get('rhs'));
        if (isNaN(rhs)) {
          this._reportError('Invalid numerical condition right-hand side.');
          return false;
        }
        switch (this.get('operator')) {
          case 'equal':
            return number === rhs;
          case 'not_equal':
            return number !== rhs;
          case 'greater_than':
            return number > rhs;
          case 'greater_than_or_equal':
            return number >= rhs;
          case 'less_than':
            return number < rhs;
          case 'less_than_or_equal':
            return number <= rhs;
          default:
            this._reportError('Invalid numerical condition operator.');
            return false;
        }
      }
    },
    _evaluateCommon: function _evaluateCommon(lhs) {
      if (Ember.typeOf(lhs) === 'string') {
        lhs = lhs.trim();
      }
      switch (this.get('operator')) {
        case 'is_blank':
          return Ember.isEmpty(lhs);
        case 'is_present':
          return !Ember.isEmpty(lhs);
        default:
          return void 0;
      }
    },
    _reportError: function _reportError(message, extra) {
      return Raven.captureMessage(message, {
        extra: Ember.merge({
          conditionId: this.get('id')
        }, extra)
      });
    }
  });
});
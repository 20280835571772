define("presenter/utils/bitfields", ["exports", "long"], function (exports, _long) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Bitfields;

  Bitfields = {};

  _long.default.prototype.toLongInt = function () {
    return this.getLowBitsUnsigned() + this.getHighBitsUnsigned() * Math.pow(2, 32);
  };

  Bitfields.addProperties = function (klass, bitfield, properties) {
    var bit;
    klass[bitfield.toUpperCase() + "_FIELDS"] = properties;
    bit = 1;
    return klass.reopenForEach(properties, function (field, klazz) {
      (function (bit) {
        return klazz["" + bitfield + field.capitalize()] = Ember.computed(bitfield, {
          get: function get(key) {
            var bitfieldValue;
            bitfieldValue = _long.default.fromNumber(this.get(bitfield), true);
            return !!bitfieldValue.and(bit).toLongInt();
          },
          set: function set(key, value) {
            var bitfieldValue, enabled;
            bitfieldValue = _long.default.fromNumber(this.get(bitfield), true);
            value = !!value;
            enabled = bitfieldValue.or(bit).toLongInt();
            this.set(bitfield, value ? enabled : enabled - bit);
            return value;
          }
        });
      })(bit);
      return bit *= 2;
    });
  };

  exports.default = Bitfields;
});
define('presenter/components/presenter/picture-choice-choice', ['exports', 'presenter/components/presenter/multiple-choice-choice'], function (exports, _multipleChoiceChoice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _multipleChoiceChoice.default.extend({
    layoutName: 'presenter/picture-choice-choice',
    tagName: 'button',
    classNames: ['presenter-picture-choice-choice', 'theme-answer'],
    classNameBindings: ['isSelected:theme-selected:theme-background-screen', 'isSelected::theme-border-10', 'isSelected:is-selected', 'hasImage'],
    attributeBindings: ['isSelected:aria-pressed'],
    hasImage: Ember.computed.notEmpty('choice.mediaObject'),
    tabIndex: Ember.computed('component.minMaxQuestionTip', function () {
      if (this.get('component.minMaxQuestionTip')) {
        return 0;
      } else {
        return null;
      }
    }),
    click: function click() {
      return this.send('toggle', this.get('choice'));
    }
  });
});
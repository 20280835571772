define('presenter/mixins/placeholder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    placeholderValue: '',
    placeholderVisibilityClass: 'hidden',
    label: null,
    ie: function () {
      return Modernizr.ie;
    }.property(),
    ieLessThan11: function () {
      return Modernizr.ie9 || Modernizr.ie10;
    }.property(),
    shouldHandlePlaceholder: function () {
      return !Modernizr.input.placeholder || this.get('ie');
    }.property('ie'),
    placeholder: Ember.computed({
      get: function get(key) {},
      set: function set(key, value) {
        if (!Ember.isEmpty(value) && this.get('shouldHandlePlaceholder')) {
          this.set('placeholderValue', value);
          return null;
        } else {
          return value;
        }
      }
    }),
    _removeDefaultPlaceholder: function () {
      if (this.get('shouldHandlePlaceholder')) {
        return this.$().attr('placeholder', null);
      }
    }.on('didInsertElement'),
    _togglePlaceholderVisibility: function () {
      var addOrRemove, cssClass, label;
      if (!(label = this.get('label'))) {
        return;
      }
      cssClass = this.get('placeholderVisibilityClass');
      addOrRemove = !Ember.isEmpty(this.get('value'));
      return label.toggleClass(cssClass, addOrRemove);
    }.observes('value'),
    _initPlaceholder: function () {
      if (!this.get('shouldHandlePlaceholder')) {
        return;
      }
      this._addPlaceholder();
      this.on('focusIn', function (_this) {
        return function () {
          var ref;
          return (ref = _this.get('label')) != null ? ref.addClass('focus') : void 0;
        };
      }(this));
      return this.on('focusOut', function (_this) {
        return function () {
          var ref;
          return (ref = _this.get('label')) != null ? ref.removeClass('focus') : void 0;
        };
      }(this));
    }.on('didInsertElement'),
    willRemoveElement: function willRemoveElement() {
      var ref;
      this._super.apply(this, arguments);
      if ((ref = this.get('label')) != null) {
        ref.remove();
      }
      this.set('label', null);
      return this.$().unwrap();
    },
    _addPlaceholder: function _addPlaceholder() {
      var element, label, placeholderValue, wrapper;
      if (!(label = this.get('label'))) {
        element = this.$();
        placeholderValue = this.get('placeholderValue');
        label = Ember.$("<label class=\"placeholder-label\">" + placeholderValue + "</label>");
        if (!Ember.isEmpty(this.get('value'))) {
          label.addClass('hidden');
        }
        if (this.get('ieLessThan11')) {
          label.on('mousedown', function (event) {
            element.focus();
            return false;
          });
        }
        this.set('label', label);
        this._setLabelCss();
        wrapper = Ember.$("<span class=\"placeholder-wrapper\" />");
        element.addClass('placeholder-input');
        element.wrap(wrapper);
        return element.before(label);
      }
    },
    _setLabelCss: function _setLabelCss() {
      var css, element, fontSize, label, paddingLeft, paddingTop, textAlign;
      element = this.$();
      paddingLeft = this._sizeWithUnits(this._getSizeFor('padding-left'));
      paddingTop = this._sizeWithUnits(this._getSizeFor('padding-top'));
      fontSize = this._sizeWithUnits(this._getSizeFor('font-size'));
      textAlign = element.css('text-align');
      css = {
        'padding-left': textAlign === 'center' ? 0 : paddingLeft,
        'padding-top': paddingTop,
        'font-size': fontSize,
        'text-align': textAlign
      };
      label = this.get('label');
      return label != null ? label.css(css) : void 0;
    },
    _getSizeFor: function _getSizeFor(property) {
      var attribute;
      attribute = Modernizr.cssremunit ? 'cssRem' : 'cssFloat';
      return this.$()[attribute](property);
    },
    _sizeWithUnits: function _sizeWithUnits(size) {
      if (Modernizr.cssremunit) {
        return size + "rem";
      } else {
        return size + "px";
      }
    }
  });
});
define("presenter/templates/svg/response_graph/infinite", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/response_graph/infinite.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "class", "nc-icon outline");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "width", "32");
        dom.setAttribute(el1, "height", "32");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "stroke-width", "2");
        dom.setAttribute(el2, "stroke-linecap", "square");
        dom.setAttribute(el2, "stroke-miterlimit", "10");
        dom.setAttribute(el2, "d", "M31 16c0 3.314-2.686 6-6 6-7 0-11-12-18-12-3.314 0-6 2.686-6 6s2.686 6 6 6c7 0 11-12 18-12 3.314 0 6 2.686 6 6z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
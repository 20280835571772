define('presenter/mixins/autogrowing', ['exports', 'presenter/mixins/text-sizing', 'presenter/utils/layout'], function (exports, _textSizing, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_textSizing.default, {
    autogrow: false,
    classNameBindings: ['autogrow:autogrowing'],
    autogrowDimension: function () {
      if (this.get('tagName') === 'textarea') {
        return 'height';
      } else {
        return 'width';
      }
    }.property('tagName'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('autogrow')) {
        return this.registerSizing(this._autogrow);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.get('autogrow')) {
        return;
      }
      return Ember.run.next(function (_this) {
        return function () {
          if (!_this.$()) {
            return;
          }
          _this._autogrowMin = _this.get('autogrowDimension') === 'width' && _this.get('placeholder') ? Math.max(_this.$()['width'](), _this._widthForText(_this.get('placeholder'))) : _this.$()[_this.get('autogrowDimension')]();
          return _this._autogrow();
        };
      }(this));
    },
    _autogrow: function _autogrow() {
      var dimension, originalHeight, size;
      if (this.get('autogrow')) {
        dimension = this.get('autogrowDimension');
        size = this["_" + dimension + "ForText"](this.get('value') || this.get('placeholder'));
        if (size < this._autogrowMin) {
          size = this._autogrowMin;
        }
        if (dimension === 'width') {
          return this.$().width(size);
        } else {
          size += this.$().cssInt('paddingTop') + this.$().cssInt('paddingBottom') + this.$().cssInt('borderTop') + this.$().cssInt('borderBottom');
          originalHeight = Math.round(this.$().outerHeight());
          this.$().height(size);
          if (originalHeight !== Math.round(size)) {
            return _layout.default.trigger('possibleResize');
          }
        }
      }
    }
  });
});
define('presenter/controllers/presenter/short-answer', ['exports', 'presenter/controllers/presenter/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    hasAnswer: Ember.computed.notEmpty('answer.text'),
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.text'),
    pipingAnswer: function pipingAnswer() {
      return this.get('answer.text');
    },
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      return this.set('answer.text', null);
    }
  });
});
define('presenter/components/bourbon-demo-prompt', ['exports', 'bourbon/components/bourbon-demo-prompt'], function (exports, _bourbonDemoPrompt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bourbonDemoPrompt.default;
    }
  });
});
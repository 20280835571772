define("presenter/templates/svg/dashboard/tile_bundle", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/tile_bundle.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 161 104");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "d", "M158 104h-41c-1.657 0-3-1.343-3-3V60c0-1.657 1.343-3 3-3h41c1.657 0 3 1.343 3 3v41c0 1.657-1.343 3-3 3zm1-43c0-1.105-.895-2-2-2h-39c-1.105 0-2 .895-2 2v39c0 1.105.895 2 2 2h39c1.105 0 2-.895 2-2V61zm-1-14h-41c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h41c1.657 0 3 1.343 3 3v41c0 1.657-1.343 3-3 3zm1-43c0-1.105-.895-2-2-2h-39c-1.105 0-2 .895-2 2v39c0 1.105.895 2 2 2h39c1.105 0 2-.895 2-2V4zm-58 43H60c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h41c1.657 0 3 1.343 3 3v41c0 1.657-1.343 3-3 3zm1-43c0-1.105-.895-2-2-2H61c-1.105 0-2 .895-2 2v39c0 1.105.895 2 2 2h39c1.105 0 2-.895 2-2V4zM44 47H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h41c1.657 0 3 1.343 3 3v41c0 1.657-1.343 3-3 3zm1.001-43.001c0-1.105-.895-2-2-2H3.999c-1.105 0-2 .895-2 2v39.002c0 1.105.895 2 2 2h39.002c1.105 0 2-.895 2-2V3.999zM3 57h98c1.657 0 3 1.343 3 3v41c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V60c0-1.657 1.343-3 3-3zm-1 43c0 1.105.895 2 2 2h96c1.105 0 2-.895 2-2V61c0-1.105-.895-2-2-2H4c-1.105 0-2 .895-2 2v39z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
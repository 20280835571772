define('presenter/models/salesforce-folder', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceFolder;

  SalesforceFolder = _data.Model.extend({
    folderId: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    fetchReports: (0, _data.attr)('boolean'),
    isOpen: (0, _data.attr)('boolean', {
      serialize: false
    }),
    salesforceReports: (0, _data.hasMany)('salesforce_report', {
      serialize: false
    })
  });

  SalesforceFolder.reopenClass({
    resourceName: 'salesforce_folder',
    useModelKey: true
  });

  exports.default = SalesforceFolder;
});
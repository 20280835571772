define('presenter/components/floating-label-field', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'components/controls/floating-label-field',
    classNames: 'FloatingLabel',
    classNameBindings: ['hasValue'],
    target: Ember.computed.alias('targetObject'),
    value: Ember.computed.oneWay('validated'),
    hasValue: Ember.computed.notEmpty('value'),
    type: 'text'
  });
});
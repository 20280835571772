define('presenter/models/distribution-grouping', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DistributionGrouping;

  DistributionGrouping = _data.Model.extend({
    count: (0, _data.attr)('number'),
    number: (0, _data.attr)('raw'),
    total: (0, _data.attr)('number'),
    financialAmount: (0, _data.attr)('number')
  });

  DistributionGrouping.reopenClass({
    resourceName: 'distribution_grouping'
  });

  exports.default = DistributionGrouping;
});
define("presenter/templates/svg/_eye_closed", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_eye_closed.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        dom.setAttribute(el1, "width", "24");
        dom.setAttribute(el1, "height", "24");
        var el2 = dom.createElement("g");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "d", "M2,12c0,0,4-7,10-7 c6,0,10,7,10,7s-4,7-10,7C6,19,2,12,2,12z");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke", "#111111");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "cx", "12");
        dom.setAttribute(el3, "cy", "12");
        dom.setAttribute(el3, "r", "3");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "stroke-width", "2");
        dom.setAttribute(el3, "stroke-linecap", "square");
        dom.setAttribute(el3, "stroke-miterlimit", "10");
        dom.setAttribute(el3, "x1", "3");
        dom.setAttribute(el3, "y1", "21");
        dom.setAttribute(el3, "x2", "21");
        dom.setAttribute(el3, "y2", "3");
        dom.setAttribute(el3, "stroke-linejoin", "miter");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
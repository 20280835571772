define('presenter/models/web-image', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WebImage;

  WebImage = _data.Model.extend({
    url: (0, _data.attr)('string', {
      readOnly: true
    }),
    contentType: (0, _data.attr)('string', {
      readOnly: true
    }),
    thumbnailUrl: (0, _data.attr)('string', {
      readOnly: true
    }),
    thumbnailHeight: (0, _data.attr)('number', {
      readOnly: true
    }),
    thumbnailWidth: (0, _data.attr)('number', {
      readOnly: true
    })
  });

  WebImage.reopenClass({
    resourceName: 'web_image'
  });

  exports.default = WebImage;
});
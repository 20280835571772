define('presenter/helpers/filter-missing-piped-answers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    presenterResponseController: Ember.K,
    destroy: function destroy() {
      var response;
      this._super(arguments);
      response = this.presenterResponseController.get('model');
      return response.off('answerChange', this, this.recompute);
    },
    __onInit: function () {
      var response;
      response = this.presenterResponseController.get('model');
      return response.on('answerChange', this, this.recompute);
    }.on('init'),
    compute: function compute(params) {
      var choices, presenterComponentController;
      choices = params[0];
      presenterComponentController = params[1];
      return presenterComponentController.computeFilteredChoices(choices);
    }
  });
});
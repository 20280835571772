define('presenter/models/salesforce-record-type', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceRecordType;

  SalesforceRecordType = _data.Model.extend({
    id: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    developerName: (0, _data.attr)('string')
  });

  SalesforceRecordType.reopenClass({
    resourceName: 'salesforce_record_type'
  });

  exports.default = SalesforceRecordType;
});
define('presenter/controllers/presenter/section-break', ['exports', 'presenter/controllers/presenter/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    createAnswer: function createAnswer() {
      return void 0;
    },
    skipQuestionIfMissingTitle: function skipQuestionIfMissingTitle() {
      return false;
    }
  });
});
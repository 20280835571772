define('presenter/instance-initializers/settings', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'injectSettings',
    initialize: function initialize(instance) {
      instance.registry.register('settings:js', _globals.jsSettings, {
        instantiate: false
      });
      instance.registry.register('settings:code', _globals.codeSettings, {
        instantiate: false
      });
      instance.registry.injection('controller', 'jsSettings', 'settings:js');
      instance.registry.injection('controller', 'codeSettings', 'settings:code');
      instance.registry.injection('component', 'jsSettings', 'settings:js');
      instance.registry.injection('component', 'codeSettings', 'settings:code');
      instance.registry.injection('route', 'jsSettings', 'settings:js');
      return instance.registry.injection('route', 'codeSettings', 'settings:code');
    }
  };
});
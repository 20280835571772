define('presenter/components/autofit-text', ['exports', 'presenter/mixins/autofitting'], function (exports, _autofitting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_autofitting.default, {
    layoutName: 'components/controls/autofit-text',
    autofit: true,
    value: null
  });
});
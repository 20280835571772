define('presenter/models/recipe-menu-item', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RecipeMenuItem;

  RecipeMenuItem = _data.Model.extend({
    name: (0, _data.attr)('string'),
    orderedRecipeMenus: (0, _data.hasMany)('recipe_menu', {
      serialize: true
    }),
    recipeTemplate: (0, _data.attr)('recipe_template', {
      serialize: false
    })
  });

  RecipeMenuItem.reopenClass({
    resourceName: 'recipe_menu_item'
  });

  exports.default = RecipeMenuItem;
});
define('presenter/components/presenter/thank-you-page-component', ['exports', 'presenter/components/presenter/component', 'presenter/globals'], function (exports, _component, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    typeClass: 'thank-you-page',
    isCustomer: (0, _globals.presenterFlag)('isCustomer'),
    click: function click(e) {
      if (this.$(e.target).closest('[data-action="close"]').length) {
        if (window.parent !== window) {
          return window.parent.closeModal();
        } else {
          return window.location.href = _globals.BASE_URL + "/?ref=est";
        }
      }
    }
  });
});
define('presenter/models/website', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Website;

  Website = _data.Model.extend({
    domain: (0, _data.attr)('string'),
    installed: (0, _data.attr)('boolean'),
    approved: (0, _data.attr)('boolean')
  });

  Website.reopenClass({
    resourceName: 'website'
  });

  exports.default = Website;
});
define('presenter/controllers/presenter/file-upload', ['exports', 'i18n-js', 'presenter/controllers/presenter/component', 'presenter/utils', 'presenter/globals'], function (exports, _i18nJs, _component, _utils, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    uploadedImageFile: null,
    fileDataUrl: '',
    isValid: true,
    errorMessage: '',
    isDragging: false,
    hasAnswer: Ember.computed.notEmpty('fileDataUrl'),
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.answerAttachments.[]'),
    clearAnswer: function clearAnswer() {
      return this.send('removeFile');
    },
    imageUrl: function () {
      if ((0, _globals.presenterFlag)('isReadOnly')) {
        return this.get('answer.answerAttachments.firstObject.url');
      } else if (this.get('uploadedImageFile')) {
        return this.get('fileDataUrl');
      }
    }.property('answer.answerAttachments.firstObject.url', 'uploadedImageFile', 'fileDataUrl'),
    _saveAttachment: function () {
      if (!this.get('uploadedImageFile')) {
        return;
      }
      this.set('components.survey.isPending', true);
      return this.saveAnswer().then(function (_this) {
        return function () {
          var file;
          file = _this.get('uploadedImageFile');
          _this.get('answer.answerAttachments').create({
            originalFile: file.name,
            response: _this.get('answer.response'),
            component: _this.get('content'),
            imageNum: 1
          });
          if (_this.get('isLiveSurvey')) {
            return _this.get('answer.answerAttachments.firstObject').saveRecord().then(function (answerAttachment) {
              var formData, methodType, ticket;
              if (ticket = answerAttachment.get('ticket')) {
                formData = new FormData();
                methodType = 'POST';
                if (ticket.provider === 'google') {
                  formData.append('key', ticket.key);
                  formData.append('AWSAccessKeyId', ticket.AWSAccessKeyId);
                  formData.append('acl', ticket.acl);
                  formData.append('policy', ticket.policy);
                  formData.append('signature', ticket.signature);
                  formData.append('Cache-Control', ticket['Cache-Control']);
                  formData.append('Content-Type', answerAttachment.get('contentType'));
                  formData.append('file', file);
                } else {
                  methodType = 'PUT';
                  formData = file;
                }
                return $.ajax({
                  type: methodType,
                  url: ticket.url,
                  data: formData,
                  processData: false,
                  contentType: false
                }).then(function () {
                  (0, _utils.readFile)(_this.get('uploadedImageFile')).then(function (result) {
                    return _this.set('fileDataUrl', result);
                  });
                  return _this.set('components.survey.isPending', false);
                }, function () {
                  _this.set('components.survey.isPending', false);
                  _this.set('isValid', false);
                  return _this.set('errorMessage', _i18nJs.default.t('presenter.file_upload.image_upload_error'));
                });
              } else {
                _this.set('components.survey.isPending', false);
                _this.set('uploadedImageFile', null);
                _this.set('isValid', false);
                return _this.set('errorMessage', _i18nJs.default.t('presenter.file_upload.image_upload_error'));
              }
            });
          } else {
            (0, _utils.readFile)(_this.get('uploadedImageFile')).then(function (result) {
              return _this.set('fileDataUrl', result);
            });
            return _this.set('components.survey.isPending', false);
          }
        };
      }(this));
    }.observes('uploadedImageFile'),
    actions: {
      setImage: function setImage(file) {
        if (this.get('uploadedImageFile')) {
          this.send('removeFile');
        }
        if (!(file && (0, _utils.isImageExtension)(file.name))) {
          return this.setProperties({
            isValid: false,
            errorMessage: _i18nJs.default.t('presenter.file_upload.image_file_required')
          });
        } else if (!(0, _utils.fileIsValidSize)(file.size)) {
          return this.setProperties({
            isValid: false,
            errorMessage: _i18nJs.default.t('presenter.file_upload.image_size_exceeded')
          });
        } else {
          return this.setProperties({
            uploadedImageFile: file,
            isValid: true
          });
        }
      },
      removeFile: function removeFile() {
        this.set('uploadedImageFile', null);
        this.set('fileDataUrl', '');
        return this.get('answer.answerAttachments').clear();
      }
    }
  });
});
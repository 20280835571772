define('presenter/mixins/autofitting', ['exports', 'presenter/mixins/text-sizing'], function (exports, _textSizing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_textSizing.default, {
    autofit: false,
    maxHeight: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('autofit')) {
        return this.registerSizing(this._autofit);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('autofit')) {
        this.set('_originalFontSize', this._getFontSize());
        this._calcMaxHeight();
        return this._autofit();
      }
    },
    onResizeEnd: function onResizeEnd() {
      this._calcMaxHeight();
      return this._super.apply(this, arguments);
    },
    _autofit: function _autofit() {
      var adjustedFontSize, fontSize, nextFontSize, size;
      if (!(this.get('autofit') && this.get('maxHeight'))) {
        return;
      }
      fontSize = adjustedFontSize = this._getFontSize();
      if ((size = this._height(fontSize)) > this.get('maxHeight')) {
        while (size > this.get('maxHeight')) {
          adjustedFontSize *= .9;
          size = this._height(adjustedFontSize);
        }
      } else {
        nextFontSize = fontSize / .9;
        while (nextFontSize <= this.get('_originalFontSize') && this._height(nextFontSize) <= this.get('maxHeight')) {
          adjustedFontSize = nextFontSize;
          nextFontSize /= .9;
        }
      }
      if (adjustedFontSize !== fontSize) {
        this.$().css({
          fontSize: this._fontSizeWithUnits(adjustedFontSize)
        });
        if (Modernizr.ie10) {
          return this.$().val(this.$().val());
        }
      }
    },
    _calcMaxHeight: function _calcMaxHeight() {
      var $container, padding;
      if (!(this.get('autofit') && ($container = this.$().closest('.js-autofit-container')))) {
        return;
      }
      padding = this.$().cssFloat('paddingTop') + this.$().cssFloat('paddingBottom');
      return this.set('maxHeight', $container.height() - padding);
    },
    _getFontSize: function _getFontSize() {
      if (Modernizr.cssremunit) {
        return this.$().cssRem('font-size');
      } else {
        return this.$().cssFloat('font-size');
      }
    },
    _fontSizeWithUnits: function _fontSizeWithUnits(fontSize) {
      if (Modernizr.cssremunit) {
        return fontSize + "rem";
      } else {
        return fontSize + "px";
      }
    },
    _height: function _height(fontSize) {
      return this._heightForText(this.get('value'), this._fontSizeWithUnits(fontSize));
    }
  });
});
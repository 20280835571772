define('presenter/components/etw/module-style-example', ['exports', 'ember-cli-tailwind/utils/classes-for-module-style'], function (exports, _classesForModuleStyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    etwTailwindStyleguide: Ember.inject.service(),

    // Passed in
    moduleStyle: null,

    classesForModuleStyle: Ember.computed('moduleStyle', function () {
      return (0, _classesForModuleStyle.default)(this.get('moduleStyle'));
    }),

    actions: {
      selectModuleStyle: function selectModuleStyle() {
        this.get('etwTailwindStyleguide').set('selectedModuleStyle', this.get('moduleStyle'));
      }
    }

  });
});
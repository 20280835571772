define('presenter/components/presenter/mobile-date-input-field', ['exports', 'presenter/components/presenter/date-input-field'], function (exports, _dateInputField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateInputField.default.extend({
    placeholder: 'Date',
    placeholderClass: 'mobile-placeholder',
    type: 'date',
    min: '0000-01-01',
    max: '3000-12-31',
    classNames: ['DateField-mobileDatepicker', 'mobile-placeholder'],
    didInsertElement: function didInsertElement() {
      this._super();
      if (!Ember.isEmpty(this.get('value'))) {
        return this._hidePlaceholder();
      }
    },
    focusIn: function focusIn() {
      this._super.apply(this, arguments);
      return this._hidePlaceholder();
    },
    focusOut: function focusOut() {
      var state;
      this._super.apply(this, arguments);
      state = Ember.isEmpty(this.get('value'));
      return this.$().toggleClass(this.get('placeholderClass'), state);
    },
    _hidePlaceholder: function _hidePlaceholder() {
      return this.$().removeClass(this.get('placeholderClass'));
    }
  });
});
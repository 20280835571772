define('presenter/app', ['exports', 'ember-load-initializers', 'presenter/resolver', 'presenter/config/environment', 'presenter/globals'], function (exports, _emberLoadInitializers, _resolver, _environment, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var App;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    rootElement: _globals.rootElement,
    customEvents: {
      dragstop: 'dragEnd'
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  Ember.run.backburner.DEBUG = true;

  exports.default = App;
});
define('presenter/models/insight', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Insight;

  Insight = _data.Model.extend({
    type: (0, _data.attr)('string'),
    survey: (0, _data.belongsTo)('survey', {
      serialize: false
    }),
    componentText: (0, _data.attr)('string'),
    segmentedComponentText: (0, _data.attr)('string'),
    kpiType: (0, _data.attr)('string'),
    kpiScore: (0, _data.attr)('number'),
    kpiAverage: (0, _data.attr)('number'),
    responseCount: (0, _data.attr)('number'),
    segmentedData: (0, _data.hasMany)('insight_segment', {
      serialize: false
    })
  });

  Insight.reopenClass({
    resourceName: 'insight'
  });

  exports.default = Insight;
});
define('presenter/components/drop-down', ['exports', 'presenter/utils', 'presenter/utils/layout'], function (exports, _utils, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AUTO_SCROLL_MARGIN, TRIGGER_SELECTOR, activeDropdowns, hideAllDropdowns;

  activeDropdowns = _utils.emberSet.create();

  hideAllDropdowns = function hideAllDropdowns() {
    return activeDropdowns.forEach(function (dropdown) {
      return dropdown.hide();
    });
  };

  Ember.$(document).on('click', function (event) {
    if (!event.isDefaultPrevented()) {
      return hideAllDropdowns();
    }
  });

  TRIGGER_SELECTOR = '[data-role="trigger"]';

  AUTO_SCROLL_MARGIN = 10;

  exports.default = Ember.Component.extend({
    autoScroll: true,
    classNames: ['Dropdown'],
    classNameBindings: ['active:is-open', 'childActive:is-active', 'viewVersion'],
    active: false,
    readOnly: false,
    childActive: false,
    setFixed: false,
    onDidInsertElement: null,
    onDidInsertElementContext: null,
    openOnHover: false,
    didInsertElement: function didInsertElement() {
      var context, handler;
      if (!this.get('openOnHover')) {
        activeDropdowns.add(this);
      }
      _layout.default.on('currentPathChanged', this, this._handlePathChanged);
      Ember.run.scheduleOnce('afterRender', function (_this) {
        return function () {
          return _this._handlePathChanged();
        };
      }(this));
      if (handler = this.get('onDidInsertElement')) {
        if (context = this.get('onDidInsertElementContext')) {
          return handler.call(context);
        } else {
          return handler();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      return activeDropdowns.remove(this);
    },
    click: function click(event) {
      var $target;
      if (this.get('openOnHover')) {
        return;
      }
      $target = Ember.$(event.target);
      if (this.get('readOnly')) {
        return event.preventDefault();
      } else if ($target.closest(TRIGGER_SELECTOR).length) {
        if (this.get('active')) {
          this.hide();
        } else {
          this.show();
        }
        return event.preventDefault();
      } else if ($target.parents('.js-menu')) {
        if ($target.closest('.disabled').length) {
          return event.preventDefault();
        } else {
          return this.hide();
        }
      }
    },
    mouseEnter: function mouseEnter() {
      if (this.get('openOnHover')) {
        return this.show();
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('openOnHover')) {
        return this.hide();
      }
    },
    show: function show() {
      if (this.get('active') || this.get('readOnly')) {
        return;
      }
      hideAllDropdowns();
      this.set('active', true);
      return Ember.run.schedule('afterRender', this, this._repositionMenu);
    },
    _repositionMenu: function _repositionMenu() {
      var $menu, $viewport, contentHeight, downwardHeight, height, leftWidth, offsetLeft, offsetTop, rightWidth, triggerHeight, upwardHeight, viewportHeight, viewportOffsetLeft, viewportOffsetTop, viewportWidth;
      $menu = this.$('.js-menu');
      if (!$menu) {
        return;
      }
      $menu.css({
        bottom: '',
        left: '',
        right: '',
        height: 'auto'
      });
      if (!this.get('setFixed') && this.$().closest('.js-viewport').length) {
        $viewport = this.$().closest('.js-viewport');
        viewportOffsetLeft = $viewport.offset().left;
        viewportOffsetTop = $viewport.offset().top;
      } else {
        $viewport = Ember.$(window);
        viewportOffsetTop = viewportOffsetLeft = 0;
      }
      viewportHeight = $viewport.outerHeight();
      viewportWidth = $viewport.outerWidth();
      offsetTop = this.$().offset().top;
      offsetLeft = this.$().offset().left;
      upwardHeight = offsetTop - viewportOffsetTop - AUTO_SCROLL_MARGIN;
      downwardHeight = viewportHeight + viewportOffsetTop - (offsetTop + this.$('[data-role=trigger]').outerHeight()) - AUTO_SCROLL_MARGIN;
      triggerHeight = this.$(TRIGGER_SELECTOR).outerHeight();
      contentHeight = $menu.prop('scrollHeight');
      rightWidth = viewportWidth + viewportOffsetLeft - offsetLeft;
      leftWidth = this.$().offset().left + this.$().outerWidth() - viewportOffsetLeft;
      if (this.get('setFixed')) {
        $menu.css({
          position: 'fixed'
        });
      }
      if (contentHeight < downwardHeight) {
        if (this.get('setFixed')) {
          $menu.css({
            bottom: '',
            top: offsetTop + this.$().outerHeight(),
            height: 'auto'
          });
        } else {
          $menu.css({
            top: '',
            bottom: '',
            height: 'auto'
          });
        }
      } else if (contentHeight < upwardHeight) {
        if (this.get('setFixed')) {
          $menu.css({
            top: '',
            bottom: viewportHeight - offsetTop
          });
        } else {
          $menu.css({
            top: '',
            bottom: triggerHeight
          });
        }
      } else if (downwardHeight < upwardHeight) {
        height = this.get('autoScroll') ? upwardHeight : 'auto';
        if (this.get('setFixed')) {
          $menu.css({
            top: '',
            bottom: viewportHeight - offsetTop,
            height: height
          });
        } else {
          $menu.css({
            top: '',
            bottom: triggerHeight,
            height: height
          });
        }
      } else {
        height = this.get('autoScroll') ? downwardHeight : 'auto';
        if (this.get('setFixed')) {
          $menu.css({
            bottom: '',
            top: offsetTop + this.$().outerHeight(),
            height: height
          });
        } else {
          $menu.css({
            top: '',
            bottom: '',
            height: height
          });
        }
      }
      if (this.get('setFixed')) {
        $menu.css({
          position: 'fixed',
          minWidth: this.$().outerWidth(),
          maxWidth: rightWidth - 10
        });
      }
      if ($menu.outerWidth() > rightWidth && leftWidth > rightWidth) {
        return $menu.css({
          left: '',
          right: this.get('setFixed') ? offsetLeft + this.$().outerWidth() : 0,
          minWidth: this.$().outerWidth(),
          maxWidth: leftWidth - 10
        });
      } else {
        return $menu.css({
          right: '',
          left: this.get('setFixed') ? offsetLeft : 0,
          minWidth: this.$().outerWidth(),
          maxWidth: rightWidth - 10
        });
      }
    },
    hide: function hide() {
      if (!this.get('active')) {
        return;
      }
      return this.set('active', false);
    },
    _handlePathChanged: function _handlePathChanged() {
      Ember.run.schedule('afterRender', this, function (_this) {
        return function () {
          if (_this.$()) {
            return _this.set('childActive', _this.$('.is-active').length > 0);
          }
        };
      }(this));
      return this.hide();
    }
  });
});
define('presenter/models/customer', ['exports', 'presenter/data', 'presenter/mixins/allows-merges'], function (exports, _data, _allowsMerges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Customer;

  Customer = _data.Model.extend(_allowsMerges.default, {
    stripeCardToken: (0, _data.attr)('string'),
    cancelFuturePlan: (0, _data.attr)('boolean'),
    cancelSubscription: (0, _data.attr)('boolean'),
    teamMemberQuantity: (0, _data.attr)('number'),
    cardDescription: (0, _data.attr)('string', {
      readOnly: true
    }),
    cardExpirationDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    hasInvalidCard: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    subscriptionTotal: (0, _data.attr)('number', {
      readOnly: true
    }),
    currentPeriodEnd: (0, _data.attr)('date', {
      readOnly: true
    }),
    currentPeriodCredit: (0, _data.attr)('number', {
      readOnly: true
    }),
    currentPeriodRemaining: (0, _data.attr)('number', {
      readOnly: true
    }),
    discountPercentage: (0, _data.attr)('number', {
      readOnly: true
    }),
    accountCredit: (0, _data.attr)('number', {
      readOnly: true
    }),
    plan: (0, _data.belongsTo)('plan', {
      serialize: 'id'
    }),
    promoCode: (0, _data.belongsTo)('promo_code', {
      serialize: 'id'
    }),
    futurePlan: (0, _data.belongsTo)('plan', {
      serialize: false
    }),
    nextBillingDate: function () {
      return moment(this.get('currentPeriodEnd')).format('M/D/YYYY');
    }.property('currentPeriodEnd'),
    deserialize: function deserialize() {
      this._super.apply(this, arguments);
      return this.setProperties({
        stripeCardToken: null,
        cancelFuturePlan: null,
        cancelSubscription: null,
        plan: null,
        promoCode: null
      });
    }
  });

  Customer.reopenClass({
    endpoint: 'customer',
    resourceName: 'customer'
  });

  exports.default = Customer;
});
define('presenter/models/template', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Template;

  Template = _data.Model.extend({
    name: (0, _data.attr)('string'),
    categoryName: (0, _data.attr)('string'),
    categoryPosition: (0, _data.attr)('integer'),
    position: (0, _data.attr)('integer'),
    description: (0, _data.attr)('string'),
    survey: (0, _data.belongsTo)('survey'),
    dashboardId: (0, _data.attr)('integer'),
    dashboardDescription: (0, _data.attr)('string')
  });

  Template.reopenClass({
    resourceName: 'template'
  });

  exports.default = Template;
});
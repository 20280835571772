define('presenter/models/pardot-field-mapping', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PardotFieldMapping;

  PardotFieldMapping = _data.Model.extend({
    fieldName: (0, _data.attr)('string'),
    fieldType: (0, _data.attr)('string'),
    mergeKey: (0, _data.attr)('string'),
    responseAttribute: (0, _data.attr)('string'),
    mapQuestionTitle: (0, _data.attr)('boolean'),
    mapComment: (0, _data.attr)('boolean'),
    mapCommentHeader: (0, _data.attr)('boolean'),
    mapCommentSentiment: (0, _data.attr)('boolean'),
    fieldRequired: (0, _data.attr)('boolean'),
    fixedValue: (0, _data.attr)('string'),
    incrementValue: (0, _data.attr)('integer'),
    conditional: (0, _data.attr)('boolean'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    choice: (0, _data.belongsTo)('choice', {
      serialize: 'id'
    }),
    pardotObjectMapping: (0, _data.belongsTo)('pardot_object_mapping', {
      serialize: false
    }),
    actionToken: (0, _data.attr)('string'),
    rule: null,
    pardotObject: Ember.computed.oneWay('pardotObjectMapping.pardotObject'),
    pardotField: function () {
      var ref;
      return (ref = this.get('pardotObject.fieldsByName').get(this.get('fieldName'))) != null ? ref[0] : void 0;
    }.property('pardotObject.fields.[]', 'fieldName'),
    _toPardotFieldTypes: function _toPardotFieldTypes(types) {
      var pardotMappableTypes;
      pardotMappableTypes = types.copy();
      return pardotMappableTypes.map(function (_this) {
        return function (type) {
          return _this.constructor.PARDOT_FIELD_EQUIVALENTS[type] || type;
        };
      }(this)).flatten().uniq();
    },
    mappableFieldTypes: function () {
      var attribute, choice, question;
      if (this.get('mapQuestionTitle')) {
        return ['string', 'text', 'textarea'];
      } else if (this.get('mapCommentSentiment')) {
        return ['float', 'number', 'string', 'text', 'textarea'];
      } else if (choice = this.get('choice')) {
        return this._toPardotFieldTypes(choice.get('mappableFieldTypes'));
      } else if (question = this.get('component')) {
        if (this.get('mapComment')) {
          return ['string', 'text', 'textarea'];
        } else if (this.get('component.hasCustomValues')) {
          return this.constructor.SUPPORTED_FIELD_TYPES;
        } else {
          return this._toPardotFieldTypes(question.get('mappableFieldTypes'));
        }
      } else if (attribute = this.get('responseAttribute')) {
        return this.constructor.RESPONSE_ATTRIBUTES.findBy('value', attribute).mappableFieldTypes;
      } else if (this.get('incrementValue')) {
        return ['int', 'float', 'number'];
      } else {
        return this.constructor.SUPPORTED_FIELD_TYPES;
      }
    }.property('component.hasCustomValues', 'choice', 'mergeKey', 'responseAttribute', 'mapQuestionTitle', 'mapComment', 'incrementValue'),
    isValid: Ember.computed.and('sourceIsValid', 'targetIsValid', 'isUniqueOrValidAnswer'),
    sourceIsValid: function () {
      return this.get('incrementValue') || this.get('fixedValue') || this.get('mergeKey') || this.get('responseAttribute') || this.get('component') && (!this.get('component.hasGridItems') || this.get('gridItem')) && (!this.get('component.isForm') || this.get('choice'));
    }.property('component.{hasGridItems,isForm}', 'gridItem', 'choice', 'mergeKey', 'responseAttribute', 'fixedValue', 'incrementValue'),
    targetIsValid: Ember.computed.and('fieldExists', 'fieldName', 'fieldHasRequiredCrud', 'fieldTypeIsMappable'),
    isUnique: function () {
      return !this.get('pardotObjectMapping.pardotFieldMappings').find(function (_this) {
        return function (mapping) {
          return !mapping.get('conditional') && _this !== mapping && _this.get('fieldName') === mapping.get('fieldName');
        };
      }(this));
    }.property('pardotObjectMapping.pardotFieldMappings.@each.fieldName', 'fieldName'),
    isAnswerType: function () {
      return !this.get('mapQuestionTitle') && !!this.get('component.id');
    }.property('mapQuestionTitle', 'component.id'),
    isAnswerAndHasCollisionWithNonAnswer: function () {
      return this.get('pardotObjectMapping.pardotFieldMappings').find(function (_this) {
        return function (mapping) {
          return !mapping.get('conditional') && !mapping.get('isAnswerType') && _this.get('fieldName') === mapping.get('fieldName');
        };
      }(this));
    }.property('isAnswerType', 'fieldName', 'pardotObjectMapping.pardotFieldMappings.@each.fieldName'),
    isUniqueOrValidAnswer: function () {
      if (!this.get('isAnswerType')) {
        return this.get('isUnique');
      } else {
        return !this.get('isAnswerAndHasCollisionWithNonAnswer');
      }
    }.property('isUnique', 'isAnswerType', 'isAnswerAndHasCollisionWithNonAnswer'),
    fieldExists: Ember.computed.notEmpty('pardotField'),
    fieldHasRequiredCrud: function () {
      return this.get('pardotObjectMapping.isCreate') && this.get('pardotField.createable') || this.get('pardotObjectMapping.isUpdate') && this.get('pardotField.updateable');
    }.property('pardotField.{createable,updateable}', 'pardotObjectMapping.{isCreate,isUpdate}'),
    fieldTypeIsMappable: function () {
      var fieldTypeNames;
      fieldTypeNames = this.get('mappableFieldTypes').map(function (_this) {
        return function (type) {
          return _this.constructor.FIELD_TYPE_NAMES[type];
        };
      }(this));
      return fieldTypeNames.contains(this.get('pardotField.type'));
    }.property('pardotField.type', 'mappableFieldTypes.[]'),
    conditions: Ember.computed('pardotObjectMapping.survey.rules.@each.conditions.[]', 'id', 'conditional', 'rule', function () {
      var mappingRule;
      if (this.get('conditional')) {
        if (this.get('rule.conditions')) {
          return this.get('rule.conditions');
        } else {
          mappingRule = this.get('pardotObjectMapping.survey.rules').find(function (_this) {
            return function (rule) {
              return rule.get('type') === 'exit' && rule.get('actions.firstObject.pardotFieldMapping.id') === _this.get('id');
            };
          }(this));
          if (mappingRule) {
            return mappingRule.get('conditions');
          } else {
            return [];
          }
        }
      }
    })
  });

  PardotFieldMapping.reopenClass({
    resourceName: 'pardot_field_mapping',
    RESPONSE_ATTRIBUTES: [{
      value: 'survey_name',
      label: 'Survey Name',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'created_at',
      label: 'Start Time',
      mappableFieldTypes: ['date', 'timestamp', 'string', 'Text', 'Textarea']
    }, {
      value: 'completed_at',
      label: 'Completion Time',
      mappableFieldTypes: ['date', 'timestamp', 'string', 'Text', 'Textarea']
    }, {
      value: 'viewer_url',
      label: 'Link to Response',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'summary_url',
      label: 'Link to Summary Report',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'id',
      label: 'API ID',
      mappableFieldTypes: ['int', 'float', 'string', 'Text', 'Textarea']
    }, {
      value: 'text_render',
      label: 'Full Text',
      mappableFieldTypes: ['Textarea']
    }, {
      value: 'language_label',
      label: 'Language',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'language_code',
      label: 'Language Code (ISO 639-1)',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'platform',
      label: 'Platform',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }, {
      value: 'browser',
      label: 'Browser',
      mappableFieldTypes: ['string', 'Text', 'Textarea']
    }],
    SUPPORTED_FIELD_TYPES: _globals.codeSettings.pardotFieldTypes,
    FIELD_TYPE_NAMES: {
      'string': 'string',
      'int': 'int',
      'float': 'float',
      'boolean': 'boolean',
      'timestamp': 'timestamp',
      'text': 'Text',
      'number': 'Number',
      'date': 'Date',
      'radio_button': 'Radio Button',
      'checkbox': 'Checkbox',
      'dropdown': 'Dropdown',
      'textarea': 'Textarea',
      'multi_select': 'Multi-Select',
      'hidden': 'Hidden',
      'crm_user': 'CRM User'
    },
    PARDOT_FIELD_EQUIVALENTS: {
      int: ['int', 'number'],
      string: ['string', 'text'],
      double: ['float', 'number'],
      picklist: ['dropdown'],
      multipicklist: ['multi_select'],
      email: ['string', 'text', 'textarea'],
      phone: ['string', 'text', 'textarea'],
      url: ['string', 'text', 'textarea'],
      datetime: ['timestamp'],
      date: ['Date'],
      reference: ['string', 'text', 'textarea'],
      percent: ['float'],
      currency: ['float', 'int'],
      encryptedstring: ['string', 'text', 'textarea'],
      combobox: ['string', 'text', 'textarea'],
      textarea: ['textarea']
    }
  });

  exports.default = PardotFieldMapping;
});
define('presenter/utils/fonts', ['exports', 'webfontloader', 'presenter/globals'], function (exports, _webfontloader, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Fonts = exports.GOOGLE_FONTS = exports.DEPRECATED_FONTS = exports.CURRENT_FONTS = exports.FONTS = exports.FONT_STACKS = undefined;
  var fonts,
      googleWebfontMapping,
      onDone,
      sansSerif,
      selfHostedMapping,
      serif,
      typekitFonts,
      hasProp = {}.hasOwnProperty;

  sansSerif = 'Arial, Helvetica, sans-serif';

  serif = "'Times New Roman', serif";

  var FONT_STACKS = exports.FONT_STACKS = {
    'Aaargh': "Aaargh, " + sansSerif,
    'Amaranth': "'Amaranth', " + sansSerif,
    'Cookie': "Cookie, cursive",
    'Fjord One': "'Fjord One', " + serif,
    'Gentium Basic': "'Gentium Basic', " + serif,
    'Josefin Slab': "'Josefin Slab', " + serif,
    'Jura': "Jura, " + sansSerif,
    'Lobster Two': "'Lobster Two', cursive",
    'Lobster': "Lobster, cursive",
    'Museo Slab': "'Museo Slab', " + serif,
    'Oleo Script': "'Oleo Script', cursive",
    'PT Sans': "'PT Sans', " + sansSerif,
    'Pacifico': "Pacifico, cursive",
    'Roboto': "Roboto, " + sansSerif,
    'Ubuntu': "Ubuntu, " + sansSerif,
    'Vollkorn': "Vollkorn, " + serif,
    'Museo Sans': "'Museo Sans', " + sansSerif,
    'Open Sans': "'Open Sans', " + sansSerif,
    'Helvetica': "'Helvetica Neue', Helvetica, " + sansSerif,
    'Verdana': "Verdana, " + sansSerif,
    'Lucida Grande': "'Lucida Sans Unicode', 'Lucida Grande', Verdana, " + sansSerif,
    'VAG Rounded': "'vag-rundschrift-d-n3', 'vag-rundschrift-d', " + sansSerif,
    'Proxima Nova': "'proxima-nova', " + sansSerif,
    'Museo': "museo, " + serif,
    'Effra': "effra, " + sansSerif,
    'Myriad Pro': "'myriad-pro', " + sansSerif,
    'Prenton': "prenton, " + sansSerif,
    'Adelle': "adelle, " + serif,
    'Adelle Sans': "'adelle-sans', " + sansSerif,
    'Source Sans Pro': "'Source Sans Pro', " + sansSerif,
    'Bree': "bree, " + sansSerif,
    'Architects Daughter': "'Architects Daughter', " + sansSerif,
    'Lato': "'Lato', " + sansSerif,
    'Calibri': "Calibri, " + sansSerif,
    'Accord Light': "Accord Light, " + sansSerif,
    'Accord': "Accord, " + sansSerif,
    'ITC Avant Garde Gothic Std Book': "'ITC Avant Garde Gothic Std Book', " + sansSerif,
    'Arial Black': "ArialBlack, " + sansSerif,
    'Arial': "Arial, " + sansSerif,
    'Audi Type Extended': "AudiTypeExtendedNormal, " + sansSerif,
    'Audi Type': "AudiTypeNormal, " + sansSerif,
    'Brandon Text': "Brandon Text, " + sansSerif,
    'EK03 Plain Light': "'EK03 Plain Light', " + sansSerif,
    'EK03 Plain Medium': "'EK03 Plain Medium', " + sansSerif,
    'Emirates Bold': "'Emirates Bold', " + sansSerif,
    'FS Joey': "FSJoey, " + sansSerif,
    'FS Joey Bold': "'FSJoey Bold', " + sansSerif,
    'FS Joey Medium': "'FSJoey Medium', " + sansSerif,
    'Futura Std Book': "FuturaStdBook, " + sansSerif,
    'Futura Std Light': "FuturaStdLight, " + sansSerif,
    'Helvetica Neue 02': "'Helvetica Neue 02', " + sansSerif,
    'Helvetica Neue Condensed': "'Helvetica Neue Condensed', " + sansSerif,
    'Helvetica Neue Light': "'Helvetica Neue Light', " + sansSerif,
    'Helvetica Neue Medium': "'Helvetica Neue Medium', " + sansSerif,
    'Helvetica Neue Thin': "'Helvetica Neue Thin', " + sansSerif,
    'Helvetica Neue UltraLight': "'Helvetica Neue UltraLight', " + sansSerif,
    'Salesforce Sans': "'Salesforce Sans', " + sansSerif,
    'TMix': "TMix, " + sansSerif,
    'Trebuchet MS': "'Trebuchet MS', " + sansSerif,
    'Whitney Book': "'Whitney Book', " + sansSerif,
    'Harmonia Sans Std Bold': "'Harmonia Sans Std Bold', " + sansSerif,
    'Swedish Gothic Regular': "'Swedish Gothic Regular', " + sansSerif,
    'Larsseit': "'Larsseit', " + sansSerif,
    'Oscine': "'Oscine', " + sansSerif,
    'DNV Display Regular': "'DNV Display Regular', " + sansSerif,
    'Klavika Bold Italic': "'Klavika Bold Italic', " + sansSerif,
    'Klavika Bold': "'Klavika Bold', " + sansSerif,
    'Klavika Light Italic': "'Klavika Light Italic', " + sansSerif,
    'Klavika Light': "'Klavika Light', " + sansSerif,
    'Klavika Medium Italic': "'Klavika Medium Italic', " + sansSerif,
    'Klavika Medium': "'Klavika Medium', " + sansSerif,
    'Klavika Regular Italic': "'Klavika Regular Italic', " + sansSerif,
    'Shell Bold': "'Shell Bold', " + sansSerif,
    'Shell Book Italic': "'Shell Book Italic', " + sansSerif,
    'Shell Book': "'Shell Book', " + sansSerif,
    'Shell Condensed Bold': "'Shell Condensed Bold', " + sansSerif,
    'Shell Condensed Light': "'Shell Condensed Light', " + sansSerif,
    'Shell Condensed Medium': "'Shell Condensed Medium', " + sansSerif,
    'Shell Heavy': "'Shell Heavy', " + sansSerif,
    'Shell Light Italic': "'Shell Light Italic', " + sansSerif,
    'Shell Light': "'Shell Light', " + sansSerif,
    'Shell Medium': "'Shell Medium', " + sansSerif,
    'Shell Medium Italic': "'Shell Medium Italic', " + sansSerif,
    'Aktiv Grotesk Bold': "'Aktiv Grotesk Bold', " + sansSerif,
    'Aktiv Grotesk Medium': "'Aktiv Grotesk Medium', " + sansSerif,
    'Aktiv Grotesk Regular': "'Aktiv Grotesk Regular', " + sansSerif,
    'Aktiv Grotesk Thin': "'Aktiv Grotesk Thin', " + sansSerif,
    'Aktiv Grotesk xBold': "'Aktiv Grotesk xBold', " + sansSerif,
    'DIN® Next Cyr Regular': "'DIN® Next Cyr Regular', " + sansSerif,
    'Manrope Regular': "'Manrope Regular', " + sansSerif,
    'Tele2 Sans Light': "'Tele2 Sans Light', " + sansSerif,
    'Tele2 Sans Regular': "'Tele2 Sans Regular', " + sansSerif,
    'Roche Sans Bold': "'Roche Sans Bold', " + sansSerif,
    'Roche Sans Bold Italic': "'Roche Sans Bold Italic', " + sansSerif,
    'Roche Sans Italic': "'Roche Sans Italic', " + sansSerif,
    'Roche Sans Medium Bold': "'Roche Sans Medium Bold', " + sansSerif,
    'Roche Sans Regular': "'Roche Sans Regular', " + sansSerif,
    'Roche Sans Condensed Italic': "'Roche Sans Condensed Italic', " + sansSerif,
    'Roche Sans Condensed Light': "'Roche Sans Condensed Light', " + sansSerif,
    'Roche Sans Condensed Light Italic': "'Roche Sans Condensed Light Italic', " + sansSerif,
    'Roche Sans Condensed Regular': "'Roche Sans Condensed Regular', " + sansSerif,
    'Roche Sans Light': "'Roche Sans Light', " + sansSerif,
    'Roche Sans Light Italic': "'Roche Sans Light Italic', " + sansSerif,
    'Roche Sans Light Medium': "'Roche Sans Light Medium', " + sansSerif,
    'Roche Sans Light Medium Italic': "'Roche Sans Light Medium Italic', " + sansSerif,
    'Roche Sans Medium Bold Italic': "'Roche Sans Medium Bold Italic', " + sansSerif,
    'Roche Sans Medium': "'Roche Sans Medium', " + sansSerif,
    'Roche Sans Medium Italic': "'Roche Sans Medium Italic', " + sansSerif,
    'Roche Serif Bold': "'Roche Serif Bold', " + serif,
    'Roche Serif Bold Italic': "'Roche Serif Bold Italic', " + serif,
    'Roche Serif Italic': "'Roche Serif Italic', " + serif,
    'Roche Serif Regular': "'Roche Serif Regular', " + serif,
    'Roche Serif Light Bold': "'Roche Serif Light Bold', " + serif,
    'Roche Serif Light Bold Italic': "'Roche Serif Light Bold Italic', " + serif,
    'Roche Serif Light': "'Roche Serif Light', " + serif,
    'Roche Serif Light Italic': "'Roche Serif Light Italic', " + serif,
    'Gotham Light': "'Gotham Light', " + sansSerif,
    'Pepper Type Mantonico Italic': "'Pepper Type Mantonico Italic', " + sansSerif,
    'Pepper Type Mantonico Regular': "'Pepper Type Mantonico Regular', " + sansSerif
  };

  var FONTS = exports.FONTS = _globals.codeSettings.fonts;

  var CURRENT_FONTS = exports.CURRENT_FONTS = _globals.codeSettings.currentFonts;

  var DEPRECATED_FONTS = exports.DEPRECATED_FONTS = _globals.codeSettings.deprecatedFonts;

  var GOOGLE_FONTS = exports.GOOGLE_FONTS = _globals.codeSettings.googleFonts;

  googleWebfontMapping = {
    'Amaranth': 'Amaranth:400,700:latin',
    'Architects Daughter': 'Architects+Daughter::latin',
    'Cookie': 'Cookie::latin',
    'Droid Sans': 'Droid+Sans:400,700:latin',
    'Fjord One': 'Fjord+One::latin',
    'Gentium Basic': 'Gentium+Basic:400,700:latin',
    'Josefin Slab': 'Josefin+Slab:400,700:latin',
    'Jura': 'Jura:400,600:latin',
    'Lato': 'Lato:400,700:latin',
    'Lobster Two': 'Lobster+Two:400,700:latin',
    'Lobster': 'Lobster::latin',
    'Oleo Script': 'Oleo+Script:400,700:latin',
    'Open Sans': 'Open+Sans:300,400,600,700:latin',
    'PT Sans': 'PT+Sans:400,700:latin',
    'Pacifico': 'Pacifico::latin',
    'Roboto': 'Roboto:400,700:latin',
    'Ubuntu': 'Ubuntu:400,700:latin',
    'Vollkorn': 'Vollkorn:400,700:latin',
    'Source Sans Pro': 'Source+Sans+Pro:400,700:latin'
  };

  typekitFonts = {
    'Proxima Nova': true,
    'Museo': true,
    'Effra': true,
    'Myriad Pro': true,
    'Prenton': true,
    'Adelle': true,
    'Adelle Sans': true,
    'Bree': true,
    'VAG Rounded': true
  };

  selfHostedMapping = {
    'Aaargh': _globals.codeSettings.selfHostedFonts.aaargh,
    'Arial Black': _globals.codeSettings.selfHostedFonts.arialBlack,
    'Audi Type Extended': _globals.codeSettings.selfHostedFonts.audiTypeExtended,
    'Audi Type': _globals.codeSettings.selfHostedFonts.audiType,
    'Brandon Text': _globals.codeSettings.selfHostedFonts.ceaStudyAbroad,
    'Accord Light': _globals.codeSettings.selfHostedFonts.appspace,
    'Accord': _globals.codeSettings.selfHostedFonts.appspace,
    'ITC Avant Garde Gothic Std Book': _globals.codeSettings.selfHostedFonts.gaf,
    'EK03 Plain Light': _globals.codeSettings.selfHostedFonts.dnata,
    'EK03 Plain Medium': _globals.codeSettings.selfHostedFonts.dnata,
    'Emirates Bold': _globals.codeSettings.selfHostedFonts.dnata,
    'FS Joey': _globals.codeSettings.selfHostedFonts.dnata,
    'FS Joey Bold': _globals.codeSettings.selfHostedFonts.dnata,
    'FS Joey Medium': _globals.codeSettings.selfHostedFonts.dnata,
    'Futura Std Book': _globals.codeSettings.selfHostedFonts.calvinKlein,
    'Futura Std Light': _globals.codeSettings.selfHostedFonts.calvinKlein,
    'Helvetica Neue 02': _globals.codeSettings.selfHostedFonts.dnata,
    'Helvetica Neue Condensed': _globals.codeSettings.selfHostedFonts.dnata,
    'Helvetica Neue Light': _globals.codeSettings.selfHostedFonts.dnata,
    'Helvetica Neue Medium': _globals.codeSettings.selfHostedFonts.dnata,
    'Helvetica Neue Thin': _globals.codeSettings.selfHostedFonts.dnata,
    'Helvetica Neue UltraLight': _globals.codeSettings.selfHostedFonts.dnata,
    'Museo Sans': _globals.codeSettings.selfHostedFonts.museoSans,
    'Museo Slab': _globals.codeSettings.selfHostedFonts.museoSlab,
    'Salesforce Sans': _globals.codeSettings.selfHostedFonts.salesforceSans,
    'TMix': _globals.codeSettings.selfHostedFonts.tmix,
    'Whitney Book': _globals.codeSettings.selfHostedFonts.quip,
    'Harmonia Sans Std Bold': _globals.codeSettings.selfHostedFonts.quip,
    'Swedish Gothic Regular': _globals.codeSettings.selfHostedFonts.olinkProteomics,
    'Larsseit': _globals.codeSettings.selfHostedFonts.kijijiCanada,
    'Oscine': _globals.codeSettings.selfHostedFonts.dazn,
    'DNV Display Regular': _globals.codeSettings.selfHostedFonts.dnvDisplay,
    'Klavika Medium': _globals.codeSettings.selfHostedFonts.sika,
    'Shell Bold': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Book': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Book Italic': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Condensed Bold': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Condensed Light': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Condensed Medium': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Heavy': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Light': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Light Italic': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Medium': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'Shell Medium Italic': _globals.codeSettings.selfHostedFonts.shellEnergy,
    'DIN® Next Cyr Regular': _globals.codeSettings.selfHostedFonts.birkenstock,
    'Manrope Regular': _globals.codeSettings.selfHostedFonts.oresys,
    'Tele2 Sans Light': _globals.codeSettings.selfHostedFonts.tele2,
    'Tele2 Sans Regular': _globals.codeSettings.selfHostedFonts.tele2,
    'Roche Sans Bold': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Bold Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Medium Bold': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Regular': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Condensed Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Condensed Light': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Condensed Light Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Condensed Regular': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Light': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Light Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Light Medium': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Light Medium Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Medium Bold Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Medium': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Sans Medium Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Bold': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Bold Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Regular': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Light Bold': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Light Bold Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Light': _globals.codeSettings.selfHostedFonts.roche,
    'Roche Serif Light Italic': _globals.codeSettings.selfHostedFonts.roche,
    'Gotham Light': _globals.codeSettings.selfHostedFonts.miroglio_group,
    'Pepper Type Mantonico Italic': _globals.codeSettings.selfHostedFonts.miroglio_group,
    'Pepper Type Mantonico Regular': _globals.codeSettings.selfHostedFonts.miroglio_group,
    'Aktiv Grotesk Bold': _globals.codeSettings.selfHostedFonts.iata
  };

  var Fonts = exports.Fonts = fonts = Ember.Object.extend(Ember.Evented).create();

  onDone = function onDone() {
    fonts.set('finished', true);
    fonts.trigger('loaded');
    return fonts.propertyDidChange('changed');
  };

  Fonts.loadFonts = function (fontList) {
    var _googleWebfontMapping, all, batchConfig, batchSize, config, google, index, k, needsLoad, size, v;
    all = fontList == null;
    if (!(all || Ember.isArray(fontList))) {
      throw 'Invalid args for loadFonts.';
    }
    needsLoad = false;
    config = {
      active: onDone,
      inactive: onDone,
      custom: {
        families: ['custom-icons']
      }
    };
    google = {
      api: _globals.codeSettings.googleFontsApiUrl,
      families: []
    };
    if (all || fontList.indexOf('FontAwesome') !== -1) {
      needsLoad = true;
      config.custom.families.push('FontAwesome');
      config.custom.testStrings = {
        'FontAwesome': '\uF00C\uF000'
      };
    }
    if (all) {
      google.families = function () {
        var results;
        results = [];
        for (k in googleWebfontMapping) {
          if (!hasProp.call(googleWebfontMapping, k)) continue;
          v = googleWebfontMapping[k];
          results.push(v);
        }
        return results;
      }();
      config.typekit = {
        id: window._typekit_id
      };
      config.custom.urls = function () {
        var results;
        results = [];
        for (k in selfHostedMapping) {
          if (!hasProp.call(selfHostedMapping, k)) continue;
          v = selfHostedMapping[k];
          results.push(v);
        }
        return results;
      }();
      config.custom.families = config.custom.families.concat(Object.keys(selfHostedMapping));
    } else {
      _googleWebfontMapping = googleWebfontMapping;
      _globals.codeSettings.googleFonts.forEach(function (google_font) {
        return _googleWebfontMapping[google_font.family] = google_font.family + ":300,400,600,700:latin";
      });
      fontList.forEach(function (font) {
        var googleFamily, selfUrl, typekitFamily;
        googleFamily = _googleWebfontMapping[font];
        typekitFamily = typekitFonts[font];
        selfUrl = selfHostedMapping[font];
        if (typekitFamily || selfUrl) {
          needsLoad = true;
        }
        if (googleFamily) {
          google.families.push(googleFamily);
        }
        if (typekitFamily) {
          config.typekit = {
            id: window._typekit_id
          };
        }
        if (selfUrl) {
          config.custom.urls = [selfUrl];
          return config.custom.families.push(font);
        }
      });
    }
    if (google.families.length > 0) {
      batchSize = 100;
      index = 0;
      while (index < google.families.length) {
        size = Math.min(google.families.length - index, batchSize);
        batchConfig = {
          active: onDone,
          inactive: onDone,
          google: {
            api: google.api,
            families: google.families.slice(index, +(index + size) + 1 || 9e9)
          }
        };
        _webfontloader.default.load(batchConfig);
        index += size;
      }
    }
    if (needsLoad) {
      return _webfontloader.default.load(config);
    } else {
      return onDone();
    }
  };
});
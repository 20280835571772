define('presenter/models/promo-code', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PromoCode;

  PromoCode = _data.Model.extend({
    stripeCouponCode: (0, _data.attr)('string', {
      readOnly: true
    }),
    percentOff: (0, _data.attr)('number', {
      readOnly: true
    }),
    duration: (0, _data.attr)('string', {
      readOnly: true
    }),
    durationInMonths: (0, _data.attr)('number', {
      readOnly: true
    }),
    valid: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    redeemBy: (0, _data.attr)('date', {
      readOnly: true
    }),
    planIds: (0, _data.attr)('raw', {
      readOnly: true
    })
  });

  PromoCode.reopenClass({
    resourceName: 'promo_code'
  });

  exports.default = PromoCode;
});
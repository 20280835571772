define("presenter/templates/svg/recipes/success", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/recipes/success.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "width", "24");
        dom.setAttribute(el1, "height", "24");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        var el2 = dom.createElement("g");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M22,0H2A1,1,0,0,0,1,1V6a4,4,0,0,0,4,4h.08A7,7,0,0,0,11,15.92V19h2V15.92A7,7,0,0,0,18.92,10H19a4,4,0,0,0,4-4V1A1,1,0,0,0,22,0ZM3,6V2H5V8A2,2,0,0,1,3,6ZM21,6a2,2,0,0,1-2,2V2h2Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "d", "M14,20H10c-5,0-5,4-5,4H19S19,20,14,20Z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/salesforce-matching-rule-criteria', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceMatchingRuleCriteria;

  SalesforceMatchingRuleCriteria = _data.Model.extend({
    mergeKey: (0, _data.attr)('string'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    choice: (0, _data.belongsTo)('choice', {
      serialize: 'id'
    }),
    salesforceMatchingRule: (0, _data.belongsTo)('salesforce_matching_rule', {
      serialize: false
    }),
    fieldName: (0, _data.attr)('string'),
    isValid: function () {
      return !Ember.isEmpty('fieldName') && (this.get('isMergeFieldUpsert') || this.get('isFormFieldUpsert'));
    }.property('fieldName', 'isMergeFieldUpsert', 'isFormFieldUpsert', 'upsertMatchingType'),
    isMergeFieldUpsert: function () {
      return !Ember.isEmpty(this.get('mergeKey')) && Ember.isEmpty(this.get('component')) && Ember.isEmpty(this.get('choice'));
    }.property('mergeKey', 'component', 'choice'),
    isFormFieldUpsert: function () {
      return Ember.isEmpty(this.get('mergeKey')) && !Ember.isEmpty(this.get('component')) && !Ember.isEmpty(this.get('choice'));
    }.property('mergeKey', 'component', 'choice')
  });

  SalesforceMatchingRuleCriteria.reopenClass({
    resourceName: 'salesforce_matching_rule_criteria'
  });

  exports.default = SalesforceMatchingRuleCriteria;
});
define('presenter/models/custom-url', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CustomUrl;

  CustomUrl = _data.Model.extend({
    path: (0, _data.attr)('string'),
    campaign: (0, _data.belongsTo)('campaign', {
      serialize: 'id'
    })
  });

  CustomUrl.reopenClass({
    resourceName: 'custom_url'
  });

  exports.default = CustomUrl;
});
define('presenter/controllers/presenter/response', ['exports', 'presenter/models/response', 'presenter/models/answer', 'presenter/globals'], function (exports, _response, _answer, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var escapeUTF16, unescapeUTF16;

  exports.default = Ember.ObjectController.extend({
    components: Ember.inject.controller('presenter/components'),
    survey: Ember.inject.controller('presenter/survey'),
    customSection: 'Respondent',
    saveError: false,
    submitError: false,
    submitting: false,
    duplicateResponseError: false,
    isCompleted: Ember.computed.equal('status', 'completed'),
    init: function init() {
      return this.get('container').lookup('eventBus:survey').on('updateMergeMap', function (_this) {
        return function (data) {
          var e;
          try {
            data = JSON.parse(data);
            return _this.get('model').set('mergeMap', data);
          } catch (error1) {
            e = error1;
            return Ember.onerror(e);
          }
        };
      }(this));
    },
    creationPromise: function () {
      var promise, response;
      response = this.get('content');
      if (!response.get('isNew') || _globals.isPreview) {
        return Ember.RSVP.resolve();
      } else {
        response.set('_allowMerges', true);
        (promise = response.saveRecord()).then(function () {
          response.set('_allowMerges', false);
          return response;
        });
        return promise;
      }
    }.property(),
    model: function () {
      var answers, response, stubResponse;
      response = window._response_raw ? _response.default.load(window._response_raw) : _globals.isPreview ? (stubResponse = _response.default.create({
        mergeMap: window._embed_params,
        language: window._locale
      }), window._embedded_answers && (answers = window._embedded_answers.answers) ? answers.forEach(function (answer) {
        return stubResponse.get('answers').pushObject(_answer.default.load(answer));
      }) : void 0, stubResponse) : _response.default.create({
        status: 'opened',
        campaignToken: window._campaign_token,
        mergeMap: window._embed_params,
        language: window._locale
      });
      response.get('answers').forEach(function (_this) {
        return function (answer) {
          return answer.set('response', response);
        };
      }(this));
      return response;
    }.property(),
    embeddedAnswers: function () {
      return this.get('model.answers').filter(function (answer) {
        return answer.get('embedded') && !answer.get('isNew');
      });
    }.property('model.answers.[]'),
    hasEmbeddedAnswer: Ember.computed.notEmpty('embeddedAnswers'),
    start: function start() {
      if (_globals.isPreview || this.get('status') !== 'opened') {
        return Ember.RSVP.resolve();
      }
      this.set('status', 'started');
      return this.get('creationPromise').then(function (_this) {
        return function () {
          var oldToken;
          oldToken = _this.get('model.token');
          _this.set('model._allowMerges', true);
          return _this.get('model').saveRecord().then(function () {
            _this.set('model._allowMerges', false);
            if (_this.get('model.token') !== oldToken && _this.get('hasEmbeddedAnswer')) {
              return _this.get('model.answers').forEach(function (answer) {
                if (answer.get('embedded') && !answer.get('isNew')) {
                  answer.set('isDirty', true);
                  return answer.saveRecord();
                }
              });
            }
          });
        };
      }(this));
    },
    submit: function submit() {
      var components, iframeLoadedPromise, label, promise, promises;
      components = this.get('components');
      if (_globals.isPreview) {
        this.set('submitting', true);
        this.set('status', 'completed');
        promise = components.get('lastObject.iframeLoadedPromise') || Ember.RSVP.resolve();
        this.set('submitting', false);
        return promise;
      } else {
        this.set('submitting', true);
        promises = components.map(function (component) {
          return component.saveAnswer();
        }).flatten().compact();
        if (iframeLoadedPromise = components.get('lastObject.iframeLoadedPromise')) {
          promises.pushObject(iframeLoadedPromise);
        }
        label = 'Answer saves during response submission and possible loading of thank you page iframe';
        return Ember.RSVP.allSettled(promises, label).then(function (array) {
          var error, errors;
          errors = [];
          array.forEach(function (promise, index) {
            if (promise.state === 'rejected') {
              if (/500|501|505/.test(promise.reason.status)) {
                return Raven.captureMessage('Answer save error', {
                  extra: {
                    answer: promises[index].answer.serialize(),
                    error: promise.reason
                  }
                });
              } else {
                return errors.push(promise.reason);
              }
            }
          });
          if (error = errors[0]) {
            throw error;
          }
        }).then(function (_this) {
          return function () {
            _this.set('status', 'completed');
            _this.set('submitError', false);
            return _this.get('content').saveRecord().then(function () {
              _this.set('submitting', false);
              return _this.get('container').lookup('eventBus:survey').trigger('submittedResponse', _this.get('campaignToken'));
            });
          };
        }(this))["catch"](function (_this) {
          return function (error) {
            _this.set('submitting', false);
            if (/500|501|505/.test(error.status)) {
              return;
            }
            if (error != null ? error.duplicate_response : void 0) {
              return window.location.reload();
            } else {
              _this.set('submitError', true);
              throw new Error('Final survey submission failed');
            }
          };
        }(this));
      }
    },
    base64MergeMap: Ember.computed({
      get: function get(key) {},
      set: function set(key, value) {
        try {
          this.set('escapedMergeMap', JSON.parse(atob(decodeURIComponent(value))));
          return value;
        } catch (error1) {}
      }
    }),
    escapedMergeMap: Ember.computed({
      get: function get(key) {},
      set: function set(key, value) {
        this.set('content.mergeMap', (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' ? Object.keys(value).reduce(function (unescaped, key) {
          unescaped[unescapeUTF16(key)] = unescapeUTF16(value[key]);
          return unescaped;
        }, {}) : value);
        return value;
      }
    }),
    actions: {
      registerTriggeredAction: function registerTriggeredAction(action) {
        return this.get('model.triggeredActions').pushObject(action);
      }
    }
  });


  unescapeUTF16 = function unescapeUTF16(str) {
    return decodeURIComponent(escape(str));
  };

  escapeUTF16 = function escapeUTF16(str) {
    return unescape(encodeURIComponent(str));
  };
});
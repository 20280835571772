define('presenter/controllers/presenter/survey', ['exports', 'presenter/controllers/modal', 'presenter/mixins/theme-properties', 'presenter/mixins/presenter/rules-evaluator', 'presenter/globals'], function (exports, _modal, _themeProperties, _rulesEvaluator, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend(_themeProperties.default, _rulesEvaluator.default, _modal.default, {
    components: Ember.inject.controller('presenter/components'),
    response: Ember.inject.controller('presenter/response'),
    campaignToken: window._campaign_token,
    mergeFieldValidationErrors: [],
    isPending: false,
    showOverlay: function () {
      return this.get('components.submitting') || this.get('isPending');
    }.property('components.submitting', 'isPending'),
    exitController: function () {
      return this.get('components').findBy('isExit');
    }.property('components.@each.isExit'),
    saveError: Ember.computed.alias('response.saveError'),
    submitError: Ember.computed.alias('response.submitError'),
    duplicateResponseError: Ember.computed.alias('response.duplicateResponseError'),
    showRequiredAlert: Ember.computed.oneWay('components.showRequiredAlert'),
    useDefaultBackground: Ember.computed.and('components.currentComponent.isDefault', 'components.currentComponent.isBranded'),
    backgroundClass: function () {
      if (this.get('useDefaultBackground')) {
        return 'DefaultPage-background';
      } else {
        return 'theme-background-scaled';
      }
    }.property('useDefaultBackground'),
    computedBackgroundAdjustmentStyle: function () {
      if (!this.get('useDefaultBackground')) {
        return this.get('backgroundAdjustmentStyle');
      }
    }.property('backgroundAdjustmentStyle', 'useDefaultBackground'),
    showPreviewAlert: _globals.isPreview && !_globals.isEmbedded && !(0, _globals.presenterFlag)('isHiddenPreviewBar'),
    actions: {
      assetsLoaded: function assetsLoaded() {
        var token;
        if (_globals.isEmbedded && (token = this.get('campaignToken'))) {
          return this.get('container').lookup('eventBus:survey').trigger('loaded', token);
        }
      },
      evaluateRules: function evaluateRules() {
        var actions;
        actions = this._evaluateRules();
        return actions.forEach(function (actionController) {
          return actionController.evaluate();
        });
      }
    }
  });
});
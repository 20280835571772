define('presenter/components/you-tube-player', ['exports', 'presenter/components/video-player', 'presenter/utils'], function (exports, _videoPlayer, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var YouTubePlayer;

  YouTubePlayer = _videoPlayer.default.extend({
    init: function init() {
      this._super();
      return this._loadApi();
    },
    didInsertElement: function didInsertElement() {
      this._initPlayer();
      return this._cueVideo();
    },
    stopPlayback: function stopPlayback() {
      return this._loadPlayer.then(function (_this) {
        return function () {
          return _this._player.stopVideo();
        };
      }(this));
    },
    _cueVideo: function () {
      var id;
      if (!this.get('media.videoUrl')) {
        return;
      }
      id = (0, _utils.getYouTubeId)(this.get('media.videoUrl'));
      return this._loadPlayer.then(function (_this) {
        return function () {
          return _this._player.cueVideoById({
            videoId: id
          });
        };
      }(this));
    }.observes('media.videoId'),
    _reloadVideo: function () {
      if (Modernizr.ios && this.get('playbackSwitch')) {
        return this._cueVideo();
      }
    }.observes('playbackSwitch'),
    _loadApi: function _loadApi() {
      if (!YouTubePlayer.apiLoadPromise) {
        return YouTubePlayer.apiLoadPromise = new Ember.RSVP.Promise(function (resolve) {
          var firstScriptTag, tag;
          window.onYouTubeIframeAPIReady = resolve;
          tag = document.createElement('script');
          tag.src = "https://www.youtube.com/iframe_api";
          firstScriptTag = document.getElementsByTagName('script')[0];
          return firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });
      }
    },
    _initPlayer: function _initPlayer(callback) {
      return this._loadPlayer || (this._loadPlayer = new Ember.RSVP.Promise(function (_this) {
        return function (resolve) {
          return YouTubePlayer.apiLoadPromise.then(function () {
            var player;
            return player = new YT.Player(_this.$().attr('id'), {
              playerVars: {
                rel: 0,
                showinfo: 0
              },
              events: {
                onReady: function onReady() {
                  _this._player = player;
                  return resolve();
                }
              }
            });
          });
        };
      }(this)));
    }
  });

  YouTubePlayer.apiLoadPromise = null;

  exports.default = YouTubePlayer;
});
define("presenter/templates/svg/phone-container", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/phone-container.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "width", "752");
        dom.setAttribute(el1, "height", "1398");
        var el2 = dom.createElement("defs");
        var el3 = dom.createElement("filter");
        dom.setAttribute(el3, "id", "a");
        dom.setAttribute(el3, "width", "129.4%");
        dom.setAttribute(el3, "height", "114.8%");
        dom.setAttribute(el3, "x", "-14.7%");
        dom.setAttribute(el3, "y", "-7.4%");
        dom.setAttribute(el3, "filterUnits", "objectBoundingBox");
        var el4 = dom.createElement("feOffset");
        dom.setAttribute(el4, "in", "SourceAlpha");
        dom.setAttribute(el4, "result", "shadowOffsetOuter1");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feGaussianBlur");
        dom.setAttribute(el4, "in", "shadowOffsetOuter1");
        dom.setAttribute(el4, "result", "shadowBlurOuter1");
        dom.setAttribute(el4, "stdDeviation", "25");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feColorMatrix");
        dom.setAttribute(el4, "in", "shadowBlurOuter1");
        dom.setAttribute(el4, "result", "shadowMatrixOuter1");
        dom.setAttribute(el4, "values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feMerge");
        var el5 = dom.createElement("feMergeNode");
        dom.setAttribute(el5, "in", "shadowMatrixOuter1");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("feMergeNode");
        dom.setAttribute(el5, "in", "SourceGraphic");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "b");
        dom.setAttribute(el3, "d", "M0 0h652v1298H0z");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "d");
        dom.setAttribute(el3, "d", "M6 99.999C6 44.77 50.78 0 106 0h440c55.228 0 100 44.774 100 99.999V1198c0 55.228-44.78 99.999-100 99.999H106c-55.228 0-100-44.774-100-99.999V100z");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("filter");
        dom.setAttribute(el3, "id", "e");
        dom.setAttribute(el3, "width", "102.2%");
        dom.setAttribute(el3, "height", "101.1%");
        dom.setAttribute(el3, "x", "-1.1%");
        dom.setAttribute(el3, "y", "-.5%");
        dom.setAttribute(el3, "filterUnits", "objectBoundingBox");
        var el4 = dom.createElement("feGaussianBlur");
        dom.setAttribute(el4, "in", "SourceAlpha");
        dom.setAttribute(el4, "result", "shadowBlurInner1");
        dom.setAttribute(el4, "stdDeviation", "3");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feOffset");
        dom.setAttribute(el4, "dx", "3");
        dom.setAttribute(el4, "dy", "8");
        dom.setAttribute(el4, "in", "shadowBlurInner1");
        dom.setAttribute(el4, "result", "shadowOffsetInner1");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feComposite");
        dom.setAttribute(el4, "in", "shadowOffsetInner1");
        dom.setAttribute(el4, "in2", "SourceAlpha");
        dom.setAttribute(el4, "k2", "-1");
        dom.setAttribute(el4, "k3", "1");
        dom.setAttribute(el4, "operator", "arithmetic");
        dom.setAttribute(el4, "result", "shadowInnerInner1");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feColorMatrix");
        dom.setAttribute(el4, "in", "shadowInnerInner1");
        dom.setAttribute(el4, "result", "shadowMatrixInner1");
        dom.setAttribute(el4, "values", "0 0 0 0 0.708041029 0 0 0 0 0.708041029 0 0 0 0 0.708041029 0 0 0 0.123329937 0");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feGaussianBlur");
        dom.setAttribute(el4, "in", "SourceAlpha");
        dom.setAttribute(el4, "result", "shadowBlurInner2");
        dom.setAttribute(el4, "stdDeviation", "1.5");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feOffset");
        dom.setAttribute(el4, "dx", "-6");
        dom.setAttribute(el4, "in", "shadowBlurInner2");
        dom.setAttribute(el4, "result", "shadowOffsetInner2");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feComposite");
        dom.setAttribute(el4, "in", "shadowOffsetInner2");
        dom.setAttribute(el4, "in2", "SourceAlpha");
        dom.setAttribute(el4, "k2", "-1");
        dom.setAttribute(el4, "k3", "1");
        dom.setAttribute(el4, "operator", "arithmetic");
        dom.setAttribute(el4, "result", "shadowInnerInner2");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feColorMatrix");
        dom.setAttribute(el4, "in", "shadowInnerInner2");
        dom.setAttribute(el4, "result", "shadowMatrixInner2");
        dom.setAttribute(el4, "values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("feMerge");
        var el5 = dom.createElement("feMergeNode");
        dom.setAttribute(el5, "in", "shadowMatrixInner1");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("feMergeNode");
        dom.setAttribute(el5, "in", "shadowMatrixInner2");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3, "id", "f");
        dom.setAttribute(el3, "width", "562.5");
        dom.setAttribute(el3, "height", "1218");
        dom.setAttribute(el3, "rx", "57");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "filter", "url(#a)");
        dom.setAttribute(el2, "transform", "translate(50 50)");
        var el3 = dom.createElement("mask");
        dom.setAttribute(el3, "id", "c");
        dom.setAttribute(el3, "fill", "#fff");
        var el4 = dom.createElement("use");
        dom.setAttributeNS(el4, "http://www.w3.org/1999/xlink", "xlink:href", "#b");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3, "mask", "url(#c)");
        var el4 = dom.createElement("use");
        dom.setAttribute(el4, "fill", "#FFF");
        dom.setAttributeNS(el4, "http://www.w3.org/1999/xlink", "xlink:href", "#d");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("use");
        dom.setAttribute(el4, "fill", "#000");
        dom.setAttribute(el4, "filter", "url(#e)");
        dom.setAttributeNS(el4, "http://www.w3.org/1999/xlink", "xlink:href", "#d");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("use");
        dom.setAttribute(el3, "fill", "#FAFAFA");
        dom.setAttribute(el3, "transform", "translate(43 40)");
        dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#f");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
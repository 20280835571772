define("presenter/templates/components/controls/rich-text-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/components/controls/rich-text-field.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" TODO: figure out why including the svg icons as partials doesn't work  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "hidden");
        dom.setAttribute(el1, "name", "content");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("trix-toolbar");
        dom.setAttribute(el1, "class", "RichTextField-toolbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "RichTextField-toolbarButton");
        dom.setAttribute(el2, "data-attribute", "bold");
        dom.setAttribute(el2, "title", "Bold");
        dom.setAttribute(el2, "data-key", "b");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el3, "width", "44");
        dom.setAttribute(el3, "height", "44");
        dom.setAttribute(el3, "viewBox", "0 0 44 44");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4, "id", "bold");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "id", "bold-2");
        dom.setAttribute(el5, "d", "M1398,969.013h6.43a9.454,9.454,0,0,0,2.56-.331,6.611,6.611,0,0,0,2.09-.966,4.645,4.645,0,0,0,1.42-1.563,4.391,4.391,0,0,0,.51-2.149,4.506,4.506,0,0,0-1.17-3.089,4.331,4.331,0,0,0-1.28-.966,4.134,4.134,0,0,0-1.59-.42v-0.051a5.3,5.3,0,0,0,2.33-1.436,3.632,3.632,0,0,0,.89-2.53,4.333,4.333,0,0,0-.43-2,3.912,3.912,0,0,0-1.19-1.4,5.389,5.389,0,0,0-1.8-.826,8.756,8.756,0,0,0-2.26-.28H1398v18Zm2.54-15.866h4a2.87,2.87,0,0,1,2.24.788,2.663,2.663,0,0,1,.72,1.856,2.628,2.628,0,0,1-.28,1.246,2.523,2.523,0,0,1-.75.839,3.116,3.116,0,0,1-1.11.471,6.371,6.371,0,0,1-1.35.14h-3.47v-5.34Zm0,7.475h3.76a4.823,4.823,0,0,1,2.93.8,2.781,2.781,0,0,1,1.09,2.4,2.537,2.537,0,0,1-.43,1.551,3.139,3.139,0,0,1-1.03.928,3.863,3.863,0,0,1-1.31.458,7.919,7.919,0,0,1-1.28.114h-3.73v-6.255Z");
        dom.setAttribute(el5, "transform", "translate(-1382 -938)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "RichTextField-toolbarButton");
        dom.setAttribute(el2, "data-attribute", "italic");
        dom.setAttribute(el2, "title", "Italic");
        dom.setAttribute(el2, "data-key", "i");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el3, "width", "44");
        dom.setAttribute(el3, "height", "44");
        dom.setAttribute(el3, "viewBox", "0 0 44 44");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4, "id", "italic");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "id", "italic-2");
        dom.setAttribute(el5, "d", "M1258.05,951l-0.18.838a7.415,7.415,0,0,1,.85.158,4.967,4.967,0,0,1,.91.286,0.925,0.925,0,0,1,.44.343,0.9,0.9,0,0,1,.1.444,1.763,1.763,0,0,1-.01.229l-0.03.228-2.98,13.178a1.694,1.694,0,0,1-.34.755,1.6,1.6,0,0,1-.73.489,3.721,3.721,0,0,1-.92.152c-0.43.034-.76,0.06-0.99,0.076l-0.18.838h7.93l0.19-.838a9.156,9.156,0,0,1-.94-0.146,4.147,4.147,0,0,1-.82-0.209,0.976,0.976,0,0,1-.45-0.356,0.955,0.955,0,0,1-.12-0.5,1.5,1.5,0,0,1,.01-0.2,1.023,1.023,0,0,1,.04-0.2l2.98-13.163a2.489,2.489,0,0,1,.33-0.754,1.307,1.307,0,0,1,.74-0.508,10.8,10.8,0,0,1,1.92-.3l0.19-.838h-7.94Z");
        dom.setAttribute(el5, "transform", "translate(-1238 -938)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "RichTextField-toolbarButton");
        dom.setAttribute(el2, "data-attribute", "big");
        dom.setAttribute(el2, "title", "Big");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el3, "width", "44");
        dom.setAttribute(el3, "height", "44");
        dom.setAttribute(el3, "viewBox", "0 0 44 44");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4, "id", "big");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "id", "big-2");
        dom.setAttribute(el5, "d", "M1111,953v16h-3V953h-6v-3h15v3h-6Zm19,8h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2Z");
        dom.setAttribute(el5, "transform", "translate(-1094 -938)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "RichTextField-toolbarButton");
        dom.setAttribute(el2, "data-attribute", "small");
        dom.setAttribute(el2, "title", "Small");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el3, "width", "44");
        dom.setAttribute(el3, "height", "44");
        dom.setAttribute(el3, "viewBox", "0 0 44 44");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4, "id", "small");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "id", "small-2");
        dom.setAttribute(el5, "d", "M967,960v9h-3v-9h-5v-3h13v3h-5Zm8,3h10v2H975v-2Z");
        dom.setAttribute(el5, "transform", "translate(-950 -938)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "RichTextField-toolbarButton RichTextField-toolbarButton--align");
        dom.setAttribute(el2, "title", "Toggle Alignment");
        dom.setAttribute(el2, "data-action", "toggleAlignment");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("trix-editor");
        dom.setAttribute(el1, "class", "RichTextField-editor");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var element2 = dom.childAt(fragment, [6]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'value');
        morphs[2] = dom.createAttrMorph(element1, 'id');
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [9]), 0, 0);
        morphs[4] = dom.createAttrMorph(element2, 'autofocus');
        morphs[5] = dom.createAttrMorph(element2, 'input');
        morphs[6] = dom.createAttrMorph(element2, 'placeholder');
        morphs[7] = dom.createAttrMorph(element2, 'toolbar');
        return morphs;
      },
      statements: [["attribute", "id", ["concat", [["get", "inputId", ["loc", [null, [3, 13], [3, 20]]]]]]], ["attribute", "value", ["concat", [["get", "value", ["loc", [null, [3, 62], [3, 67]]]]]]], ["attribute", "id", ["concat", [["get", "toolbarId", ["loc", [null, [4, 50], [4, 59]]]]]]], ["inline", "partial", [["get", "alignmentIcon", ["loc", [null, [33, 160], [33, 173]]]]], [], ["loc", [null, [33, 150], [33, 175]]]], ["attribute", "autofocus", ["concat", [["get", "autofocusOn", ["loc", [null, [35, 55], [35, 66]]]]]]], ["attribute", "input", ["concat", [["get", "inputId", ["loc", [null, [35, 79], [35, 86]]]]]]], ["attribute", "placeholder", ["concat", [["get", "placeholder", ["loc", [null, [35, 105], [35, 116]]]]]]], ["attribute", "toolbar", ["concat", [["get", "toolbarId", ["loc", [null, [35, 131], [35, 140]]]]]]]],
      locals: [],
      templates: []
    };
  }());
});
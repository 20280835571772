define('presenter/initializers/fixIos7Webview', ['exports', 'presenter/utils/ios'], function (exports, _ios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'fixIos7Webview',
    initialize: function initialize() {
      return (0, _ios.fixIos7Webview)();
    }
  };
});
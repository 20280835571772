define("presenter/templates/svg/_chevron_left", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_chevron_left.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "chevron-left");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "class", "chevron-icon left");
        dom.setAttribute(el1, "viewBox", "0 0 14 24");
        dom.setAttribute(el1, "enable-background", "new 0 0 14 24");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("polygon");
        dom.setAttribute(el2, "class", "chevron-polygon");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "clip-rule", "evenodd");
        dom.setAttribute(el2, "fill", "#FFFFFF");
        dom.setAttribute(el2, "points", "12,24 14,22 4,12 14,2 12,0 0,12 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/key-driver', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var KeyDriver;

  KeyDriver = _data.Model.extend({
    npsComponentId: (0, _data.attr)('number'),
    ratingComponentId: (0, _data.attr)('number'),
    keyDrivers: (0, _data.attr)('raw'),
    gridItemId: (0, _data.attr)('number')
  });

  KeyDriver.reopenClass({
    resourceName: 'keydriver',
    baseUrl: _globals.jsSettings.sluricane.url,
    endpoint: 'key-driver'
  });

  exports.default = KeyDriver;
});
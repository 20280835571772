define('presenter/controllers/presenter/list', ['exports', 'presenter/controllers/presenter/component', 'presenter/models/list-item'], function (exports, _component, _listItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    _initAnswer: function () {
      var answer, i, ref, results;
      if (answer = this.get('answer')) {
        results = [];
        for (i = 1, ref = this.get('maximum'); 1 <= ref ? i <= ref : i >= ref; 1 <= ref ? i++ : i--) {
          results.push(answer.get('listItems').pushObject(_listItem.default.create({
            answer: answer
          })));
        }
        return results;
      }
    }.observes('answer'),
    hasAnswer: function () {
      return this.get('answer.listItems').filter(function (item) {
        return !Ember.isEmpty(('' + item.get('text')).trim());
      }).length >= this.get('minimum');
    }.property('answer.listItems.@each.text', 'minimum'),
    required: Ember.computed.gt('minimum', 0)
  });
});
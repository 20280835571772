define('presenter/components/presenter/ref-link', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    newWindow: true,
    refCode: null,
    refToken: null,
    layoutName: 'presenter/components/controls/ref-link',
    isIE9: Modernizr.ie9,
    href: function () {
      var params, refCode, refToken;
      if (!(refCode = this.get('refCode'))) {
        Ember.Logger.warn("WARNING: ref-link used without refCode!");
      }
      params = "ref=" + refCode;
      if (refToken = this.get('refToken')) {
        params += "&rtkn=" + refToken;
      }
      return _globals.jsSettings.base_url + "/signup?" + params;
    }.property('refCode', 'refToken'),
    target: function () {
      if (this.get('newWindow')) {
        return '_blank';
      } else {
        return '_self';
      }
    }.property('target')
  });
});
define('presenter/mixins/change-feed-manager', ['exports', 'presenter/utils/sluricane-change-feed'], function (exports, _sluricaneChangeFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    changeFeed: function () {
      return _sluricaneChangeFeed.default.create({
        model: this
      });
    }.property()
  });
});
define('presenter/components/presenter/component', ['exports', 'presenter/mixins/resize-handler', 'presenter/utils', 'presenter/globals', 'presenter/utils/layout'], function (exports, _resizeHandler, _utils, _globals, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /* global Modernizr */
  var maxSubmitHeight, maxSubmitWidth, minimumPadding, transitionDuration;

  transitionDuration = 200;

  maxSubmitWidth = 80;

  maxSubmitHeight = 40;

  minimumPadding = 3;

  exports.default = Ember.Component.extend(_resizeHandler.default, {
    classNames: ['presenter-component-container', 'overthrow', 'js-componentScrollContainer'],
    classNameBindings: ['component.type', 'isCurrent:is-current', 'navigationLocation', 'progressLocation', 'isOffset:is-offset', 'isDefaultOffset:is-default-offset', 'progressVisibility', 'footerVisibility'],
    attributeBindings: ['isHidden:aria-hidden'],
    layoutName: 'presenter/layouts/component',
    rightToLeft: null,
    component: null,
    isFocussed: false,
    positioned: true,
    isCurrent: Ember.computed.equal('component.side', 0),
    isHidden: Ember.computed('isCurrent', function () {
      return !this.get('isCurrent');
    }),
    onResize: function onResize() {
      return this._updatePosition();
    },
    _preventOverscroll: function () {
      if (_globals.isEmbedded && Modernizr.touch) {
        return (0, _utils.preventOverscroll)(this.$()[0]);
      }
    }.on('didInsertElement'),
    _updatePosition: function () {
      var $el, after, current, focussed, position, style, wrapperWidth;
      $el = this.$();
      if (!($el && this.get('positioned'))) {
        return;
      }
      wrapperWidth = $el.find('.js-componentWrapper').width();
      position = this.get('component.side') * $(window).width();
      style = Modernizr.transition && !Modernizr.ios ? {
        x: position
      } : {
        left: position
      };
      focussed = this.get('isFocussed');
      current = this.get('component.isCurrent');
      if (focussed && !current || !focussed && current) {
        this.toggleProperty('isFocussed');
        if (!Modernizr.ios) {
          $el.css({
            overflow: 'hidden'
          });
        }
        after = function (_this) {
          return function () {
            if (_this.isDestroyed) {
              return;
            }
            if (!Modernizr.ios) {
              $el.css({
                overflow: ''
              });
            }
            _this.onFocus();
            if (current) {
              _this._showRequiredAlert();
            }
            if (position === 0 && $el.find('.js-questionTitle').length > 0 && !_globals.isIframed && !_globals.isEmbedded) {
              $el.find('.js-questionTitle')[0].focus();
            }
            return Ember.run.later(function () {
              var ref;
              return (ref = _this.get('controller')) != null ? ref.send('enableNavigationAndInput') : void 0;
            }, 500);
          };
        }(this);
        if (Modernizr.transition && Modernizr.ios) {
          $el.css({
            x: $el.css('left'),
            left: 0
          });
          $el.transition({
            x: position
          }, transitionDuration, function (_this) {
            return function () {
              $el.removeAttr('style');
              $el.css({
                left: position
              });
              return after();
            };
          }(this));
        } else if (Modernizr.transition) {
          $el.transition(style, transitionDuration, after);
        } else {
          $el.animate(style, transitionDuration, after);
        }
        if (!focussed && current) {
          this._updateWrapperWidth(wrapperWidth);
          return this._checkIfNeedsScroll(true);
        }
      } else if (!focussed && !current) {
        return this.$().css(style);
      } else if (focussed && current) {
        this._updateWrapperWidth(wrapperWidth);
        if (this.get('canOverrideLocations')) {
          return this._checkIfNeedsScroll();
        }
      }
    }.observes('component.side').on('didInsertElement'),
    _updateWrapperWidth: function _updateWrapperWidth(wrapperWidth) {
      if (this.get('canOverrideLocations')) {
        return this.set('component.components.wrapperWidth', wrapperWidth);
      }
    },
    canOverrideLocations: Ember.computed('component.model.type', 'component.components.isNotEmbedded', function () {
      var ref;
      return this.get('component.components.isNotEmbedded') && (ref = this.get('component.model.type')) !== 'ThankYouPage' && ref !== 'CoverPage';
    }),
    progressVisibility: Ember.computed('canOverrideLocations', 'component.components.isHiddenProgress', function () {
      if (!this.get('canOverrideLocations')) {
        return;
      }
      if (this.get('component.components.isHiddenProgress')) {
        return 'progress-hidden';
      } else {
        return 'progress-shown';
      }
    }),
    footerVisibility: Ember.computed('canOverrideLocations', 'component.components.isFooterDark', function () {
      if (this.get('canOverrideLocations') && this.get('component.components.isFooterDark')) {
        return 'footer-dark';
      }
    }),
    navigationLocation: Ember.computed('canOverrideLocations', 'component.components.navigationLocation', function () {
      if (this.get('canOverrideLocations')) {
        return this.get('component.components.navigationLocation');
      }
    }),
    progressLocation: Ember.computed('canOverrideLocations', 'component.components.progressLocation', function () {
      if (this.get('canOverrideLocations')) {
        return this.get('component.components.progressLocation');
      }
    }),
    overlappingNavigation: false,
    _checkOverlappingNavigation: function _checkOverlappingNavigation() {
      var contentHeight, overlapping, wrapperWidth;
      if (this.get('component.components.isFooterDark') || this.get('component.components.isOffset')) {
        return;
      }
      wrapperWidth = this.get('component.components.wrapperWidth');
      if (wrapperWidth) {
        contentHeight = this.$().find('.js-componentContent').outerHeight();
        overlapping = (this.get('component.components.needsScroll') || ($(document).height() - contentHeight) / 2 <= maxSubmitHeight) && ($(document).width() - wrapperWidth) / 2 + minimumPadding < maxSubmitWidth;
        return this.set('overlappingNavigation', overlapping);
      }
    },
    isOffset: Ember.computed('canOverrideLocations', 'component.components.isOffset', 'overlappingNavigation', function () {
      return this.get('canOverrideLocations') && (this.get('component.components.isOffset') || this.get('overlappingNavigation'));
    }),
    isDefaultOffset: Ember.computed('canOverrideLocations', 'isOffset', 'component.components.{isFooterDark,isShownProgress}', function () {
      return this.get('canOverrideLocations') && !this.get('isOffset') && !this.get('component.components.isFooterDark') && this.get('needsScroll') && this.get('component.components.isShownProgress');
    }),
    _checkIfNeedsScroll: function _checkIfNeedsScroll(adjustHeight) {
      var $componentWrapper, $el, needsScroll, newHeight;
      if (adjustHeight == null) {
        adjustHeight = false;
      }
      $el = this.$();
      if (!$el || !this.get('component.isCurrent')) {
        return;
      }
      needsScroll = $el[0].scrollHeight > $el.height();
      $componentWrapper = $el.find('.js-componentWrapper');
      if ((0, _globals.presenterFlag)('isDrawer') && adjustHeight && !Modernizr.touch) {
        newHeight = $el.find('.js-componentContent').outerHeight() + $componentWrapper.cssInt('padding-top') + $componentWrapper.cssInt('padding-bottom') - $el.find('.js-excludeHeight').height();
        this.get('container').lookup('eventBus:survey').trigger('heightChanged', newHeight);
        needsScroll = newHeight > _globals.codeSettings.embedMaxDrawerHeight;
      }
      this.set('component.components.needsScroll', needsScroll);
      return this._checkOverlappingNavigation();
    },
    _enableCheckForScroll: function () {
      return _layout.default.on('possibleResize', this, this._checkIfNeedsScroll);
    }.on('didInsertElement'),
    _disableCheckForScroll: function () {
      return _layout.default.off('possibleResize', this, this._checkIfNeedsScroll);
    }.on('willDestroyElement'),
    _showRequiredAlert: function _showRequiredAlert() {
      return this.set('component.components.showRequiredAlert', this.get('component.isQuestion') && this.get('component.required'));
    },
    _clearRequiredAlert: function () {
      if (this.get('component.completed') || !this.get('component.isCurrent')) {
        return this.set('component.components.showRequiredAlert', false);
      }
    }.observes('component.completed', 'component.isCurrent'),
    _toggleTabbability: function () {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          if (!_this.$()) {
            return;
          }
          return _this.$('input, textarea, a, button, link, select, [draggable], iframe').attr('tabindex', _this.get('component.isCurrent') ? 0 : -1);
        };
      }(this));
    }.observes('component.isCurrent').on('didInsertElement'),
    onFocus: Ember.K
  });
});
define('presenter/components/presenter-components', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'presenter-components',
    classNameBindings: ['showFooter', 'disableUserSelection', 'rightToLeft', 'focusCssForAccessibility:presenter-components-accessible-focus'],
    focusCssForAccessibility: false,
    _initKeyHandler: function () {
      this._keyHandler = function (_this) {
        return function (e) {
          var currIndex;
          currIndex = _this.get('content').indexOf(_this.get('currentComponent'));
          if (e.which === 9) {
            _this.set('focusCssForAccessibility', true);
          }
          if (e.which === 38) {
            _this.set('focusCssForAccessibility', true);
          }
          if (e.which === 40) {
            _this.set('focusCssForAccessibility', true);
          }
          if (e.which === 37) {
            _this.sendAction('previous');
            e.preventDefault();
          }
          if (e.which === 39) {
            _this.sendAction('next');
            return e.preventDefault();
          }
        };
      }(this);
      return Ember.$(window).on('keydown', this._keyHandler);
    }.on('didInsertElement'),
    _destroyKeyHandler: function () {
      return Ember.$(window).off('keydown', this._keyHandler);
    }.on('willDestroyElement'),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      return this.set('focusCssForAccessibility', false);
    }
  });
});
define('presenter/models/campaign', ['exports', 'presenter/globals', 'presenter/data'], function (exports, _globals, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Campaign, TOGGLEABLE_DEFAULTS;

  Campaign = _data.Model.extend({
    _allowMerges: ['type', 'token', 'endedAt', 'urls', 'style', 'showTab', 'showMobile', 'placement', 'percentVisitors', 'triggerOnce', 'tabColor', 'icon', 'iconColor', 'sentAt', 'recipients', 'numRecipients', 'totalRecipients', 'headers', 'exclude_emails', 'customKeys', 'cmListId', 'sentEmailsCount', 'openedEmailsCount', 'clickedEmailsCount', 'distributeState', 'lastError', 'duplicateEmails', 'transitionCancelled', 'hasMaxSalesforceRecipients', 'salesforceReport', 'templateBuilderToken'],
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    type: (0, _data.attr)('string'),
    token: (0, _data.attr)('string'),
    endedAt: (0, _data.attr)('date'),
    urls: (0, _data.hasMany)('campaign_url', {
      serialize: true
    }),
    style: (0, _data.attr)('string'),
    showTab: (0, _data.attr)('boolean'),
    showMobile: (0, _data.attr)('boolean'),
    placement: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    timingSeconds: (0, _data.attr)('number'),
    scrollPosition: (0, _data.attr)('number'),
    percentVisitors: (0, _data.attr)('number', {
      defaultValue: 100
    }),
    triggerOnce: (0, _data.attr)('boolean'),
    tabColor: (0, _data.attr)('color'),
    icon: (0, _data.attr)('string'),
    iconColor: (0, _data.attr)('color'),
    autocloseTimingSeconds: (0, _data.attr)('number'),
    sentAt: (0, _data.attr)('date'),
    fromName: (0, _data.attr)('string', {
      printableName: 'From Name'
    }),
    fromEmail: (0, _data.attr)('string', {
      printableName: 'From Email'
    }),
    subject: (0, _data.attr)('string', {
      printableName: 'Subject'
    }),
    title: (0, _data.attr)('string', {
      printableName: 'Email Greeting'
    }),
    body: (0, _data.attr)('string', {
      printableName: 'Email Body'
    }),
    footer: (0, _data.attr)('string', {
      printableName: 'Email Footer'
    }),
    links: (0, _data.attr)('array', {
      printableName: 'Email Links'
    }),
    buttonLabel: (0, _data.attr)('string', {
      printableName: 'Email Button Label'
    }),
    recipients: (0, _data.attr)('string'),
    numRecipients: (0, _data.attr)('number'),
    totalRecipients: (0, _data.attr)('number'),
    sendPreview: (0, _data.attr)('boolean'),
    sendNow: (0, _data.attr)('boolean'),
    excludeEmails: (0, _data.attr)('boolean'),
    headers: (0, _data.hasMany)('campaign_email_header', {
      serialize: true
    }),
    customKeys: (0, _data.attr)('array'),
    cmListId: (0, _data.attr)('string'),
    cmV2ListId: (0, _data.attr)('string'),
    cmClientId: (0, _data.attr)('string'),
    mergeFields: (0, _data.hasMany)('merge_field', {
      serialize: true
    }),
    exportType: (0, _data.attr)('string'),
    outboundMessageId: (0, _data.attr)('string', {
      readOnly: true
    }),
    fromEmailName: (0, _data.attr)('string', {
      printableName: 'From Email Name'
    }),
    fromEmailDomain: (0, _data.attr)('string', {
      printableName: 'From Email Domain'
    }),
    recipientNameField: (0, _data.attr)('string'),
    recipientEmailField: (0, _data.attr)('string'),
    sfRelatedTo: (0, _data.attr)('string'),
    zendeskRelatedTo: (0, _data.attr)('string'),
    integrationType: (0, _data.attr)('string'),
    templateBuilderToken: (0, _data.attr)('string'),
    selectedEsp: (0, _data.attr)('string', {
      defaultValue: 'general'
    }),
    sentEmailsCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    openedEmailsCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    clickedEmailsCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    distributeState: (0, _data.attr)('string'),
    lastError: (0, _data.attr)('string', {
      readOnly: true
    }),
    duplicateEmails: (0, _data.attr)('array', {
      readOnly: true
    }),
    transitionTo: (0, _data.attr)('string'),
    transitionCancelled: (0, _data.attr)('boolean'),
    importedRecipients: (0, _data.attr)('string', {
      serialize: false
    }),
    anonymizeEmails: (0, _data.attr)('boolean'),
    embeddedComponentId: (0, _data.attr)('number'),
    emailMergeMap: (0, _data.attr)('raw'),
    sparkpost: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    recipientListCreated: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    createdAt: (0, _data.attr)('date', {
      readOnly: true
    }),
    firstReminderSubject: (0, _data.attr)('string'),
    firstReminderTitle: (0, _data.attr)('string'),
    firstReminderBody: (0, _data.attr)('string'),
    firstReminderButtonLabel: (0, _data.attr)('string'),
    secondReminderSubject: (0, _data.attr)('string'),
    secondReminderTitle: (0, _data.attr)('string'),
    secondReminderBody: (0, _data.attr)('string'),
    secondReminderButtonLabel: (0, _data.attr)('string'),
    firstReminderDelay: (0, _data.attr)('number'),
    secondReminderDelay: (0, _data.attr)('number'),
    firstReminderEnabled: (0, _data.attr)('boolean'),
    secondReminderEnabled: (0, _data.attr)('boolean'),
    expiryPeriod: (0, _data.attr)('number'),
    recipientUnsubscribeLinkUrlField: (0, _data.attr)('string'),
    workflowRuleId: (0, _data.attr)('string', {
      readOnly: true
    }),
    sfdcCaseObject: (0, _data.attr)('boolean'),
    replyTo: (0, _data.attr)('string'),
    flowId: (0, _data.attr)('string'),
    sentCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    deliveredCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    openedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    startedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    completedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    softBouncedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    hardBouncedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    unsubscribedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    failedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    anonymizedCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    recipientPhoneNumberField: (0, _data.attr)('string'),
    customMessage: (0, _data.attr)('string'),
    suppressionSync: (0, _data.attr)('boolean'),
    suppressionHardBounceField: (0, _data.attr)('string'),
    suppressionUnsubscribeField: (0, _data.attr)('string'),
    suppressionSmsStopField: (0, _data.attr)('string'),
    hasMaxSalesforceRecipients: (0, _data.attr)('boolean'),
    responses: (0, _data.hasMany)('response', {
      serialize: false
    }),
    customUrls: (0, _data.hasMany)('custom_url', {
      serialize: false
    }),
    salesforceReport: (0, _data.belongsTo)('salesforce_report', {
      serialize: true
    }),
    logoMediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    active: Ember.computed('endedAt', {
      get: function get(key) {
        return !this.get('endedAt') || this.get('endedAt') > new Date();
      },
      set: function set(key, value) {
        this.set('endedAt', value ? null : new Date());
        return value;
      }
    }),
    hasError: Ember.computed.notEmpty('lastError'),
    emailHeaders: Ember.computed.filterBy('headers', 'type', 'email'),
    emailHeader: function () {
      return this.get('headers').findBy('emailAddress');
    }.property('headers.@each.type'),
    hasSalesforceReport: Ember.computed.notEmpty('salesforceReport'),
    hasEmailField: Ember.computed.notEmpty('emailHeader'),
    hasOnlyEmailHeader: function () {
      return this.get('headers.length') === 1 && this.get('headers.firstObject.type') === 'email';
    }.property('headers.length', 'headers.firstObject.type'),
    listCreated: Ember.computed.oneWay('isImported'),
    isSendingOrSent: function () {
      return this.get('isSent') || this.get('isSending') || this.get('transitionTo') === 'sent' || !Ember.isEmpty(this.get('sentAt'));
    }.property('isSent', 'isSending', 'transitionTo', 'sentAt'),
    errorFromErrors: function errorFromErrors(errors) {
      var error;
      if ((error = errors.base || errors.campaigns) && !Ember.isEmpty(error)) {
        if (Ember.isArray(error)) {
          return error.join(', ');
        } else {
          return error;
        }
      }
    },
    getNumRecipients: function getNumRecipients() {
      return this.get('emailHeader.number') || 0;
    },
    setRecipients: function setRecipients() {
      return this.setProperties({
        importedRecipients: this.get('recipients'),
        numRecipients: this.getNumRecipients()
      });
    },
    resetExportContent: function resetExportContent() {
      this.setProperties({
        buttonLabel: null,
        title: null,
        body: null
      });
      return this.get('mergeFields').clear();
    }
  });

  TOGGLEABLE_DEFAULTS = {
    timingSeconds: 5,
    scrollPosition: 100,
    autocloseTimingSeconds: 5
  };

  Campaign.reopenForEach(['timingSeconds', 'scrollPosition', 'autocloseTimingSeconds'], function (prop, Campaign) {
    return Campaign[prop + "Enabled"] = Ember.computed(prop, {
      get: function get(key) {
        return !Ember.isNone(this.get(prop));
      },
      set: function set(key, value) {
        this.set(prop, value ? TOGGLEABLE_DEFAULTS[prop] : null);
        return value;
      }
    }).property(prop);
  });

  Campaign.reopenForEach(_globals.codeSettings.campaignDistributeStates, function (distributeState, Model) {
    return Model["is" + distributeState.camelize().titleize()] = Ember.computed.equal('distributeState', distributeState);
  });

  Campaign.reopenClass({
    resourceName: 'campaign'
  });

  exports.default = Campaign;
});
define('presenter/components/select-field-option', ['exports', 'presenter/mixins/aria'], function (exports, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aria.default, {
    layoutName: 'components/controls/select-field-option',
    tagName: 'option',
    attributeBindings: ['selected', 'value', 'disabled', 'label:aria-label'],
    'tabindex': null,
    content: null,
    labelPath: null,
    valuePath: null,
    enabledPath: null,
    selection: null,
    _initDisabledProperty: function () {
      var path;
      if (!(path = this.get('enabledPath'))) {
        return;
      }
      return Ember.defineProperty(this, 'disabled', Ember.computed(path, function () {
        return !this.get(path);
      }));
    }.on('init'),
    label: function () {
      var path;
      if (path = this.get('labelPath')) {
        return this.get(path);
      } else {
        return this.get('content');
      }
    }.property('content', 'labelPath'),
    value: function () {
      var path;
      if (path = this.get('valuePath')) {
        return this.get(path);
      } else {
        return this.get('content');
      }
    }.property('content', 'valuePath'),
    selected: function () {
      return this.get('content') === this.get('selection');
    }.property('content', 'selection')
  });
});
define('presenter/mixins/answerable', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    answer: null,
    hasAnswer: true,
    shouldSaveAnswer: function () {
      var answer, existingAndDirty, hasAnswer, isDirty, isNew;
      if (!this.get('isLiveSurvey')) {
        return;
      }
      answer = this.get('answer');
      hasAnswer = this.get('hasAnswer');
      isNew = this.get('answer.isNew');
      isDirty = this.get('answer.isDirty');
      existingAndDirty = !isNew && isDirty;
      return answer && (hasAnswer || existingAndDirty);
    }.property('answer.{isNew,isDirty}', 'hasAnswer', 'isLiveSurvey'),
    isLiveSurvey: function () {
      return !((0, _globals.presenterFlag)('isReadOnly') || _globals.isPreview);
    }.property()
  });
});
define("presenter/templates/presenter/survey", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 682
                    },
                    "end": {
                      "line": 1,
                      "column": 760
                    }
                  },
                  "moduleName": "presenter/templates/presenter/survey.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" but it will be saved in survey responses.");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 760
                    },
                    "end": {
                      "line": 1,
                      "column": 814
                    }
                  },
                  "moduleName": "presenter/templates/presenter/survey.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" and it will not be saved in survey responses.");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 582
                  },
                  "end": {
                    "line": 1,
                    "column": 825
                  }
                },
                "moduleName": "presenter/templates/presenter/survey.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                var el2 = dom.createElement("strong");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" is not a defined key,");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["content", "error.key", ["loc", [null, [1, 638], [1, 651]]]], ["block", "if", [["subexpr", "eq", [["get", "error.action", ["loc", [null, [1, 692], [1, 704]]]], "accepted"], [], ["loc", [null, [1, 688], [1, 716]]]]], [], 0, 1, ["loc", [null, [1, 682], [1, 821]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 1014
                      },
                      "end": {
                        "line": 1,
                        "column": 1092
                      }
                    },
                    "moduleName": "presenter/templates/presenter/survey.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" but it will be saved in survey responses.");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 1092
                      },
                      "end": {
                        "line": 1,
                        "column": 1146
                      }
                    },
                    "moduleName": "presenter/templates/presenter/survey.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" and it will not be saved in survey responses.");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 825
                    },
                    "end": {
                      "line": 1,
                      "column": 1157
                    }
                  },
                  "moduleName": "presenter/templates/presenter/survey.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("p");
                  var el2 = dom.createTextNode("The ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" value ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" is not a valid ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(",");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [0]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
                  morphs[3] = dom.createMorphAt(element0, 7, 7);
                  return morphs;
                },
                statements: [["content", "error.key", ["loc", [null, [1, 889], [1, 902]]]], ["content", "error.value", ["loc", [null, [1, 926], [1, 941]]]], ["content", "error.merge_key.value_type", ["loc", [null, [1, 974], [1, 1004]]]], ["block", "if", [["subexpr", "eq", [["get", "error.action", ["loc", [null, [1, 1024], [1, 1036]]]], "accepted"], [], ["loc", [null, [1, 1020], [1, 1048]]]]], [], 0, 1, ["loc", [null, [1, 1014], [1, 1153]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 825
                  },
                  "end": {
                    "line": 1,
                    "column": 1157
                  }
                },
                "moduleName": "presenter/templates/presenter/survey.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "error.error_type", ["loc", [null, [1, 839], [1, 855]]]], "invalid_value"], [], ["loc", [null, [1, 835], [1, 872]]]]], [], 0, null, ["loc", [null, [1, 825], [1, 1157]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 535
                },
                "end": {
                  "line": 1,
                  "column": 1164
                }
              },
              "moduleName": "presenter/templates/presenter/survey.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "error.error_type", ["loc", [null, [1, 592], [1, 608]]]], "undefined_key"], [], ["loc", [null, [1, 588], [1, 625]]]]], [], 0, 1, ["loc", [null, [1, 582], [1, 1164]]]]],
            locals: ["error"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 405
              },
              "end": {
                "line": 1,
                "column": 1173
              }
            },
            "moduleName": "presenter/templates/presenter/survey.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("This is a survey preview. Responses will not be saved.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "mergeFieldValidationErrors", ["loc", [null, [1, 543], [1, 569]]]]], [], 0, null, ["loc", [null, [1, 535], [1, 1173]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1188
              },
              "end": {
                "line": 1,
                "column": 1328
              }
            },
            "moduleName": "presenter/templates/presenter/survey.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "i18n", ["presenter.alerts.save_issues"], [], ["loc", [null, [1, 1289], [1, 1328]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1343
              },
              "end": {
                "line": 1,
                "column": 1490
              }
            },
            "moduleName": "presenter/templates/presenter/survey.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "i18n", ["presenter.alerts.submit_issues"], [], ["loc", [null, [1, 1449], [1, 1490]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 1599
            }
          },
          "moduleName": "presenter/templates/presenter/survey.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "presenter-survey-adjustment-layer");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "Overlay-spinner");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(fragment, [6]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createAttrMorph(element3, 'style');
          morphs[2] = dom.createMorphAt(element3, 0, 0);
          morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[7] = dom.createAttrMorph(element4, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["presenter-survey-background ", ["get", "backgroundClass", ["loc", [null, [1, 140], [1, 155]]]]]]], ["attribute", "style", ["get", "computedBackgroundAdjustmentStyle", ["loc", [null, [1, 178], [1, 211]]]]], ["content", "outlet", ["loc", [null, [1, 256], [1, 266]]]], ["inline", "application-modal", [], ["showModal", ["subexpr", "@mut", [["get", "showModal", ["loc", [null, [1, 302], [1, 311]]]]], [], []], "modalOptions", ["subexpr", "@mut", [["get", "modalOptions", ["loc", [null, [1, 325], [1, 337]]]]], [], []], "modalDismissed", "modalDismissed", "disconnectModal", "disconnectModal"], ["loc", [null, [1, 272], [1, 405]]]], ["block", "alert-view", [], ["toggle", ["subexpr", "@mut", [["get", "showPreviewAlert", ["loc", [null, [1, 426], [1, 442]]]]], [], []], "duration", 10000, "position", "top"], 0, null, ["loc", [null, [1, 405], [1, 1188]]]], ["block", "alert-view", [], ["classNames", "presenter-save-error-alert", "toggle", ["subexpr", "@mut", [["get", "saveError", ["loc", [null, [1, 1249], [1, 1258]]]]], [], []], "position", "top", "duration", 5000], 1, null, ["loc", [null, [1, 1188], [1, 1343]]]], ["block", "alert-view", [], ["classNames", "presenter-submit-error-alert", "toggle", ["subexpr", "@mut", [["get", "submitError", ["loc", [null, [1, 1406], [1, 1417]]]]], [], []], "position", "top", "duration", 10000], 2, null, ["loc", [null, [1, 1343], [1, 1505]]]], ["attribute", "class", ["concat", ["Overlay ", ["subexpr", "if", [["get", "showOverlay", ["loc", [null, [1, 1530], [1, 1541]]]], "is-visible"], [], ["loc", [null, [1, 1525], [1, 1556]]]]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1625
          }
        },
        "moduleName": "presenter/templates/presenter/survey.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "presenter-survey-view", [], ["isQuestionColorBright", ["subexpr", "@mut", [["get", "isQuestionColorBright", ["loc", [null, [1, 47], [1, 68]]]]], [], []], "assetsLoaded", "assetsLoaded"], 0, null, ["loc", [null, [1, 0], [1, 1625]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
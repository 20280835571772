define('presenter/components/media-video', ['exports', 'presenter/mixins/resize-handler', 'presenter/utils'], function (exports, _resizeHandler, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ASPECT_RATIO;

  exports.default = Ember.Component.extend(_resizeHandler.default, {
    layoutName: 'components/controls/media-video',
    classNames: ['MediaVideo', 'theme-background-mask-fixed'],
    media: null,
    isYouTube: function () {
      if (this.get('media.videoUrl')) {
        return (0, _utils.getYouTubeId)(this.get('media.videoUrl'));
      }
    }.property('media.videoUrl'),
    isVimeo: function () {
      if (this.get('media.videoUrl')) {
        return (0, _utils.getVimeoId)(this.get('media.videoUrl'));
      }
    }.property('media.videoUrl'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      return this.onResize();
    },
    onResize: function onResize() {
      return this.$().height(this.$().width() / ASPECT_RATIO);
    }
  });


  ASPECT_RATIO = 16 / 9;
});
define('presenter/controllers/presenter/components', ['exports', 'i18n-js', 'presenter/globals'], function (exports, _i18nJs, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var COMPONENT_CONTROLLER_MAP;

  COMPONENT_CONTROLLER_MAP = ['Exit', 'ThankYouPage', 'Redirect', 'Form', 'SalesforceForm', 'CampaignMonitorForm', 'MultipleChoice', 'PictureChoice', 'NetPromoter', 'Rating', 'Rank', 'SectionBreak', 'Slider', 'FileUpload', 'ShortAnswer', 'LikeDislike', 'CustomerEffort', 'CustomerSatisfaction', 'KeyDriver'];

  exports.default = Ember.ArrayController.extend({
    response: Ember.inject.controller('presenter/response'),
    survey: Ember.inject.controller('presenter/survey'),
    disableUserSelection: false,
    selectedLanguage: function () {
      var ref;
      return ((ref = _globals.codeSettings.translatedSurveyLanguages.findBy('locale', this.get('response.content.language'))) != null ? ref.label : void 0) || _globals.codeSettings.translatedSurveyLanguages.findBy('locale', window._locale || 'en').label;
    }.property('response.content.language'),
    hasManuallyAdvanced: false,
    surveyLanguages: function () {
      return _globals.codeSettings.translatedSurveyLanguages.filter(function (_this) {
        return function (lang) {
          return _this.get('survey.model.enabledLanguages').contains(lang.locale) || lang.locale === _this.get('survey.model.language');
        };
      }(this));
    }.property('survey.model.{enabledLanguages,language}'),
    hasAlternateLanguages: Ember.computed.gt('surveyLanguages.length', 1),
    showLanguageSettings: function () {
      if ((0, _globals.presenterFlag)('isUnallowedIframe') && _globals.isIframed) {
        return false;
      } else {
        return !(0, _globals.presenterFlag)('isClosed') && !(0, _globals.presenterFlag)('isDuplicateResponse') && !(0, _globals.presenterFlag)('isInvalid') && !this.get('hasManuallyAdvanced') && this.get('survey.model.multilanguage') && this.get('hasAlternateLanguages');
      }
    }.property('hasManuallyAdvanced', 'survey.model.multilanguage', 'hasAlternateLanguages'),
    rightToLeft: function () {
      if (this.get('response.model.language')) {
        return _globals.codeSettings.rightToLeftLanguages.contains(this.get('response.model.language'));
      } else {
        return _globals.codeSettings.rightToLeftLanguages.contains(this.get('survey.language'));
      }
    }.property('response.model.language'),
    coverPageController: function () {
      return this.findBy('type', 'CoverPage');
    }.property('[]'),
    questionsAndSectionsControllers: function () {
      return this.filter(function (component) {
        return component.get('isQuestion') || component.get('isSectionBreak');
      });
    }.property('@each.{isQuestion,isSectionBreak}'),
    defaultExitController: function () {
      return this.findBy('isExit');
    }.property('[]'),
    isPreview: _globals.isPreview,
    isIframed: _globals.isIframed,
    isEmbedded: _globals.isEmbedded,
    isReadOnly: (0, _globals.presenterFlag)('isReadOnly'),
    isHiddenProgress: Ember.computed('hideFooterForEmbed', function () {
      return (0, _globals.presenterFlag)('isHiddenProgress') || this.get('hideFooterForEmbed');
    }),
    isShownProgress: Ember.computed.not('isHiddenProgress'),
    isNotEmbedded: Ember.computed.not('isEmbedded'),
    currentComponent: null,
    isNavigationNarrow: (0, _globals.presenterFlag)('isNavigationNarrow'),
    isNavigationCompact: (0, _globals.presenterFlag)('isNavigationCompact'),
    isNotNavigationNarrow: Ember.computed.not('isNavigationNarrow'),
    placeNavigationNarrow: Ember.computed.and('isNavigationNarrow', 'navigationLocation'),
    placeNavigationCompact: Ember.computed.and('isNavigationCompact', 'isNotNavigationNarrow', 'navigationLocation'),
    navigationLocation: Ember.computed('isNotEmbedded', 'computed.components.{placeNavigationTop,placeNavigationBottom}', function () {
      if (this.get('isEmbedded')) {
        return;
      }
      switch (false) {
        case !(0, _globals.presenterFlag)('isNavigationTop'):
          return 'navigation-top';
        case !(0, _globals.presenterFlag)('isNavigationBottom'):
          return 'navigation-bottom';
      }
    }),
    progressLocation: Ember.computed('isEmbedded', 'isHiddenProgress', 'navigationLocation', function () {
      var ref;
      if (this.get('isEmbedded') || this.get('isHiddenProgress')) {
        return;
      }
      if ((0, _globals.presenterFlag)('isProgressTop')) {
        return 'progress-top';
      } else if ((0, _globals.presenterFlag)('isProgressBottom')) {
        return 'progress-bottom';
      } else if ((ref = this.get('navigationLocation')) === 'navigation-top' || ref === 'navigation-bottom') {
        if ((0, _globals.presenterFlag)('isProgressAboveFooter')) {
          return 'progress-above-footer';
        } else if ((0, _globals.presenterFlag)('isProgressBelowFooter')) {
          return 'progress-below-footer';
        }
      }
    }),
    placeGFLogo: Ember.computed('survey.model.logo', 'survey.model.whitelabeled', function () {
      return !this.get('survey.model.logo') && !this.get('survey.model.whitelabeled');
    }),
    currentComponentController: function () {
      return this.controllerForComponent(this.get('currentComponent'));
    }.property('[]', 'currentComponent'),
    currentQuestionNumber: function () {
      return this.get('questionsAndSectionsControllers').indexOf(this.get('currentComponentController')) + 1;
    }.property('currentComponentController'),
    renderedComponentControllers: function () {
      return [];
    }.property(),
    _adjustRenderedComponentSet: function () {
      var array, controller, lastHistory, shouldRender, toRemove;
      array = this.get('renderedComponentControllers');
      controller = this.get('currentComponentController');
      shouldRender = [controller];
      shouldRender.addObjects(controller.get('possibleNextComponents').map(function (_this) {
        return function (component) {
          return _this.controllerForComponent(component);
        };
      }(this)));
      lastHistory = this.controllerForComponent(this.get('_history').get('firstObject'));
      if (lastHistory) {
        shouldRender.addObject(lastHistory);
      }
      shouldRender.addObject(this.lastComponent);
      toRemove = array.reject(function (existing) {
        return shouldRender.contains(existing);
      });
      array.removeObjects(toRemove);
      array.addObjects(shouldRender.compact());
      this.lastComponent = controller;
      return controller.checkConditionsToSkip();
    }.observes('currentComponent'),
    prerenderComponent: function prerenderComponent(component) {
      var controller;
      if (this.get('renderedComponentControllers').indexOf(controller = this.controllerForComponent(component)) !== -1) {
        return false;
      } else {
        this.get('renderedComponentControllers').pushObject(controller);
        return true;
      }
    },
    _history: function () {
      return [];
    }.property(),
    pushHistory: function pushHistory(component) {
      return this.get('_history').unshiftObject(component || this.get('currentComponent'));
    },
    popHistory: function popHistory() {
      return this.get('_history').shiftObject();
    },
    clearHistory: function clearHistory(upto) {
      var history;
      history = this.get('_history');
      return history.removeAt(0, history.indexOf(upto) + 1);
    },
    landedAtStart: false,
    lastNavigationDirection: null,
    hasCoverPage: Ember.computed.bool('coverPageController'),
    onLastQuestion: function () {
      return this.get('currentComponentController') === this.get('questionsAndSectionsControllers.lastObject');
    }.property('currentComponentController', 'questionsAndSectionsControllers.lastObject'),
    nextComponent: function () {
      return this.get('content').objectAt(this.get('currentComponentController.index') + 1);
    }.property('currentComponentController', 'currentComponentController.index'),
    previousComponent: function () {
      return this.get('content').objectAt(this.get('currentComponentController.index') - 1);
    }.property('currentComponentController', 'currentComponentController.index'),
    questions: Ember.computed.filterBy('content', 'isQuestion'),
    numQuestions: Ember.computed.oneWay('questionsAndSectionsControllers.length'),
    exiting: Ember.computed.bool('currentComponent.isExit'),
    onCoverPage: Ember.computed.equal('currentComponent.type', 'CoverPage'),
    disableNavigation: false,
    disableInput: false,
    showRequiredAlert: false,
    needsScroll: false,
    wrapperWidth: null,
    computedNavigationMaxWidthStyle: function () {
      var wrapperWidth;
      wrapperWidth = this.get('wrapperWidth');
      if (this.get('placeNavigationNarrow') && wrapperWidth) {
        return "max-width: calc(50% - (" + wrapperWidth + "px / 2));";
      }
    }.property('wrapperWidth', 'placeNavigationNarrow'),
    isLogoLight: Ember.computed.or('isFooterDark', 'survey.isQuestionColorBright'),
    isFooterDark: Ember.computed('needsScroll', 'survey.model.logo', 'footerBackgroundOff', 'hideFooterForEmbed', function () {
      return (this.get('needsScroll') || this.get('survey.model.logo')) && !(this.get('footerBackgroundOff') || this.get('hideFooterForEmbed'));
    }),
    showFooter: Ember.computed('currentComponent.{isQuestion,isFileUpload}', 'currentComponentController.isDragging', 'currentComponent.isSectionBreak', function () {
      return (this.get('currentComponent.isQuestion') || this.get('currentComponent.isSectionBreak')) && !(this.get('currentComponent.isFileUpload') && this.get('currentComponentController.isDragging'));
    }),
    hideFooterForEmbed: (0, _globals.presenterFlag)('isHiddenFooter') && _globals.isIframed,
    hideLogo: Ember.computed('isNotEmbedded', 'hideFooterForEmbed', function () {
      return (0, _globals.presenterFlag)('isHiddenLogo') && this.get('isNotEmbedded') || this.get('hideFooterForEmbed');
    }),
    footerBackgroundOff: Ember.computed('isNotEmbedded', function () {
      return (0, _globals.presenterFlag)('isFooterBackgroundOff') && this.get('isNotEmbedded');
    }),
    displayingLogo: Ember.computed('survey.model.logo', 'hideLogo', 'survey.model.whitelabeled', function () {
      return this.get('survey.model.logo') && !this.get('hideLogo') || !this.get('survey.model.whitelabeled');
    }),
    placeNavigationBlankLogo: Ember.computed.not('displayingLogo'),
    placingNavigationHorizontalAdjustment: Ember.computed.or('placeNavigationNarrow', 'placeNavigationCompact'),
    placingProgressWithContent: Ember.computed('navigationLocation', 'progressLocation', function () {
      var navigationLocation, progressLocation;
      navigationLocation = this.get('navigationLocation');
      progressLocation = this.get('progressLocation');
      return navigationLocation === 'navigation-top' && progressLocation === 'progress-below-footer' || navigationLocation === 'navigation-bottom' && progressLocation === 'progress-above-footer';
    }),
    offsetCompatible: Ember.computed('isNotEmbedded', 'isFooterDark', 'needsScroll', 'footerBackgroundOff', 'navigationLocation', function () {
      return this.get('isNotEmbedded') && !this.get('isFooterDark') && (this.get('needsScroll') || this.get('footerBackgroundOff') || this.get('navigationLocation') === 'navigation-top');
    }),
    isOffset: Ember.computed('offsetCompatible', 'displayingLogo', 'placingNavigationHorizontalAdjustment', 'placingProgressWithContent', function () {
      return this.get('offsetCompatible') && (this.get('displayingLogo') || this.get('placingNavigationHorizontalAdjustment') || this.get('placingProgressWithContent'));
    }),
    lookupItemController: function lookupItemController(object) {
      var type;
      type = object.get('type');
      if (object.get('isDefault')) {
        return 'presenter/defaultPage';
      } else if (COMPONENT_CONTROLLER_MAP.indexOf(type) !== -1) {
        return "presenter/" + type.decamelize().camelize();
      } else {
        return 'presenter/component';
      }
    },
    showPromptNext: Ember.computed.and('canStepForward', 'currentComponentController.isChanged', 'currentComponentController.hasAnswer'),
    controllerForComponent: function controllerForComponent(component) {
      return this.findBy('content', component);
    },
    _createAnswerForCurrentComponent: function () {
      return this.get('currentComponentController').createAnswer(this.get('response.content'));
    }.observes('currentComponent'),
    submitting: Ember.computed.oneWay('response.submitting'),
    canStepForward: function () {
      return !(0, _globals.presenterFlag)('isReadOnly') && this.get('currentComponentController.completed') || (0, _globals.presenterFlag)('isReadOnly') && !this.get('onLastQuestion');
    }.property('currentComponentController.completed', 'onLastQuestion'),
    canStepBackward: Ember.computed.gt('_history.length', 0),
    removeComponent: function removeComponent(component) {
      return this.get('currentContent').removeObject(component);
    },
    pipingAnswer: function pipingAnswer(questionIndex, itemIndex) {
      var component;
      if (this.get('survey.hasPiping')) {
        component = this.get('questionsAndSectionsControllers').objectAt(questionIndex);
        return component != null ? component.pipingAnswer(itemIndex) : void 0;
      }
    },
    isMissingPipingAnswer: function isMissingPipingAnswer(questionIndex, itemIndex) {
      var component;
      if (!this.get('survey.hasPiping')) {
        return false;
      }
      component = this.get('questionsAndSectionsControllers').objectAt(questionIndex);
      if (!component) {
        return true;
      }
      return component.isMissingPipingAnswer(itemIndex);
    },
    showBrandFooter: function () {
      var component;
      component = this.get('currentComponent');
      return !(component.get('type') === 'ThankYouPage' && !!component.get('iframe')) && component.get('type') !== 'Redirect' && !this.get('survey.whitelabeled') && !(0, _globals.presenterFlag)('isCustomer');
    }.property('currentComponent.type', 'survey.whitelabeled'),
    requiredLegendText: Ember.computed('response.model.language', function () {
      return _i18nJs.default.t('presenter.alerts.required');
    }),
    actions: {
      setNeedsScroll: function setNeedsScroll(value) {
        return this.set('needsScroll', value);
      },
      disableUserSelection: function disableUserSelection() {
        return this.set('disableUserSelection', true);
      },
      enableUserSelection: function enableUserSelection() {
        return this.set('disableUserSelection', false);
      },
      selectLanguage: function selectLanguage(lang) {
        var trans;
        if ((trans = _i18nJs.default.translations[lang.locale]) && (trans.components || trans.choices || trans.grid_items)) {
          return this.send('setLanguage', lang.locale);
        } else {
          this.set('survey.isPending', true);
          return $.ajax({
            type: 'GET',
            url: "/translations/" + window._campaign_token + "/" + lang.locale
          }).then(function (_this) {
            return function (translations) {
              _i18nJs.default.translations[lang.locale] = translations;
              _this.set('survey.isPending', false);
              return _this.send('setLanguage', lang.locale);
            };
          }(this), function (_this) {
            return function () {
              _this.set('survey.isPending', false);
              _this.send('hideModal');
              return Raven.captureMessage('Translations were unable to be fetched', {
                extra: {
                  campaignToken: window._campaign_token,
                  locale: lang.locale
                }
              });
            };
          }(this));
        }
      },
      setLanguage: function setLanguage(locale) {
        var response;
        _i18nJs.default.locale = locale;
        response = this.get('response.model');
        response.set('language', locale);
        if (!_globals.isPreview) {
          response.saveRecord();
        }
        response.trigger('languageChange');
        return this.send('hideModal');
      }
    }
  });
});
define("presenter/templates/components/controls/select-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 111
            },
            "end": {
              "line": 1,
              "column": 155
            }
          },
          "moduleName": "presenter/templates/components/controls/select-field.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["content", "prompt", ["loc", [null, [1, 136], [1, 146]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 248
                },
                "end": {
                  "line": 1,
                  "column": 433
                }
              },
              "moduleName": "presenter/templates/components/controls/select-field.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "select-field-option", [], ["content", ["subexpr", "@mut", [["get", "option", ["loc", [null, [1, 311], [1, 317]]]]], [], []], "selection", ["subexpr", "@mut", [["get", "selection", ["loc", [null, [1, 328], [1, 337]]]]], [], []], "labelPath", ["subexpr", "@mut", [["get", "optionLabelPath", ["loc", [null, [1, 348], [1, 363]]]]], [], []], "valuePath", ["subexpr", "@mut", [["get", "optionValuePath", ["loc", [null, [1, 374], [1, 389]]]]], [], []], "enabledPath", ["subexpr", "@mut", [["get", "optionEnabledPath", ["loc", [null, [1, 402], [1, 419]]]]], [], []], "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 427], [1, 431]]]]], [], []]], ["loc", [null, [1, 281], [1, 433]]]]],
            locals: ["option"],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 181
              },
              "end": {
                "line": 1,
                "column": 453
              }
            },
            "moduleName": "presenter/templates/components/controls/select-field.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("optgroup");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'label');
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "label", ["get", "group.value", ["loc", [null, [1, 234], [1, 245]]]]], ["block", "each", [["get", "group.items", ["loc", [null, [1, 256], [1, 267]]]]], [], 0, null, ["loc", [null, [1, 248], [1, 442]]]]],
          locals: ["group"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 162
            },
            "end": {
              "line": 1,
              "column": 462
            }
          },
          "moduleName": "presenter/templates/components/controls/select-field.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "groupedContent", ["loc", [null, [1, 189], [1, 203]]]]], [], 0, null, ["loc", [null, [1, 181], [1, 462]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 470
              },
              "end": {
                "line": 1,
                "column": 667
              }
            },
            "moduleName": "presenter/templates/components/controls/select-field.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "select-field-option", [], ["content", ["subexpr", "@mut", [["get", "option", ["loc", [null, [1, 529], [1, 535]]]]], [], []], "selection", ["subexpr", "@mut", [["get", "selection", ["loc", [null, [1, 546], [1, 555]]]]], [], []], "labelPath", ["subexpr", "@mut", [["get", "optionLabelPath", ["loc", [null, [1, 566], [1, 581]]]]], [], []], "valuePath", ["subexpr", "@mut", [["get", "optionValuePath", ["loc", [null, [1, 592], [1, 607]]]]], [], []], "enabledPath", ["subexpr", "@mut", [["get", "optionEnabledPath", ["loc", [null, [1, 620], [1, 637]]]]], [], []], "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 645], [1, 649]]]]], [], []], "role", "listitem"], ["loc", [null, [1, 499], [1, 667]]]]],
          locals: ["option"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 462
            },
            "end": {
              "line": 1,
              "column": 676
            }
          },
          "moduleName": "presenter/templates/components/controls/select-field.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "content", ["loc", [null, [1, 478], [1, 485]]]]], [], 0, null, ["loc", [null, [1, 470], [1, 676]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 692
          }
        },
        "moduleName": "presenter/templates/components/controls/select-field.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("select");
        dom.setAttribute(el1, "role", "listbox");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'disabled');
        morphs[1] = dom.createAttrMorph(element1, 'aria-label');
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(element1, 0, 0);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 75], [1, 83]]]]], ["attribute", "aria-label", ["get", "ariaLabel", ["loc", [null, [1, 99], [1, 108]]]]], ["element", "action", ["updateSelection"], ["on", "change"], ["loc", [null, [1, 8], [1, 48]]]], ["block", "if", [["get", "hasPrompt", ["loc", [null, [1, 117], [1, 126]]]]], [], 0, null, ["loc", [null, [1, 111], [1, 162]]]], ["block", "if", [["get", "useOptGroup", ["loc", [null, [1, 168], [1, 179]]]]], [], 1, 2, ["loc", [null, [1, 162], [1, 683]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});
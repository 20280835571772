define('presenter/models/merge-field', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MergeField;

  MergeField = _data.Model.extend({
    type: (0, _data.attr)('string', {
      defaultValue: 'custom'
    }),
    key: (0, _data.attr)('string'),
    value: (0, _data.attr)('string'),
    humanizedName: (0, _data.attr)('string'),
    tokenizedValue: (0, _data.attr)('string'),
    tokenizedUrlencodedValue: (0, _data.attr)('string'),
    valueReadonly: (0, _data.attr)('boolean'),
    originalObject: (0, _data.attr)('string'),
    salesforceFieldType: (0, _data.attr)('string'),
    convertForEsp: (0, _data.attr)('boolean', {
      "default": false
    })
  });

  MergeField.reopenClass({
    resourceName: 'merge_field'
  });

  exports.default = MergeField;
});
define('presenter/components/presenter/picture-choice-question', ['exports', 'presenter/components/presenter/multiple-choice-question'], function (exports, _multipleChoiceQuestion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _multipleChoiceQuestion.default.extend({
    atLeastOneImageLabeled: function () {
      return this.get('component.choices').some(function (choice) {
        return choice.get('text') !== '';
      });
    }.property('choices.@each')
  });
});
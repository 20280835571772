define("presenter/templates/svg/dashboard/donut", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/donut.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        dom.setAttribute(el1, "style", "enable-background:new 0 0 32 32;");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "points", "15,2 15,10 17,10 17,2 	");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3, "points", "11,19.3 5,24.9 6.4,26.3 12.4,20.7 	");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M16,2v1c3.6,0,6.8,1.5,9.2,3.8C27.5,9.2,29,12.4,29,16c0,3.6-1.5,6.8-3.8,9.2C22.8,27.5,19.6,29,16,29\n  		c-3.6,0-6.8-1.5-9.2-3.8C4.5,22.8,3,19.6,3,16c0-3.6,1.5-6.8,3.8-9.2C9.2,4.5,12.4,3,16,3V2V1C7.7,1,1,7.7,1,16c0,8.3,6.7,15,15,15\n  		c8.3,0,15-6.7,15-15c0-8.3-6.7-15-15-15V2z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M16,10v1c1.4,0,2.6,0.6,3.5,1.5c0.9,0.9,1.5,2.2,1.5,3.5c0,1.4-0.6,2.6-1.5,3.5C18.6,20.4,17.4,21,16,21\n  		c-1.4,0-2.6-0.6-3.5-1.5C11.6,18.6,11,17.4,11,16c0-1.4,0.6-2.6,1.5-3.5c0.9-0.9,2.2-1.5,3.5-1.5V10V9c-3.9,0-7,3.1-7,7\n  		c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7V10z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
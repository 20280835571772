define('presenter/models/contact', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Contact;

  Contact = _data.Model.extend({
    firstName: (0, _data.attr)('string'),
    lastName: (0, _data.attr)('string'),
    email: (0, _data.attr)('string'),
    user: (0, _data.belongsTo)('user'),
    name: function () {
      return [this.get('firstName')] + " " + [this.get('lastName')];
    }.property('firstName', 'lastName')
  });

  Contact.reopenClass({
    resourceName: 'contact'
  });

  exports.default = Contact;
});
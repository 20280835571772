define('presenter/mixins/validatable', ['exports', 'i18n-js', 'presenter/utils'], function (exports, _i18nJs, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var validations;

  exports.default = Ember.Mixin.create({
    value: Ember.computed.oneWay('validated'),
    valid: true,
    validate: null,
    validated: null,
    validationError: null,
    urlValue: null,
    validating: false,
    doingInitialValidationCheck: true,
    shouldClearPrepopulatedValue: false,
    _elementValueDidChange: function _elementValueDidChange() {
      var isValidValue, value;
      if (this.get('isDestroyed')) {
        return;
      }
      if (!((this.get('value') === null || this.get('value') === void 0) && this.readDOMAttr('value') === '')) {
        value = this.readDOMAttr('value');
        this.setProperties({
          validating: true,
          value: value
        });
      } else {
        this.setProperties({
          validating: true
        });
      }
      if (this.get('validate')) {
        isValidValue = this._validate(value);
        if (isValidValue) {
          this.setProperties({
            validationError: null,
            validated: value,
            valid: true
          });
          if (this.get('doingInitialValidationCheck')) {
            this.sendAction('updateFieldProperty', 'passedInitialValidationCheck', true);
          }
        } else if (!isValidValue && this.get('shouldClearPrepopulatedValue')) {
          this.setProperties({
            validated: void 0,
            valid: true
          });
        } else {
          this.setProperties({
            validated: void 0,
            valid: false
          });
        }
      } else {
        this.setProperties({
          validationError: null,
          validated: value,
          valid: true
        });
      }
      this.set('doingInitialValidationCheck', false);
      return this.set('validating', false);
    },
    _setupValidation: function () {
      return Ember.run.next(function (_this) {
        return function () {
          _this._validation = validations[_this.get('validate')];
          _this._elementValueDidChange();
          return _this._updateValidationState();
        };
      }(this));
    }.observes('validate').on('didInsertElement'),
    _initValue: function () {
      if (this.get('validating')) {
        return;
      }
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          return _this.set('value', _this.get('validated'));
        };
      }(this));
    }.observes('validated'),
    _validate: function _validate(value) {
      var base, base1, transformed;
      if (!(value && this._validation)) {
        return true;
      }
      transformed = this._validation.transform != null ? this._validation.transform(value) : value;
      return (typeof (base = this._validation.validate).test === "function" ? base.test(transformed) : void 0) || (typeof (base1 = this._validation).validate === "function" ? base1.validate(transformed) : void 0);
    },
    _updateValidationState: function _updateValidationState() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('validationError', this.get('valid') || this.get('shouldClearPrepopulatedValue') ? null : this._validation.message || _i18nJs.default.t("shared.input_errors." + this.get('validate')));
      return this.sendAction('updateFieldProperty', 'shouldClearPrepopulatedValue', false);
    },
    focusOut: function focusOut(event) {
      this._super.apply(this, arguments);
      return this._updateValidationState();
    },
    insertNewline: function insertNewline(event) {
      this._super.apply(this, arguments);
      return this._updateValidationState();
    }
  });


  validations = {
    number: {
      validate: /^[\-\+]?(([0-9]{1,3}(,[0-9]{3})*)|[0-9]+)\.?[0-9]*$/
    },
    url: {
      validate: _utils.URL_REGEX,
      transform: function transform(value) {
        return value.toLowerCase();
      }
    },
    email: {
      validate: function validate(value) {
        return _utils.isValidEmail(value);
      }
    },
    phone: {
      validate: _utils.PHONE_NUMBER_REGEX
    },
    mediaUrl: {
      message: 'Please enter a valid YouTube or Vimeo URL',
      validate: function validate(value) {
        return _utils.YOUTUBE_URL_REGEX.test(value) || _utils.YOUTUBE_SHORTENED_URL_REGEX.test(value) || _utils.VIMEO_URL_REGEX.test(value);
      }
    },
    customSurveyPath: {
      message: 'Path must contain only numbers, letters, dashes, and underscores. ',
      validate: /^[a-zA-Z0-9-_]*$/
    },
    imageFile: {
      message: 'Please select an image file of type .jpeg, .png, .gif or .bmp',
      validate: function validate(value) {
        return (/(jpg|jpeg|png|bmp|gif)$/i.test(value.name)
        );
      }
    },
    salesforceId: {
      message: 'Please enter a valid Salesforce ID',
      validate: _utils.SALESFORCE_ID_REGEXP
    },
    salesforceDate: {
      message: 'Please enter a date in the format yyyy-mm-dd',
      validate: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
    },
    salesforceDatetime: {
      message: 'Please enter a date and time in GMT in the format yyyy-mm-ddThh:mm:ssZ. E.g. 2015-01-10T12:30:00Z',
      validate: /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/
    },
    pardotTimestamp: {
      message: 'Please enter a date and time in GMT in the format yyyy-mm-dd HH:MM:SS. E.g. 2015-01-10 12:30:00',
      validate: /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/
    }
  };
});
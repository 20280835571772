define("presenter/templates/svg/like_dislike/thumb_like", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/like_dislike/thumb_like.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "class", "nc-icon-wrapper");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M47,63h-1h-30.6c-3.4,0,-6.3-2.4,-6.9-5.7L5,38.2c0-0.1,0-0.1,0-0.2v-2c0-3.9 3.1-7 7-7H31V10c0-3.9 3.1-7 7-7\n	l0,0c0.4,0 0.8,0.3 1,0.7L47,31L47,63z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "d", "M49,31H58c0.6,0 1,0.4 1,1v30c0,0.6,-0.4,1,-1,1h-9L49,31z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
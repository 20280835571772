define('presenter/models/default-thank-you-page', ['exports', 'presenter/models/default-page', 'presenter/globals'], function (exports, _defaultPage, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _defaultPage.default.extend({
    isExit: true,
    isBranded: true,
    type: 'ThankYouPage',
    title: 'This survey was made with getfeedback.com',
    description: null,
    ref: 'est',
    campaignToken: window._campaign_token,
    iframe: function () {
      var path;
      if (_globals.isIframed) {
        return false;
      }
      path = _globals.codeSettings.inlineThankYouUrl;
      return path + ("?ref=" + this.get('ref') + "&rtkn=" + this.get('campaignToken'));
    }.property('ref', 'campaignToken')
  });
});
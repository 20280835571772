define('presenter/mixins/presenter/grid-controller-helper', ['exports', 'i18n-js', 'presenter/utils', 'presenter/mixins/grid-controller-helper', 'presenter/globals', 'presenter/utils/register-controller', 'presenter/models/answer', 'presenter/mixins/answerable'], function (exports, _i18nJs, _utils, _gridControllerHelper, _globals, _registerController, _answer, _answerable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_gridControllerHelper.default, {
    response: Ember.inject.controller('presenter/response'),
    component: Ember.computed.alias('model'),
    isInitialized: false,
    isTransitioning: false,
    answers: Ember.computed.mapBy('gridItems', 'answer'),
    answer: Ember.computed.oneWay('currentItem.answer'),
    hasAnswer: function () {
      return this.get('items').isEvery('hasAnswer');
    }.property('items.@each.hasAnswer'),
    required: function () {
      if (this.get('isMissingTitle')) {
        return false;
      } else {
        switch (this.get('type')) {
          case 'MultipleChoice':
            return this._super();
          case 'Rating':
          case 'KeyDriver':
            return this.get('content.required');
        }
      }
    }.property('type', 'components', 'items', 'items.@each.hasAnswer', 'items.@each.text', 'isMissingTitle', 'content.required', 'choices.[]', 'visibleChoicesCount'),
    gridItems: function () {
      if (this.get('randomizeGridItems')) {
        return this.get('content.gridItems').shuffle();
      } else {
        return this.get('content.gridItems');
      }
    }.property('gridItems.[]'),
    itemsWithTitles: [],
    pipingAnswer: function pipingAnswer(itemIndex) {
      var gridAnswer;
      if (this.get('answers') && Number.isFinite(itemIndex)) {
        if (gridAnswer = this.get('answers').objectAt(itemIndex)) {
          switch (this.get('type')) {
            case 'MultipleChoice':
              return _i18nJs.default.t("choices." + gridAnswer.get('choices.firstObject.id') + ".text");
            case 'Rating':
            case 'KeyDriver':
              return gridAnswer.get('number');
          }
        }
      }
    },
    isMissingPipingAnswer: function isMissingPipingAnswer(itemIndex) {
      if (itemIndex == null) {
        itemIndex = null;
      }
      return !Ember.isPresent(this.pipingAnswer(itemIndex));
    },
    handleMissingChoice: function handleMissingChoice(choice) {
      if (this.get('type') === 'MultipleChoice') {
        return this.get('items').map(function (_this) {
          return function (item) {
            var choiceToRemove, selectedChoices;
            if (!item.get('answer')) {
              return;
            }
            selectedChoices = item.get('answer.choices');
            _this.beginPropertyChanges();
            if (choiceToRemove = selectedChoices.findBy('id', choice.get('id'))) {
              selectedChoices.removeObject(choiceToRemove);
            }
            return _this.endPropertyChanges();
          };
        }(this));
      } else {
        return this._super.apply(this, arguments);
      }
    },
    clearAnswer: function clearAnswer(item) {
      if (!item.get('answer')) {
        return;
      }
      switch (this.get('type')) {
        case 'MultipleChoice':
          if (!item.get('answer.choices')) {
            return;
          }
          return item.get('answer.choices').replaceContent(0, item.get('answer.choices.length'), []);
        case 'Rating':
        case 'KeyDriver':
          return item.get('answer').setProperties({
            number: this.get('valueAfterReset'),
            notApplicable: this.get('valueAfterReset')
          });
      }
    },
    createAnswer: function createAnswer(response) {
      return this.get('gridItems').forEach(function (_this) {
        return function (item) {
          var answer;
          if (Ember.isEmpty(item.get('answer'))) {
            answer = _answer.default.create({
              response: response,
              component: _this.get('content'),
              gridItem: item
            });
            item.set('answer', answer);
            return answer;
          }
        };
      }(this));
    },
    saveAnswer: function saveAnswer() {
      return this.get('items').map(function (_this) {
        return function (item) {
          var promise;
          if (item.get('shouldSaveAnswer')) {
            promise = _this.get('response.creationPromise').then(function () {
              return item.get('answer').saveRecord();
            });
            promise.answer = item.get('answer');
            return promise;
          }
        };
      }(this));
    },
    updateIsMissingMainTitle: function updateIsMissingMainTitle() {
      var controller, isMainTitleMissing;
      controller = this;
      isMainTitleMissing = (0, _utils.isMissingAnyPipedAnswer)(this.get('title'), this.get('components'));
      this.set('isMissingTitle', isMainTitleMissing);
      if (isMainTitleMissing) {
        return this.get('items').forEach(function (item) {
          return controller.clearAnswer(item);
        });
      }
    },
    updateIsMissingTitleInItems: function updateIsMissingTitleInItems() {
      var componentsController, controller, hasItemNeedingAnswer, itemsWithTitles;
      controller = this;
      componentsController = this.get('components');
      hasItemNeedingAnswer = false;
      itemsWithTitles = this.get('items').filter(function (item) {
        var isItemMissingTitle;
        isItemMissingTitle = (0, _utils.isMissingAnyPipedAnswer)(item.get('text'), componentsController);
        if (isItemMissingTitle) {
          controller.clearAnswer(item);
        } else if (!item.get('hasAnswer')) {
          hasItemNeedingAnswer = true;
        }
        return !isItemMissingTitle;
      });
      this.set('isMissingTitle', !hasItemNeedingAnswer);
      return this.set('itemsWithTitles', itemsWithTitles);
    },
    showNext: function showNext(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid, forceAdvance) {
      if (shouldUpdateIsMissingTitle == null) {
        shouldUpdateIsMissingTitle = true;
      }
      if (navigateOnlyWithinGrid == null) {
        navigateOnlyWithinGrid = false;
      }
      if (forceAdvance == null) {
        forceAdvance = false;
      }
      if (shouldUpdateIsMissingTitle) {
        this.updateIsMissingTitleInItems();
      }
      if (!this.get('isTransitioning')) {
        this.set('isTransitioning', true);
        if (!_globals.isPreview) {
          this.get('response.creationPromise').then(function (_this) {
            return function () {
              return _this.get('answer').saveRecord();
            };
          }(this));
        }
        if (forceAdvance) {
          return this.toNext(navigateOnlyWithinGrid, forceAdvance);
        } else {
          Ember.run.later(function (_this) {
            return function () {
              return _this.toNext(navigateOnlyWithinGrid, forceAdvance);
            };
          }(this), 250);
        }
      }
      return false;
    },
    toNext: function toNext(navigateOnlyWithinGrid, forceAdvance) {
      var componentsController, controller, index, isCurrentItemLastWithTitle, items, itemsAfterCurrentWithTitles, itemsBeforeCurrentWithTitles, itemsWithTitles, next;
      this.set('isTransitioning', false);
      index = this.get('gridItems').indexOf(this.get('currentItem'));
      controller = this;
      componentsController = this.get('components');
      items = this.get('items');
      itemsAfterCurrentWithTitles = items.slice(index + 1).filter(function (item) {
        return !(0, _utils.isMissingAnyPipedAnswer)(item.get('text'), componentsController);
      });
      itemsBeforeCurrentWithTitles = navigateOnlyWithinGrid ? [] : items.slice(0, index).filter(function (item) {
        return !(0, _utils.isMissingAnyPipedAnswer)(item.get('text'), componentsController);
      });
      itemsWithTitles = itemsAfterCurrentWithTitles.concat(itemsBeforeCurrentWithTitles);
      next = itemsWithTitles.find(function (item) {
        return !item.get('hasAnswer');
      });
      if (!next) {
        isCurrentItemLastWithTitle = !itemsAfterCurrentWithTitles.objectAt(0);
        if (forceAdvance || !isCurrentItemLastWithTitle) {
          next = itemsWithTitles.objectAt(0);
        }
      }
      if (next) {
        this._setCurrentItem(next.get('content'), 1);
      } else if (!navigateOnlyWithinGrid) {
        if (forceAdvance) {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else {
          this.send('autoAdvance', true);
        }
      }
      return false;
    },
    showPrevious: function showPrevious(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid) {
      var componentsController, controller, index, items, itemsWithTitles, previous, wrapped;
      if (shouldUpdateIsMissingTitle == null) {
        shouldUpdateIsMissingTitle = true;
      }
      if (navigateOnlyWithinGrid == null) {
        navigateOnlyWithinGrid = false;
      }
      if (shouldUpdateIsMissingTitle) {
        this.updateIsMissingTitleInItems();
      }
      if (!this.get('isTransitioning')) {
        this.set('isTransitioning', true);
        if (!_globals.isPreview) {
          this.get('response.creationPromise').then(function (_this) {
            return function () {
              return _this.get('answer').saveRecord();
            };
          }(this));
        }
        this.set('isTransitioning', false);
        index = this.get('gridItems').indexOf(this.get('currentItem'));
        controller = this;
        componentsController = this.get('components');
        items = this.get('items');
        wrapped = navigateOnlyWithinGrid ? [] : items.slice(index + 1);
        itemsWithTitles = wrapped.concat(items.slice(0, index)).filter(function (item) {
          return !(0, _utils.isMissingAnyPipedAnswer)(item.get('text'), componentsController);
        }).reverse();
        previous = itemsWithTitles.find(function (item) {
          return !item.get('hasAnswer');
        });
        previous || (previous = itemsWithTitles.objectAt(0));
        if (previous) {
          this._setCurrentItem(previous.get('content'), -1);
        } else if (!navigateOnlyWithinGrid) {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      return false;
    },
    _getItemControllerName: function _getItemControllerName() {
      var className, hasAnswerDesc;
      className = "presenter/" + this.get('type').decamelize().camelize() + "GridItem";
      if (!(0, _registerController.lookup)(className)) {
        hasAnswerDesc = this.constructor.proto().hasAnswer;
        (0, _registerController.register)(className, Ember.ObjectController.extend(_answerable.default, {
          answer: Ember.computed.oneWay('content.answer'),
          hasAnswer: hasAnswerDesc,
          isCurrent: function () {
            return this.get('parentController.target.currentItem') === this.get('content');
          }.property('parentController.target.currentItem')
        }));
      }
      return className;
    },
    checkConditionsToSkip: function checkConditionsToSkip() {
      return this.skipQuestionIfEmpty() || this.skipQuestionIfMissingTitle();
    },
    skipQuestionIfMissingTitle: function skipQuestionIfMissingTitle() {
      var currentItem, forceAdvance, navigateOnlyWithinGrid, shouldUpdateIsMissingTitle;
      this.updateIsMissingMainTitle();
      if (this.get('isMissingTitle')) {
        if (this.get('components.lastNavigationDirection') === 'next') {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      this.updateIsMissingTitleInItems();
      shouldUpdateIsMissingTitle = false;
      navigateOnlyWithinGrid = false;
      currentItem = this.get('currentItem');
      if (this.isItemMissingTitle(currentItem)) {
        if (this.get('components.lastNavigationDirection') === 'next') {
          forceAdvance = true;
          return this.showNext(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid, forceAdvance);
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          return this.showPrevious(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid);
        }
      }
      return false;
    },
    isItemMissingTitle: function isItemMissingTitle(item) {
      var title;
      title = item.get('text');
      return (0, _utils.isMissingAnyPipedAnswer)(title, this.get('components'));
    },
    actions: {
      autoAdvance: function autoAdvance(nextQuestion) {
        if (this.get('shouldSaveAnswer')) {
          this.get('answer').saveRecord();
        }
        if (nextQuestion) {
          return this._super.apply(this, arguments);
        } else {
          return this.get('animateSelectionPromise').then(function (_this) {
            return function () {
              return _this.showNext();
            };
          }(this));
        }
      },
      forceAutoAdvanceToNextQuestion: function forceAutoAdvanceToNextQuestion() {
        this.saveAnswer();
        return this._super.apply(this, arguments);
      },
      forceAutoAdvanceToPreviousQuestion: function forceAutoAdvanceToPreviousQuestion() {
        this.saveAnswer();
        return this._super.apply(this, arguments);
      }
    }
  });
});
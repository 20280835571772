define("presenter/templates/components/controls/floating-label-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 408
          }
        },
        "moduleName": "presenter/templates/components/controls/floating-label-field.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "theme-text");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "presenter/text-field", [], ["classNames", ["subexpr", "@mut", [["get", "textFieldClassNames", ["loc", [null, [1, 34], [1, 53]]]]], [], []], "aria-label", ["subexpr", "concat", [["get", "label", ["loc", [null, [1, 73], [1, 78]]]], ["subexpr", "if", [["get", "required", ["loc", [null, [1, 83], [1, 91]]]], ["subexpr", "concat", [", ", ["subexpr", "translated-properties", ["presenter", "alerts", "required_field"], [], ["loc", [null, [1, 105], [1, 166]]]]], [], ["loc", [null, [1, 92], [1, 167]]]]], [], ["loc", [null, [1, 79], [1, 168]]]]], [], ["loc", [null, [1, 65], [1, 169]]]], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 176], [1, 181]]]]], [], []], "validated", ["subexpr", "@mut", [["get", "validated", ["loc", [null, [1, 192], [1, 201]]]]], [], []], "validate", ["subexpr", "@mut", [["get", "validate", ["loc", [null, [1, 211], [1, 219]]]]], [], []], "valid", ["subexpr", "@mut", [["get", "valid", ["loc", [null, [1, 226], [1, 231]]]]], [], []], "validationError", ["subexpr", "@mut", [["get", "validationError", ["loc", [null, [1, 248], [1, 263]]]]], [], []], "updateFieldProperty", "updateFieldProperty", "shouldClearPrepopulatedValue", ["subexpr", "@mut", [["get", "shouldClearPrepopulatedValue", ["loc", [null, [1, 335], [1, 363]]]]], [], []]], ["loc", [null, [1, 0], [1, 365]]]], ["content", "label", ["loc", [null, [1, 391], [1, 400]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/invoice', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Invoice;

  Invoice = _data.Model.extend({
    id: (0, _data.attr)('string'),
    date: (0, _data.attr)('date'),
    amount: (0, _data.attr)('number'),
    paid: (0, _data.attr)('boolean'),
    htmlUrl: (0, _data.attr)('string'),
    pdfUrl: (0, _data.attr)('string')
  });

  Invoice.reopenClass({
    resourceName: 'invoice'
  });

  exports.default = Invoice;
});
define("presenter/templates/svg/question_types/_thank_you_page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_thank_you_page.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M62 13H48.9c1.3-1.3 2.1-3 2.1-5 0-3.9-3.1-7-7-7-6.8 0-10.4 6.2-12 10-1.6-3.8-5.2-10-12-10-3.9 0-7 3.1-7 7 0 2 .8 3.7 2.1 5H2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h60c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1zM44 3c2.8 0 5 2.2 5 5s-2.2 5-5 5H33.4c1-2.9 4.2-10 10.6-10zM15 8c0-2.8 2.2-5 5-5 6.4 0 9.6 7.1 10.6 10H20c-2.8 0-5-2.2-5-5zM57 25H36v36h17c2.2 0 4-1.8 4-4V25zM28 25H7v32c0 2.2 1.8 4 4 4h17V25z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
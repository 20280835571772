define('presenter/components/bourbon-svg--upgrade-badge', ['exports', 'bourbon/components/bourbon-svg--upgrade-badge'], function (exports, _bourbonSvgUpgradeBadge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bourbonSvgUpgradeBadge.default;
    }
  });
});
define('presenter/components/inline-select-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['InlineSelect-dropdownMenu-item'],
    classNameBindings: ['selected', 'enabled::disabled'],
    optionLabelPath: null,
    optionValuePath: null,
    optionEnabledPath: null,
    selectedOption: null,
    model: null,
    label: function () {
      var labelPath, ref;
      labelPath = this.get('optionLabelPath');
      if (labelPath && Ember.typeOf(this.get('model')) === 'object') {
        return this.get('model')[labelPath] || '&nbsp;'.htmlSafe();
      } else if (labelPath) {
        return ((ref = this.get('model')) != null ? ref.get(labelPath) : void 0) || '&nbsp;'.htmlSafe();
      } else {
        return this.get('model');
      }
    }.property('optionLabelPath'),
    value: function () {
      var ref, valuePath;
      if (valuePath = this.get('optionValuePath')) {
        return (ref = this.get('model')) != null ? ref.get(valuePath) : void 0;
      } else {
        return this.get('model');
      }
    }.property('optionValuePath'),
    selected: function () {
      return this.get('model') === this.get('selectedOption');
    }.property('model', 'selectedOption'),
    enabled: function () {
      var enabledPath;
      return !(enabledPath = this.get('optionEnabledPath')) || this.get('model').get(enabledPath);
    }.property('optionEnabledPath'),
    actions: {
      select: function select(m) {
        return this.sendAction('select', m);
      }
    }
  });
});
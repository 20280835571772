define('presenter/models/theme', ['exports', 'presenter/data', 'presenter/mixins/themeable'], function (exports, _data, _themeable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Theme;

  Theme = _data.Model.extend(_themeable.default.ContrastProperties, {
    name: (0, _data.attr)('string'),
    category: (0, _data.attr)('string'),
    font: (0, _data.attr)('string', {
      defaultValue: 'Open Sans'
    }),
    questionColor: (0, _data.attr)('color', {
      defaultValue: 0x000000
    }),
    answerColor: (0, _data.attr)('color', {
      defaultValue: 0x000000
    }),
    buttonColor: (0, _data.attr)('color', {
      defaultValue: 0xEEEEEE
    }),
    backgroundDisplay: (0, _data.attr)('string', {
      defaultValue: 'fill'
    }),
    backgroundColor: (0, _data.attr)('color', {
      defaultValue: 0xFFFFFF
    }),
    backgroundImage: (0, _data.belongsTo)('image', {
      serialize: 'id'
    }),
    backgroundImageBrightness: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    backgroundType: (0, _data.attr)('string')
  });

  Theme.reopenClass({
    resourceName: 'theme'
  });

  exports.default = Theme;
});
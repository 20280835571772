define("presenter/templates/presenter/customer-effort-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 91
            },
            "end": {
              "line": 1,
              "column": 297
            }
          },
          "moduleName": "presenter/templates/presenter/customer-effort-question.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/cs-rating", [], ["rating", ["subexpr", "@mut", [["get", "rating", ["loc", [null, [1, 162], [1, 168]]]]], [], []], "aria-label", ["subexpr", "translated-properties", ["presenter.labels", "ces", ["get", "rating.text", ["loc", [null, [1, 228], [1, 239]]]]], [], ["loc", [null, [1, 180], [1, 240]]]], "role", "button", "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 262], [1, 278]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 286], [1, 295]]]]], [], []]], ["loc", [null, [1, 133], [1, 297]]]]],
        locals: ["rating"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 312
          }
        },
        "moduleName": "presenter/templates/presenter/customer-effort-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["Cs-ratings Cs-ratings--ces theme-answer ", ["subexpr", "if", [["get", "component.reverse", ["loc", [null, [1, 57], [1, 74]]]], "is-reverse"], [], ["loc", [null, [1, 52], [1, 89]]]]]]], ["block", "each", [["get", "component.cesRatings", ["loc", [null, [1, 99], [1, 119]]]]], [], 0, null, ["loc", [null, [1, 91], [1, 306]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
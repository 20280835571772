define('presenter/models/pardot-object', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PardotObject;

  PardotObject = _data.Model.extend({
    name: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    createable: (0, _data.attr)('boolean'),
    updateable: (0, _data.attr)('boolean'),
    fields: (0, _data.hasMany)('pardot_field', {
      serialize: false
    }),
    fieldsByName: Ember.computed.indexBy('fields', 'name'),
    normalizedLabel: Ember.computed.alias('label')
  });

  PardotObject.reopenClass({
    resourceName: 'pardot_object',
    useModelKey: true
  });

  exports.default = PardotObject;
});
define('presenter/controllers/presenter/component', ['exports', 'presenter/utils', 'presenter/mixins/answerable', 'presenter/mixins/presenter/grid-controller-helper', 'presenter/globals', 'presenter/models/answer', 'presenter/mixins/presenter/rules-evaluator'], function (exports, _utils, _answerable, _gridControllerHelper, _globals, _answer, _rulesEvaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend(_answerable.default, _rulesEvaluator.default, {
    components: Ember.inject.controller('presenter/components'),
    response: Ember.inject.controller('presenter/response'),
    survey: Ember.inject.controller('presenter/survey'),
    useRichText: Ember.computed.oneWay('survey.useRichText'),
    isChanged: true,
    isMissingTitle: false,
    hasEmbeddedAnswer: Ember.computed.and('isEmbeddedAuto', 'hasAnswer', 'answer.embedded'),
    refreshChangeState: function refreshChangeState() {
      return this.set('isChanged', false);
    },
    index: function () {
      return this.get('components').indexOf(this);
    }.property('components.[]'),
    evaluateRules: function evaluateRules(preconditionsOnly) {
      var actions, jump, rest;
      actions = this._evaluateRules(preconditionsOnly);
      jump = actions.findBy('type', 'jump');
      rest = actions.filter(function (action) {
        return action.get('type') !== 'jump';
      });
      rest.forEach(function (actionController) {
        return actionController.evaluate();
      });
      if (jump) {
        return jump.evaluate();
      }
    },
    possibleNextComponents: Ember.computed('index', 'components.[]', 'rulesController.[]', function () {
      var allActions, next;
      next = this.get('components.content').objectAt(this.get('index') + 1);
      allActions = this.get('rulesController').mapBy('actionsController').reduce(function (acc, actions) {
        return acc.concat(actions.toArray());
      }, []);
      return [next].concat(allActions.mapBy('jumpTarget')).compact();
    }),
    hasDescription: Ember.computed.and('showDescription', 'description'),
    hasMedia: Ember.computed.and('showMedia', 'mediaObject'),
    hasTopMedia: Ember.computed.and('showTopMedia', 'topMediaObject'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('hasGridItems')) {
        return this.reopen(_gridControllerHelper.default);
      }
    },
    createAnswer: function createAnswer(response) {
      var answer;
      if (this.get('answer')) {
        return;
      }
      answer = _answer.default.create({
        response: response,
        component: this.get('content'),
        identityMap: this.get('content.identityMap')
      });
      return this.set('answer', answer);
    },
    saveAnswer: function saveAnswer() {
      var promise;
      if (this.get('shouldSaveAnswer')) {
        promise = this.get('response.creationPromise').then(function (_this) {
          return function () {
            if (_this.get('type') !== 'CoverPage') {
              return _this.get('response').start();
            }
          };
        }(this)).then(function (_this) {
          return function () {
            return _this.get('answer').saveRecord();
          };
        }(this))["catch"](function (_this) {
          return function (error) {
            if (error != null ? error.duplicate_response : void 0) {
              return window.location.reload();
            }
          };
        }(this));
        promise.answer = this.get('answer');
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    },
    answerChanged: function () {
      if (this.get('answer.response')) {
        return this.get('answer.response').trigger('answerChange');
      }
    }.observes('answer.{text,number,formFields.@each.text,choices.@each.text,rankedChoices.@each.position}'),
    completed: function () {
      return !this.get('answer') || this.get('hasAnswer') || !this.get('required') || this.get('isMissingTitle');
    }.property('answer', 'hasAnswer', 'required', 'isMissingTitle'),
    animateSelection: function () {
      return Ember.RSVP.defer();
    }.property('components.currentComponentController.answer'),
    animateSelectionPromise: Ember.computed.oneWay('animateSelection.promise'),
    isCurrent: Ember.computed.equal('side', 0),
    side: function () {
      var currentIndex, index;
      index = this.get('index');
      currentIndex = this.get('components.currentComponentController.index');
      if (index < currentIndex) {
        return -1;
      } else if (index > currentIndex) {
        return 1;
      } else {
        return 0;
      }
    }.property('index', 'components.currentComponentController.index'),
    isReadOnly: (0, _globals.presenterFlag)('isReadOnly'),
    showTips: !(0, _globals.presenterFlag)('isReadOnly'),
    shouldAutoAdvance: Ember.computed.not('components.onLastQuestion'),
    pipingAnswer: function pipingAnswer() {
      return null;
    },
    isMissingPipingAnswer: function isMissingPipingAnswer(itemIndex) {
      if (itemIndex == null) {
        itemIndex = null;
      }
      return !Ember.isPresent(this.pipingAnswer(itemIndex));
    },
    computeFilteredChoices: function computeFilteredChoices(choices) {
      var componentsController, controller, filtered;
      controller = this;
      componentsController = this.get('components');
      filtered = choices.filter(function (choice) {
        var missing;
        missing = (0, _utils.isMissingAnyPipedAnswer)(choice.get('text'), componentsController);
        if (missing) {
          controller.handleMissingChoice(choice);
        }
        return !missing;
      });
      controller.onComputeFilteredChoices(filtered);
      return filtered;
    },
    handleMissingChoice: function handleMissingChoice() {
      return void 0;
    },
    clearAnswer: function clearAnswer() {
      return void 0;
    },
    onComputeFilteredChoices: function onComputeFilteredChoices() {
      return void 0;
    },
    checkConditionsToSkip: function checkConditionsToSkip() {
      return this.skipQuestionIfMissingTitle() || this.skipQuestionIfEmpty() || this.reinitialize();
    },
    skipQuestionIfEmpty: function skipQuestionIfEmpty() {
      return false;
    },
    skipQuestionIfMissingTitle: function skipQuestionIfMissingTitle() {
      var isMissingTitle;
      isMissingTitle = (0, _utils.isMissingAnyPipedAnswer)(this.get('title'), this.get('components'));
      this.set('isMissingTitle', isMissingTitle);
      if (isMissingTitle) {
        this.clearAnswer();
        if (this.get('components.lastNavigationDirection') === 'next') {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      return false;
    },
    reinitialize: function reinitialize() {
      return false;
    },
    actions: {
      advance: function advance(disableNavigation, disableHistory, forceNavigation) {
        if (disableNavigation == null) {
          disableNavigation = false;
        }
        if (disableHistory == null) {
          disableHistory = false;
        }
        if (forceNavigation == null) {
          forceNavigation = false;
        }
        if (this.get('completed')) {
          return this.get('components').send('next', disableNavigation, disableHistory, forceNavigation);
        }
      },
      autoAdvance: function autoAdvance() {
        return this.get('animateSelectionPromise').then(function (_this) {
          return function () {
            if (_this.get('shouldAutoAdvance')) {
              return _this.send('advance', true);
            }
          };
        }(this));
      },
      forceAutoAdvanceToNextQuestion: function forceAutoAdvanceToNextQuestion() {
        var disableHistory, disableNavigation, forceNavigation;
        if (this.get('shouldAutoAdvance')) {
          disableNavigation = true;
          disableHistory = false;
          forceNavigation = true;
          return this.send('advance', disableNavigation, disableHistory, forceNavigation);
        }
      },
      forceAutoAdvanceToPreviousQuestion: function forceAutoAdvanceToPreviousQuestion() {
        var forceNavigation;
        forceNavigation = true;
        return this.get('components').send('previous', forceNavigation);
      }
    }
  });
});
define('presenter/mixins/resize-handler', ['exports', 'presenter/utils/layout'], function (exports, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    resizeEndDelay: 100,
    resizing: false,
    onResizeStart: Ember.K,
    onResizeEnd: Ember.K,
    onResize: Ember.K,
    resizeEnabled: true,
    observeEnabled: function () {
      if (this.get('resizeEnabled')) {
        return this._enabled();
      } else {
        return this._disabled();
      }
    }.observes('resizeEnabled'),
    _debounceResizeEnd: function () {
      return debounce(function (_this) {
        return function (event) {
          if (_this.get('isDestroyed')) {
            return;
          }
          _this.set('resizing', false);
          return typeof _this.onResizeEnd === "function" ? _this.onResizeEnd(event) : void 0;
        };
      }(this), this.get('resizeEndDelay'));
    }.property('resizeEndDelay'),
    handleWindowResize: function handleWindowResize(event) {
      if (!this.get('resizing')) {
        this.set('resizing', true);
        if (typeof this.onResizeStart === "function") {
          this.onResizeStart(event);
        }
      }
      if (typeof this.onResize === "function") {
        this.onResize(event);
      }
      return this.get('_debounceResizeEnd')(event);
    },
    _enabled: function () {
      this._resizeHandler = function (_this) {
        return function () {
          if (!_this.get('isDestroyed')) {
            return _this.handleWindowResize();
          }
        };
      }(this);
      Ember.$(window).on('resize orientationchange', this._resizeHandler);
      return _layout.default.on('resize', this._resizeHandler);
    }.on('didInsertElement'),
    _disabled: function () {
      Ember.$(window).off('resize orientationchange', this._resizeHandler);
      return _layout.default.off('resize', this._resizeHandler);
    }.on('willDestroyElement')
  });
});
define('presenter/components/presenter/restart-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      return Ember.run.next(function (_this) {
        return function () {
          return _this.$().addClass('show');
        };
      }(this));
    }
  });
});
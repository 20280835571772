define('presenter/mixins/cs-questions-helper', ['exports', 'presenter/models/component', 'i18n-js'], function (exports, _component, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cesRatings: function () {
      var values;
      values = _component.default.CES_VALUES.slice();
      if (this.get('reverse')) {
        values.reverse();
      }
      return values.map(function (rating) {
        var transformed;
        transformed = Ember.Object.create(rating);
        transformed.set('translationPath', "presenter.labels.ces." + transformed.get('text').underscore());
        return transformed;
      });
    }.property('reverse'),
    csatRatings: function () {
      var values;
      values = _component.default.CSAT_VALUES.slice();
      if (this.get('reverse')) {
        values.reverse();
      }
      return values.map(function (rating) {
        var transformed;
        transformed = Ember.Object.create(rating);
        transformed.set('translationPath', "presenter.labels.csat." + transformed.get('text').underscore());
        return transformed;
      });
    }.property('reverse'),
    locale: function () {
      return _i18nJs.default.locale;
    }.property('survey.{currentLanguage.locale,language}'),
    reverse: Ember.computed.oneWay('model.reverse')
  });
});
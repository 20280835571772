define('presenter/controllers/presenter/multiple-choice', ['exports', 'i18n-js', 'presenter/controllers/presenter/component', 'presenter/mixins/randomizable-choices', 'presenter/models/component', 'presenter/globals'], function (exports, _i18nJs, _component, _randomizableChoices, _component2, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_randomizableChoices.default, {
    hasAnswer: Ember.computed.gt('answer.choices.length', 0),
    existingComment: '',
    visibleChoicesCount: null,
    _initAnswerChoices: function () {
      if (!(this.get('answer') && this.get('commentableEnabled'))) {
        return;
      }
      return this.get('answer.choices').forEach(function (_this) {
        return function (choice) {
          var questionChoice;
          if ((questionChoice = _this.get('choices').findBy('id', choice.get('id'))) && questionChoice.get('commentEnabled')) {
            if (!choice.get('commentEnabled')) {
              return choice.set('commentEnabled', true);
            }
          }
        };
      }(this));
    }.observes('answer', 'commentableEnabled'),
    showComment: function () {
      var ref;
      if (!this.get('commentableEnabled')) {
        return;
      }
      return !!((ref = this.get('answer.choices')) != null ? ref.findBy('commentEnabled') : void 0);
    }.property('answer.choices.[]', 'answer.choices.@each.commentEnabled'),
    shouldAutoAdvance: function () {
      var answerChoices, defaultBehavior, maxAnswers, multipleAnswers, noCommentShown, visibleChoicesCount;
      defaultBehavior = this._super.apply(this, arguments);
      if (!defaultBehavior) {
        return false;
      }
      if (this.get('isMissingTitle')) {
        return true;
      }
      noCommentShown = !this.get('showComment');
      maxAnswers = this.get('maxAnswers');
      answerChoices = this.get('answer.choices');
      multipleAnswers = this.get('multipleAnswers');
      if (noCommentShown) {
        visibleChoicesCount = this.get('visibleChoicesCount');
        if (visibleChoicesCount !== null && visibleChoicesCount === 0) {
          return true;
        }
        if (answerChoices) {
          return answerChoices.get('length') > 0 && !multipleAnswers || Number.isFinite(maxAnswers) && answerChoices.get('length') >= maxAnswers || visibleChoicesCount !== null && answerChoices.get('length') >= visibleChoicesCount;
        }
      }
      return false;
    }.property('showComment', 'hasAnswer', 'maxAnswers', 'answer.choices.[]', 'multipleAnswers', 'visibleChoicesCount', 'isMissingTitle'),
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.choices.length'),
    _handleExistingComment: function () {
      if (this.get('showComment')) {
        if (!Ember.isEmpty(this.get('existingComment'))) {
          return this.set('answer.comment', this.get('existingComment'));
        }
      } else {
        this.set('existingComment', this.get('answer.comment'));
        return this.set('answer.comment', null);
      }
    }.observes('showComment'),
    required: function () {
      if (!this.get('content.required') || Ember.isEmpty(this.get('choices'))) {
        return false;
      }
      return !(Ember.isPresent(this.get('visibleChoicesCount')) && this.get('visibleChoicesCount') === 0);
    }.property('content.required', 'choices.[]', 'visibleChoicesCount'),
    pipingAnswerRaw: function pipingAnswerRaw(itemIndex) {
      var item;
      if (itemIndex == null) {
        itemIndex = null;
      }
      if (!this.get('answer.choices')) {
        return null;
      }
      if (itemIndex !== null) {
        item = this.get('answer.choices').objectAt(itemIndex);
        if (!item) {
          return null;
        }
        return _i18nJs.default.t("choices." + item.get('id') + ".text");
      } else {
        return this.get('answer.choices').map(function (_this) {
          return function (choice) {
            if (choice.get('isOther')) {
              return _this.get('answer.text');
            } else {
              return _i18nJs.default.t("choices." + choice.get('id') + ".text");
            }
          };
        }(this)).join(', ');
      }
    },
    pipingAnswer: function pipingAnswer(itemIndex) {
      var piped;
      if (itemIndex == null) {
        itemIndex = null;
      }
      piped = this.pipingAnswerRaw(itemIndex);
      if (piped === null) {
        return 'N/A';
      } else {
        return piped;
      }
    },
    isMissingPipingAnswer: function isMissingPipingAnswer(itemIndex) {
      var piped;
      if (itemIndex == null) {
        itemIndex = null;
      }
      piped = this.pipingAnswerRaw(itemIndex);
      return !Ember.isPresent(piped);
    },
    handleMissingChoice: function handleMissingChoice(choice) {
      var choiceToRemove, selectedChoices;
      if (!this.get('answer')) {
        return;
      }
      selectedChoices = this.get('answer.choices');
      this.beginPropertyChanges();
      if (choiceToRemove = selectedChoices.findBy('id', choice.get('id'))) {
        selectedChoices.removeObject(choiceToRemove);
      }
      return this.endPropertyChanges();
    },
    onComputeFilteredChoices: function onComputeFilteredChoices(filteredChoices) {
      return this.set('visibleChoicesCount', filteredChoices.length);
    },
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      this.beginPropertyChanges();
      this.get('answer.choices').clear();
      this.set('answer.comment', null);
      return this.endPropertyChanges();
    },
    completed: function () {
      var actualMin;
      if (!this._super()) {
        return false;
      }
      if (this.get('hasGridItems') || Ember.isEmpty(this.get('minAnswers')) || this.get('isMissingTitle')) {
        return true;
      }
      actualMin = this.get('visibleChoicesCount') === null ? this.get('minAnswers') : Math.min(this.get('visibleChoicesCount'), this.get('minAnswers'));
      return this.get('choices.length') < this.get('minAnswers') || this.get('answer.choices.length') >= actualMin;
    }.property('answer.choices.[]', 'hasGridItems', 'minAnswers', 'choices.[]', 'visibleChoicesCount', 'answer', 'hasAnswer', 'required', 'isMissingTitle'),
    minMaxQuestionTip: Ember.computed('minAnswers', 'maxAnswers', 'multipleAnswers', 'response.model.language', 'visibleChoicesCount', function () {
      var maxAnswers, minAnswers;
      this.get('response.model.language');
      if (this.get('multipleAnswers')) {
        minAnswers = Number.isFinite(this.get('minAnswers')) && this.get('visibleChoicesCount') !== null ? Math.min(this.get('visibleChoicesCount'), this.get('minAnswers')) : this.get('minAnswers');
        maxAnswers = Number.isFinite(this.get('maxAnswers')) && this.get('visibleChoicesCount') !== null ? Math.min(this.get('visibleChoicesCount'), this.get('maxAnswers')) : this.get('maxAnswers');
        if (Number.isFinite(minAnswers) && minAnswers === maxAnswers) {
          return _i18nJs.default.t('presenter.tips.exact_number_of_answers', {
            number: minAnswers
          });
        } else if (Number.isFinite(minAnswers) && !Number.isFinite(maxAnswers) && minAnswers !== 1 && minAnswers !== 0) {
          return _i18nJs.default.t('presenter.tips.min_answers', {
            min: minAnswers
          });
        } else if (Number.isFinite(maxAnswers) && !Number.isFinite(minAnswers)) {
          return _i18nJs.default.t('presenter.tips.max_answers', {
            max: maxAnswers
          });
        } else if (Number.isFinite(minAnswers) && Number.isFinite(maxAnswers) && maxAnswers !== minAnswers) {
          return _i18nJs.default.t('presenter.tips.min_max_answers', {
            min: minAnswers,
            max: maxAnswers
          });
        } else {
          return _i18nJs.default.t('presenter.tips.multi_answer');
        }
      }
    }),
    _setChangedForComment: function () {
      return this.set('isChanged', true);
    }.observes('answer.comment'),
    skipQuestionIfEmpty: function skipQuestionIfEmpty() {
      this.computeFilteredChoices(this.get('displayChoices'));
      if (this.get('visibleChoicesCount') !== null && this.get('visibleChoicesCount') === 0) {
        if (this.get('components.lastNavigationDirection') === 'next') {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      return false;
    },
    actions: {
      toggle: function toggle(choice) {
        var choiceToRemove, choices, maxAnswers;
        if (!this.get('answer')) {
          return;
        }
        choices = this.get('answer.choices');
        maxAnswers = this.get('maxAnswers');
        this.beginPropertyChanges();
        if (choiceToRemove = choices.findBy('id', choice.get('id'))) {
          choices.removeObject(choiceToRemove);
        } else {
          if (!this.get('multipleAnswers')) {
            choices.clear();
          }
          if (Ember.isEmpty(maxAnswers) || choices.get('length') < maxAnswers) {
            choices.addObject(choice);
          }
          if ((0, _globals.presenterFlag)('isDrawer') && !Modernizr.touch && this.get('commentableEnabled') && choice.get('commentEnabled')) {
            this.send('setNeedsScroll', true);
          }
        }
        this.endPropertyChanges();
        if (!choice.get('isOther')) {
          return this.send('autoAdvance');
        }
      }
    }
  });
});
define('presenter/components/presenter/redirect-component', ['exports', 'presenter/components/presenter/component', 'presenter/globals'], function (exports, _component, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    typeClass: 'Redirect',
    isIframed: _globals.isIframed
  });
});
define('presenter/mixins/restrictable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    restrictNumeric: false,
    restrictMin: null,
    restrictMax: null,
    init: function init() {
      this._super();
      return this.on('keyPress', this, this._checkRestrictedKey);
    },
    _checkRestrictedKey: function _checkRestrictedKey(event) {
      var char, max, min, value;
      if (!this.get('restrictNumeric')) {
        return;
      }
      if (event.metaKey || event.ctrlKey) {
        return;
      }
      if (event.which === 32) {
        return event.preventDefault();
      }
      if (event.which === 0) {
        return;
      }
      if (event.which < 33) {
        return;
      }
      if (!/\d/.test(char = String.fromCharCode(event.which))) {
        return event.preventDefault();
      }
      min = this.get('restrictMin');
      max = this.get('restrictMax');
      if (typeof min === 'number' || typeof max === 'number') {
        value = parseInt(this.get('value').toString().splice(char, event.target.selectionStart, event.target.selectionEnd), 10);
        if (typeof min === 'number' && value < min || typeof max === 'number' && value > max) {
          return event.preventDefault();
        }
      }
    }
  });
});
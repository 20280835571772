define('presenter/models/translation', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Translation;

  Translation = _data.Model.extend({
    language: (0, _data.attr)('string'),
    labelPath: (0, _data.attr)('string'),
    text: (0, _data.attr)('string'),
    component: (0, _data.belongsTo)('component'),
    choice: (0, _data.belongsTo)('choice'),
    gridItem: (0, _data.belongsTo)('gridItem')
  });

  Translation.reopenClass({
    resourceName: 'translation'
  });

  exports.default = Translation;
});
define('presenter/mixins/randomizable-choices', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    displayChoices: function () {
      var choices, other;
      if (other = this.get('otherChoice')) {
        choices = this.get('choices').without(other);
      } else {
        choices = this.get('choices.content').copy();
      }
      if (this.get('randomize')) {
        choices = choices.shuffle();
      }
      if (other) {
        choices.pushObject(other);
      }
      return choices;
    }.property('randomized', 'choices', 'answer.rankedChoices.length')
  });
});
define('presenter/utils/layout', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /* global Modernizr */
  var NATIVE_LAYOUT_WIDTH;

  NATIVE_LAYOUT_WIDTH = _globals.codeSettings.componentMaxWidth;

  exports.default = Ember.Object.extend(Ember.Evented, {
    viewportWidthScale: 1,
    viewportWidthMax: null,
    setViewportScale: function setViewportScale() {
      var scale, width;
      if (typeof Modernizr !== "undefined" && Modernizr !== null ? Modernizr.mobile : void 0) {
        if (Modernizr.tablet) {
          width = Math.max($(window).width(), $(window).height());
        } else {
          width = Math.min($(window).width(), $(window).height());
        }
        scale = Math.min(1, width / NATIVE_LAYOUT_WIDTH);
        scale *= window.devicePixelRatio || 1;
        this.set('viewportWidthScale', scale);
        return this.set('viewportWidthMax', width);
      }
    },
    resize: function resize() {
      return this.trigger('resize');
    },
    dashboardResize: function dashboardResize() {
      return this.trigger('dashboardResize');
    },
    currentPathChanged: function currentPathChanged() {
      return this.trigger('currentPathChanged');
    }
  }).create();
});
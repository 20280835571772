define("presenter/templates/presenter/key-driver-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 401
              },
              "end": {
                "line": 1,
                "column": 847
              }
            },
            "moduleName": "presenter/templates/presenter/key-driver-question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "KeyDriver Rating-labels js-labels");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "KeyDriver Rating-minLabel js-label theme-question");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "KeyDriver Rating-maxLabel js-label theme-question");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 615], [1, 633]]]], "minimumLabel"], [], ["loc", [null, [1, 579], [1, 649]]]]], [], ["loc", [null, [1, 556], [1, 650]]]]], [], ["loc", [null, [1, 538], [1, 652]]]], ["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 798], [1, 816]]]], "maximumLabel"], [], ["loc", [null, [1, 762], [1, 832]]]]], [], ["loc", [null, [1, 739], [1, 833]]]]], [], ["loc", [null, [1, 721], [1, 835]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 373
            },
            "end": {
              "line": 1,
              "column": 854
            }
          },
          "moduleName": "presenter/templates/presenter/key-driver-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.hasLabels", ["loc", [null, [1, 407], [1, 426]]]]], [], 0, null, ["loc", [null, [1, 401], [1, 854]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 871
          }
        },
        "moduleName": "presenter/templates/presenter/key-driver-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "keyDriver");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "Rating-wrapper");
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "role", "heading");
        dom.setAttribute(el2, "aria-level", "1");
        dom.setAttribute(el2, "for", "keyDriver");
        dom.setAttribute(el2, "id", "keyDriverLabel");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [0]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element1, 'min');
        morphs[1] = dom.createAttrMorph(element1, 'max');
        morphs[2] = dom.createAttrMorph(element1, 'value');
        morphs[3] = dom.createAttrMorph(element1, 'aria-valuemin');
        morphs[4] = dom.createAttrMorph(element1, 'aria-valuemax');
        morphs[5] = dom.createAttrMorph(element2, 'aria-label');
        morphs[6] = dom.createMorphAt(element1, 1, 1);
        morphs[7] = dom.createMorphAt(element1, 2, 2);
        return morphs;
      },
      statements: [["attribute", "min", ["get", "component.model.startAt", ["loc", [null, [1, 41], [1, 64]]]]], ["attribute", "max", ["get", "component.model.number", ["loc", [null, [1, 73], [1, 95]]]]], ["attribute", "value", ["get", "value", ["loc", [null, [1, 106], [1, 111]]]]], ["attribute", "aria-valuemin", ["get", "component.model.startAt", ["loc", [null, [1, 130], [1, 153]]]]], ["attribute", "aria-valuemax", ["get", "component.model.number", ["loc", [null, [1, 172], [1, 194]]]]], ["attribute", "aria-label", ["get", "component.ariaLabel", ["loc", [null, [1, 270], [1, 289]]]]], ["inline", "partial", ["survey/key_driver_scale"], [], ["loc", [null, [1, 336], [1, 373]]]], ["block", "if", [["get", "component.showLabels", ["loc", [null, [1, 379], [1, 399]]]]], [], 0, null, ["loc", [null, [1, 373], [1, 861]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
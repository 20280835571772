define('presenter/models/default-page', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    isDefault: true,
    isBranded: Ember.computed.not('survey.whitelabeled'),
    description: function () {
      if (this.get('isBranded') && _i18nJs.default.locale === 'en') {
        return "This survey was made with getfeedback.com, a modern online survey\napplication that allows anyone to create engaging, branded,\nmobile-ready surveys in minutes";
      } else {
        return null;
      }
    }.property('isBranded')
  });
});
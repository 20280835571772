define('presenter/models/choice', ['exports', 'i18n-js', 'presenter/data', 'presenter/utils', 'presenter/models/choice-stats'], function (exports, _i18nJs, _data, _utils, _choiceStats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Choice,
      hasProp = {}.hasOwnProperty;

  Choice = _data.Model.extend({
    position: (0, _data.attr)('number', {
      positionIn: 'component.choices'
    }),
    text: (0, _data.attr)('string'),
    value: (0, _data.attr)('string'),
    pardotValue: (0, _data.attr)('string'),
    isOther: (0, _data.attr)('boolean'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    customDropdownList: (0, _data.belongsTo)('custom_dropdown_list', {
      serialize: 'id'
    }),
    mediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    stats: (0, _data.belongsTo)('choice_stats', {
      readOnly: true,
      defaultValue: function defaultValue() {
        return _choiceStats.default.create();
      }
    }),
    answer: (0, _data.belongsTo)('answer'),
    translations: (0, _data.hasMany)('translation', {
      serialize: true
    }),
    formFieldType: (0, _data.attr)('string'),
    listType: (0, _data.attr)('string'),
    mappableFieldTypes: (0, _data.attr)('raw', {
      readOnly: true
    }),
    shouldHideMergeField: (0, _data.attr)('boolean'),
    required: (0, _data.attr)('boolean'),
    hasMatchingRuleCriteria: (0, _data.attr)('boolean'),
    commentEnabled: (0, _data.attr)('boolean'),
    salesforceField: (0, _data.attr)('raw'),
    cmListKey: (0, _data.attr)('string'),
    isNotOther: Ember.computed.not('isOther'),
    isNotDate: Ember.computed.not('isDate'),
    listOptionsNotEmpty: function () {
      return this.get('isList') && this.get('listOptions.length') > 0;
    }.property('listOptions'),
    survey: Ember.computed.oneWay('component.survey'),
    didLoad: function didLoad() {
      if (!this.get('isFormType')) {
        return;
      }
      if (this.get('required') === void 0 || this.get('required') === false && this.get('component.required') === true) {
        return this.set('required', this.get('component.required'));
      }
    },
    onDeleted: function onDeleted() {
      this._super.apply(this, arguments);
      if (this.get('component.type').match(/Form|SalesforceForm/)) {
        return this.get('survey.salesforceObjectMappings').forEach(function (_this) {
          return function (objectMapping) {
            if (objectMapping.get('fieldMappingsByChoice').has(_this)) {
              return objectMapping.reloadRecord();
            }
          };
        }(this));
      }
    },
    listOptions: function () {
      var browserLocale, countries, countriesLocale, countryOptions, currentFieldType, currentListType, customOptions, fieldType, label, retrievedCustomOptions, stateOptions, states, statesLocale, value;
      if (!this.get('isList')) {
        return;
      }
      currentFieldType = this.get('formFieldType');
      currentListType = this.get('listType');
      if (currentFieldType === 'list' && currentListType === 'custom') {
        fieldType = {
          value: {
            fieldType: 'list',
            listType: 'custom'
          }
        };
      } else {
        fieldType = Choice.FORM_FIELD_TYPES.find(function (type) {
          return currentFieldType === type.value.fieldType && currentListType === type.value.listType;
        });
      }
      switch (fieldType.value.listType) {
        case 'us_states':
          statesLocale = _i18nJs.default.translations[_i18nJs.default.locale].states ? _i18nJs.default.locale : 'en';
          states = _i18nJs.default.translations[statesLocale].us_states;
          stateOptions = [];
          for (value in states) {
            if (!hasProp.call(states, value)) continue;
            label = states[value];
            stateOptions.push({
              label: label,
              value: label
            });
          }
          return Ember.ArrayController.create({
            content: stateOptions,
            sortProperties: ['label'],
            sortAscending: true
          });
        case 'countries':
          countriesLocale = _i18nJs.default.translations[_i18nJs.default.locale].countries ? _i18nJs.default.locale : 'en';
          browserLocale = (0, _utils.userLanguage)().split('-')[1] || '';
          countries = _i18nJs.default.translations[countriesLocale].countries;
          countryOptions = [];
          for (value in countries) {
            if (!hasProp.call(countries, value)) continue;
            label = countries[value];
            countryOptions.push({
              label: label,
              value: value,
              currentLocale: value.toLowerCase() === browserLocale.toLowerCase() ? 'a' : 'b'
            });
          }
          return Ember.ArrayController.create({
            content: countryOptions,
            sortProperties: ['currentLocale', 'label'],
            sortAscending: true
          });
        case 'custom':
          customOptions = [];
          if (this.get('customDropdownList.list')) {
            retrievedCustomOptions = this.get('customDropdownList.list');
            retrievedCustomOptions.map(function (label) {
              return customOptions.push({
                label: label,
                value: label
              });
            });
          }
          return Ember.ArrayController.create({
            content: customOptions
          });
      }
    }.property()
  });

  Choice.FORM_FIELD_TYPES = [{
    label: 'Text',
    value: {
      fieldType: 'text'
    }
  }, {
    label: 'Number',
    value: {
      fieldType: 'number'
    }
  }, {
    label: 'Email',
    value: {
      fieldType: 'email'
    }
  }, {
    label: 'Phone',
    value: {
      fieldType: 'phone'
    }
  }, {
    label: 'URL',
    value: {
      fieldType: 'url'
    }
  }, {
    label: 'Date',
    value: {
      fieldType: 'date'
    }
  }, {
    label: 'US States',
    value: {
      fieldType: 'list',
      listType: 'us_states'
    }
  }, {
    label: 'Countries',
    value: {
      fieldType: 'list',
      listType: 'countries'
    }
  }];

  Choice.reopenForEach(Choice.FORM_FIELD_TYPES, function (type, Model) {
    return Model["is" + type.value.fieldType.camelize().titleize()] = Ember.computed.equal('formFieldType', type.value.fieldType);
  });

  Choice.reopenClass({
    resourceName: 'choice',
    deleteIds: function deleteIds(ids) {
      return Ember.get(this, 'adapter').request({
        model: Choice.create(),
        params: {
          type: 'DELETE'
        },
        key: ids
      });
    }
  });

  exports.default = Choice;
});
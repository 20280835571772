define('presenter/models/campaign-monitor-list', ['exports', 'presenter/data', 'presenter/models/campaign-monitor-list-field'], function (exports, _data, _campaignMonitorListField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CampaignMonitorList;

  CampaignMonitorList = _data.Model.extend({
    listId: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    clientId: (0, _data.attr)('string'),
    fields: (0, _data.hasMany)('campaign_monitor_list_field', {
      serialize: false
    }),
    loadFields: function loadFields() {
      var recordArray;
      if ((recordArray = this.get('fields')).get('length')) {
        return Ember.RSVP.resolve();
      } else {
        return Ember.$.ajax({
          type: 'GET',
          url: '/campaign_monitor_lists/' + this.get('listId') + '/list_keys'
        }).then(function (_this) {
          return function (response) {
            response.forEach(function (field) {
              return recordArray.pushObject(_campaignMonitorListField.default.create({
                isNew: false
              }).deserialize(field));
            });
            return recordArray.set('isLoaded', true);
          };
        }(this));
      }
    }
  });

  CampaignMonitorList.reopenClass({
    resourceName: 'campaign_monitor_list'
  });

  exports.default = CampaignMonitorList;
});
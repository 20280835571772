define('presenter/initializers/response-primary-key', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'responsePrimaryKey',
    after: 'restless',
    initialize: function initialize() {
      var adapter;
      adapter = _data.Client.get('adapter');
      return adapter.map('response', {
        primaryKey: 'token'
      });
    }
  };
});
define('presenter/components/image-file-field', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'accept'],
    type: 'file',
    accept: 'image/*',
    value: null,
    action: null,
    isValid: true,
    change: function change(e) {
      var file;
      if (!(file = e.target.files[0])) {
        return;
      }
      if (this.get('action')) {
        return this.sendAction('action', file);
      } else if ((0, _utils.isImageExtension)(file.name)) {
        return this.setProperties({
          value: file,
          isValid: true
        });
      } else {
        return this.setProperties({
          value: null,
          isValid: false
        });
      }
    }
  });
});
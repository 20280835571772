define('presenter/models/object-mapping-push', ['exports', 'presenter/data', 'presenter/models/salesforce-push'], function (exports, _data, _salesforcePush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectMappingPush;

  ObjectMappingPush = _salesforcePush.default.extend({
    salesforceMapping: (0, _data.belongsTo)('salesforce_object_mapping', {
      serialize: false
    }),
    isObjectMappingPush: true
  });

  ObjectMappingPush.reopenClass({
    resourceName: 'object_mapping_push'
  });

  exports.default = ObjectMappingPush;
});
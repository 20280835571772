define('presenter/instance-initializers/filter-missing-piped-answers', ['exports', 'presenter/helpers/filter-missing-piped-answers'], function (exports, _filterMissingPipedAnswers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'filterMissingPipedAnswers',
    initialize: function initialize(instance) {
      return _filterMissingPipedAnswers.default.reopen({
        presenterResponseController: instance.container.lookup('controller:presenter/response')
      });
    }
  };
});
define("presenter/templates/svg/visual_builder_icons/tiny", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/visual_builder_icons/tiny.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 42 18");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M20 0h2v18h-2zM28 8h9v1.94h-9z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M31.6 3L26 9l1.4 1.5 5.6-6L31.6 3z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M27.4 7.5L26 9l5.6 6 1.4-1.5-5.6-6z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M5 8.06h9V10H5z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M14.6 7.5l-5.6 6 1.4 1.5L16 9l-1.4-1.5z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M10.4 3L9 4.5l5.6 6L16 9l-5.6-6z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
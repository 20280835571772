define('presenter/models/summary-filter', ['exports', 'presenter/mixins/change-feed-manager', 'presenter/mixins/sluricane-query-properties', 'presenter/utils/sluricane-adapter', 'presenter/models/stats', 'presenter/models/choice-stats', 'presenter/models/report-response', 'presenter/data'], function (exports, _changeFeedManager, _sluricaneQueryProperties, _sluricaneAdapter, _stats, _choiceStats, _reportResponse, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SummaryFilter;

  SummaryFilter = _data.Model.extend(_changeFeedManager.default, _sluricaneQueryProperties.default, {
    title: (0, _data.attr)('string'),
    token: (0, _data.attr)('string', {
      readOnly: true
    }),
    changeFeedToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    sluricaneToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    responseGraphToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    answersTokenMap: (0, _data.attr)('raw', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    conditionOperator: (0, _data.attr)('string', {
      defaultValue: 'and',
      deserializeOnPreview: true
    }),
    shareToken: Ember.computed.oneWay('survey.shareToken'),
    includeIncompleteResponses: Ember.computed.oneWay('survey.showIncompleteResponses'),
    sluricaneAdapter: function () {
      return _sluricaneAdapter.default.create({
        model: this
      });
    }.property(),
    changeFeedId: 'summary_filter',
    register: function register() {
      if (this.get('changeFeedToken')) {
        this.get('changeFeed').register(this, 'changeFeedId');
        return this.get('changeFeed').start();
      }
    },
    _unregister: function () {
      return this.get('changeFeed').unregister(this, 'changeFeedId');
    }.on('didDelete'),
    statsUpdated: function statsUpdated(statsArray) {
      return this.get('survey.components').forEach(function (_this) {
        return function (component) {
          var choicesStats, componentStats, stats;
          switch (component.get('type')) {
            case 'Rank':
              stats = statsArray.findBy('componentId', component.get('id'));
              return component.get('choices').forEach(function (choice) {
                var distribution, j, ref, ref1, ref2, ref3, results;
                distribution = function () {
                  results = [];
                  for (var j = 1, ref = component.get('choices.length'); 1 <= ref ? j <= ref : j >= ref; 1 <= ref ? j++ : j--) {
                    results.push(j);
                  }
                  return results;
                }.apply(this).map(function (i) {
                  var ref;
                  return (stats != null ? (ref = stats.get('histogram').find(function (grouping) {
                    return grouping.choice_id === choice.get('id') && grouping.rank === i;
                  })) != null ? ref.count : void 0 : void 0) || 0;
                });
                return choice.set('stats', _choiceStats.default.create({
                  choiceId: choice.get('id'),
                  average: (stats != null ? (ref1 = stats.get('averages').findBy('choiceId', choice.get('id'))) != null ? (ref2 = ref1.get('avg')) != null ? ref2.roundTo(2) : void 0 : void 0 : void 0) || null,
                  distribution: distribution,
                  answerCount: distribution.reduce(function (count, sum) {
                    return count + sum;
                  }, 0),
                  financialAmount: (stats != null ? (ref3 = stats.get('financialAmounts').findBy('choice_id', choice.get('id'))) != null ? ref3.financial_amount : void 0 : void 0) || 0
                }));
              });
            case 'Rating':
              componentStats = statsArray.filterBy('componentId', component.get('id'));
              if (component.get('hasGridItems')) {
                return component.get('gridItems').forEach(function (gridItem) {
                  return gridItem.set('stats', componentStats.findBy('gridItemId', gridItem.get('id')) || _stats.default.create());
                });
              } else {
                return component.set('stats', componentStats[0] || _stats.default.create());
              }
              break;
            case 'KeyDriver':
              componentStats = statsArray.filterBy('componentId', component.get('id'));
              if (component.get('hasGridItems')) {
                return component.get('gridItems').forEach(function (gridItem) {
                  return gridItem.set('stats', componentStats.findBy('gridItemId', gridItem.get('id')) || _stats.default.create());
                });
              } else {
                return component.set('stats', componentStats[0] || _stats.default.create());
              }
              break;
            case 'MultipleChoice':
            case 'PictureChoice':
              componentStats = statsArray.filterBy('componentId', component.get('id'));
              if (component.get('hasGridItems')) {
                return component.get('gridItems').forEach(function (gridItem) {
                  var choicesStats, gridItemStats;
                  gridItemStats = componentStats.filterBy('gridItemId', gridItem.get('id'));
                  gridItem.set('choicesStats', choicesStats = []);
                  _this.get('survey.deletedMultipleChoiceChoices').forEach(function (choice) {
                    if (!component.get('choices').contains(choice) && gridItemStats.findBy('choiceId', choice.get('id'))) {
                      return component.get('choices').pushObject(choice);
                    }
                  });
                  return component.get('choices').forEach(function (choice) {
                    var ref, ref1;
                    stats = gridItemStats.findBy('choiceId', choice.get('id'));
                    return choicesStats.pushObject(_choiceStats.default.create({
                      choiceId: choice.get('id'),
                      answerCount: (stats != null ? stats.get('total') : void 0) || 0,
                      percentage: (stats != null ? (ref = stats.get('totalPercentage')) != null ? ref.roundTo() : void 0 : void 0) || 0,
                      financialAmount: (stats != null ? (ref1 = stats.get('financialAmount')) != null ? ref1.roundTo() : void 0 : void 0) || 0
                    }));
                  });
                });
              } else {
                component.set('choicesStats', choicesStats = []);
                _this.get('survey.deletedMultipleChoiceChoices').forEach(function (choice) {
                  if (!component.get('choices').contains(choice) && componentStats.findBy('choiceId', choice.get('id'))) {
                    return component.get('choices').pushObject(choice);
                  }
                });
                return component.get('choices').forEach(function (choice) {
                  var ref, ref1;
                  stats = componentStats.findBy('choiceId', choice.get('id'));
                  return choicesStats.pushObject(_choiceStats.default.create({
                    choiceId: choice.get('id'),
                    answerCount: (stats != null ? stats.get('total') : void 0) || 0,
                    percentage: (stats != null ? (ref = stats.get('totalPercentage')) != null ? ref.roundTo() : void 0 : void 0) || 0,
                    financialAmount: (stats != null ? (ref1 = stats.get('financialAmount')) != null ? ref1.roundTo() : void 0 : void 0) || 0
                  }));
                });
              }
              break;
            case 'NetPromoter':
            case 'Slider':
            case 'LikeDislike':
            case 'CustomerEffort':
            case 'CustomerSatisfaction':
              return component.set('stats', statsArray.findBy('componentId', component.get('id')) || _stats.default.create());
          }
        };
      }(this));
    },
    dataInserted: function dataInserted(data) {
      var insertedData;
      insertedData = _reportResponse.default.load(data);
      return this.get('survey.components').forEach(function (_this) {
        return function (component) {
          var answer;
          if ((answer = insertedData.get('answers').findBy('component.id', component.get('id'))) && answer.get('isTextPresent')) {
            answer.set('responseId', insertedData.get('id'));
            answer.set('responseRethinkId', insertedData.get('rethinkId'));
            answer.set('responseDate', insertedData.get('aggregateDate'));
            answer.sideloadRelationships(_this.get('identityMap'));
            return component.get('answers').unshiftObject(answer);
          }
        };
      }(this));
    },
    dataUpdated: function dataUpdated(data) {
      var updatedResponse;
      updatedResponse = _reportResponse.default.load(data);
      return this.get('survey.components').forEach(function (_this) {
        return function (component) {
          var answer, answers, updatedAnswer;
          if ((answers = component.get('answers')) && (answer = answers.findBy('responseRethinkId', updatedResponse.get('rethinkId'))) && (updatedAnswer = updatedResponse.get('answers').findBy('id', answer.get('id')))) {
            updatedAnswer.set('responseId', updatedResponse.get('id'));
            updatedAnswer.set('responseRethinkId', updatedResponse.get('rethinkId'));
            updatedAnswer.set('responseDate', updatedResponse.get('aggregateDate'));
            updatedAnswer.sideloadRelationships(_this.get('identityMap'));
            return answers.replace(answers.indexOf(answer), 1, [updatedAnswer]);
          } else if ((answer = updatedResponse.get('answers').findBy('component.id', component.get('id'))) && answer.get('isTextPresent')) {
            answer.set('responseId', updatedResponse.get('id'));
            answer.set('responseRethinkId', updatedResponse.get('rethinkId'));
            answer.set('responseDate', updatedResponse.get('aggregateDate'));
            answer.sideloadRelationships(_this.get('identityMap'));
            return component.get('answers').unshiftObject(answer);
          }
        };
      }(this));
    },
    dataDeleted: function dataDeleted(data) {
      return this.get('survey.components').forEach(function (component) {
        return component.get('answers').forEach(function (answer, index, answers) {
          if (answer.get('responseRethinkId') === data.rethink_id) {
            return answers.removeObject(answer);
          }
        });
      });
    }
  });

  SummaryFilter.reopenClass({
    endpoint: 'summary_filters',
    resourceName: 'summary_filter'
  });

  exports.default = SummaryFilter;
});
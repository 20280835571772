define("presenter/templates/svg/_sfdc_cloud", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_sfdc_cloud.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 40 28");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M10.5 0C6.2 0 2.7 3.5 2.7 7.8c0 1.1.2 2.1.6 3.1C1.3 12 0 14.2 0 16.7c0 3.7 3 6.7 6.7 6.7.5 0 .9 0 1.4-.1 1 2.7 3.7 4.7 6.7 4.7s5.5-1.8 6.6-4.4c.8.4 1.8.6 2.8.6 2.4 0 4.4-1.3 5.5-3.2.5.1 1.1.2 1.7.2 4.8 0 8.6-3.9 8.6-8.7 0-4.8-3.9-8.7-8.6-8.7-1.2 0-2.4.3-3.5.7C26.7 2.3 24.4.8 21.7.8c-2 0-3.8.8-5.1 2.2-1.4-1.8-3.6-3-6.1-3");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
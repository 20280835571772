define("presenter/templates/presenter/picture-choice-choice", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 138
              },
              "end": {
                "line": 1,
                "column": 233
              }
            },
            "moduleName": "presenter/templates/presenter/picture-choice-choice.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "merge-response-values", [["subexpr", "translated-properties", ["choices", ["get", "choice.id", ["loc", [null, [1, 214], [1, 223]]]], "text"], [], ["loc", [null, [1, 181], [1, 231]]]]], [], ["loc", [null, [1, 157], [1, 233]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 233
              },
              "end": {
                "line": 1,
                "column": 355
              }
            },
            "moduleName": "presenter/templates/presenter/picture-choice-choice.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "picture-choice-choice-no-text");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'aria-label');
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "i18n", ["presenter.labels.picture"], [], ["loc", [null, [1, 258], [1, 293]]]], " ", ["get", "index", ["loc", [null, [1, 296], [1, 301]]]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 40
            },
            "end": {
              "line": 1,
              "column": 368
            }
          },
          "moduleName": "presenter/templates/presenter/picture-choice-choice.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "picture-choice-choice-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "choice.text", ["loc", [null, [1, 144], [1, 155]]]]], [], 0, 1, ["loc", [null, [1, 138], [1, 362]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 375
          }
        },
        "moduleName": "presenter/templates/presenter/picture-choice-choice.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "media-image", [], ["media", ["subexpr", "@mut", [["get", "choice.mediaObject", ["loc", [null, [1, 20], [1, 38]]]]], [], []]], ["loc", [null, [1, 0], [1, 40]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "hideImageLabels", ["loc", [null, [1, 56], [1, 71]]]]], [], ["loc", [null, [1, 51], [1, 72]]]], ["get", "atLeastOneImageLabeled", ["loc", [null, [1, 73], [1, 95]]]]], [], ["loc", [null, [1, 46], [1, 96]]]]], [], 0, null, ["loc", [null, [1, 40], [1, 375]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
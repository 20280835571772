define("presenter/templates/svg/dashboard/moon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/dashboard/moon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        var el2 = dom.createElement("style");
        var el3 = dom.createTextNode(".Dashboards-moon{fill:url(#moon-gradient);}");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "transform", "translate(.5 .5)");
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3, "id", "moon-gradient");
        dom.setAttribute(el3, "gradientUnits", "userSpaceOnUse");
        dom.setAttribute(el3, "x1", "2.5");
        dom.setAttribute(el3, "y1", "31.9997");
        dom.setAttribute(el3, "x2", "61.5");
        dom.setAttribute(el3, "y2", "31.9997");
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "0");
        dom.setAttribute(el4, "stop-color", "#3273F8");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "1");
        dom.setAttribute(el4, "stop-color", "#5B4CF4");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "Dashboards-moon");
        dom.setAttribute(el3, "d", "M32.5 61.5c-16.5 0-30-13.5-30-30C2.5 18 11.6 6.1 24.6 2.6c.5-.1 1.1 0 1.4.4.4.4.5.9.4 1.4-.6 2.6-1 4.9-1 7.1 0 14.9 12.1 27 27 27 2.6 0 4.9-.3 7.1-.9.5-.2 1.1 0 1.5.4.4.4.5.9.4 1.5-3.5 12.9-15.4 22-28.9 22zM23 6.2c-10.4 3.9-17.5 14-17.5 25.3 0 14.9 12.1 27 27 27 11.3 0 21.3-7.1 25.3-17.4-1.7.3-3.4.4-5.3.4-16.5 0-30-13.5-30-30 0-1.7.2-3.4.5-5.3z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/defs", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/defs.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "height", "0");
        dom.setAttribute(el1, "width", "0");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3, "id", "line-chart-gradient");
        dom.setAttribute(el3, "x1", "1");
        dom.setAttribute(el3, "y1", "1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "0%");
        dom.setAttribute(el4, "stop-opacity", "0.2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "25%");
        dom.setAttribute(el4, "stop-opacity", "0.4");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "50%");
        dom.setAttribute(el4, "stop-opacity", "0.6");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "75%");
        dom.setAttribute(el4, "stop-opacity", "0.8");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3, "id", "date-graph-gradient");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "stop-color", "#526CA0");
        dom.setAttribute(el4, "stop-opacity", "0.2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "class", "js-dateGraphFillStart");
        dom.setAttribute(el4, "offset", "100%");
        dom.setAttribute(el4, "stop-color", "#526CA0");
        dom.setAttribute(el4, "stop-opacity", "0.2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "stop-color", "#2E71FC");
        dom.setAttribute(el4, "stop-opacity", "1");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "class", "js-dateGraphFillEnd");
        dom.setAttribute(el4, "offset", "100%");
        dom.setAttribute(el4, "stop-color", "#2E71FC");
        dom.setAttribute(el4, "stop-opacity", "1");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "stop-color", "#526CA0");
        dom.setAttribute(el4, "stop-opacity", "0.2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
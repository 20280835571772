define('presenter/models/logic-condition', ['exports', 'presenter/models/abstract-condition', 'presenter/data'], function (exports, _abstractCondition, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LogicCondition;

  LogicCondition = _abstractCondition.default.extend({
    rule: (0, _data.belongsTo)('rule', {
      serialize: false
    }),
    isValid: function () {
      return (!!this.get('lhsComponent') && (this.get('rule.isExit') || this.get('lhsComponent.position') <= this.get('rule.component.position')) || !!this.get('lhsMergeKey')) && !!this.get('operator') && this.get('validRhs') && this.get('validRhsRange');
    }.property('lhsComponent.position', 'lhsMergeKey', 'rule.component.position', 'operator', 'validRhs', 'validRhsRange')
  });

  LogicCondition.reopenClass({
    resourceName: 'logic_condition'
  });

  exports.default = LogicCondition;
});
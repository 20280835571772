define("presenter/templates/survey/_net_promoter_labels", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 267
            }
          },
          "moduleName": "presenter/templates/survey/_net_promoter_labels.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "NetPromoter-ratingLabels theme-question");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "NetPromoter-ratingLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "NetPromoter-ratingLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "i18n", ["presenter.labels.nps.not_likely"], [], ["loc", [null, [1, 122], [1, 164]]]], ["inline", "i18n", ["presenter.labels.nps.extremely_likely"], [], ["loc", [null, [1, 207], [1, 255]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 278
          }
        },
        "moduleName": "presenter/templates/survey/_net_promoter_labels.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "component.hideLabels", ["loc", [null, [1, 10], [1, 30]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 278]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
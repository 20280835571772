define('presenter/components/presenter/next-button', ['exports', 'i18n-js', 'presenter/mixins/aria'], function (exports, _i18nJs, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aria.default, {
    tagName: 'button',
    classNames: ['button', 'NavigationButton', 'hint--left', 'pull-right', 'theme-text'],
    classNameBindings: ['required', 'canStepForward:enabled:disabled', 'showPromptNext:theme-button'],
    attributeBindings: ['data-hint', 'data-role', 'tabindex:tabindex'],
    showPromptNext: false,
    canStepForward: false,
    requiredQuestion: false,
    locale: null,
    action: null,
    required: function () {
      return this.get('requiredQuestion') && !this.get('canStepForward');
    }.property('requiredQuestion', 'canStepForward'),
    'aria-label': function () {
      if (this.get('required')) {
        return _i18nJs.default.t('presenter.alerts.required_question');
      } else {
        return this.get('data-role');
      }
    }.property('required', 'locale'),
    'data-hint': function () {
      if (this.get('required')) {
        return _i18nJs.default.t('presenter.alerts.required_question');
      }
    }.property('required', 'locale'),
    'aria-disabled': Ember.computed.oneWay('required'),
    click: function click() {
      if (this.get('canStepForward') && this.get('action')) {
        return this.send(this.get('action'));
      }
    }
  });
});
define('presenter/components/etw/module-style-detail', ['exports', 'ember-cli-tailwind/utils/classes-for-module-style'], function (exports, _classesForModuleStyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    etwTailwindStyleguide: Ember.inject.service(),
    moduleStyle: Ember.computed.reads('etwTailwindStyleguide.selectedModuleStyle'),

    activeResponsiveClass: 'all',
    activeState: 'none',

    detailStyles: Ember.computed('moduleStyle', 'activeResponsiveClass', 'activeState', function () {
      var moduleStyle = this.get('moduleStyle');
      var activeResponsiveClass = this.get('activeResponsiveClass');
      var responsivePrefix = activeResponsiveClass === 'all' ? '' : activeResponsiveClass + ':';
      var activeState = this.get('activeState');
      var statePrefix = activeState === 'none' ? '' : activeState + ':';

      return (0, _classesForModuleStyle.default)(moduleStyle).map(function (cssClass) {
        return '' + responsivePrefix + statePrefix + cssClass;
      });
    }),

    actions: {
      highlightStyle: function highlightStyle(style) {
        var _this = this;

        this.set('highlightedStyle', style);
        Ember.run.later(function () {
          _this.set('highlightedStyle', null);
        }, 1500);
      }
    }

  });
});
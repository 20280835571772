define('presenter/components/rating-scale-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    answer: null,
    fill: false,
    number: null,
    questionController: null,
    isSelected: Ember.computed('number', 'answer.number', function () {
      if (this.get('questionController.hasNumberScale')) {
        return this.get('number') === this.get('answer.number');
      } else {
        return this.get('number') <= this.get('answer.number');
      }
    }),
    isNotApplicable: Ember.computed.equal('number', null),
    actions: {
      select: function select() {
        return this.sendAction('select', this.get('number'));
      }
    }
  });
});
define('presenter/components/presenter/form-field', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'presenter/form-field',
    classNames: ['presenter-form-field'],
    classNameBindings: ['hideThisField:is-hidden'],
    targetObject: Ember.computed.oneWay('target'),
    field: null,
    component: null,
    required: Ember.computed.or('field.choice.required', 'field.choice.component.required'),
    mergeFieldHidingEnabled: Ember.computed.and('component.canHideMergeFields', 'field.choice.shouldHideMergeField'),
    listOptionMatchedPrepopulatedValue: Ember.computed('field.choice.listOptions', 'field.text', function () {
      return !!this.get('field.choice.listOptions').findBy('value', this.get('field.text'));
    }),
    hideThisField: Ember.computed('mergeFieldHidingEnabled', 'field.isPrepopulated', 'field.passedInitialValidationCheck', function () {
      if (this.get('field.choice.listOptionsNotEmpty')) {
        return this.get('mergeFieldHidingEnabled') && this.get('field.isPrepopulated') && this.get('listOptionMatchedPrepopulatedValue');
      } else {
        return this.get('mergeFieldHidingEnabled') && this.get('field.isPrepopulated') && this.get('field.passedInitialValidationCheck');
      }
    }),
    _setFieldHidden: Ember.on('init', Ember.observer('hideThisField', function () {
      if (this.get('mergeFieldHidingEnabled')) {
        return this.set('field.isHidden', this.get('hideThisField'));
      }
    })),
    showPhishingWarning: function () {
      var phishingWords, regex;
      phishingWords = _globals.jsSettings.password_translations;
      regex = new RegExp(_globals.codeSettings.normalizedPhishingRegex, _globals.codeSettings.normalizedPhishingRegexOptions);
      return phishingWords.any(function (_this) {
        return function (word) {
          var ref;
          return ((ref = _this.get('field.choice.text')) != null ? ref.replace(regex, '').toLowerCase().indexOf(word) : void 0) > -1;
        };
      }(this));
    }.property('field.choice.text'),
    actions: {
      updateFieldProperty: function updateFieldProperty(property, value) {
        return this.set("field." + property, value);
      }
    }
  });
});
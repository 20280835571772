define('presenter/router', ['exports', 'presenter/config/environment', 'presenter/globals'], function (exports, _environment, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /* global Modernizr */
  var Router;

  Router = Ember.Router.extend({
    rootURL: _globals.rootPath,
    location: _globals.isPreview || _globals.isIframed || !Modernizr.history ? 'none' : 'history',
    setupRouter: function setupRouter() {
      var _ret, base64Regex, match, newRootUrl, recognizer, response, rootURL, rootUrl, uuidRegex;
      _ret = this._super.apply(this, arguments);
      if (!(0, _globals.presenterFlag)('isReadOnly') && this.location.history) {
        recognizer = this.router.recognizer;
        response = this.container.lookup('controller:presenter/response');
        if (!recognizer.recognize(this.location.getURL())) {
          base64Regex = /^\/([a-z0-9%=]+)(?:$|((?:\/|\?).*))/i;
          uuidRegex = /^\/([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})(?:$|((?:\/|\?).*))/i;
          rootURL = this.location.get('rootURL');
          if ((match = this.location.getURL().match(base64Regex)) && recognizer.recognize(match[2] || '')) {
            this.location.set('rootURL', "" + rootURL + match[1]);
            response.set('base64MergeMap', match[1]);
          } else if ((match = this.location.getURL().match(uuidRegex)) && recognizer.recognize(match[2] || '')) {
            if (!!window._merge_map_token && window._merge_map_token !== match[1]) {
              newRootUrl = "" + rootURL + window._merge_map_token + "/";
              this.location.replaceState(newRootUrl);
              this.location.set('rootURL', newRootUrl);
            } else {
              this.location.set('rootURL', "" + rootURL + match[1] + "/");
            }
          } else {
            window.location.replace('/404');
          }
        } else if (window._merge_map_token) {
          rootUrl = this.location.get('rootURL') + window._merge_map_token;
          this.location.replaceState(rootUrl);
          this.location.set('rootURL', rootUrl);
        }
      }
      return _ret;
    }
  });

  Router.map(function () {
    return this.resource('presenter/survey', {
      path: '/'
    }, function () {
      return this.resource('presenter/components', {
        path: '/'
      }, function () {
        this.route('view', {
          path: '/q/:component_id'
        });
        return this.route('thanks');
      });
    });
  });

  exports.default = Router;
});
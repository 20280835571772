define('presenter/routes/presenter/components/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }return -1;
  };

  exports.default = Ember.Route.extend({
    model: function model() {
      var components;
      components = this.controllerFor('presenter/components');
      if (components.get('landedAtStart')) {
        return this.replaceWith('presenter/components.view', components.get('coverPageController').get('content'));
      }
    },
    setupController: function setupController(controller, model) {
      var answeredComponentIds, answers, components, componentsController, jumpComponent, ref, responseController, shouldAdvance;
      components = this.modelFor('presenter/components');
      componentsController = this.controllerFor('presenter/components');
      responseController = this.controllerFor('presenter/response');
      if (((ref = responseController.get('model.mergeMap')) != null ? ref['gf_id'] : void 0) && (answers = responseController.get('model.answers')).get('length') && !answers.isEvery('embedded')) {
        componentsController.set('hasManuallyAdvanced', true);
        jumpComponent = components.get('firstObject');
        componentsController.set('currentComponent', jumpComponent);
        answeredComponentIds = answers.mapBy('component.id');
        shouldAdvance = function (_this) {
          return function () {
            var ref1;
            if (componentsController.get('currentComponentController').evaluateRules(true)) {
              return true;
            }
            if (!componentsController.get('canStepForward')) {
              return false;
            }
            if (componentsController.get('onLastQuestion')) {
              return false;
            }
            if (!componentsController.get('currentComponent.isQuestion')) {
              return true;
            }
            return ref1 = componentsController.get('currentComponent.id'), indexOf.call(answeredComponentIds, ref1) >= 0;
          };
        }(this);
        while (shouldAdvance()) {
          jumpComponent = this._advancePage(componentsController, jumpComponent);
        }
        return Ember.run.next(function (_this) {
          return function () {
            return _this.replaceWith('presenter/components.view', jumpComponent);
          };
        }(this));
      } else {
        if (components.get('firstObject.type') !== 'CoverPage') {
          return Ember.run.next(function (_this) {
            return function () {
              return _this.replaceWith('presenter/components.view', components.get('firstObject')).then(function () {
                return _this.send('openLanguageSelectorOnInit');
              });
            };
          }(this));
        } else {
          componentsController.set('currentComponent', components.get('firstObject'));
          return Ember.run.next(function (_this) {
            return function () {
              return _this.send('openLanguageSelectorOnInit');
            };
          }(this));
        }
      }
    },
    _advancePage: function _advancePage(componentsController, jumpComponent) {
      var currIndex, currentComponentController, nextIndex;
      currentComponentController = componentsController.get('currentComponentController');
      componentsController.pushHistory();
      jumpComponent = componentsController.get('currentComponentController').evaluateRules();
      if (jumpComponent) {
        currIndex = componentsController.get('model').indexOf(currentComponentController.get('model'));
        nextIndex = componentsController.get('model').indexOf(jumpComponent);
        componentsController.slice(currIndex + 1, nextIndex).forEach(function (component) {
          var answer;
          if (!(answer = component.get('answer'))) {
            return;
          }
          answer.set('skipped', true);
          return answer.saveRecord();
        });
      } else {
        jumpComponent = componentsController.get('nextComponent');
      }
      componentsController.set('currentComponent', jumpComponent);
      return jumpComponent;
    },
    activate: function activate() {
      return this.controllerFor('presenter/components').set('landedAtStart', true);
    }
  });
});
define('presenter/mixins/like-dislike-helper', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    hasNeutral: function () {
      return this.get('shape').indexOf('neutral') !== -1;
    }.property('shape'),
    svgShape: function () {
      return this.get('shape').replace('_neutral', '');
    }.property('shape'),
    options: function () {
      var options;
      options = [{
        number: -1,
        sentiment: _i18nJs.default.t('shared.labels.dislike'),
        svgPath: "svg/likeDislike/" + this.get('svgShape') + "Dislike"
      }, {
        number: 1,
        sentiment: _i18nJs.default.t('shared.labels.like'),
        svgPath: "svg/likeDislike/" + this.get('svgShape') + "Like"
      }];
      if (this.get('reverse')) {
        options = options.reverse();
      }
      if (this.get('hasNeutral')) {
        options.splice(1, 0, {
          number: 0,
          sentiment: _i18nJs.default.t('shared.labels.neutral'),
          svgPath: "svg/likeDislike/" + this.get('svgShape') + "Neutral"
        });
      }
      return options;
    }.property('reverse', 'svgShape', 'hasNeutral', 'survey.currentLanguage.locale'),
    actions: {
      select: Ember.K
    }
  });
});
define('presenter/controllers/presenter/slider', ['exports', 'presenter/controllers/presenter/scale'], function (exports, _scale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scale.default.extend({
    hasBeenSelected: false,
    needsReinitializing: false,
    _initAnswer: function () {
      return this.setInitialAnswer();
    }.observes('answer'),
    setInitialAnswer: function setInitialAnswer() {
      var max, min;
      if (!this.get('answer') || this.get('hasAnswer')) {
        return;
      }
      min = this.get('minimumValue');
      max = this.get('maximumValue');
      return this.set('answer.number', function () {
        switch (this.get('startPosition')) {
          case 'left':
            return min;
          case 'center':
            return Math.round((max - min) / 2);
          case 'right':
            return max;
        }
      }.call(this));
    },
    middleValue: Ember.computed('minimumValue', 'maximumValue', function () {
      return Math.round((this.get('maximumValue') - this.get('minimumValue')) / 2);
    }),
    completed: function () {
      return this._super() && (this.get('hasBeenSelected') || !this.get('required') || this.get('isMissingTitle'));
    }.property('hasBeenSelected', 'required', 'isMissingTitle'),
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      this.beginPropertyChanges();
      this.set('answer.number', null);
      this.set('hasBeenSelected', false);
      this.set('needsReinitializing', true);
      return this.endPropertyChanges();
    },
    reinitialize: function reinitialize() {
      if (this.get('needsReinitializing')) {
        this.set('needsReinitializing', false);
        return this.setInitialAnswer();
      }
    }
  });
});
define("presenter/templates/svg/_salesforce_sync", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_salesforce_sync.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0");
        dom.setAttribute(el2, "d", "M16 2.8c4.2 0 8.1 2.1 10.5 5.5l-7.2-1-.3 2.1 10.4 1.4L30.8.2l-2-.2-.9 6.6c-2.8-3.7-7.2-6-11.9-6C7.7.6 1 7.5 1 16h2.1C3.1 8.7 8.9 2.8 16 2.8zM28.9 16c0 7.3-5.8 13.2-12.9 13.2-4.2 0-8.1-2.1-10.5-5.5l7.2 1 .3-2.1-10.5-1.5-1.4 10.6 2.1.3.9-6.6c2.8 3.7 7.2 6 11.9 6 8.3 0 15-6.9 15-15.4h-2.1z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
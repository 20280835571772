define('presenter/models/rule', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Rule;

  Rule = _data.Model.extend({
    conditionsOperator: (0, _data.attr)('string', {
      defaultValue: 'all'
    }),
    createdAt: (0, _data.attr)('date'),
    updatedAt: (0, _data.attr)('date'),
    position: (0, _data.attr)('number', {
      positionIn: 'component.rules'
    }),
    type: (0, _data.attr)('string', {
      defaultValue: 'precondition'
    }),
    purpose: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    active: (0, _data.attr)('boolean', {
      defaultValue: 'true'
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    conditions: (0, _data.hasMany)('logic_condition', {
      serialize: true
    }),
    actions: (0, _data.hasMany)('action', {
      serialize: true
    }),
    hasValidConditions: function () {
      var hasConditions;
      hasConditions = Ember.isPresent(this.get('conditions'));
      return this.get('isOtherwise') || this.get('isExit') && !hasConditions || hasConditions && this.get('conditions').isEvery('isValid');
    }.property('conditions.@each.isValid', 'isOtherwise', 'isExit'),
    hasValidActions: function () {
      return !Ember.isEmpty(this.get('actions')) && this.get('actions').isEvery('isValid');
    }.property('actions.@each.isValid'),
    isValid: Ember.computed.and('hasValidConditions', 'hasValidActions')
  });

  Rule.CONDITIONS_OPERATORS = _globals.codeSettings.ruleConditionsOperators;

  Rule.reopenForEach(_globals.codeSettings.ruleTypes, function (type, Controller) {
    return Controller["is" + type.classify()] = Ember.computed.equal('type', type);
  });

  Rule.reopenClass({
    resourceName: 'rule'
  });

  exports.default = Rule;
});
define('presenter/models/recipe', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Recipe;

  Recipe = _data.Model.extend({
    name: (0, _data.attr)('string'),
    completedAt: (0, _data.attr)('date'),
    recipeTemplate: (0, _data.belongsTo)('recipe_template', {
      serialize: true
    }),
    orderedChildRecipeSteps: (0, _data.hasMany)('recipe_step'),
    allSteps: Ember.computed('orderedChildRecipeSteps.[]', 'orderedChildRecipeSteps.{childRecipes.[]}', function () {
      var steps;
      steps = [];
      this.get('orderedChildRecipeSteps').forEach(function (_this) {
        return function (step) {
          if (step.get('childRecipes.length')) {
            return step.get('childRecipes').forEach(function (stepRecipe) {
              return steps = steps.concat(stepRecipe.get('orderedChildRecipeSteps.content'));
            });
          } else {
            return steps.pushObject(step);
          }
        };
      }(this));
      return steps;
    }),
    totalStepCount: Ember.computed('allSteps', function () {
      return this.get('allSteps.length');
    }),
    completedStepCount: Ember.computed('allSteps.{isCompleted}', function () {
      return this.get('allSteps').filter(function (_this) {
        return function (step) {
          return step.get('isCompleted');
        };
      }(this)).length;
    }),
    progressPercentage: Ember.computed('completedStepCount', function () {
      return this.get('completedStepCount') / this.get('totalStepCount') * 100;
    }),
    category: Ember.computed.alias('recipeTemplate.category')
  });

  Recipe.reopenClass({
    resourceName: 'recipe'
  });

  exports.default = Recipe;
});
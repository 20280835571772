define('presenter/components/presenter/rating-question', ['exports', 'presenter/components/presenter/question', 'presenter/mixins/rating-component-helper', 'presenter/globals'], function (exports, _question, _ratingComponentHelper, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend(_ratingComponentHelper.default, {
    classNameBindings: ['hasSelection'],
    number: Ember.computed.oneWay('component.number'),
    value: Ember.computed.alias('component.answer.number'),
    hasSelection: Ember.computed.bool('value'),
    _ratingChanged: function () {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          return _this._animateSelection(_this.$('.is-selected'), _this.get('_animatedCssProperty'));
        };
      }(this));
    }.observes('value').on('didInsertElement'),
    _animatedCssProperty: function () {
      if (this.get('component.hasNumberScale')) {
        return 'backgroundColor';
      } else {
        return 'opacity';
      }
    }.property('component.hasNumberScale'),
    onFocus: function onFocus() {
      if ((0, _globals.presenterFlag)('isReadOnly')) {
        this._ratingChanged();
      }
      return this.positionLabels();
    }
  });
});
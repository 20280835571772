define('presenter/instance-initializers/event-buses', ['exports', 'presenter/helpers/survey-event-bus'], function (exports, _surveyEventBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'registerEventBuses',
    initialize: function initialize(instance) {
      instance.registry.optionsForType('eventBus', {
        singleton: true
      });
      instance.registry.register('eventBus:survey', _surveyEventBus.default);
      return instance.container.lookup('eventBus:survey');
    }
  };
});
define("presenter/templates/presenter/picture-choice-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 145
            },
            "end": {
              "line": 1,
              "column": 428
            }
          },
          "moduleName": "presenter/templates/presenter/picture-choice-question.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/picture-choice-choice", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [1, 279], [1, 285]]]]], [], []], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [1, 292], [1, 297]]]]], [], []], "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 305], [1, 321]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 329], [1, 338]]]]], [], []], "atLeastOneImageLabeled", ["subexpr", "@mut", [["get", "atLeastOneImageLabeled", ["loc", [null, [1, 362], [1, 384]]]]], [], []], "hideImageLabels", ["subexpr", "@mut", [["get", "component.hideImageLabels", ["loc", [null, [1, 401], [1, 426]]]]], [], []]], ["loc", [null, [1, 238], [1, 428]]]]],
        locals: ["choice", "index"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 443
            },
            "end": {
              "line": 1,
              "column": 638
            }
          },
          "moduleName": "presenter/templates/presenter/picture-choice-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "custom-icon-circled-info");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["presenter-question-tip theme-answer ", ["subexpr", "if", [["get", "component.showTips", ["loc", [null, [1, 529], [1, 547]]]], "show-tips"], [], ["loc", [null, [1, 524], [1, 561]]]]]]], ["content", "component.minMaxQuestionTip", ["loc", [null, [1, 603], [1, 634]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 651
          }
        },
        "moduleName": "presenter/templates/presenter/picture-choice-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "picture-choice-choices-wrapper");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "picture-choice-choices");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'aria-label');
        morphs[1] = dom.createAttrMorph(element1, 'tabindex');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["get", "component.minMaxQuestionTip", ["loc", [null, [1, 18], [1, 45]]]]], ["attribute", "tabindex", ["get", "tabIndex", ["loc", [null, [1, 59], [1, 67]]]]], ["block", "each", [["subexpr", "filter-missing-piped-answers", [["get", "component.displayChoices", ["loc", [null, [1, 183], [1, 207]]]], ["get", "component", ["loc", [null, [1, 208], [1, 217]]]]], [], ["loc", [null, [1, 153], [1, 218]]]]], [], 0, null, ["loc", [null, [1, 145], [1, 437]]]], ["block", "if", [["get", "component.minMaxQuestionTip", ["loc", [null, [1, 449], [1, 476]]]]], [], 1, null, ["loc", [null, [1, 443], [1, 645]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define("presenter/templates/svg/question_types/_file_upload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_file_upload.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M38.87 22A16.08 16.08 0 0 0 23 8 15.9 15.9 0 0 0 7.07 22.5 9 9 0 0 0 10 40h28a9 9 0 0 0 .9-18zm-8.2 3.7a1 1 0 0 1-1.4 0L25 21.4V35a1 1 0 0 1-2 0V21.4l-4.3 4.3a1 1 0 0 1-1.4-1.4l6-6c.1-.1.2-.2.3-.2a1.1 1.1 0 0 1 .8 0c.1.1.2.1.3.2l6 6a1.08 1.08 0 0 1-.03 1.4z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/controllers/presenter/default-page', ['exports', 'presenter/controllers/presenter/exit', 'presenter/globals'], function (exports, _exit, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _exit.default.extend({
    video: {
      videoUrl: 'http://player.vimeo.com/video/212100812?title=0&badge=0&portrait=0&byline=0'
    },
    iframeLoadedPromise: null
  });
});
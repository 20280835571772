define('presenter/controllers/application', ['exports', 'presenter/controllers/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_modal.default, {
    actions: {
      modalDismissed: function modalDismissed() {
        return false;
      }
    }
  });
});
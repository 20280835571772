define('presenter/controllers/presenter/action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend({
    components: Ember.inject.controller('presenter/components'),
    response: Ember.inject.controller('presenter/response'),
    evaluate: function evaluate() {
      if (this.get('type') === 'jump') {
        return this.get('jumpTarget');
      } else if (this.get('model.rule.type') === 'exit') {
        if (this.get('model.rule.active')) {
          return this.get('response').send('registerTriggeredAction', this.get('model'));
        } else {
          return false;
        }
      } else {
        Raven.captureMessage('Invalid logic action type.', {
          extra: {
            actionId: this.get('id')
          }
        });
        return false;
      }
    },
    jumpTarget: function () {
      var component;
      if (Ember.isEmpty(component = this.get('component'))) {
        return this.get('components.defaultExitController.content');
      } else if (component.get('isExit')) {
        return this.get('components.content').findBy('id', component.get('id'));
      } else {
        return component;
      }
    }.property('component', 'components.defaultExitController', 'components.@each.id')
  });
});
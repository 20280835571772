define("presenter/templates/svg/like_dislike/thumb_dislike", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/like_dislike/thumb_dislike.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "class", "nc-icon-wrapper");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "data-color", "color-2");
        dom.setAttribute(el3, "d", "M5,2v30c0,0.6,0.4,1,1,1h9V1H6C5.4,1,5,1.4,5,2z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M59,25.8L55.4,6.7C54.8,3.4,51.9,1,48.6,1H18h-1v32l8,27.3c0.1,0.4,0.5,0.7,1,0.7l0,0c3.9,0,7-3.1,7-7V35h19 c3.9,0,7-3.1,7-7v-2C59,25.9,59,25.9,59,25.8z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
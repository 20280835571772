define("presenter/templates/survey/_like_dislike_template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 189
                },
                "end": {
                  "line": 1,
                  "column": 524
                }
              },
              "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "tabindex", "0");
              dom.setAttribute(el1, "role", "button");
              dom.setAttribute(el1, "class", "LikeDislike-button theme-answer");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'aria-label');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1, 0, 0);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["get", "option.sentiment", ["loc", [null, [1, 371], [1, 387]]]]], ["element", "action", ["select", ["get", "option.number", ["loc", [null, [1, 422], [1, 435]]]]], ["on", "click"], ["loc", [null, [1, 404], [1, 448]]]], ["inline", "partial", [["get", "option.svgPath", ["loc", [null, [1, 499], [1, 513]]]]], [], ["loc", [null, [1, 489], [1, 515]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 152
              },
              "end": {
                "line": 1,
                "column": 549
              }
            },
            "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "selectable-list-item", [], ["tagName", "span", "classNames", "LikeDislike-icon", "listItem", ["subexpr", "@mut", [["get", "option.number", ["loc", [null, [1, 267], [1, 280]]]]], [], []], "listSelection", ["subexpr", "@mut", [["get", "component.answer.number", ["loc", [null, [1, 295], [1, 318]]]]], [], []], "isSelectable", false], 0, null, ["loc", [null, [1, 189], [1, 549]]]]],
          locals: ["option"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 127
            },
            "end": {
              "line": 1,
              "column": 558
            }
          },
          "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "component.options", ["loc", [null, [1, 170], [1, 187]]]]], [], 0, null, ["loc", [null, [1, 152], [1, 558]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 620
                },
                "end": {
                  "line": 1,
                  "column": 955
                }
              },
              "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "tabindex", "0");
              dom.setAttribute(el1, "role", "button");
              dom.setAttribute(el1, "class", "LikeDislike-button theme-answer");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'aria-label');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["get", "option.sentiment", ["loc", [null, [1, 802], [1, 818]]]]], ["element", "action", ["select", ["get", "option.number", ["loc", [null, [1, 853], [1, 866]]]]], ["on", "click"], ["loc", [null, [1, 835], [1, 879]]]], ["inline", "partial", [["get", "option.svgPath", ["loc", [null, [1, 930], [1, 944]]]]], [], ["loc", [null, [1, 920], [1, 946]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 566
              },
              "end": {
                "line": 1,
                "column": 980
              }
            },
            "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "selectable-list-item", [], ["tagName", "span", "classNames", "LikeDislike-icon", "listItem", ["subexpr", "@mut", [["get", "option.number", ["loc", [null, [1, 698], [1, 711]]]]], [], []], "listSelection", ["subexpr", "@mut", [["get", "component.answer.number", ["loc", [null, [1, 726], [1, 749]]]]], [], []], "isSelectable", false], 0, null, ["loc", [null, [1, 620], [1, 980]]]]],
          locals: ["option"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 558
            },
            "end": {
              "line": 1,
              "column": 989
            }
          },
          "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "currentComponentController.options", ["loc", [null, [1, 584], [1, 618]]]]], [], 0, null, ["loc", [null, [1, 566], [1, 989]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1008
          }
        },
        "moduleName": "presenter/templates/survey/_like_dislike_template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "LikeDislike-wrapper");
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createMorphAt(element2, 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["LikeDislike-icons ", ["get", "component.shape", ["loc", [null, [1, 65], [1, 80]]]], " ", ["subexpr", "if", [["get", "component.hasAnswer", ["loc", [null, [1, 88], [1, 107]]]], "has-selection"], [], ["loc", [null, [1, 83], [1, 125]]]]]]], ["block", "if", [["get", "component.options", ["loc", [null, [1, 133], [1, 150]]]]], [], 0, 1, ["loc", [null, [1, 127], [1, 996]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
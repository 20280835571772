define('presenter/initializers/i18n', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'i18n',
    initialize: function initialize() {
      if (_i18nJs.default == null) {
        return;
      }
      _i18nJs.default.fallbacks = true;
      if (window._translations) {
        _i18nJs.default.translations = window._translations;
      }
      _i18nJs.default.missingTranslation = function () {
        return void 0;
      };
      return _i18nJs.default.locale = window._locale || 'en';
    }
  };
});
define('presenter/mixins/text-sizing', ['exports', 'presenter/mixins/resize-handler', 'presenter/utils', 'presenter/utils/fonts'], function (exports, _resizeHandler, _utils, _fonts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_resizeHandler.default, {
    _setupFontsChanged: function () {
      return _fonts.Fonts.on('changed', function (_this) {
        return function () {
          _this._performSizing();
          return _this._adjustFont();
        };
      }(this));
    }.on('init'),
    _sizingFunctions: function () {
      return [];
    }.property(),
    registerSizing: function registerSizing(sizingFunction) {
      return this.get('_sizingFunctions').pushObject(sizingFunction);
    },
    onResizeEnd: function onResizeEnd() {
      return this._performSizing();
    },
    _performSizing: function () {
      return Ember.run.next(function (_this) {
        return function () {
          var ref;
          return (ref = _this.get('_sizingFunctions')) != null ? ref.forEach(function (f) {
            return f.apply(_this);
          }) : void 0;
        };
      }(this));
    }.observes('value'),
    _adjustFont: function _adjustFont() {
      var fontFamily, ref, ref1, ref2;
      fontFamily = (ref = this.$()) != null ? ref.css('fontFamily') : void 0;
      if ((ref1 = this.widthDup) != null) {
        ref1.css({
          fontFamily: fontFamily
        });
      }
      return (ref2 = this.heightDup) != null ? ref2.css({
        fontFamily: fontFamily
      }) : void 0;
    },
    _widthForText: function _widthForText(text) {
      var $orig, width;
      if (!this.widthDup) {
        $orig = this.$();
        this.widthDup = this.$('<div/>').css({
          position: 'absolute',
          top: -9999,
          left: -9999,
          width: 'auto',
          paddingLeft: $orig.css('paddingLeft'),
          paddingRight: $orig.css('paddingRight'),
          fontSize: $orig.css('fontSize'),
          fontFamily: $orig.css('fontFamily'),
          fontWeight: $orig.css('fontWeight'),
          letterSpacing: $orig.css('letterSpacing'),
          textTransform: $orig.css('textTransform'),
          textIndent: 0,
          whiteSpace: 'nowrap'
        });
        this.widthDup.insertAfter($orig);
      }
      this.widthDup.show();
      this.widthDup.html(Ember.Handlebars.Utils.escapeExpression(text).replace(/\s/g, '&nbsp;'));
      width = this.widthDup.outerWidth();
      this.widthDup.hide();
      return width;
    },
    _heightForText: function _heightForText(text, fontSize) {
      var $orig, height, isTextArea, lineHeight, whiteSpace;
      if (fontSize == null) {
        fontSize = null;
      }
      $orig = this.$();
      isTextArea = $orig.is('textarea');
      if (!this.heightDup) {
        lineHeight = $orig.css('lineHeight') === 'normal' ? 'normal' : $orig.cssFloat('lineHeight') / $orig.cssFloat('fontSize') + "em";
        whiteSpace = isTextArea && $orig.css('whiteSpace') === 'normal' ? 'pre-wrap' : $orig.css('whiteSpace');
        this.heightDup = this.$('<div/>').css({
          width: 'auto',
          height: 'auto',
          paddingLeft: $orig.css('padding-left'),
          paddingTop: $orig.css('padding-top'),
          paddingRight: $orig.css('padding-right'),
          paddingBottom: $orig.css('padding-bottom'),
          fontSize: $orig.css('fontSize'),
          fontWeight: $orig.css('fontWeight'),
          fontFamily: $orig.css('fontFamily'),
          letterSpacing: $orig.css('letterSpacing'),
          textTransform: $orig.css('textTransform'),
          lineHeight: lineHeight,
          whiteSpace: whiteSpace,
          wordWrap: $orig.css('wordWrap'),
          textIndent: 0
        });
        this.heightDup.insertAfter($orig);
      }
      this.heightDup.css({
        fontSize: fontSize || $orig.css('fontSize')
      });
      this.heightDup.show();
      if (isTextArea) {
        this.heightDup.html(Ember.Handlebars.Utils.escapeExpression(text) + "&#8203;");
      } else {
        this.heightDup.html((0, _utils.escapeAndFormatWhitespace)(text || '&nbsp;'));
      }
      height = this.heightDup.height();
      this.heightDup.hide();
      return height;
    },
    willDestroyElement: function willDestroyElement() {
      var ref, ref1;
      if ((ref = this.widthDup) != null) {
        ref.remove();
      }
      if ((ref1 = this.heightDup) != null) {
        ref1.remove();
      }
      return this._super();
    }
  });
});
define("presenter/templates/svg/recipes/home", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/recipes/home.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 24 24");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        dom.setAttribute(el1, "width", "24");
        dom.setAttribute(el1, "height", "24");
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("home simple");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "class", "nc-icon-wrapper");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M22.74,12.327l-10-11c-0.397-0.437-1.084-0.437-1.48,0l-10,11c-0.266,0.293-0.334,0.715-0.174,1.078 C1.246,13.767,1.605,14,2,14h2v9c0,0.552,0.448,1,1,1h5v-6h4v6h5c0.552,0,1-0.448,1-1v-9h2c0.396,0,0.754-0.233,0.914-0.595 C23.074,13.042,23.006,12.62,22.74,12.327z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
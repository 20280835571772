define('presenter/models/salesforce-object-mapping', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceObjectMapping;

  SalesforceObjectMapping = _data.Model.extend({
    type: (0, _data.attr)('string'),
    objectName: (0, _data.attr)('string'),
    recordTypeId: (0, _data.attr)('string'),
    recordTypeDeveloperName: (0, _data.attr)('string'),
    bypassAssignmentRules: (0, _data.attr)('boolean'),
    excludeFromAutomapping: (0, _data.attr)('raw'),
    conditional: (0, _data.attr)('boolean'),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    salesforceMatchingRule: (0, _data.belongsTo)('salesforce_matching_rule', {
      serialize: true
    }),
    salesforceFieldMappings: (0, _data.hasMany)('salesforce_field_mapping', {
      serialize: true
    }),
    fieldMappingRules: [],
    saveError: null,
    salesforceObject: null,
    isCreateOrUpsert: Ember.computed.or('isCreate', 'isUpsert'),
    isDirtyOrNew: Ember.computed.or('isDirty', 'isNew'),
    isValid: function () {
      return this.get('hasValidFieldMappings') && !this.get('isUpsert') || this.get('hasValidMatchingRule');
    }.property('isUpsert', 'hasValidFieldMappings', 'hasValidMatchingRule'),
    hasValidMatchingRule: Ember.computed.oneWay('salesforceMatchingRule.isValid'),
    hasValidFieldMappings: function () {
      return this.get('salesforceFieldMappings.length') && this.get('salesforceFieldMappings').isEvery('isValid');
    }.property('salesforceFieldMappings.@each.isValid'),
    fieldMappingsByFieldName: Ember.computed.indexBy('salesforceFieldMappings', 'fieldName'),
    fieldMappingsByChoice: Ember.computed.indexBy('salesforceFieldMappings', 'choice'),
    needsSave: function () {
      return this.get('isDirtyOrNew') && this.get('isValid');
    }.property('isDirtyOrNew', 'isValid'),
    cannotSave: Ember.computed.not('needsSave'),
    actionTabRule: Ember.computed('survey.rules.@each.actions.[]', 'id', function () {
      return this.get('survey.rules').find(function (_this) {
        return function (rule) {
          return rule.get('purpose') === 'user_defined_action' && rule.get('actions.firstObject.salesforceObjectMapping.id') === _this.get('id');
        };
      }(this));
    }),
    objectMappingRule: Ember.computed('survey.rules.@each.actions.[]', function () {
      return this.get('survey.rules').find(function (_this) {
        return function (rule) {
          return rule.get('type') === 'exit' && rule.get('actions.firstObject.salesforceObjectMapping.id') === _this.get('id');
        };
      }(this));
    }),
    conditions: Ember.computed('survey.rules.@each.conditions.[]', 'id', 'conditional', function () {
      return this.get('conditional') && this.get('objectMappingRule.conditions') || [];
    })
  });

  SalesforceObjectMapping.reopenForEach(_globals.codeSettings.salesforceObjectMappingTypes, function (type, Model) {
    return Model["is" + type.camelize().titleize()] = Ember.computed.equal('type', type);
  });

  SalesforceObjectMapping.reopenClass({
    resourceName: 'salesforce_object_mapping'
  });

  exports.default = SalesforceObjectMapping;
});
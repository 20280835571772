define('presenter/components/presenter/multiple-choice-choice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'presenter/multiple-choice-choice',
    answer: null,
    choice: null,
    isSelected: function () {
      var ref;
      return !!((ref = this.get('answer.choices')) != null ? ref.findBy('id', this.get('choice.id')) : void 0);
    }.property('answer.choices.[]')
  });
});
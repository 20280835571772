define('presenter/helpers/cdnify', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var path;
    path = arg[0];
    if (path[0] !== '/') {
      path = '/' + path;
    }
    if (_globals.jsSettings.cdn.asset_host) {
      return "//" + _globals.jsSettings.cdn.asset_host + path;
    } else {
      return path;
    }
  });
});
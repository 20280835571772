define('presenter/controllers/presenter/key-driver', ['exports', 'i18n-js', 'presenter/controllers/presenter/scale', 'presenter/mixins/rating-controller-helper'], function (exports, _i18nJs, _scale, _ratingControllerHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scale.default.extend(_ratingControllerHelper.default, {
    valueAfterReset: void 0,
    numberValueWhenNA: null,
    hasAnswer: function () {
      return !Ember.isEmpty(this.get('answer.number')) || this.get('answer.notApplicable');
    }.property('answer.{number,notApplicable}'),
    ariaLabel: Ember.computed('startAt', 'number', 'response.model.language', function () {
      this.get('response.model.language');
      return _i18nJs.default.t('presenter.labels.rating.instructions', {
        startAt: this.get('startAt'),
        endAt: this.get('number')
      });
    }),
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      return this.get('answer').setProperties({
        number: this.get('valueAfterReset'),
        notApplicable: this.get('valueAfterReset')
      });
    },
    actions: {
      select: function select(value) {
        this.get('answer').setProperties({
          number: value,
          notApplicable: false
        });
        this.send('autoAdvance');
        return false;
      },
      setNotApplicable: function setNotApplicable() {
        this.get('answer').setProperties({
          number: this.get('numberValueWhenNA'),
          notApplicable: true
        });
        this.send('autoAdvance');
        return false;
      }
    }
  });
});
define('presenter/models/salesforce-mapping-failure-strategy', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceMappingFailureStrategy;

  SalesforceMappingFailureStrategy = _data.Model.extend({
    emailNotificationsEnabled: (0, _data.attr)('boolean', {
      "default": false
    }),
    emailSummariesEnabled: (0, _data.attr)('boolean', {
      "default": false
    }),
    emailSummariesSentCount: (0, _data.attr)('number', {
      "default": 0
    })
  });

  SalesforceMappingFailureStrategy.reopenClass({
    resourceName: 'salesforce_mapping_failure_strategy'
  });

  exports.default = SalesforceMappingFailureStrategy;
});
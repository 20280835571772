define('presenter/models/campaign-monitor-client', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CampaignMonitorClient;

  CampaignMonitorClient = _data.Model.extend({
    clientId: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    campaignMonitorLists: (0, _data.hasMany)('campaign_monitor_list', {
      serialize: false
    })
  });

  CampaignMonitorClient.reopenClass({
    resourceName: 'campaign_monitor_client'
  });

  exports.default = CampaignMonitorClient;
});
define('presenter/components/text-field-validation-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'components/controls/text-field-validation-error',
    classNames: ['presenter-text-field-validation-error'],
    validationError: null
  });
});
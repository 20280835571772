define('presenter/mixins/sluricane-url-helper', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    feedUrl: function feedUrl(token) {
      return _globals.jsSettings.sluricane.url + ("/feed?token=" + token + "&t=" + Date.now());
    },
    totalResponsesUrl: function totalResponsesUrl(token) {
      var queryParams;
      queryParams = {
        token: token,
        utc_offset: moment().format('Z'),
        t: Date.now()
      };
      return _globals.jsSettings.sluricane.url + ("/total?" + Qs.stringify(queryParams));
    }
  });
});
define('presenter/routes/presenter/components', ['exports', 'presenter/globals', 'presenter/models/default-closed-page', 'presenter/models/default-completed-page', 'presenter/models/invalid-page', 'presenter/models/default-thank-you-page', 'presenter/models/invalid-iframe-embed'], function (exports, _globals, _defaultClosedPage, _defaultCompletedPage, _invalidPage, _defaultThankYouPage, _invalidIframeEmbed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var components, exitPages, page, survey;
      survey = this.modelFor('presenter/survey');
      if ((0, _globals.presenterFlag)('isClosed')) {
        return [_defaultClosedPage.default.create({
          survey: survey
        })];
      } else if ((0, _globals.presenterFlag)('isDuplicateResponse')) {
        return [_defaultCompletedPage.default.create({
          survey: survey
        })];
      } else if ((0, _globals.presenterFlag)('isInvalid')) {
        return [_invalidPage.default.create({
          survey: survey
        })];
      } else if ((0, _globals.presenterFlag)('isUnallowedIframe') && _globals.isIframed) {
        return [_invalidIframeEmbed.default.create({
          survey: survey
        })];
      } else {
        components = survey.get('components').rejectBy('isUntyped');
        exitPages = components.filterBy('isExit');
        if (Ember.isEmpty(exitPages)) {
          page = _defaultThankYouPage.default.create({
            survey: survey
          });
          page.set('position', components.get('length') + 1);
          components.pushObject(page);
        } else {
          exitPages.forEach(function (_this) {
            return function (exitPage) {
              var index, length;
              if (exitPage.get('type') === 'Exit' || exitPage.get('type') === 'Redirect' && Ember.isEmpty(exitPage.get('redirectUrl'))) {
                page = _defaultThankYouPage.default.create({
                  id: exitPage.get('id'),
                  position: exitPage.get('position'),
                  survey: survey
                });
                components.removeObject(exitPage);
                index = exitPage.get('position');
                if ((length = components.get('length')) && index > length) {
                  index = length;
                }
                return components.insertAt(index, page);
              }
            };
          }(this));
        }
        return components;
      }
    },
    setupController: function setupController(controller, model) {
      var answers, route;
      this._super(controller, model);
      route = this;
      if ((answers = this.controllerFor('presenter/response').get('answers')).get('length')) {
        return controller.forEach(function (component) {
          var answer;
          if (component.get('hasGridItems')) {
            return component.get('gridItems').forEach(function (item) {
              var answer;
              if (answer = answers.findBy('gridItem.id', item.get('id'))) {
                item.set('answer', answer);
                return answer.setProperties({
                  component: component.get('model'),
                  identityMap: component.get('model.identityMap')
                });
              }
            });
          } else {
            if (answer = answers.findBy('component.id', component.get('id'))) {
              component.set('answer', answer);
              return answer.setProperties({
                component: component.get('model'),
                identityMap: component.get('model.identityMap')
              });
            }
          }
        });
      }
    },
    actions: {
      next: function next(disableNavigation, disableHistory, forceNavigation) {
        var controller, jumpComponent, ref;
        if (disableHistory == null) {
          disableHistory = false;
        }
        if (forceNavigation == null) {
          forceNavigation = false;
        }
        controller = this.controllerFor('presenter/components');
        if (controller.get('canStepForward') && (!controller.get('disableNavigation') || forceNavigation)) {
          if (!disableHistory) {
            controller.pushHistory();
          }
          controller.setProperties({
            disableNavigation: disableNavigation,
            disableInput: true
          });
          jumpComponent = controller.get('currentComponentController').evaluateRules();
          controller.set('hasManuallyAdvanced', true);
          controller.set('lastNavigationDirection', 'next');
          if (forceNavigation) {
            if ((ref = controller.get('currentComponentController')) != null) {
              ref.saveAnswer();
            }
          }
          return this.transitionTo('presenter/components.view', jumpComponent || controller.get('nextComponent'));
        } else {
          if (!controller.get('currentComponentController.completed')) {
            return controller.set('showRequiredAlert', true);
          }
        }
      },
      previous: function previous(forceNavigation) {
        var controller, previousHistory, ref;
        if (forceNavigation == null) {
          forceNavigation = false;
        }
        controller = this.controllerFor('presenter/components');
        if (controller.get('canStepBackward') && (!controller.get('disableNavigation') || forceNavigation)) {
          previousHistory = controller.popHistory();
          controller.set('lastNavigationDirection', 'previous');
          if (forceNavigation) {
            if ((ref = controller.get('currentComponentController')) != null) {
              ref.saveAnswer();
            }
          }
          return this.transitionTo('presenter/components.view', previousHistory);
        }
      },
      submitResponse: function submitResponse() {
        if ((0, _globals.presenterFlag)('isReadOnly')) {
          return;
        }
        return this.send('next');
      },
      redirect: function redirect(url) {
        url = /^(http|ftp|https):\/\//.test(url) ? url : "http://" + url;
        this.get('container').lookup('eventBus:survey').trigger('requestRedirect', url);
        if (!_globals.isEmbedded) {
          return window.location.href = url;
        }
      },
      willTransition: function willTransition(transition) {
        var ref;
        if ((ref = this.controllerFor('presenter/components').get('currentComponentController')) != null) {
          ref.saveAnswer();
        }
        this.send('hideModal');
        return false;
      },
      enableNavigationAndInput: function enableNavigationAndInput() {
        return this.controllerFor('presenter/components').setProperties({
          disableNavigation: false,
          disableInput: false
        });
      },
      openLanguageSelectorOnInit: function openLanguageSelectorOnInit() {
        var components;
        components = this.controllerFor('presenter/components');
        if (!components.get('response.content.language') && components.get('showLanguageSettings')) {
          return this.send('openLanguageSelector');
        }
      },
      openLanguageSelector: function openLanguageSelector() {
        return this.send('showModal', {
          templateName: 'components/controls/language-selector',
          className: 'LanguageSelector',
          controller: this.controllerFor('presenter/components')
        });
      }
    }
  });
});
define('presenter/models/stripe-charge', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var StripeCharge;

  StripeCharge = _data.Model.extend({
    id: (0, _data.attr)('string'),
    date: (0, _data.attr)('date'),
    amount: (0, _data.attr)('number'),
    captured: (0, _data.attr)('boolean'),
    paid: (0, _data.attr)('boolean'),
    status: (0, _data.attr)('string'),
    description: (0, _data.attr)('string'),
    failureMessage: (0, _data.attr)('string'),
    invoiceId: (0, _data.attr)('string'),
    cardBrand: (0, _data.attr)('string'),
    cardLast4: (0, _data.attr)('string'),
    quantity: (0, _data.attr)('number'),
    dateString: Ember.computed('date', function () {
      return moment(this.get('date')).format('MMM D, YYYY');
    })
  });

  StripeCharge.reopenClass({
    resourceName: 'charge'
  });

  exports.default = StripeCharge;
});
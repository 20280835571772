define('presenter/models/salesforce-field-mapping', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceFieldMapping;

  SalesforceFieldMapping = _data.Model.extend({
    fieldName: (0, _data.attr)('string'),
    fieldType: (0, _data.attr)('string'),
    mergeKey: (0, _data.attr)('string'),
    responseAttribute: (0, _data.attr)('string'),
    mapQuestionTitle: (0, _data.attr)('boolean'),
    mapComment: (0, _data.attr)('boolean'),
    mapCommentHeader: (0, _data.attr)('boolean'),
    mapCommentSentiment: (0, _data.attr)('boolean'),
    fieldRequired: (0, _data.attr)('boolean'),
    upsertScope: (0, _data.attr)('string'),
    fixedValue: (0, _data.attr)('string'),
    activityDateInterval: (0, _data.attr)('string'),
    sendSurveyResponse: (0, _data.attr)('string'),
    conditional: (0, _data.attr)('boolean'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    choice: (0, _data.belongsTo)('choice', {
      serialize: 'id'
    }),
    salesforceObjectMapping: (0, _data.belongsTo)('salesforce_object_mapping', {
      serialize: false
    }),
    actionToken: (0, _data.attr)('string'),
    rule: null,
    salesforceObject: Ember.computed.oneWay('salesforceObjectMapping.salesforceObject'),
    salesforceField: function () {
      var ref;
      return (ref = this.get('salesforceObject.fieldsByName').get(this.get('fieldName'))) != null ? ref[0] : void 0;
    }.property('salesforceObject.fields.[]', 'fieldName'),
    mappableFieldTypes: function () {
      var attribute, choice, question;
      if (this.get('mapQuestionTitle')) {
        return ['string', 'textarea'];
      } else if (this.get('mapCommentSentiment')) {
        return ['double', 'string', 'textarea'];
      } else if (choice = this.get('choice')) {
        return choice.get('mappableFieldTypes');
      } else if (question = this.get('component')) {
        if (this.get('mapComment')) {
          return ['string', 'textarea'];
        } else if (this.get('component.hasCustomValues')) {
          return this.constructor.SUPPORTED_FIELD_TYPES;
        } else {
          return question.get('mappableFieldTypes');
        }
      } else if (attribute = this.get('responseAttribute')) {
        return this.constructor.RESPONSE_ATTRIBUTES.findBy('value', attribute).mappableFieldTypes;
      } else {
        return this.constructor.SUPPORTED_FIELD_TYPES;
      }
    }.property('component.hasCustomValues', 'choice', 'mergeKey', 'responseAttribute', 'mapQuestionTitle', 'mapComment'),
    isValid: Ember.computed.and('sourceIsValid', 'targetIsValid', 'isUniqueOrValidAnswer', 'hasUpsertScope'),
    sourceIsValid: function () {
      return this.get('fixedValue') || this.get('mergeKey') || this.get('responseAttribute') || this.get('component') && (!this.get('component.hasGridItems') || this.get('gridItem')) && (!this.get('component.isForm') || this.get('choice'));
    }.property('component.{hasGridItems,isForm}', 'gridItem', 'choice', 'mergeKey', 'responseAttribute', 'fixedValue'),
    targetIsValid: Ember.computed.and('fieldExists', 'fieldName', 'fieldHasRequiredCrud', 'fieldTypeIsMappable'),
    isUnique: function () {
      return !this.get('salesforceObjectMapping.salesforceFieldMappings').find(function (_this) {
        return function (mapping) {
          return !mapping.get('conditional') && _this !== mapping && _this.get('fieldName') === mapping.get('fieldName');
        };
      }(this));
    }.property('salesforceObjectMapping.salesforceFieldMappings.@each.fieldName', 'fieldName'),
    isAnswerType: function () {
      return !this.get('mapQuestionTitle') && !!this.get('component.id');
    }.property('mapQuestionTitle', 'component.id'),
    isAnswerAndHasCollisionWithNonAnswer: function () {
      return this.get('salesforceObjectMapping.salesforceFieldMappings').find(function (_this) {
        return function (mapping) {
          return !mapping.get('conditional') && !mapping.get('isAnswerType') && _this.get('fieldName') === mapping.get('fieldName');
        };
      }(this));
    }.property('isAnswerType', 'fieldName', 'salesforceObjectMapping.salesforceFieldMappings.@each.fieldName'),
    isUniqueOrValidAnswer: function () {
      if (!this.get('isAnswerType')) {
        return this.get('isUnique');
      } else {
        return !this.get('isAnswerAndHasCollisionWithNonAnswer');
      }
    }.property('isUnique', 'isAnswerType', 'isAnswerAndHasCollisionWithNonAnswer'),
    hasUpsertScope: function () {
      return !this.get('salesforceObjectMapping.isUpsert') || this.get('upsertScope');
    }.property('salesforceObjectMapping.isUpsert', 'upsertScope'),
    fieldExists: Ember.computed.notEmpty('salesforceField'),
    fieldHasRequiredCrud: function () {
      return this.get('salesforceObjectMapping.isCreate') && this.get('salesforceField.createable') || this.get('salesforceObjectMapping.isUpdate') && this.get('salesforceField.updateable') || this.get('salesforceObjectMapping.isUpsert') && this.get('salesforceField.upsertable');
    }.property('salesforceField.{createable,updateable,upsertable}', 'salesforceObjectMapping.{isCreate,isUpdate,isUpsert}'),
    fieldTypeIsMappable: function () {
      return this.get('mappableFieldTypes').contains(this.get('salesforceField.type'));
    }.property('salesforceField.type', 'mappableFieldTypes.[]'),
    conditions: Ember.computed('salesforceObjectMapping.survey.rules.@each.conditions.[]', 'id', 'conditional', 'rule', function () {
      var mappingRule;
      if (this.get('conditional')) {
        if (this.get('rule.conditions')) {
          return this.get('rule.conditions');
        } else {
          mappingRule = this.get('salesforceObjectMapping.survey.rules').find(function (_this) {
            return function (rule) {
              return rule.get('type') === 'exit' && rule.get('actions.firstObject.salesforceFieldMapping.id') === _this.get('id');
            };
          }(this));
          if (mappingRule) {
            return mappingRule.get('conditions');
          } else {
            return [];
          }
        }
      }
    })
  });

  SalesforceFieldMapping.reopenClass({
    resourceName: 'salesforce_field_mapping',
    RESPONSE_ATTRIBUTES: [{
      value: 'survey_name',
      label: 'Survey Name',
      mappableFieldTypes: ['string', 'textarea']
    }, {
      value: 'created_at',
      label: 'Start Time',
      mappableFieldTypes: ['date', 'datetime', 'string', 'textarea']
    }, {
      value: 'completed_at',
      label: 'Completion Time',
      mappableFieldTypes: ['date', 'datetime', 'string', 'textarea']
    }, {
      value: 'viewer_url',
      label: 'Link to Response',
      mappableFieldTypes: ['url', 'string', 'textarea']
    }, {
      value: 'summary_url',
      label: 'Link to Summary Report',
      mappableFieldTypes: ['url', 'string', 'textarea']
    }, {
      value: 'id',
      label: 'API ID',
      mappableFieldTypes: ['int', 'double', 'string', 'textarea']
    }, {
      value: 'text_render',
      label: 'Full Text',
      mappableFieldTypes: ['textarea']
    }, {
      value: 'platform',
      label: 'Platform',
      mappableFieldTypes: ['string', 'textarea']
    }, {
      value: 'browser',
      label: 'Browser',
      mappableFieldTypes: ['string', 'textarea']
    }, {
      value: 'language_label',
      label: 'Language',
      mappableFieldTypes: ['string', 'textarea']
    }, {
      value: 'language_code',
      label: 'Language Code (ISO 639-1)',
      mappableFieldTypes: ['string', 'textarea']
    }, {
      value: 'ip_address',
      label: 'IP Address',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'country_name',
      label: 'Country Name',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'country_code',
      label: 'Country Code',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'region_name',
      label: 'Region Name',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'region_code',
      label: 'Region Code',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'city',
      label: 'City',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'latitude',
      label: 'Latitude',
      mappableFieldTypes: ['string', 'textarea', 'double'],
      storeIpdataAttr: true
    }, {
      value: 'longitude',
      label: 'Longitude',
      mappableFieldTypes: ['string', 'textarea', 'double'],
      storeIpdataAttr: true
    }, {
      value: 'postal_code',
      label: 'Postal Code',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }, {
      value: 'in_eu',
      label: 'In EU?',
      mappableFieldTypes: ['string', 'textarea'],
      storeIpdataAttr: true
    }],
    UPSERT_SCOPES: [{
      value: 'create_and_update',
      label: 'Create & Update'
    }, {
      value: 'create_only',
      label: 'Create Only'
    }, {
      value: 'update_only',
      label: 'Update Only'
    }],
    SUPPORTED_FIELD_TYPES: _globals.codeSettings.salesforceFieldTypes
  });

  exports.default = SalesforceFieldMapping;
});
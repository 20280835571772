define('presenter/models/salesforce-picklist-value', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforcePicklistValue;

  SalesforcePicklistValue = _data.Model.extend({
    label: (0, _data.attr)('string'),
    value: (0, _data.attr)('string')
  });

  SalesforcePicklistValue.reopenClass({
    resourceName: 'salesforce_picklist_value'
  });

  exports.default = SalesforcePicklistValue;
});
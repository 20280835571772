define('presenter/helpers/i18n', ['exports', 'i18n-js'], function (exports, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    surveyController: Ember.K,
    _recomputeOnLanguageChange: function () {
      return this.recompute();
    }.observes('surveyController.currentLanguage'),
    compute: function compute(params) {
      return new Ember.Handlebars.SafeString(_i18nJs.default.t(params[0], params[1]));
    }
  });
});
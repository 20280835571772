define('presenter/models/recipe-step-template', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RecipeStepTemplate;

  RecipeStepTemplate = _data.Model.extend({
    text: (0, _data.attr)('string'),
    stepType: (0, _data.attr)('string'),
    repeatable: (0, _data.attr)('boolean'),
    objectType: (0, _data.attr)('string'),
    childRecipeTemplates: (0, _data.hasMany)('recipe_template')
  });

  RecipeStepTemplate.reopenClass({
    resourceName: 'recipe_step_template'
  });

  exports.default = RecipeStepTemplate;
});
define('presenter/components/presenter/multiple-choice-question', ['exports', 'presenter/globals', 'presenter/components/presenter/question', 'presenter/mixins/form-field-support'], function (exports, _globals, _question, _formFieldSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend(_formFieldSupport.default, {
    autofocusInput: false,
    click: function click(e) {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          return _this._animateSelection(_this.$(e.target).closest('.is-selected'), 'backgroundColor');
        };
      }(this));
    },
    disabledTextArea: Ember.computed.not('component.showComment'),
    tabIndex: Ember.computed('component.minMaxQuestionTip', function () {
      if (this.get('component.minMaxQuestionTip')) {
        return 0;
      } else {
        return null;
      }
    }),
    onFocus: function onFocus() {
      if ((0, _globals.presenterFlag)('isReadOnly')) {
        return this._animateSelection(this.$('.is-selected'), 'backgroundColor');
      }
    },
    autoFocusCommentHeaderArea: function () {
      var element, focusComment, isFocussed;
      isFocussed = this.get('isFocussed');
      focusComment = this.get('component.showComment') && !this.get('component.multipleAnswers');
      if (isFocussed && focusComment && (element = this.$('textarea'))) {
        return Ember.run.schedule('afterRender', function (_this) {
          return function () {
            var scrollElement;
            if (element == null) {
              return;
            }
            if (!(_globals.isEmbedded && Modernizr.touch)) {
              element.focus();
            }
            scrollElement = _this.$().closest('.js-componentScrollContainer');
            return scrollElement.scrollTop(_this.pixelsToBottom(scrollElement));
          };
        }(this));
      }
    }.observes('component.showComment'),
    _preventScrollToBottom: function () {
      var scrollElement;
      if (_globals.isEmbedded && Modernizr.android) {
        scrollElement = this.$().closest('.js-componentScrollContainer');
        return scrollElement.on('scroll', this._preventScroll = function (_this) {
          return function () {
            var distance;
            distance = _this.pixelsToBottom(scrollElement);
            if (scrollElement[0].scrollTop === distance) {
              return scrollElement.scrollTop(distance - 1);
            }
          };
        }(this));
      }
    }.on('didInsertElement'),
    _removeScrollBlocker: function () {
      if (_globals.isEmbedded && Modernizr.android) {
        return this.$().closest('.js-componentScrollContainer').off('scroll', this._preventScroll);
      }
    }.on('willDestroyElement'),
    pixelsToBottom: function pixelsToBottom(scrollElement) {
      var height, scrollHeight;
      scrollHeight = scrollElement[0].scrollHeight;
      height = scrollElement.height();
      return scrollHeight - height;
    },
    onResizeEnd: function onResizeEnd() {
      var element, scrollElement;
      if (_globals.isEmbedded && Modernizr.android && (element = this.$('textarea')) && element.is(':focus')) {
        scrollElement = this.$().closest('.js-componentScrollContainer');
        return scrollElement.scrollTop(this.pixelsToBottom(scrollElement));
      }
    }
  });
});
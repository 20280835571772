define("presenter/templates/svg/visual_builder_icons/narrow", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/visual_builder_icons/narrow.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 42 18");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M26 0h2v18h-2zM14 0h2v18h-2zM33 8h9v1.94h-9z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M36.6 3L31 9l1.4 1.5 5.6-6L36.6 3z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M32.4 7.5L31 9l5.6 6 1.4-1.5-5.6-6z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M0 8.06h9V10H0z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M9.6 7.5l-5.6 6L5.4 15 11 9 9.6 7.5z");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "a");
        dom.setAttribute(el2, "d", "M5.4 3L4 4.5l5.6 6L11 9 5.4 3z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
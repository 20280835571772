define('presenter/components/presenter/rank-choice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'presenter/rank-choice',
    tagName: 'li',
    classNames: ['presenter-rank-choice-wrapper', 'needsclick', 'js-rankChoice'],
    attributeBindings: ['data-id'],
    'data-id': Ember.computed.oneWay('choice.id'),
    choice: null,
    index: null,
    position: function () {
      return this.get('index') + 1;
    }.property('index')
  });
});
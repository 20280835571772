define('presenter/instance-initializers/error-handler', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'errorHandler',
    before: 'raven',
    initialize: function initialize() {
      if (!_globals.debug) {
        return;
      }
      return Ember.onerror = function (e) {
        return console.error(e);
      };
    }
  };
});
define("presenter/templates/svg/question_types/_customer_effort", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_customer_effort.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M35.8 8c-1.8 0-3.2 1.4-3.2 3.2V23H15.4V11.2C15.4 9.5 14 8 12.2 8s-2.8 1.4-2.8 3.2v25.6c0 1.8 1 3.2 2.8 3.2s3.2-1.4 3.2-3.2V25h17.2v11.8c0 1.8 1.4 3.2 3.2 3.2 1.8 0 2.6-1.4 2.6-3.2V11.2c0-1.7-.9-3.2-2.6-3.2zM5.8 12.8c-.9 0-1.4.7-1.4 1.6v8H2.9c-.9 0-1.6.7-1.6 1.6s.7 1.6 1.6 1.6h1.6v8c0 .9.5 1.6 1.4 1.6s1.6-.7 1.6-1.6V14.4c-.1-.9-.8-1.6-1.7-1.6zM45 22.4h-1.6v-8c0-.9-.5-1.6-1.3-1.6s-1.6.7-1.6 1.6v19.2c0 .9.7 1.6 1.6 1.6s1.3-.7 1.3-1.6v-8H45c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/survey', ['exports', 'presenter/data', 'presenter/globals', 'presenter/models/survey-compact', 'presenter/mixins/themeable', 'presenter/utils/bitfields', 'presenter/mixins/change-feed-manager'], function (exports, _data, _globals, _surveyCompact, _themeable, _bitfields, _changeFeedManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Survey, calculatedRateProperty, filterCampaignByIntegration;

  calculatedRateProperty = function calculatedRateProperty(numerator, denominator) {
    return function () {
      var percent;
      if (!(this.get(denominator) > 0)) {
        return 0;
      }
      percent = this.get(numerator) / this.get(denominator) * 100;
      if (percent < 10) {
        return Math.round(percent * 10) / 10;
      } else {
        return Math.round(percent);
      }
    }.property(numerator, denominator).readOnly();
  };

  filterCampaignByIntegration = function filterCampaignByIntegration(item, integrationType) {
    var ref;
    return item.get('type') === 'TransactionalEmailCampaign' && ((ref = item.get('integrationType')) != null ? ref : "salesforce") === integrationType;
  };

  Survey = _surveyCompact.default.extend(_changeFeedManager.default, {
    status: (0, _data.attr)('number'),
    walkthrough: (0, _data.attr)('number', {
      defaultValue: 0
    }),
    language: (0, _data.attr)('string'),
    locale: (0, _data.attr)('string'),
    shareToken: (0, _data.attr)('string'),
    zendeskConnectionVoxId: (0, _data.attr)('string'),
    updatedAt: (0, _data.attr)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    startedResponsesCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    limitedResponsesCount: (0, _data.attr)('number', {
      readOnly: true
    }),
    notificationLevel: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'all';
      }
    }),
    showIncompleteResponses: (0, _data.attr)('boolean'),
    sendIncompleteResponses: (0, _data.attr)('boolean'),
    mergeKeys: (0, _data.attr)('raw', {
      readOnly: true
    }),
    embeddedAnswerKeyErrors: (0, _data.attr)('raw', {
      readOnly: true,
      defaultValue: []
    }),
    salesforceActivityMappings: (0, _data.attr)('raw'),
    pushCompletedResponses: (0, _data.attr)('boolean'),
    pushFailedResponses: (0, _data.attr)('boolean'),
    pushMissingResponses: (0, _data.attr)('boolean'),
    resyncAutomagic: (0, _data.attr)('boolean'),
    whitelabeled: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    hasPiping: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    hasIpdata: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    useRichText: (0, _data.attr)('boolean'),
    showPrivacyNotice: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    firstResponseDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    lastResponseDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    multilanguage: (0, _data.attr)('boolean'),
    enabledLanguages: (0, _data.attr)('raw', {
      serialize: true
    }),
    hasAutomagicInconsistency: (0, _data.attr)('boolean'),
    publicHostSettings: (0, _data.attr)('raw', {
      readOnly: true
    }),
    template: (0, _data.belongsTo)('template', {
      serialize: 'id'
    }),
    logoMediaObject: (0, _data.belongsTo)('media_object', {
      serialize: 'id'
    }),
    logo: (0, _data.belongsTo)('media_object', {
      serialize: 'false'
    }),
    customDomain: (0, _data.belongsTo)('custom_domain', {
      serialize: 'id'
    }),
    orderedComponents: (0, _data.hasMany)('component', {
      serialize: 'id'
    }),
    campaigns: (0, _data.hasMany)('campaign', {
      serialize: false
    }),
    salesforceObjectMappings: (0, _data.hasMany)('salesforce_object_mapping', {
      serialize: false
    }),
    pardotObjectMappings: (0, _data.hasMany)('pardot_object_mapping', {
      serialize: false
    }),
    salesforceMappingFailureStrategy: (0, _data.belongsTo)('salesforce_mapping_failure_strategy', {
      serialize: true
    }),
    exports: (0, _data.hasMany)('export', {
      serialize: false
    }),
    deletedMultipleChoiceChoices: (0, _data.hasMany)('choice', {
      serialize: false
    }),
    rules: (0, _data.hasMany)('rule', {
      serialize: false
    }),
    responseFilters: (0, _data.hasMany)('response_filter', {
      serialize: false
    }),
    summaryFilters: (0, _data.hasMany)('summary_filter', {
      serialize: false
    }),
    automagicSalesforceSync: (0, _data.attr)('boolean'),
    mergeFieldEncryption: (0, _data.attr)('boolean'),
    salesforceCredential: (0, _data.belongsTo)('credential', {
      serialize: 'id'
    }),
    draftSalesforceCredentialId: (0, _data.attr)('number', {
      defaultValue: null
    }),
    pardotCredential: (0, _data.belongsTo)('credential', {
      serialize: 'id'
    }),
    draftPardotCredentialId: (0, _data.attr)('number', {
      defaultValue: null
    }),
    lastPublished: (0, _data.attr)('date', {
      readOnly: true
    }),
    publishing: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    salesforceObjects: (0, _data.hasMany)('salesforce_object', {
      serialize: false
    }),
    pardotObjects: (0, _data.hasMany)('pardot_object', {
      serialize: false
    }),
    onlyDefinedMergeKeys: (0, _data.attr)('boolean', {
      "default": false
    }),
    discardInvalidValues: (0, _data.attr)('boolean', {
      "default": false
    }),
    definedMergeKeys: (0, _data.hasMany)('merge_key', {
      serialize: false
    }),
    serverHeldChangeCount: (0, _data.attr)('integer', {
      "default": 0,
      readOnly: true
    }),
    hasCredential: Ember.computed.or('salesforceCredential', 'pardotCredential'),
    components: Ember.computed.alias('orderedComponents'),
    orderedTypedQuestions: Ember.computed.filter('orderedComponents', function (component) {
      return component.get('type') && (component.get('isQuestion') || component.get('isSectionBreak'));
    }),
    orderedQuestions: Ember.computed.filterBy('orderedComponents', 'isQuestion', true),
    backgroundDisplayOverride: (0, _data.attr)('string'),
    answerColorOverride: (0, _data.attr)('color'),
    buttonColorOverride: (0, _data.attr)('color'),
    financialDataPaths: (0, _data.attr)('raw', {
      readOnly: true
    }),
    financialDataField: (0, _data.attr)('string', {
      defaultValue: ''
    }),
    financialDataPathsObject: Ember.computed('financialDataPaths', function () {
      var obj;
      obj = {};
      this.get('financialDataPaths').forEach(function (path) {
        var stepper;
        stepper = obj;
        return path.split('.').forEach(function (field) {
          if (stepper[field] === void 0) {
            stepper[field] = {};
          }
          return stepper = stepper[field];
        });
      });
      return obj;
    }),
    clearOverrides: function clearOverrides() {
      var attribute, fn, i, len, ref;
      this.beginPropertyChanges();
      ref = _themeable.default.ATTRIBUTES;
      fn = function (_this) {
        return function (attribute) {
          return _this.set(attribute + "Override", null);
        };
      }(this);
      for (i = 0, len = ref.length; i < len; i++) {
        attribute = ref[i];
        fn(attribute);
      }
      this.set('backgroundImageBrightness', 0);
      return this.endPropertyChanges();
    },
    hasStartedResponses: Ember.computed.gt('startedResponsesCount', 0),
    completionRate: calculatedRateProperty('completedResponsesCount', 'startedResponsesCount'),
    openedRate: calculatedRateProperty('openedEmailsCount', 'sentEmailsCount'),
    clickedRate: calculatedRateProperty('clickedEmailsCount', 'openedEmailsCount'),
    hasCoverPage: Ember.computed.equal('components.firstObject.type', 'CoverPage'),
    linkCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'WebLinkCampaign') : void 0;
    }.property('campaigns.[]'),
    webEmbedCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'WebEmbedCampaign') : void 0;
    }.property('campaigns.[]'),
    embedCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'EmbedCampaign') : void 0;
    }.property('campaigns.[]'),
    externalEmailCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'ExternalEmailCampaign') : void 0;
    }.property('campaigns.[]'),
    emailCampaigns: Ember.computed.filterBy('campaigns', 'type', 'EmailCampaign'),
    externalEmailEmbedCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'ExternalEmailEmbedCampaign') : void 0;
    }.property('campaigns.[]'),
    zendeskTransactionalEmailCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.find(function (item) {
        return filterCampaignByIntegration(item, 'zendesk');
      }) : void 0;
    }.property('campaigns.[]'),
    salesforceTransactionalEmailCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.find(function (item) {
        return filterCampaignByIntegration(item, 'salesforce');
      }) : void 0;
    }.property('campaigns.[]'),
    transactionalSmsCampaign: function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.findBy('type', 'TransactionalSmsCampaign') : void 0;
    }.property('campaigns.[]'),
    hasEnabledLanguages: function () {
      return !!this.get('enabledLanguages').length;
    }.property('enabledLanguages.[]'),
    salesforceCampaign: Ember.computed('campaigns.[]', function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.filterBy('type', 'ExternalEmailCampaign').findBy('exportType', 'sf') : void 0;
    }),
    pardotExportCampaign: Ember.computed('campaigns.[]', function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.filterBy('type', 'ExternalEmailCampaign').findBy('exportType', 'pardot') : void 0;
    }),
    pardotEmbedCampaign: Ember.computed('campaigns.[]', function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.filterBy('type', 'ExternalEmailEmbedCampaign').findBy('exportType', 'pardot') : void 0;
    }),
    customCampaign: Ember.computed('campaigns.[]', function () {
      var ref;
      return (ref = this.get('campaigns')) != null ? ref.filterBy('type', 'ExternalEmailCampaign').findBy('exportType', 'custom') : void 0;
    }),
    unsentEmailCampaign: function () {
      var emailCampaigns, shouldUseSparkpost;
      if (emailCampaigns = this.get('emailCampaigns')) {
        shouldUseSparkpost = this.get('currentUser.model.team.hasVerifiedSendToList');
        return emailCampaigns.find(function (_this) {
          return function (emailCampaign) {
            return emailCampaign.get('sparkpost') === shouldUseSparkpost && !(emailCampaign.get('isSendingOrSent') || emailCampaign.get('isFailed'));
          };
        }(this));
      }
    }.property('emailCampaigns.@each.{isSendingOrSent,isFailed}'),
    languageLabel: function () {
      return Survey.LANGUAGES.findBy('locale', this.get('language')).label;
    }.property('language'),
    dateFormat: function () {
      return Survey.DATE_FORMATS.findBy('locale', this.get('locale')).label;
    }.property('locale'),
    salesforceFieldMappings: function () {
      return this.get('salesforceObjectMappings').reduce(function (fieldMappings, objectMapping) {
        fieldMappings.pushObjects(objectMapping.get('salesforceFieldMappings.content'));
        return fieldMappings;
      }, []);
    }.property('salesforceObjectMappings.@each.length'),
    _orderingFixed: false,
    _fixOrdering: function () {
      var components, cover, exitComponents, index, nonExitComponents;
      if (!this.get('_orderingFixed') && !Ember.isEmpty(components = this.get('components'))) {
        nonExitComponents = components.rejectBy('isExit');
        exitComponents = components.filterBy('isExit').sortBy('id');
        components = nonExitComponents.concat(exitComponents);
        if ((cover = components.findBy('type', 'CoverPage')) && (index = components.indexOf(cover)) !== 0) {
          components.removeAt(index);
          components.unshiftObject(cover);
          this.get('components').replaceContent(0, components.length, components);
        }
      }
      return this.set('_orderingFixed', true);
    }.on('didLoad'),
    reloadRecord: function reloadRecord() {
      this.set('_orderingFixed', false);
      return this._super.apply(this, arguments);
    },
    sentEmailsCount: 0,
    openedEmailsCount: 0,
    clickedEmailsCount: 0,
    hasSentEmails: false,
    updateEmailCounts: function () {
      var clicked, opened, sent;
      sent = 0;
      opened = 0;
      clicked = 0;
      this.get('emailCampaigns').forEach(function (emailCampaign) {
        sent += emailCampaign.get('sentEmailsCount') || 0;
        opened += emailCampaign.get('openedEmailsCount') || 0;
        return clicked += emailCampaign.get('clickedEmailsCount') || 0;
      });
      this.set('sentEmailsCount', sent);
      this.set('openedEmailsCount', opened);
      this.set('clickedEmailsCount', clicked);
      if (this.get('sentEmailsCount') > 0 && !this.get('hasSentEmails')) {
        return this.set('hasSentEmails', true);
      }
    }.observes('emailCampaigns.[]').on('init'),
    findPreviousNPSQuestion: function findPreviousNPSQuestion(componentId) {
      var orderedComponents;
      orderedComponents = Ember.copy(this.get('orderedComponents').content);
      return orderedComponents.reverse().reduce(function (acc, comp) {
        if (comp.get('id') === componentId) {
          acc.kdFound = true;
        }
        if (acc.kdFound && comp.get('type') === 'NetPromoter' && !acc.previousNPSComponent) {
          acc.previousNPSComponent = comp;
        }
        return acc;
      }, {
        kdFound: false,
        previousNPSComponent: null
      }).previousNPSComponent;
    },
    emailCampaignById: function emailCampaignById(id) {
      return this.get('emailCampaigns').findBy('id', id);
    },
    hasEmbeddable: function () {
      return !!this.get('components').findBy('isEmailEmbeddable');
    }.property('components.@each.isEmailEmbeddable'),
    createDraft: function createDraft() {
      return $.ajax({
        type: 'POST',
        url: '/surveys/' + this.get('id') + '/create_draft'
      }).then(function (_this) {
        return function (data, textStatus, jqXHR) {
          return _this.setWithoutDirtying('draft', Survey.load(data.survey));
        };
      }(this));
    },
    discardDraft: function discardDraft() {
      return $.ajax({
        type: 'DELETE',
        url: '/surveys/' + this.get('id') + '/discard_draft'
      }).then(function (data, textStatus, jqXHR) {
        return true;
      });
    },
    publishDraft: function publishDraft() {
      return $.ajax({
        type: 'PUT',
        url: '/surveys/' + this.get('id') + '/publish_draft'
      }).then(function (data, textStatus, jqXHR) {
        return true;
      });
    }
  });

  _bitfields.default.addProperties(Survey, 'walkthrough', _globals.codeSettings.surveyWalkthroughFields);

  Survey.LANGUAGES = _globals.codeSettings.surveyLanguages;

  Survey.DATE_FORMATS = _globals.codeSettings.surveyDateFormats;

  Survey.reopenClass({
    resourceName: 'survey'
  });

  exports.default = Survey;
});
define('presenter/models/salesforce-matching-rule', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceMatchingRule;

  SalesforceMatchingRule = _data.Model.extend({
    sortOrder: (0, _data.attr)('string'),
    salesforceMatchingRuleCriteria: (0, _data.hasMany)('salesforce_matching_rule_criteria', {
      serialize: true
    }),
    isValid: function () {
      return !Ember.isEmpty(this.get('sortOrder')) && this.get('salesforceMatchingRuleCriteria.length') && this.get('salesforceMatchingRuleCriteria').isEvery('isValid');
    }.property('sortOrder', 'salesforceMatchingRuleCriteria.@each.isValid')
  });

  SalesforceMatchingRule.reopenClass({
    resourceName: 'salesforce_matching_rule'
  });

  exports.default = SalesforceMatchingRule;
});
define("presenter/templates/svg/question_types/_short_answer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_short_answer.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M10.2 39.1c3.4-1.5 6.2-3.6 8.6-6.5 2.4-2.9 3.6-5.7 3.6-8.5 0-3-.7-5.4-2.1-7.1-1.4-1.7-3.4-2.6-6-2.6-1.6 0-3.1.6-4.3 1.7-1.2 1.1-1.8 2.4-1.8 3.9 0 .7.1 1.4.3 2.1.2.7.6 1.3 1.1 1.9.6.6 1.3 1 2.2 1.4.9.3 2.1.5 3.6.5h2c-.2 2.4-1.1 4.5-2.6 6.2-1.5 1.7-3.6 3.2-6.2 4.5l1.6 2.5zm17.3 0c3.4-1.5 6.2-3.6 8.6-6.5 2.4-2.9 3.6-5.7 3.6-8.5 0-3-.7-5.4-2.1-7.1-1.4-1.7-3.4-2.6-6-2.6-1.6 0-3.1.6-4.3 1.7-1.2 1.1-1.8 2.4-1.8 3.9 0 .7.1 1.4.3 2.1.2.7.6 1.3 1.1 1.9.6.6 1.3 1 2.2 1.4.9.3 2.1.5 3.6.5h2c-.2 2.4-1.1 4.5-2.6 6.2-1.5 1.7-3.6 3.2-6.2 4.5l1.6 2.5z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
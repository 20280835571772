define('presenter/components/loading-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'components/controls/loading-image',
    classNames: 'LoadingImage',
    classNameBindings: ['isLoading', 'isFailed'],
    src: null,
    error: null,
    isLoading: false,
    isFailed: false,
    _srcChanged: function () {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          var imageLoad;
          if (!!_this.get('src')) {
            _this.setProperties({
              isLoading: true,
              isFailed: false
            });
            imageLoad = imagesLoaded(_this.$());
            return imageLoad.once('always', function () {
              return _this.setProperties({
                isLoading: false,
                isFailed: !imageLoad.images[0].isLoaded
              });
            });
          } else {
            return _this.setProperties({
              isLoading: false,
              isFailed: false
            });
          }
        };
      }(this));
    }.on('init').observes('src')
  });
});
define('presenter/models/answer-attachment', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AnswerAttachment;

  AnswerAttachment = _data.Model.extend({
    _allowMerges: true,
    file: (0, _data.attr)('string'),
    url: (0, _data.attr)('string', {
      readOnly: true
    }),
    response: (0, _data.belongsTo)('response', {
      serialize: 'id'
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    originalFile: (0, _data.attr)('string'),
    imageNum: (0, _data.attr)('number'),
    contentType: (0, _data.attr)('string'),
    ticket: (0, _data.attr)('raw', {
      readOnly: true
    })
  });

  AnswerAttachment.reopenClass({
    resourceName: 'answer_attachment'
  });

  exports.default = AnswerAttachment;
});
define('presenter/models/plan', ['exports', 'presenter/globals', 'presenter/data', 'presenter/mixins/feature-flaggable'], function (exports, _globals, _data, _featureFlaggable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Plan;

  Plan = _data.Model.extend(Ember.Comparable, _featureFlaggable.default, {
    name: (0, _data.attr)('string'),
    responses: (0, _data.attr)('number'),
    interval: (0, _data.attr)('string'),
    amount: (0, _data.attr)('number'),
    monthlyAmount: (0, _data.attr)('number'),
    payment: (0, _data.attr)('string'),
    tag: (0, _data.attr)('string'),
    tier: (0, _data.attr)('string'),
    invoiced: (0, _data.attr)('boolean'),
    support: (0, _data.attr)('string'),
    canChange: (0, _data.attr)('boolean'),
    billedByStripe: (0, _data.attr)('boolean'),
    features: (0, _data.attr)('raw'),
    teamMemberAmount: (0, _data.attr)('number'),
    teamMemberMonthlyAmount: (0, _data.attr)('number'),
    minimumQuantity: (0, _data.attr)('number'),
    liveChatAvailable: (0, _data.attr)('boolean'),
    trialDowngradeDescription: (0, _data.attr)('string', {
      readOnly: true
    }),
    free: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    paid: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    isUnlimited: Ember.computed.equal('responses', 0),
    friendlyInterval: function () {
      switch (this.get('interval')) {
        case 'monthly':
          return 'month';
        case 'annual':
        case 'biannual':
          return 'year';
      }
    }.property('interval'),
    compare: function compare(plan1, plan2) {
      var over1, over2, tiersRanking;
      tiersRanking = ['free', 'personal', 'professional', 'enterprise', 'advanced', 'corporate', 'salesforce'];
      over1 = plan1.get('isUnlimited') ? 10000 : null;
      over2 = plan2.get('isUnlimited') ? 10000 : null;
      if (plan1.get('tier') === plan2.get('tier')) {
        if (plan1.get('responses') === plan2.get('responses')) {
          return Ember.compare(over1 || plan1.get('amount'), over2 || plan2.get('amount'));
        } else {
          return Ember.compare(over1 || plan1.get('responses'), over2 || plan2.get('responses'));
        }
      } else {
        return Ember.compare(tiersRanking.indexOf(plan1.get('tier')), tiersRanking.indexOf(plan2.get('tier')));
      }
    }
  });

  Plan.INTERVALS = _globals.codeSettings.planIntervals;

  Plan.TIERS = _globals.codeSettings.planTiers;

  Plan.TAGS = ['nonprofit', 'salesforce_demo', 'free_personal'];

  Plan.reopenForEach(Plan.INTERVALS, function (interval, Plan) {
    return Plan["is" + interval.classify()] = Ember.computed.equal('interval', interval);
  });

  Plan.reopenForEach(Plan.TAGS, function (tag, Plan) {
    return Plan["is" + tag.classify()] = Ember.computed.equal('tag', tag);
  });

  Plan.reopenForEach(Plan.TIERS, function (tier, Plan) {
    return Plan["is" + tier.classify()] = Ember.computed.equal('tier', tier);
  });

  Plan.reopenClass({
    resourceName: 'plan'
  });

  exports.default = Plan;
});
define('presenter/helpers/component-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var component;
    component = arg[0];
    if (component.get('isDefault')) {
      return 'presenter/default-page-component';
    } else if (component.get('isQuestion')) {
      return "presenter/" + component.get('type').dasherize() + "-question";
    } else {
      return "presenter/" + component.get('type').dasherize() + "-component";
    }
  });
});
define("presenter/templates/svg/question_types/_salesforce_lead_form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_salesforce_lead_form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M24.3 13.2c3.6 0 6.6-2.9 6.6-6.6C30.9 3 28 0 24.3 0c-3.6 0-6.6 2.9-6.6 6.6.1 3.6 3 6.6 6.6 6.6zm23.2 5.7c-.4-.4-.9-.7-1.3-.7-.4-.2-.8-.2-1.1-.2H2.9c-.5 0-1 0-1.5.2-.3.1-.6.4-.9.6-.3.3-.5.8-.5 1.1 0 .6.3 1 .6 1.2.3.3.6.4.9.6l11.3 5.8c.2.1.5.5.5.8v.2L9.2 45.2c-.1.3-.2.6-.2 1 0 .3.1.7.4 1.1.4.4 1.2.7 1.8.7 1.1 0 1.5-.4 2.1-.7l10.3-13.1c.1-.1.3-.1.5-.1s.4.1.5.1l10.8 13.1c.5.3 1 .6 2 .7h.1c.5 0 1.3-.3 1.7-.7.4-.4.4-.8.4-1.1 0-.4-.1-.7-.2-1l-4.6-16.7v-.2c0-.3.3-.7.4-.7l11.2-5.8.9-.6c.3-.3.6-.6.6-1.2.1-.4-.1-.9-.4-1.1z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/svg/question_types/_rank", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_rank.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "clip-rule", "evenodd");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M46.2 28.1c.9-.7 1.3-1.6 1.3-2.8 0-1.8-1.1-3-3.3-3.5 1.9-.6 2.9-1.7 2.9-3.3 0-1-.4-1.7-1.1-2.3-.8-.6-1.9-.8-3.3-.8-1.3 0-2.7.2-3.7.6v1.8c1-.5 2.2-.7 3.2-.7 1.5 0 2.2.6 2.2 1.8 0 1.5-1.3 2.3-3.6 2.3H40V23h1c2.5 0 3.8.7 3.8 2.5 0 .7-.2 1.1-.7 1.5-.5.4-1.1.6-1.9.6-.8 0-2.2-.3-3.2-.8v2c1 .3 2.3.4 3.6.4 1.5 0 2.7-.4 3.6-1.1zM9 23H3.5c.2-1 1-1.9 2.2-2.9l.9-.8c1-.8 1.7-1.6 2.1-2.2.4-.6.6-1.3.6-2.1 0-1.1-.4-2-1.2-2.6-.8-.7-1.9-1-3.3-1-1.2 0-2.8.2-3.8.7V14c1-.6 2.3-1 3.3-1 1.5 0 2.3.7 2.3 2 0 1.2-.9 2.5-2.6 4.1l-1.1.9c-.7.6-1.2 1.4-1.9 2.4V25h8v-2zM29 14h-3V2.5l-5 1.3v1.6l2-.7V14h-2v2h8M33 39V27H15v9H0v11h48v-8");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/sluricane-answer', ['exports', 'presenter/data', 'presenter/globals', 'presenter/utils', 'presenter/models/component'], function (exports, _data, _globals, _utils, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SluricaneAnswer;

  SluricaneAnswer = _data.Model.extend({
    type: (0, _data.attr)('string', {
      readOnly: true
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    response: (0, _data.belongsTo)('response', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    responseRethinkId: (0, _data.attr)('string', {
      readOnly: true
    }),
    responseDate: (0, _data.attr)('date', {
      readOnly: true
    }),
    text: (0, _data.attr)('string', {
      readOnly: true
    }),
    sentences: (0, _data.attr)('raw', {
      readOnly: true
    }),
    terms: (0, _data.attr)('raw', {
      readOnly: true
    }),
    financialAmount: (0, _data.attr)('number', {
      readOnly: true
    }),
    choices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    comment: (0, _data.attr)('string', {
      readOnly: true
    }),
    formFields: (0, _data.hasMany)('form_field', {
      serialize: true
    }),
    number: (0, _data.attr)('number'),
    notApplicable: (0, _data.attr)('boolean'),
    rankedChoices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    url: Ember.computed('text', function () {
      return "https://" + _globals.imgixHosts[_globals.bucketType] + "/answer_attachments/" + this.get('text');
    }),
    displayText: (0, _data.attr)('string'),
    isTextPresent: function () {
      switch (this.get('type')) {
        case 'MultipleChoiceAnswer':
          return !!this.get('comment');
        case 'ShortAnswerAnswer':
          return !!this.get('text');
        case 'FormAnswer':
          return this.get('formFields').any(function (item) {
            return !!item.get('text');
          });
        case 'FileUploadAnswer':
        case 'FileUpload':
          return !!this.get('fileUploads');
        default:
          return false;
      }
    }.property('type', 'text', 'comment', 'formFields.[]'),
    fileUploads: function () {
      if (this.get('type') === 'FileUploadAnswer' || this.get('type') === 'FileUpload') {
        if ((0, _utils.isImageExtension)(this.get('url'))) {
          return [{
            isImage: true,
            croppedUrl: this.get('url') + "?fit=crop&crop=entropy&auto=format&w=400&h=320",
            url: this.get('url'),
            path: this.get('url').split('/').pop()
          }];
        } else {
          return [];
        }
      }
    }.property('text'),
    textValue: Ember.computed('type', 'number', function () {
      var ces_value, csat_value;
      if (this.get('type') === 'LikeDislikeAnswer') {
        switch (this.get('number')) {
          case -1:
            return 'Dislike';
          case 0:
            return 'Neutral';
          case 1:
            return 'Like';
          default:
            return 'Invalid';
        }
      } else if (this.get('type') === 'CustomerSatisfactionAnswer') {
        if (csat_value = _component.default.CSAT_VALUES.findBy('number', this.get('number'))) {
          return csat_value.number + " - " + csat_value.text;
        } else {
          return "Invalid";
        }
      } else if (this.get('type') === 'CustomerEffortAnswer') {
        if (ces_value = _component.default.CES_VALUES.findBy('number', this.get('number'))) {
          return ces_value.number + " - " + ces_value.text;
        } else {
          return "Invalid";
        }
      }
    })
  });

  SluricaneAnswer.reopenClass({
    resourceName: 'sluricane_answer',
    baseUrl: _globals.jsSettings.sluricane.url,
    endpoint: 'answers'
  });

  exports.default = SluricaneAnswer;
});
define("presenter/templates/presenter/cover-page-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 135
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-for-html-raw", [["subexpr", "merge-response-values", [["get", "component.model.markup", ["loc", [null, [1, 104], [1, 126]]]]], [], ["loc", [null, [1, 81], [1, 127]]]]], [], ["loc", [null, [1, 59], [1, 129]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 172
                },
                "end": {
                  "line": 1,
                  "column": 299
                }
              },
              "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.topMediaObject", ["loc", [null, [1, 267], [1, 297]]]]], [], []]], ["loc", [null, [1, 218], [1, 299]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 306
                },
                "end": {
                  "line": 1,
                  "column": 468
                }
              },
              "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 410], [1, 429]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.model.topMediaObject", ["loc", [null, [1, 436], [1, 466]]]]], [], []]], ["loc", [null, [1, 352], [1, 468]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 143
              },
              "end": {
                "line": 1,
                "column": 475
              }
            },
            "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "component.model.topMediaObject.isImage", ["loc", [null, [1, 178], [1, 216]]]]], [], 0, null, ["loc", [null, [1, 172], [1, 306]]]], ["block", "if", [["get", "component.model.topMediaObject.isVideo", ["loc", [null, [1, 312], [1, 350]]]]], [], 1, null, ["loc", [null, [1, 306], [1, 475]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 691
              },
              "end": {
                "line": 1,
                "column": 903
              }
            },
            "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "component-description theme-question");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 861], [1, 879]]]], "description"], [], ["loc", [null, [1, 825], [1, 894]]]]], [], ["loc", [null, [1, 802], [1, 895]]]]], [], ["loc", [null, [1, 784], [1, 897]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 135
            },
            "end": {
              "line": 1,
              "column": 910
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "component-title component-title-presenter theme-question js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.hasTopMedia", ["loc", [null, [1, 149], [1, 170]]]]], [], 0, null, ["loc", [null, [1, 143], [1, 482]]]], ["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 656], [1, 674]]]], "title"], [], ["loc", [null, [1, 620], [1, 683]]]]], [], ["loc", [null, [1, 597], [1, 684]]]]], [], ["loc", [null, [1, 579], [1, 686]]]], ["block", "if", [["get", "component.hasDescription", ["loc", [null, [1, 697], [1, 721]]]]], [], 1, null, ["loc", [null, [1, 691], [1, 910]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 943
              },
              "end": {
                "line": 1,
                "column": 1064
              }
            },
            "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 1035], [1, 1062]]]]], [], []]], ["loc", [null, [1, 986], [1, 1064]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1071
              },
              "end": {
                "line": 1,
                "column": 1227
              }
            },
            "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 1172], [1, 1191]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 1198], [1, 1225]]]]], [], []]], ["loc", [null, [1, 1114], [1, 1227]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 917
            },
            "end": {
              "line": 1,
              "column": 1234
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.mediaObject.isImage", ["loc", [null, [1, 949], [1, 984]]]]], [], 0, null, ["loc", [null, [1, 943], [1, 1071]]]], ["block", "if", [["get", "component.model.mediaObject.isVideo", ["loc", [null, [1, 1077], [1, 1112]]]]], [], 1, null, ["loc", [null, [1, 1071], [1, 1234]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1337
            },
            "end": {
              "line": 1,
              "column": 1459
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 1425], [1, 1443]]]], "buttonText"], [], ["loc", [null, [1, 1389], [1, 1457]]]]], [], ["loc", [null, [1, 1365], [1, 1459]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1459
            },
            "end": {
              "line": 1,
              "column": 1485
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Let's get started!");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1519
              },
              "end": {
                "line": 1,
                "column": 1694
              }
            },
            "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "theme-question presenter-viewer-tip");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "custom-icon-circled-info");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Use the arrow keys to easily page through this survey");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1501
            },
            "end": {
              "line": 1,
              "column": 1705
            }
          },
          "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "browser.touch", ["loc", [null, [1, 1529], [1, 1542]]]]], [], 0, null, ["loc", [null, [1, 1519], [1, 1705]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1712
          }
        },
        "moduleName": "presenter/templates/presenter/cover-page-component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "cover-page-button theme-button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 0, 0);
        morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.useRichText", ["loc", [null, [1, 6], [1, 27]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 917]]]], ["block", "if", [["get", "component.hasMedia", ["loc", [null, [1, 923], [1, 941]]]]], [], 2, null, ["loc", [null, [1, 917], [1, 1241]]]], ["element", "action", ["advance"], ["target", ["get", "component", ["loc", [null, [1, 1275], [1, 1284]]]], "on", "click"], ["loc", [null, [1, 1249], [1, 1297]]]], ["block", "if", [["get", "component.buttonText", ["loc", [null, [1, 1343], [1, 1363]]]]], [], 3, 4, ["loc", [null, [1, 1337], [1, 1492]]]], ["block", "if", [["get", "isReadOnly", ["loc", [null, [1, 1507], [1, 1517]]]]], [], 5, null, ["loc", [null, [1, 1501], [1, 1712]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});
define('presenter/initializers/modernizr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'modernizr',
    initialize: function initialize(container, app) {
      var ua;
      if (typeof Modernizr === "undefined" || Modernizr === null) {
        return;
      }
      ua = navigator.userAgent;
      Modernizr.addTest('ios', function () {
        return !!ua.match(/iPad|iPhone|iPod/i);
      });
      Modernizr.addTest('ios7', function () {
        return Modernizr.ios && !!ua.match(/.*CPU.*OS 7_\d/i);
      });
      Modernizr.addTest('android', function () {
        return !!ua.match(/android/i);
      });
      Modernizr.addTest('ie', function () {
        return !!ua.match(/msie/i) || !!ua.match(/Trident.*rv\:11\./);
      });
      Modernizr.addTest('ie9', function () {
        return !!ua.match(/msie 9/i);
      });
      Modernizr.addTest('ie10', function () {
        return !!ua.match(/msie 10/i);
      });
      Modernizr.addTest('edge', function () {
        return !!ua.match(/chrome.+? edge/i);
      });
      Modernizr.addTest('firefox', function () {
        return !!ua.match(/firefox/i);
      });
      Modernizr.addTest('chrome', function () {
        return !!ua.match(/chrome/i);
      });
      Modernizr.addTest('safari', function () {
        return !!ua.match(/safari/i) && !ua.match(/chrome/i);
      });
      Modernizr.addTest('mobile', function () {
        return window._is_mobile;
      });
      Modernizr.addTest('tablet', function () {
        return window._is_tablet;
      });
      Modernizr.addTest('zoom', function () {
        return Modernizr.testProp('zoom');
      });
      return Modernizr.addTest('transition', function () {
        return !(Modernizr.ios && !!ua.match(/.*CPU.*OS [3-5]_\d/i)) && Modernizr.csstransforms && Modernizr.csstransitions;
      });
    }
  };
});
define('presenter/models/survey-stats', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SurveyStats;

  SurveyStats = _data.Model.extend({
    completedCount: (0, _data.attr)('number'),
    incompleteCount: (0, _data.attr)('number'),
    completionPercentage: Ember.computed('completedCount', 'incompleteCount', function () {
      var totalCount;
      totalCount = this.get('completedCount') + this.get('incompleteCount') || 1;
      return Math.round(this.get('completedCount') / totalCount * 100);
    })
  });

  SurveyStats.reopenClass({
    resourceName: 'survey-stats',
    baseUrl: _globals.jsSettings.sluricane.url
  });

  exports.default = SurveyStats;
});
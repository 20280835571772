define("presenter/templates/presenter/rank-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 210
            },
            "end": {
              "line": 1,
              "column": 364
            }
          },
          "moduleName": "presenter/templates/presenter/rank-question.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/rank-choice", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [1, 344], [1, 350]]]]], [], []], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [1, 357], [1, 362]]]]], [], []]], ["loc", [null, [1, 313], [1, 364]]]]],
        locals: ["choice", "index"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 537
          }
        },
        "moduleName": "presenter/templates/presenter/rank-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "data-role", "rank-choices");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "presenter-rank-choices");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "presenter-question-tip theme-answer show-tips");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "custom-icon-circled-info");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'aria-label');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter.labels", "rank", "instructions"], [], ["loc", [null, [1, 52], [1, 118]]]], " ", ["subexpr", "translated-properties", ["presenter", "tips", ["get", "ariaLabelPath", ["loc", [null, [1, 162], [1, 175]]]]], [], ["loc", [null, [1, 119], [1, 177]]]]]]], ["block", "each", [["subexpr", "filter-missing-piped-answers", [["get", "component.calculatedDisplayChoices", ["loc", [null, [1, 248], [1, 282]]]], ["get", "component", ["loc", [null, [1, 283], [1, 292]]]]], [], ["loc", [null, [1, 218], [1, 293]]]]], [], 0, null, ["loc", [null, [1, 210], [1, 373]]]], ["inline", "translated-properties", ["presenter", "tips", ["get", "ariaLabelPath", ["loc", [null, [1, 518], [1, 531]]]]], [], ["loc", [null, [1, 475], [1, 533]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define("presenter/templates/presenter/section-break-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 135
            }
          },
          "moduleName": "presenter/templates/presenter/section-break-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-for-html-raw", [["subexpr", "merge-response-values", [["get", "component.model.markup", ["loc", [null, [1, 104], [1, 126]]]]], [], ["loc", [null, [1, 81], [1, 127]]]]], [], ["loc", [null, [1, 59], [1, 129]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 346
              },
              "end": {
                "line": 1,
                "column": 552
              }
            },
            "moduleName": "presenter/templates/presenter/section-break-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "component-description theme-question");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.id", ["loc", [null, [1, 516], [1, 528]]]], "description"], [], ["loc", [null, [1, 480], [1, 543]]]]], [], ["loc", [null, [1, 457], [1, 544]]]]], [], ["loc", [null, [1, 439], [1, 546]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 135
            },
            "end": {
              "line": 1,
              "column": 559
            }
          },
          "moduleName": "presenter/templates/presenter/section-break-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h1");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "component-title component-title-presenter theme-question js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.id", ["loc", [null, [1, 317], [1, 329]]]], "title"], [], ["loc", [null, [1, 281], [1, 338]]]]], [], ["loc", [null, [1, 258], [1, 339]]]]], [], ["loc", [null, [1, 240], [1, 341]]]], ["block", "if", [["get", "component.hasDescription", ["loc", [null, [1, 352], [1, 376]]]]], [], 0, null, ["loc", [null, [1, 346], [1, 559]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 592
              },
              "end": {
                "line": 1,
                "column": 713
              }
            },
            "moduleName": "presenter/templates/presenter/section-break-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 684], [1, 711]]]]], [], []]], ["loc", [null, [1, 635], [1, 713]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 720
              },
              "end": {
                "line": 1,
                "column": 876
              }
            },
            "moduleName": "presenter/templates/presenter/section-break-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 821], [1, 840]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 847], [1, 874]]]]], [], []]], ["loc", [null, [1, 763], [1, 876]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 566
            },
            "end": {
              "line": 1,
              "column": 883
            }
          },
          "moduleName": "presenter/templates/presenter/section-break-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.mediaObject.isImage", ["loc", [null, [1, 598], [1, 633]]]]], [], 0, null, ["loc", [null, [1, 592], [1, 720]]]], ["block", "if", [["get", "component.model.mediaObject.isVideo", ["loc", [null, [1, 726], [1, 761]]]]], [], 1, null, ["loc", [null, [1, 720], [1, 883]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 989
            },
            "end": {
              "line": 1,
              "column": 1111
            }
          },
          "moduleName": "presenter/templates/presenter/section-break-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 1077], [1, 1095]]]], "buttonText"], [], ["loc", [null, [1, 1041], [1, 1109]]]]], [], ["loc", [null, [1, 1017], [1, 1111]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1119
              },
              "end": {
                "line": 1,
                "column": 1166
              }
            },
            "moduleName": "presenter/templates/presenter/section-break-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Submit");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1166
              },
              "end": {
                "line": 1,
                "column": 1182
              }
            },
            "moduleName": "presenter/templates/presenter/section-break-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Continue");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1111
            },
            "end": {
              "line": 1,
              "column": 1189
            }
          },
          "moduleName": "presenter/templates/presenter/section-break-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.nextComponentNullOrExit", ["loc", [null, [1, 1125], [1, 1158]]]]], [], 0, 1, ["loc", [null, [1, 1119], [1, 1189]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1205
          }
        },
        "moduleName": "presenter/templates/presenter/section-break-component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "section-break-button theme-button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.useRichText", ["loc", [null, [1, 6], [1, 27]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 566]]]], ["block", "if", [["get", "component.hasMedia", ["loc", [null, [1, 572], [1, 590]]]]], [], 2, null, ["loc", [null, [1, 566], [1, 890]]]], ["element", "action", ["advance"], ["target", ["get", "component", ["loc", [null, [1, 924], [1, 933]]]], "on", "click"], ["loc", [null, [1, 898], [1, 946]]]], ["block", "if", [["get", "component.buttonText", ["loc", [null, [1, 995], [1, 1015]]]]], [], 3, 4, ["loc", [null, [1, 989], [1, 1196]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});
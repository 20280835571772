define("presenter/templates/svg/like_dislike/check_dislike", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/like_dislike/check_dislike.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "viewBox", "0 0 64 64");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "class", "nc-icon-wrapper");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M56.7,15.3l-8-8c-0.4-0.4-1-0.4-1.4,0L32,22.6L16.7,7.3c-0.4-0.4-1-0.4-1.4,0l-8,8c-0.4,0.4-0.4,1,0,1.4 L22.6,32L7.3,47.3c-0.4,0.4-0.4,1,0,1.4l8,8c0.4,0.4,1,0.4,1.4,0L32,41.4l15.3,15.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l8-8 c0.4-0.4,0.4-1,0-1.4L41.4,32l15.3-15.3C57.1,16.3,57.1,15.7,56.7,15.3z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
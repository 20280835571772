define("presenter/templates/presenter/customer-satisfaction-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 160
            },
            "end": {
              "line": 1,
              "column": 424
            }
          },
          "moduleName": "presenter/templates/presenter/customer-satisfaction-question.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/cs-rating", [], ["rating", ["subexpr", "@mut", [["get", "rating", ["loc", [null, [1, 232], [1, 238]]]]], [], []], "aria-label", ["subexpr", "translated-properties", ["presenter.labels", "csat", ["get", "rating.text", ["loc", [null, [1, 299], [1, 310]]]]], [], ["loc", [null, [1, 250], [1, 311]]]], "role", "radio", "aria-describedby", ["subexpr", "concat", ["question-title-", ["get", "component.id", ["loc", [null, [1, 368], [1, 380]]]]], [], ["loc", [null, [1, 342], [1, 381]]]], "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 389], [1, 405]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 413], [1, 422]]]]], [], []]], ["loc", [null, [1, 203], [1, 424]]]]],
        locals: ["rating"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 439
          }
        },
        "moduleName": "presenter/templates/presenter/customer-satisfaction-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "role", "radiogroup");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'aria-labelledby');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        return morphs;
      },
      statements: [["attribute", "aria-labelledby", ["concat", ["question-title-", ["get", "component.id", ["loc", [null, [1, 39], [1, 51]]]]]]], ["attribute", "class", ["concat", ["Cs-ratings Cs-ratings--csat theme-answer ", ["subexpr", "if", [["get", "component.reverse", ["loc", [null, [1, 126], [1, 143]]]], "is-reverse"], [], ["loc", [null, [1, 121], [1, 158]]]]]]], ["block", "each", [["get", "component.csatRatings", ["loc", [null, [1, 168], [1, 189]]]]], [], 0, null, ["loc", [null, [1, 160], [1, 433]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define('presenter/components/selectable-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isSelected', 'isSelectable', 'isSelectable:actionable', 'isSelected:js-selected'],
    action: null,
    listItem: null,
    listSelection: null,
    isSelectable: true,
    isSelected: function () {
      if (this.get('listItem') === null) {
        return false;
      }
      return this.get('listItem') === this.get('listSelection');
    }.property('listItem', 'listSelection'),
    click: function click() {
      if (!this.get('isSelectable')) {
        return;
      }
      this.set('listSelection', this.get('listItem'));
      if (this.get('action')) {
        return this.sendAction('action', this.get('listItem'));
      }
    }
  });
});
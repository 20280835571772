define('presenter/components/text-area', ['exports', 'presenter/mixins/field-focus-support', 'presenter/mixins/restrictable', 'presenter/mixins/autofitting', 'presenter/mixins/autogrowing', 'presenter/mixins/text-insert', 'presenter/utils'], function (exports, _fieldFocusSupport, _restrictable, _autofitting, _autogrowing, _textInsert, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var userAgent, wrap_regexp;

  userAgent = navigator.userAgent;

  wrap_regexp = userAgent.search("Chrome") >= 0 ? /[\s\+%\?$\/\(\[\{\}\\\!]/ : userAgent.search("MSIE") >= 0 ? /[\s\.,\(\)\{\}\?]/ : userAgent.search("Firefox") >= 0 ? /[\s\+%\?$\/\(\[\{\}\\\!]/ : userAgent.search("Safari") >= 0 ? /[\s\+%\?$\/\(\[\{\}\\\!]/ : /[\s\+%\?$\/\(\[\{\}\\\!]/;

  exports.default = Ember.TextArea.extend(_fieldFocusSupport.default, _restrictable.default, _autofitting.default, _autogrowing.default, _textInsert.default, {
    target: Ember.computed.alias('targetObject'),
    classNames: 'theme-text-area',
    autogrow: true,
    autofocus: false,
    actionOnFirstArrowUp: null,
    actionOnLastArrowDown: null,
    actionOnShiftEnter: null,
    actionOnFocusIn: null,
    actionOnFocusOut: null,
    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case 38:
          return this.checkArrowUp(event);
        case 40:
          return this.checkArrowDown(event);
        case 13:
          return this.checkEnter(event);
      }
    },
    checkArrowUp: function checkArrowUp(event) {
      if (this.get('actionOnFirstArrowUp') && (0, _utils.cursorPosition)(this.$()[0]) === 0) {
        event.preventDefault();
        return this.sendAction('actionOnFirstArrowUp', this);
      } else {
        return true;
      }
    },
    checkArrowDown: function checkArrowDown(event) {
      if (this.get('actionOnLastArrowDown') && (0, _utils.cursorPosition)(this.$()[0]) === this.$().val().length) {
        event.preventDefault();
        return this.sendAction('actionOnLastArrowDown', this);
      } else {
        return true;
      }
    },
    checkEnter: function checkEnter(event) {
      if (this.get('actionOnShiftEnter') && event.shiftKey) {
        event.preventDefault();
        return this.sendAction('actionOnShiftEnter', this);
      } else {
        if (this.get('action')) {
          event.preventDefault();
        }
        return true;
      }
    },
    focusIn: function focusIn() {
      if (this.get('actionOnFocusIn')) {
        return this.sendAction('actionOnFocusIn');
      }
    },
    focusOut: function focusOut() {
      if (this.get('actionOnFocusOut')) {
        return this.sendAction('actionOnFocusOut');
      }
    },
    _substringToNextBoundary: function _substringToNextBoundary(fullText, startPosition) {
      var ref, substring;
      substring = fullText.substring(0, startPosition);
      while (fullText[startPosition] && fullText[startPosition].search(wrap_regexp) < 0 && ((ref = fullText[startPosition - 1]) != null ? ref.search(wrap_regexp) : void 0) < 0) {
        startPosition += 1;
        substring = fullText.substring(0, startPosition);
      }
      return substring;
    },
    _autofocus: function () {
      if (this.get('autofocus')) {
        return this.$().focus()[0].setSelectionRange(this.get('value.length'), this.get('value.length'));
      }
    }.on('didInsertElement')
  });
});
define('presenter/models/campaign-email-header', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CampaignEmailHeader;

  CampaignEmailHeader = _data.Model.extend({
    sample: (0, _data.attr)('string'),
    type: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    number: (0, _data.attr)('number'),
    emailAddress: (0, _data.attr)('boolean'),
    originalName: (0, _data.attr)('string'),
    isDisabled: (0, _data.attr)('boolean', {
      serialize: false
    }),
    isSelected: (0, _data.attr)('boolean')
  });

  CampaignEmailHeader.TYPES = [{
    value: 'email',
    label: 'Email'
  }, {
    value: 'name',
    label: 'Name'
  }];

  CampaignEmailHeader.reopenClass({
    resourceName: 'campaign_email_header'
  });

  exports.default = CampaignEmailHeader;
});
define("presenter/templates/survey/_question_header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 150
            }
          },
          "moduleName": "presenter/templates/survey/_question_header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "theme-question js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-for-html-raw", [["subexpr", "merge-response-values", [["get", "component.model.markup", ["loc", [null, [1, 119], [1, 141]]]]], [], ["loc", [null, [1, 96], [1, 142]]]]], [], ["loc", [null, [1, 74], [1, 144]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 194
                },
                "end": {
                  "line": 1,
                  "column": 321
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.topMediaObject", ["loc", [null, [1, 289], [1, 319]]]]], [], []]], ["loc", [null, [1, 240], [1, 321]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 328
                },
                "end": {
                  "line": 1,
                  "column": 490
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 432], [1, 451]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.model.topMediaObject", ["loc", [null, [1, 458], [1, 488]]]]], [], []]], ["loc", [null, [1, 374], [1, 490]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 158
              },
              "end": {
                "line": 1,
                "column": 497
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "component.model.topMediaObject.isImage", ["loc", [null, [1, 200], [1, 238]]]]], [], 0, null, ["loc", [null, [1, 194], [1, 328]]]], ["block", "if", [["get", "component.model.topMediaObject.isVideo", ["loc", [null, [1, 334], [1, 372]]]]], [], 1, null, ["loc", [null, [1, 328], [1, 497]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 911
                  },
                  "end": {
                    "line": 1,
                    "column": 972
                  }
                },
                "moduleName": "presenter/templates/survey/_question_header.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "choice.text", ["loc", [null, [1, 957], [1, 972]]]]],
              locals: ["choice"],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 836
                },
                "end": {
                  "line": 1,
                  "column": 981
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "component.answer.choices", ["loc", [null, [1, 919], [1, 943]]]]], [], 0, null, ["loc", [null, [1, 911], [1, 981]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 981
                  },
                  "end": {
                    "line": 1,
                    "column": 1029
                  }
                },
                "moduleName": "presenter/templates/survey/_question_header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "answer.text", ["loc", [null, [1, 1014], [1, 1029]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 1029
                    },
                    "end": {
                      "line": 1,
                      "column": 1137
                    }
                  },
                  "moduleName": "presenter/templates/survey/_question_header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "component.answer.number", ["loc", [null, [1, 1110], [1, 1137]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 1029
                  },
                  "end": {
                    "line": 1,
                    "column": 1137
                  }
                },
                "moduleName": "presenter/templates/survey/_question_header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", ["#{component.answer.number}", ["subexpr", "not", [["get", "component.model.hasGridItems", ["loc", [null, [1, 1078], [1, 1106]]]]], [], ["loc", [null, [1, 1073], [1, 1107]]]]], [], ["loc", [null, [1, 1039], [1, 1108]]]]], [], 0, null, ["loc", [null, [1, 1029], [1, 1137]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 981
                },
                "end": {
                  "line": 1,
                  "column": 1137
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "component.answer.text", ["loc", [null, [1, 991], [1, 1012]]]]], [], 0, 1, ["loc", [null, [1, 981], [1, 1137]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 760
              },
              "end": {
                "line": 1,
                "column": 1150
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "presenter-screenreader-only");
            var el2 = dom.createTextNode("Answered");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "component.isMultipleChoice", ["loc", [null, [1, 847], [1, 873]]]], ["subexpr", "not", [["get", "component.model.hasGridItems", ["loc", [null, [1, 879], [1, 907]]]]], [], ["loc", [null, [1, 874], [1, 908]]]]], [], ["loc", [null, [1, 842], [1, 909]]]]], [], 0, 1, ["loc", [null, [1, 836], [1, 1144]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1162
              },
              "end": {
                "line": 1,
                "column": 1375
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "component-description theme-question");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 1333], [1, 1351]]]], "description"], [], ["loc", [null, [1, 1297], [1, 1366]]]]], [], ["loc", [null, [1, 1274], [1, 1367]]]]], [], ["loc", [null, [1, 1256], [1, 1369]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 150
            },
            "end": {
              "line": 1,
              "column": 1382
            }
          },
          "moduleName": "presenter/templates/survey/_question_header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          dom.setAttribute(el1, "role", "heading");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "component-title component-title-presenter theme-question js-questionTitle");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element5, 'id');
          morphs[2] = dom.createMorphAt(element5, 0, 0);
          morphs[3] = dom.createMorphAt(element5, 1, 1);
          morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.showTopMedia", ["loc", [null, [1, 164], [1, 192]]]]], [], 0, null, ["loc", [null, [1, 158], [1, 504]]]], ["attribute", "id", ["concat", ["question-title-", ["get", "component.id", ["loc", [null, [1, 555], [1, 567]]]]]]], ["inline", "format-for-html", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["components", ["get", "component.model.id", ["loc", [null, [1, 730], [1, 748]]]], "title"], [], ["loc", [null, [1, 694], [1, 757]]]]], [], ["loc", [null, [1, 671], [1, 758]]]]], [], ["loc", [null, [1, 653], [1, 760]]]], ["block", "if", [["get", "component.hasAnswer", ["loc", [null, [1, 766], [1, 785]]]]], [], 1, null, ["loc", [null, [1, 760], [1, 1157]]]], ["block", "if", [["get", "component.showDescription", ["loc", [null, [1, 1168], [1, 1193]]]]], [], 2, null, ["loc", [null, [1, 1162], [1, 1382]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1422
              },
              "end": {
                "line": 1,
                "column": 1543
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-image", [], ["classNames", "component-media", "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 1514], [1, 1541]]]]], [], []]], ["loc", [null, [1, 1465], [1, 1543]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1550
              },
              "end": {
                "line": 1,
                "column": 1706
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "media-video", [], ["classNames", "component-media", "playbackSwitch", ["subexpr", "@mut", [["get", "component.isCurrent", ["loc", [null, [1, 1651], [1, 1670]]]]], [], []], "media", ["subexpr", "@mut", [["get", "component.model.mediaObject", ["loc", [null, [1, 1677], [1, 1704]]]]], [], []]], ["loc", [null, [1, 1593], [1, 1706]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1389
            },
            "end": {
              "line": 1,
              "column": 1713
            }
          },
          "moduleName": "presenter/templates/survey/_question_header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "component.model.mediaObject.isImage", ["loc", [null, [1, 1428], [1, 1463]]]]], [], 0, null, ["loc", [null, [1, 1422], [1, 1550]]]], ["block", "if", [["get", "component.model.mediaObject.isVideo", ["loc", [null, [1, 1556], [1, 1591]]]]], [], 1, null, ["loc", [null, [1, 1550], [1, 1713]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2583
                },
                "end": {
                  "line": 1,
                  "column": 2840
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "data-role", "current-indicator");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'aria-label');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["concat", [["subexpr", "ordinalize-number", [["get", "item.positionPlusOne", ["loc", [null, [1, 2675], [1, 2695]]]]], [], ["loc", [null, [1, 2655], [1, 2697]]]]]]], ["attribute", "class", ["concat", ["theme-90 custom-icon-CircleFilled ", ["subexpr", "if", [["get", "item.hasAnswer", ["loc", [null, [1, 2800], [1, 2814]]]], "has-answer"], [], ["loc", [null, [1, 2795], [1, 2829]]]]]]], ["element", "action", ["trySetCurrentItem", ["get", "item.content", ["loc", [null, [1, 2728], [1, 2740]]]]], ["on", "click"], ["loc", [null, [1, 2699], [1, 2753]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2840
                },
                "end": {
                  "line": 1,
                  "column": 3068
                }
              },
              "moduleName": "presenter/templates/survey/_question_header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'aria-label');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["concat", [["subexpr", "ordinalize-number", [["get", "item.positionPlusOne", ["loc", [null, [1, 2888], [1, 2908]]]]], [], ["loc", [null, [1, 2868], [1, 2910]]]]]]], ["attribute", "class", ["concat", ["theme-40 ", ["subexpr", "if", [["get", "item.hasAnswer", ["loc", [null, [1, 2988], [1, 3002]]]], "custom-icon-CircleFilled", "custom-icon-CircleEmpty"], [], ["loc", [null, [1, 2983], [1, 3057]]]]]]], ["element", "action", ["trySetCurrentItem", ["get", "item.content", ["loc", [null, [1, 2941], [1, 2953]]]]], ["on", "click"], ["loc", [null, [1, 2912], [1, 2966]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2538
              },
              "end": {
                "line": 1,
                "column": 3075
              }
            },
            "moduleName": "presenter/templates/survey/_question_header.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "item.isCurrent", ["loc", [null, [1, 2589], [1, 2603]]]]], [], 0, 1, ["loc", [null, [1, 2583], [1, 3075]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1720
            },
            "end": {
              "line": 1,
              "column": 3096
            }
          },
          "moduleName": "presenter/templates/survey/_question_header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "question-grid-items");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "question-grid-button left custom-icon-triangle-left theme-30");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "question-grid-button right custom-icon-triangle-right theme-30");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "question-grid-item-container js-autofit-container js-gridItem");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "tabindex", "0");
          dom.setAttribute(el3, "aria-live", "polite");
          dom.setAttribute(el3, "class", "question-grid-item-centered");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "question-grid-progress-indicator");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var element3 = dom.childAt(element2, [0]);
          var element4 = dom.childAt(element2, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element3, 'aria-label');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createAttrMorph(element4, 'aria-label');
          morphs[3] = dom.createElementMorph(element4);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [2, 0]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "i18n", ["presenter.buttons.grid.previous_item"], [], ["loc", [null, [1, 1809], [1, 1856]]]]]]], ["element", "action", ["nextItem", "-1"], ["on", "click"], ["loc", [null, [1, 1858], [1, 1895]]]], ["attribute", "aria-label", ["concat", [["subexpr", "i18n", ["presenter.buttons.grid.next_item"], [], ["loc", [null, [1, 1994], [1, 2037]]]]]]], ["element", "action", ["nextItem", "1"], ["on", "click"], ["loc", [null, [1, 2039], [1, 2075]]]], ["inline", "autofit-text", [], ["classNames", "question-grid-item theme-question", "value", ["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["grid_items", ["get", "component.currentItemController.model.id", ["loc", [null, [1, 2429], [1, 2469]]]], "text"], [], ["loc", [null, [1, 2393], [1, 2477]]]]], [], ["loc", [null, [1, 2370], [1, 2478]]]]], ["loc", [null, [1, 2302], [1, 2480]]]], ["block", "each", [["get", "component.itemsWithTitles", ["loc", [null, [1, 2546], [1, 2571]]]]], [], 0, null, ["loc", [null, [1, 2538], [1, 3084]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 3103
          }
        },
        "moduleName": "presenter/templates/survey/_question_header.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.useRichText", ["loc", [null, [1, 6], [1, 27]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 1389]]]], ["block", "if", [["get", "component.model.showMedia", ["loc", [null, [1, 1395], [1, 1420]]]]], [], 2, null, ["loc", [null, [1, 1389], [1, 1720]]]], ["block", "if", [["get", "component.model.hasGridItems", ["loc", [null, [1, 1726], [1, 1754]]]]], [], 3, null, ["loc", [null, [1, 1720], [1, 3103]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});
define('presenter/models/recipient', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Recipient;

  Recipient = _data.Model.extend({
    type: (0, _data.attr)('string'),
    email: (0, _data.attr)('string'),
    phoneNumber: (0, _data.attr)('string'),
    identifier: (0, _data.attr)('string'),
    status: (0, _data.attr)('string'),
    createdAt: (0, _data.attr)('date'),
    updatedAt: (0, _data.attr)('date'),
    deliveredAt: (0, _data.attr)('date'),
    softBouncedAt: (0, _data.attr)('date'),
    hardBouncedAt: (0, _data.attr)('date'),
    unsubscribedAt: (0, _data.attr)('date'),
    openedAt: (0, _data.attr)('date'),
    startedAt: (0, _data.attr)('date'),
    completedAt: (0, _data.attr)('date'),
    failedAt: (0, _data.attr)('date'),
    campaign: (0, _data.belongsTo)('campaign', {
      serialize: false
    }),
    formattedDeliveredAt: Ember.computed('deliveredAt', function () {
      if (this.get('deliveredAt')) {
        return moment(this.get('deliveredAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedSoftBouncedAt: Ember.computed('softBouncedAt', function () {
      if (this.get('softBouncedAt')) {
        return moment(this.get('softBouncedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedHardBouncedAt: Ember.computed('hardBouncedAt', function () {
      if (this.get('hardBouncedAt')) {
        return moment(this.get('hardBouncedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedUnsubscribedAt: Ember.computed('unsubscribedAt', function () {
      if (this.get('unsubscribedAt')) {
        return moment(this.get('unsubscribedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedOpenedAt: Ember.computed('openedAt', function () {
      if (this.get('openedAt')) {
        return moment(this.get('openedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedStartedAt: Ember.computed('startedAt', function () {
      if (this.get('startedAt')) {
        return moment(this.get('startedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedCompletedAt: Ember.computed('completedAt', function () {
      if (this.get('completedAt')) {
        return moment(this.get('completedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    formattedFailedAt: Ember.computed('failedAt', function () {
      if (this.get('failedAt')) {
        return moment(this.get('failedAt')).format('MMMM Do YYYY, h:mm:ss a');
      }
    }),
    hasError: Ember.computed('status', function () {
      return ['undelivered', 'failed', 'over_send_limit', 'previously_unsubscribed', 'invalid_unsubscribe_link_url', 'invalid_email'].includes(this.get('status'));
    }),
    humanizedStatus: Ember.computed('status', function () {
      return this.get('status').replace(/_/g, ' ').titleize();
    })
  });

  Recipient.reopenClass({
    resourceName: 'recipient'
  });

  exports.default = Recipient;
});
define("presenter/templates/presenter/slider", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 321
          }
        },
        "moduleName": "presenter/templates/presenter/slider.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "slider-control needsclick");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "track needsclick");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "range");
        dom.setAttribute(el2, "min", "1");
        dom.setAttribute(el2, "max", "100");
        dom.setAttribute(el2, "aria-live", "polite");
        dom.setAttribute(el2, "class", "dragger needsclick");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'aria-label');
        morphs[1] = dom.createAttrMorph(element1, 'value');
        morphs[2] = dom.createAttrMorph(element1, 'aria-valuenow');
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["subexpr", "merge-response-values", [["subexpr", "translated-properties", ["presenter.labels", "slider", "instructions"], [], ["loc", [null, [1, 41], [1, 107]]]]], [], ["loc", [null, [1, 17], [1, 109]]]]]]], ["attribute", "value", ["get", "value", ["loc", [null, [1, 228], [1, 233]]]]], ["attribute", "aria-valuenow", ["get", "value", ["loc", [null, [1, 252], [1, 257]]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/address', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Address;

  Address = _data.Model.extend({
    name: (0, _data.attr)('string'),
    streetLine1: (0, _data.attr)('string'),
    streetLine2: (0, _data.attr)('string'),
    city: (0, _data.attr)('string'),
    stateProvince: (0, _data.attr)('string'),
    postalCode: (0, _data.attr)('string'),
    country: (0, _data.attr)('string'),
    email: (0, _data.attr)('string'),
    sendReceipts: (0, _data.attr)('boolean'),
    team: (0, _data.belongsTo)('team', {
      serialize: 'id'
    })
  });

  Address.reopenClass({
    resource: 'address'
  });

  exports.default = Address;
});
define('presenter/models/export', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Export;

  Export = _data.Model.extend({
    type: (0, _data.attr)('string'),
    includeStarted: (0, _data.attr)('boolean'),
    progress: (0, _data.attr)('number', {
      readOnly: true
    }),
    url: (0, _data.attr)('string', {
      readOnly: true
    }),
    createdAt: (0, _data.attr)('date', {
      readOnly: true
    }),
    dateFrom: (0, _data.attr)('date'),
    dateTo: (0, _data.attr)('date'),
    sendEmail: (0, _data.attr)('boolean'),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    hasDateRange: Ember.computed.or('dateFrom', 'dateTo')
  });

  Export.reopenClass({
    resourceName: 'export'
  });

  exports.default = Export;
});
define('presenter/models/abstract-user', ['exports', 'presenter/data', 'presenter/globals', 'presenter/utils/bitfields'], function (exports, _data, _globals, _bitfields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AbstractUser;

  AbstractUser = _data.Model.extend({
    email: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    phone: (0, _data.attr)('string'),
    status: (0, _data.attr)('string'),
    source: (0, _data.attr)('string'),
    permissions: (0, _data.attr)('number'),
    hasInvalidCard: (0, _data.attr)('boolean'),
    cardExpirationDate: (0, _data.attr)('string'),
    team: (0, _data.belongsTo)('team', {
      serialize: 'id'
    })
  });

  _bitfields.default.addProperties(AbstractUser, 'permissions', _globals.codeSettings.userPermissionsFields);

  exports.default = AbstractUser;
});
define('presenter/models/activity-push', ['exports', 'presenter/models/salesforce-push'], function (exports, _salesforcePush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ActivityPush;

  ActivityPush = _salesforcePush.default.extend({
    isActivityPush: true
  });

  ActivityPush.reopenClass({
    resourceName: 'activity_push'
  });

  exports.default = ActivityPush;
});
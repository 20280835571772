define('presenter/models/choice-stats', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ChoiceStats;

  ChoiceStats = _data.Model.extend({
    choiceId: (0, _data.attr)('number', {
      readOnly: true
    }),
    answerCount: (0, _data.attr)('number', {
      readOnly: true,
      defaultValue: 0
    }),
    percentage: (0, _data.attr)('number', {
      readOnly: true,
      defaultValue: 0
    }),
    financialAmount: (0, _data.attr)('number', {
      readOnly: true,
      defaultValue: 0
    }),
    average: (0, _data.attr)('number', {
      readOnly: true,
      defaultValue: 0
    }),
    distribution: (0, _data.attr)('raw', {
      readOnly: true
    })
  });

  ChoiceStats.reopenClass({
    resourceName: 'choice_stats'
  });

  exports.default = ChoiceStats;
});
define('presenter/components/presenter/like-dislike-question', ['exports', 'presenter/components/presenter/question'], function (exports, _question) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend({
    value: Ember.computed.alias('component.answer.number'),
    _likeDislikeChanged: function () {
      return Ember.run.schedule('afterRender', function (_this) {
        return function () {
          return _this._animateSelection(_this.$('.is-selected').find('svg'), 'fill');
        };
      }(this));
    }.observes('value').on('didInsertElement')
  });
});
define('presenter/components/presenter/date-field-label', ['exports', 'presenter/mixins/aria'], function (exports, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aria.default, {
    tagName: 'label',
    classNames: ['DateField-label', 'theme-text'],
    classNameBindings: ['hidden'],
    hidden: false,
    click: function click() {
      return this.set('hidden', true);
    }
  });
});
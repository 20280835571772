define('presenter/models/crosstab', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Crosstab;

  Crosstab = _data.Model.extend({
    componentId: (0, _data.attr)('number')
  });

  Crosstab.reopenClass({
    resourceName: 'crosstabs',
    baseUrl: _globals.jsSettings.sluricane.url,
    endpoint: 'crosstab'
  });

  exports.default = Crosstab;
});
define('presenter/components/presenter/slider-question', ['exports', 'presenter/components/presenter/question'], function (exports, _question) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend({
    enableTouch: function () {
      return Modernizr.touch;
    }.property()
  });
});
define('presenter/components/slider-input', ['exports', 'presenter/mixins/touchable', 'presenter/mixins/resize-handler'], function (exports, _touchable, _resizeHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_touchable.default, _resizeHandler.default, {
    minimum: 0,
    maximum: 100,
    step: null,
    values: null,
    equalSteps: false,
    animate: false,
    enableTouch: false,
    segments: 0,
    readOnly: false,
    value: 0,
    layoutName: 'slider',
    classNames: ['slider-container'],
    touchable: function () {
      if (this.get('enableTouch')) {
        return '.slider-control';
      }
    }.property(),
    range: function () {
      var values, valuesAsArray;
      if (values = this.get('values')) {
        valuesAsArray = values.toArray();
        return Ember.Object.create({
          min: Math.min.apply(Math, valuesAsArray),
          max: Math.max.apply(Math, valuesAsArray)
        });
      } else {
        return Ember.Object.create({
          min: parseInt(this.get('minimum')),
          max: parseInt(this.get('maximum'))
        });
      }
    }.property('values', 'minimum', 'maximum').readOnly(),
    didInsertElement: function didInsertElement() {
      var j, ref, results, segments, width;
      this._super.apply(this, arguments);
      this.$dragger = this.$('.dragger');
      this.$track = this.$('.track');
      this.$slider = this.$('.slider-control');
      Ember.$('body').on("mousemove.slider-" + this.elementId, function (_this) {
        return function (event) {
          if (_this.get('dragging')) {
            return _this._domDrag(event.pageX, event.pageY);
          }
        };
      }(this)).on("mouseup.slider-" + this.elementId, function (_this) {
        return function (event) {
          if (_this.get('dragging')) {
            return _this.set('dragging', false);
          }
        };
      }(this));
      if ((segments = this.get('segments')) > 0) {
        width = 100 / segments;
        (function () {
          results = [];
          for (var j = 1, ref = segments - 1; 1 <= ref ? j <= ref : j >= ref; 1 <= ref ? j++ : j--) {
            results.push(j);
          }
          return results;
        }).apply(this).forEach(function (_this) {
          return function (i) {
            var cssClass;
            cssClass = i === segments / 2 ? 'tick mid' : 'tick';
            return _this.$track.append("<div class='" + cssClass + "' style='width: " + width + "%;'></div>");
          };
        }(this));
      }
      return Ember.run.schedule('afterRender', this, this._initSlider);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').off(".slider-" + this.elementId);
      return this._super.apply(this, arguments);
    },
    focusIn: function focusIn(e) {
      this.set('dragging', true);
      return Ember.$(e.target).mousedown();
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 38) {
        e.preventDefault();
        return this.set('value', this._nearestValidValue(this.get('value') + 1));
      } else if (e.keyCode === 40) {
        e.preventDefault();
        return this.set('value', this._nearestValidValue(this.get('value') - 1));
      }
    },
    mouseDown: function mouseDown(event) {
      var $target;
      if (this.get('readOnly')) {
        return;
      }
      $target = Ember.$(event.target);
      if (!(event.which === 1 && (this.get('enableTouch') || $target.is(this.$track) || $target.is(this.$dragger) || $target.is('.tick')))) {
        return;
      }
      this._domDrag(event.pageX, event.pageY);
      return this.set('dragging', true);
    },
    onResize: function onResize() {
      return this._initSlider();
    },
    _initSlider: function _initSlider() {
      this.$slider.css({
        minHeight: Math.max(this.$dragger.outerHeight(), this.get('enableTouch') ? 44 : 0),
        marginLeft: this.$dragger.outerWidth() / 2,
        marginRight: this.$dragger.outerWidth() / 2
      });
      this.$track.css({
        marginTop: this.$track.outerHeight() / -2
      });
      this.$dragger.css({
        marginTop: this.$dragger.outerWidth() / -2,
        marginLeft: this.$dragger.outerWidth() / -2
      });
      return this._setSliderPosition();
    },
    _domDrag: function _domDrag(pageX, pageY) {
      var newPagePosition, ratio;
      newPagePosition = pageX - this.$slider.offset().left;
      newPagePosition = Math.min(this.$slider.outerWidth(), newPagePosition);
      newPagePosition = Math.max(0, newPagePosition);
      ratio = newPagePosition / this.$slider.outerWidth();
      return this.set('value', this._ratioToValue(ratio));
    },
    _setSliderPosition: function () {
      var position;
      if (!this.$()) {
        return;
      }
      position = this._valueToRatio(this.get('value')) * this.$slider.outerWidth();
      if (this.get('animating')) {
        this.$dragger.stop();
      }
      if (this.get('animate') && !this.get('dragging')) {
        this.set('animating', true);
        return this.$dragger.animate({
          left: position
        }, 100, function (_this) {
          return function () {
            if (!_this.get('isDestroyed')) {
              return _this.set('animating', false);
            }
          };
        }(this));
      } else {
        return this.$dragger.css({
          left: position
        });
      }
    }.observes('value'),
    _ratioToValue: function _ratioToValue(ratio) {
      var index, step, steps;
      if (this.get('values')) {
        steps = this.get('values.length');
        step = Math.round(ratio * steps - 0.5);
        index = Math.min(step, steps - 1);
        return this.get('values').objectAt(index);
      } else {
        return this._nearestValidValue(ratio * (this.get('range.max') - this.get('range.min')) + this.get('range.min'));
      }
    },
    _valueToRatio: function _valueToRatio(value) {
      var closest, closestIndex, values;
      if (values = this.get('values')) {
        closest = null;
        closestIndex = null;
        values.forEach(function (allowedValue, index) {
          var closestIdx;
          if (!closest || Math.abs(allowedValue - value) < Math.abs(closest - value)) {
            closest = allowedValue;
            return closestIdx = index;
          }
        });
        return closestIndex / (values.get('length') - 1);
      } else {
        return (value - this.get('range.min')) / (this.get('range.max') - this.get('range.min'));
      }
    },
    _nearestValidValue: function _nearestValidValue(value) {
      var closest, max, maxSteps, min, step, steps, values;
      min = this.get('range.min');
      max = this.get('range.max');
      value = Math.min(max, value);
      value = Math.max(min, value);
      if (values = this.get('values')) {
        closest = null;
        values.forEach(function (v) {
          if (!closest || Math.abs(v - value) < Math.abs(closest - value)) {
            return closest = v;
          }
        });
        return closest;
      } else if (step = this.get('step')) {
        maxSteps = (max - min) / step;
        steps = Math.floor((value - min) / step);
        if ((value - min) % step > step / 2 && steps < maxSteps) {
          steps += 1;
        }
        return steps * step + min;
      } else {
        return value;
      }
    }
  });
});
define('presenter/components/bourbon-modal-overlay', ['exports', 'bourbon/components/bourbon-modal-overlay'], function (exports, _bourbonModalOverlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bourbonModalOverlay.default;
    }
  });
});
define('presenter/components/presenter/other-choice-text-field', ['exports', 'presenter/components/presenter/text-field'], function (exports, _textField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _textField.default.extend({
    targetObject: Ember.computed.oneWay('target'),
    classNames: ['other-choice-field', 'theme-text'],
    choice: null,
    answer: null,
    valueBinding: 'answer.text',
    click: function click(event) {
      return event.stopPropagation();
    },
    _toggleAfterChange: function () {
      if (Ember.isBlank(this.get('value')) && this.get('isSelected') || !Ember.isBlank(this.get('value')) && !this.get('isSelected')) {
        return this.send('toggle', this.get('choice'));
      }
    }.observes('value'),
    isSelected: function () {
      var ref;
      return (ref = this.get('answer.choices')) != null ? ref.findBy('id', this.get('choice.id')) : void 0;
    }.property('answer.choices.[]', 'choice')
  });
});
define('presenter/initializers/session', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'sessionDeathDetector',
    initialize: function initialize(container, app) {
      var httpUnauthorized;
      httpUnauthorized = 401;
      return Ember.$(document).on('ajaxComplete', function (_, xhr) {
        if (xhr.status === httpUnauthorized) {
          return top.location.href = _globals.codeSettings.newSessionUrl;
        }
      });
    }
  };
});
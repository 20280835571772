define('presenter/controllers/presenter/rating-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectController.extend({
    question: Ember.computed.oneWay('parentController'),
    isSelected: function () {
      if (this.get('question.hasNumberScale')) {
        return this.get('content') === this.get('question.component.answer.number');
      } else {
        return this.get('content') <= this.get('question.component.answer.number');
      }
    }.property('content', 'question.component.{hasNumberScale,answer.number}')
  });
});
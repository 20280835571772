define('presenter/components/presenter-survey-view', ['exports', 'presenter/mixins/resize-handler', 'presenter/mixins/rem-resizer', 'presenter/globals', 'presenter/utils/fonts'], function (exports, _resizeHandler, _remResizer, _globals, _fonts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var footerHeight, verticalPadding;

  footerHeight = 60;

  verticalPadding = 20;

  exports.default = Ember.Component.extend(_resizeHandler.default, _remResizer.default, {
    classNames: ['presenter-survey'],
    classNameBindings: ['isQuestionColorBright:theme-dark:theme-light', 'isReadOnly', 'isEmbedded:is-widget', 'isDrawer:is-drawer', 'iFramedNonWebsiteSurveys:is-iframed-non-website', 'isPreview'],
    iFramedNonWebsiteSurveys: _globals.isIframed && !_globals.isEmbedded,
    isReadOnly: (0, _globals.presenterFlag)('isReadOnly'),
    isDrawer: (0, _globals.presenterFlag)('isDrawer'),
    isEmbedded: _globals.isEmbedded,
    isPreview: _globals.isPreview,
    workspaceAdjustment: {
      width: 0,
      height: -footerHeight - 2 * verticalPadding
    },
    idealFit: function () {
      if (_globals.isEmbedded) {
        return {
          width: 250,
          height: 325
        };
      } else {
        return {
          width: 700,
          height: 750
        };
      }
    }.property(),
    scaleBounds: function () {
      if (_globals.isEmbedded) {
        return {
          min: 0.5,
          max: 0.75
        };
      } else {
        return {
          min: 0.7,
          max: 1
        };
      }
    }.property(),
    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.scheduleOnce('render', this, this.onResize);
      if ((0, _globals.presenterFlag)('isClosed')) {
        return this.show();
      } else {
        return Ember.run.scheduleOnce('afterRender', this, this.waitForLoads);
      }
    },
    waitForLoads: function waitForLoads() {
      var $images, bgImage, fonts, images, ref;
      $images = this.$('.CoverPage img');
      bgImage = (ref = this.$().css('background-image')) != null ? ref.match(/url\((['"])?(.*?)\1\)/) : void 0;
      if (bgImage) {
        $images = $images.add(Ember.$('<img>').attr('src', bgImage.pop()));
      }
      images = $images.length ? Ember.RSVP.resolve($images.imagesLoaded()) : Ember.RSVP.resolve();
      if (_fonts.Fonts.get('finished')) {
        fonts = Ember.RSVP.resolve();
      } else {
        fonts = new Ember.RSVP.Promise(function (resolve) {
          return _fonts.Fonts.one('loaded', fonts, resolve);
        });
      }
      this.showTimer = Ember.run.later(this, this.show, 5000);
      return Ember.RSVP.all([images, fonts]).then(function (_this) {
        return function () {
          return _this.show();
        };
      }(this));
    },
    show: _once(function () {
      return Ember.run.schedule('afterRender', this, function () {
        var $spinner, spinnerClasses;
        Ember.run.cancel(this.showTimer);
        $spinner = Ember.$('.js-loadSpinner');
        spinnerClasses = $spinner.attr('class');
        $spinner.attr('class', spinnerClasses + ' ready');
        this.$().addClass('ready');
        Ember.$('body').addClass('ready');
        Ember.run.later(this, function () {
          return $spinner.remove();
        }, 200);
        this.sendAction('assetsLoaded');
        if ((0, _globals.presenterFlag)('isDrawer')) {
          return this.set('resizeEnabled', false);
        }
      });
    }),
    dragOver: function dragOver(e) {
      return e.preventDefault();
    },
    drop: function drop(e) {
      return e.preventDefault();
    }
  });
});
define('presenter/components/text-field', ['exports', 'presenter/mixins/field-focus-support', 'presenter/mixins/restrictable', 'presenter/mixins/validatable', 'presenter/mixins/autogrowing', 'presenter/mixins/text-insert'], function (exports, _fieldFocusSupport, _restrictable, _validatable, _autogrowing, _textInsert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend(_fieldFocusSupport.default, _restrictable.default, _validatable.default, _autogrowing.default, _textInsert.default, {
    target: Ember.computed.alias('targetObject'),
    classNameBindings: ['value::empty'],
    autogrow: false,
    triggerActionOnFocusOut: true,
    triggerActionOnFocusIn: false,
    actionOnFocusIn: '',
    actionOnFocusOut: '',
    onFocusOutOrEnter: null,
    autofocus: false,
    _autofocusText: function () {
      if (this.get('autofocus')) {
        return this.$().focus();
      }
    }.on('didInsertElement'),
    focusIn: function focusIn(event) {
      this._super.apply(this, arguments);
      if (this.get('triggerActionOnFocusIn')) {
        this.sendAction('action', this.get('value'), this);
      }
      if (this.get('actionOnFocusIn')) {
        return this.sendAction('actionOnFocusIn');
      }
    },
    focusOut: function focusOut(event) {
      this._super.apply(this, arguments);
      if (this.get('onFocusOutOrEnter')) {
        this.get('onFocusOutOrEnter')(this.get('value'));
      }
      if (this.get('triggerActionOnFocusOut')) {
        this.sendAction('action', this.get('value'), this);
      }
      if (this.get('actionOnFocusOut')) {
        return this.sendAction('actionOnFocusOut');
      }
    },
    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        if (this.actionOnEnter) {
          this.actionOnEnter();
        }
        if (this.get('onFocusOutOrEnter')) {
          this.get('onFocusOutOrEnter')(this.get('value'));
        }
      }
      this._super.apply(this, arguments);
      if (event.keyCode === 13) {
        return event.preventDefault();
      }
    }
  });
});
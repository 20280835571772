define("presenter/templates/svg/rich-text/italic", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "presenter/templates/svg/rich-text/italic.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "width", "44");
        dom.setAttribute(el1, "height", "44");
        dom.setAttribute(el1, "viewBox", "0 0 44 44");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "id", "italic");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "id", "italic-2");
        dom.setAttribute(el3, "d", "M1258.05,951l-0.18.838a7.415,7.415,0,0,1,.85.158,4.967,4.967,0,0,1,.91.286,0.925,0.925,0,0,1,.44.343,0.9,0.9,0,0,1,.1.444,1.763,1.763,0,0,1-.01.229l-0.03.228-2.98,13.178a1.694,1.694,0,0,1-.34.755,1.6,1.6,0,0,1-.73.489,3.721,3.721,0,0,1-.92.152c-0.43.034-.76,0.06-0.99,0.076l-0.18.838h7.93l0.19-.838a9.156,9.156,0,0,1-.94-0.146,4.147,4.147,0,0,1-.82-0.209,0.976,0.976,0,0,1-.45-0.356,0.955,0.955,0,0,1-.12-0.5,1.5,1.5,0,0,1,.01-0.2,1.023,1.023,0,0,1,.04-0.2l2.98-13.163a2.489,2.489,0,0,1,.33-0.754,1.307,1.307,0,0,1,.74-0.508,10.8,10.8,0,0,1,1.92-.3l0.19-.838h-7.94Z");
        dom.setAttribute(el3, "transform", "translate(-1238 -938)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/components/presenter/date-base', ['exports', 'presenter/components/presenter/text-field', 'presenter/utils'], function (exports, _textField, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _textField.default.extend({
    restrictNumeric: true,
    classNameBindings: ['isInvalid'],
    attributeBindings: ['maxlength'],
    isInvalid: function () {
      return !this.get('focused') && !this.get('isValid') || this.get('focused') && !this.get('isValid') && this.get('isFull');
    }.property('isValid', 'focused', 'isFull'),
    min: null,
    max: null,
    maxlength: null,
    dateField: null,
    monthValue: null,
    dayValue: null,
    yearValue: null,
    hasMin: Ember.computed.notEmpty('min'),
    hasMax: Ember.computed.notEmpty('max'),
    hasMinAndMax: Ember.computed.and('min', 'max'),
    valuePresent: Ember.computed.notEmpty('value'),
    valueEmpty: Ember.computed.empty('value'),
    focused: false,
    complete: false,
    onComplete: 'autoAdvance',
    validations: true,
    _initValue: function () {
      return this.set('value', this.get('validValue'));
    }.on('init'),
    isValid: function () {
      return this.get('valueEmpty') || this.get('isWithinMinAndMax') && this.get('validations');
    }.property('valueEmpty', 'isWithinMinAndMax', 'validations'),
    _validChanged: function () {
      if (this.get('isValid')) {
        return this.set('validValue', this.get('value'));
      } else {
        return this.set('validValue', null);
      }
    }.observes('isValid'),
    intValue: function () {
      var value;
      if ((value = this.get('value')) && !Ember.isEmpty(value)) {
        return parseInt(value, 10);
      }
    }.property('value'),
    intDay: function () {
      return this._intValueFor(this.get('dayValue'));
    }.property('dayValue'),
    intMonth: function () {
      return this._intValueFor(this.get('monthValue'));
    }.property('monthValue'),
    intYear: function () {
      return this._intValueFor(this.get('yearValue'));
    }.property('yearValue'),
    daysInMonth: function () {
      var month, year;
      month = this.get('intMonth');
      year = this.get('intYear');
      return (0, _utils.daysInMonth)(month - 1, year);
    }.property('intMonth', 'intYear'),
    isFull: function () {
      return this.get('value').length === this.get('maxlength');
    }.property('maxlength', 'value'),
    isWithinMinAndMax: function () {
      var hasMinAndMax, intValue;
      hasMinAndMax = this.get('hasMinAndMax');
      if (!hasMinAndMax) {
        return true;
      }
      return (intValue = this.get('intValue')) && intValue >= this.get('min') && intValue <= this.get('max');
    }.property('hasMinAndMax', 'intValue', 'min', 'max'),
    focusedChanged: function () {
      var element;
      element = this.$();
      if (element == null) {
        return;
      }
      if (this.get('focused')) {
        return element.focus();
      } else {
        return element.blur();
      }
    }.observes('focused'),
    completeChanged: function () {
      if (this.get('complete') && this.get('isValid')) {
        return this.sendAction('onComplete');
      }
    }.observes('complete'),
    valueChanged: function () {
      return this.set('complete', this._isComplete());
    }.observes('value'),
    focusIn: function focusIn(event) {
      this.set('complete', false);
      return this.set('focused', true);
    },
    focusOut: function focusOut(event) {
      this._setPaddedValue();
      return this.set('focused', false);
    },
    _setPaddedValue: function _setPaddedValue() {
      var intValue, paddedValue;
      intValue = this.get('intValue');
      paddedValue = this._paddedIntValueAsString(intValue);
      if (paddedValue != null) {
        return this.set('value', paddedValue);
      }
    },
    _intValueFor: function _intValueFor(value) {
      if (!Ember.isEmpty(value)) {
        return parseInt(value, 10);
      }
    },
    _paddedIntValueAsString: function _paddedIntValueAsString(intValue, padChar) {
      if (padChar == null) {
        padChar = '0';
      }
      if (intValue != null && intValue >= 1 && intValue < 10) {
        return "" + padChar + intValue;
      } else {
        if (intValue != null && !isNaN(intValue)) {
          return intValue.toString();
        }
      }
    }
  });
});
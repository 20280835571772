define("presenter/templates/svg/question_types/_salesforce_contact_form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_salesforce_contact_form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "clip-rule", "evenodd");
        dom.setAttribute(el2, "d", "M34.2 24H48v-4.8H34.2V24zm-11.4 1.2c1.8-2.3 4.2-4.8 4.2-8.4 0-4.2-3-9.6-9-9.6s-9 5.4-9 9.6c0 3.5 2.4 6.1 4.2 8.4 2.3 3-7.2 5.4-10.8 9C.6 36 0 37.8 0 39c0 1.7 1.8 1.8 1.8 1.8h32.4S36 40.7 36 39c0-1.2-.5-3-2.4-4.8-3.7-3.4-13.1-6-10.8-9zm11.4-13.8v4.8H48v-4.8H34.2z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/mixins/grid-controller-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentItem: Ember.computed.oneWay('component.gridItems.firstObject'),
    currentItemController: function () {
      return this.get('items').findBy('id', this.get('currentItem.id'));
    }.property('items.@each.id', 'currentItem.id'),
    currentDirection: 1,
    items: function () {
      return Ember.ArrayController.create({
        itemController: this._getItemControllerName(),
        contentBinding: 'parentController.model.gridItems',
        container: this.get('container'),
        parentController: this,
        target: this
      });
    }.property(),
    willDestroy: function willDestroy() {
      this.get('items').destroy();
      return this._super();
    },
    _setCurrentItem: function _setCurrentItem(item, direction) {
      if (direction == null) {
        direction = 0;
      }
      this.set('currentDirection', direction);
      return this.set('currentItem', item);
    },
    _getItemControllerName: Ember.K,
    actions: {
      trySetCurrentItem: function trySetCurrentItem(item) {
        if (this.isItemMissingTitle && this.isItemMissingTitle(item)) {} else {
          return this._setCurrentItem.apply(this, arguments);
        }
      },
      setCurrentItem: function setCurrentItem() {
        return this._setCurrentItem.apply(this, arguments);
      },
      nextItem: function nextItem(direction) {
        var dir, forceAdvance, item, items, navigateOnlyWithinGrid, shouldUpdateIsMissingTitle;
        if (direction == null) {
          direction = 1;
        }
        dir = parseInt(direction);
        shouldUpdateIsMissingTitle = false;
        navigateOnlyWithinGrid = true;
        forceAdvance = false;
        if (dir > 0 && this.showNext) {
          return this.showNext(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid, forceAdvance);
        } else if (dir < 0 && this.showPrevious) {
          return this.showPrevious(shouldUpdateIsMissingTitle, navigateOnlyWithinGrid);
        } else {
          items = this.get('component.gridItems');
          if (item = items.objectAt(items.indexOf(this.get('currentItem')) + dir)) {
            this._setCurrentItem(item, dir);
            return item;
          }
        }
      }
    }
  });
});
define("presenter/templates/svg/response_graph/live", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/response_graph/live.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "Layer_1");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("style");
        var el3 = dom.createTextNode(".st0-live{fill:none;stroke-width:2;stroke-miterlimit:10;} .st1-live{fill:none;stroke-width:2;stroke-linecap:square;stroke-miterlimit:10;}");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st0-live");
        dom.setAttribute(el2, "d", "M27.9 10.7C25.9 6.2 21.2 3 16 3 8.8 3 3 8.8 3 16");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "class", "st1-live");
        dom.setAttribute(el2, "d", "M20.2 9.7l7.7 1 1-7.7M28.3 20.1c.4-1.3.7-2.7.7-4.1M25.2 25.2c-.9.9-2 1.7-3.2 2.3M11.9 28.3c1.3.4 2.7.7 4.1.7M6.8 25.2c-.9-.9-1.7-2-2.3-3.2");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
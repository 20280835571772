define("presenter/templates/svg/_play_button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_play_button.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "preserveAspectRatio", "xMidYMid");
        dom.setAttribute(el1, "width", "32");
        dom.setAttribute(el1, "height", "32");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm5.696-16.513l-8.562-5.3c-.624-.41-1.134-.108-1.134.67v10.288c0 .776.51 1.077 1.134.668l8.562-5.3S22 16.3 22 16c0-.3-.304-.513-.304-.513z");
        dom.setAttribute(el2, "class", "cls-1");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define("presenter/templates/components/controls/icon-picker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 221
              },
              "end": {
                "line": 1,
                "column": 408
              }
            },
            "moduleName": "presenter/templates/components/controls/icon-picker.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "IconPicker-dropdownMenu-item");
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "IconPicker-dropdownMenu-link");
            var el3 = dom.createElement("img");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0, 0]);
            var element1 = dom.childAt(element0, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createAttrMorph(element1, 'src');
            return morphs;
          },
          statements: [["element", "action", ["selectIcon", ["get", "icon.filename", ["loc", [null, [1, 312], [1, 325]]]]], ["on", "click"], ["loc", [null, [1, 290], [1, 338]]]], ["attribute", "src", ["get", "icon.path", ["loc", [null, [1, 387], [1, 396]]]]]],
          locals: ["icon"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 422
            }
          },
          "moduleName": "presenter/templates/components/controls/icon-picker.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "data-role", "trigger");
          dom.setAttribute(el1, "class", "IconPicker-selection");
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "Dropdown-menu IconPicker-dropdownMenu js-menu");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0, 0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'src');
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "src", ["get", "selectedIconPath", ["loc", [null, [1, 140], [1, 156]]]]], ["block", "each", [["get", "icons", ["loc", [null, [1, 229], [1, 234]]]]], [], 0, null, ["loc", [null, [1, 221], [1, 417]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 436
          }
        },
        "moduleName": "presenter/templates/components/controls/icon-picker.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "drop-down", [], ["classNames", "IconPicker-dropdown", "readOnly", ["subexpr", "@mut", [["get", "readOnly", ["loc", [null, [1, 55], [1, 63]]]]], [], []], "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 71], [1, 75]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [1, 436]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
define("presenter/helpers/date-field-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (arg) {
    var dateField;
    dateField = arg[0];
    return "presenter/date-" + dateField;
  });
});
define("presenter/templates/components/controls/media-image", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 162
          }
        },
        "moduleName": "presenter/templates/components/controls/media-image.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "MediaImage-imageWrapper");
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "alt", "");
        dom.setAttribute(el2, "class", "MediaImage-image");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createAttrMorph(element0, 'tabindex');
        morphs[2] = dom.createAttrMorph(element1, 'src');
        morphs[3] = dom.createAttrMorph(element1, 'style');
        return morphs;
      },
      statements: [["attribute", "style", ["get", "wrapperStyle", ["loc", [null, [1, 13], [1, 25]]]]], ["attribute", "tabindex", -1], ["attribute", "src", ["get", "responsiveUrl", ["loc", [null, [1, 87], [1, 100]]]]], ["attribute", "style", ["get", "imageStyle", ["loc", [null, [1, 111], [1, 121]]]]]],
      locals: [],
      templates: []
    };
  }());
});
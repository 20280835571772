define('presenter/models/user', ['exports', 'presenter/data', 'presenter/globals', 'presenter/models/abstract-user', 'presenter/utils/bitfields', 'presenter/models/survey'], function (exports, _data, _globals, _abstractUser, _bitfields, _survey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var User;

  User = _abstractUser.default.extend({
    walkthrough: (0, _data.attr)('number'),
    timeZone: (0, _data.attr)('string'),
    oldPassword: (0, _data.attr)('string'),
    password: (0, _data.attr)('string'),
    passwordConfirmation: (0, _data.attr)('string'),
    generateNewApiToken: (0, _data.attr)('boolean'),
    websiteWaitlist: (0, _data.attr)('boolean'),
    requestedSalesforceAccess: (0, _data.attr)('boolean'),
    isStripeCustomer: (0, _data.attr)('boolean'),
    confirmedAccount: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    createdOwnPassword: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    apiToken: (0, _data.attr)('string', {
      readOnly: true
    }),
    defaultTab: (0, _data.attr)('string', {
      readOnly: true
    }),
    surveys: (0, _data.hasMany)('survey', {
      serialize: false
    }),
    folders: (0, _data.hasMany)('folder', {
      serialize: false
    }),
    campaignMonitorCredential: (0, _data.belongsTo)('credential', {
      serialize: false
    }),
    salesforceCredentials: (0, _data.hasMany)('credential', {
      serialize: false
    }),
    pardotCredentials: (0, _data.hasMany)('credential', {
      serialize: false
    }),
    customer: (0, _data.belongsTo)('customer', {
      serialize: false
    }),
    sfdcLeadId: (0, _data.attr)('string', {
      readOnly: true
    }),
    hasStripeDetails: Ember.computed.notEmpty('customer.currentPeriodEnd'),
    hasSalesforceCredentials: Ember.computed.notEmpty('salesforceCredentials'),
    hasPardotCredentials: Ember.computed.notEmpty('pardotCredentials')
  });

  User.reopenClass({
    resourceName: 'user',
    STATUSES: _globals.codeSettings.userStatuses,
    endpoint: 'account'
  });

  _bitfields.default.addProperties(User, 'walkthrough', _globals.codeSettings.userWalkthroughFields);

  exports.default = User;
});
define('presenter/mixins/rem-resizer', ['exports', 'presenter/utils/layout'], function (exports, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    workspaceSelector: window,
    workspaceAdjustment: {
      width: 0,
      height: 0
    },
    idealFit: {
      width: 200,
      height: 200
    },
    scaleBounds: {
      min: 0.7,
      max: 1
    },
    _initFontSize: function () {
      this.onResize();
      return _layout.default.trigger('resize');
    }.on('didInsertElement'),
    _resetFontSize: function () {
      return Ember.$('html').css({
        'font-size': ''
      });
    }.on('willDestroyElement'),
    onResize: function onResize() {
      var $workspace, availableHeight, availableWidth, heightScale, maxScale, scale, widthScale;
      this._super.apply(this, arguments);
      $workspace = Ember.$(this.get('workspaceSelector'));
      availableWidth = $workspace.width() + this.get('workspaceAdjustment.width');
      availableHeight = $workspace.height() + this.get('workspaceAdjustment.height');
      widthScale = availableWidth / this.get('idealFit.width');
      heightScale = availableHeight / this.get('idealFit.height');
      maxScale = Math.min(widthScale, heightScale, this.get('scaleBounds.max'));
      scale = Math.max(this.get('scaleBounds.min'), maxScale);
      return Ember.$('html').css({
        'font-size': Math.round(scale * 10000) / 100 + "%"
      });
    }
  });
});
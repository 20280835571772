define('presenter/controllers/presenter/customer-effort', ['exports', 'presenter/controllers/presenter/scale', 'presenter/mixins/cs-questions-helper'], function (exports, _scale, _csQuestionsHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scale.default.extend(_csQuestionsHelper.default, {
    locale: Ember.computed.oneWay('answer.response.language'),
    actions: {
      select: function select(value) {
        this.set('answer.number', value);
        return this.send('autoAdvance');
      }
    }
  });
});
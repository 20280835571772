define("presenter/templates/presenter/net-promoter-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 308
            },
            "end": {
              "line": 1,
              "column": 504
            }
          },
          "moduleName": "presenter/templates/presenter/net-promoter-question.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/net-promoter-rating", [], ["aria-label", ["subexpr", "@mut", [["get", "rating", ["loc", [null, [1, 390], [1, 396]]]]], [], []], "role", "button", "value", ["subexpr", "@mut", [["get", "rating", ["loc", [null, [1, 417], [1, 423]]]]], [], []], "hideLabels", ["subexpr", "@mut", [["get", "component.model.hideLabels", ["loc", [null, [1, 435], [1, 461]]]]], [], []], "answer", ["subexpr", "@mut", [["get", "component.answer", ["loc", [null, [1, 469], [1, 485]]]]], [], []], "target", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 493], [1, 502]]]]], [], []]], ["loc", [null, [1, 347], [1, 504]]]]],
        locals: ["rating"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 519
            },
            "end": {
              "line": 1,
              "column": 998
            }
          },
          "moduleName": "presenter/templates/presenter/net-promoter-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "NetPromoter-ratingLabels theme-question");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "NetPromoter-ratingLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "NetPromoter-ratingLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(element0, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element1, 'aria-label');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          morphs[2] = dom.createAttrMorph(element2, 'aria-label');
          morphs[3] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter.labels", "nps", "not_likely_full"], [], ["loc", [null, [1, 621], [1, 689]]]]]]], ["inline", "translated-properties", ["presenter.labels", "nps", "not_likely"], [], ["loc", [null, [1, 723], [1, 786]]]], ["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter.labels", "nps", "extremely_likely_full"], [], ["loc", [null, [1, 809], [1, 883]]]]]]], ["inline", "translated-properties", ["presenter.labels", "nps", "extremely_likely"], [], ["loc", [null, [1, 917], [1, 986]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1015
          }
        },
        "moduleName": "presenter/templates/presenter/net-promoter-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "NetPromoter-ratings-wrapper");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "NetPromoter-ratings");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element3, 'aria-label');
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]), 0, 0);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter.labels", "nps", "instructions"], [], ["loc", [null, [1, 17], [1, 82]]]], " ", ["subexpr", "translated-properties", ["presenter.labels", "nps", "not_likely_full"], [], ["loc", [null, [1, 83], [1, 151]]]], " ", ["subexpr", "translated-properties", ["presenter.labels", "nps", "extremely_likely_full"], [], ["loc", [null, [1, 152], [1, 226]]]]]]], ["block", "each", [["get", "component.ratings", ["loc", [null, [1, 316], [1, 333]]]]], [], 0, null, ["loc", [null, [1, 308], [1, 513]]]], ["block", "unless", [["get", "component.hideLabels", ["loc", [null, [1, 529], [1, 549]]]]], [], 1, null, ["loc", [null, [1, 519], [1, 1009]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
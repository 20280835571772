define('presenter/models/pardot-field', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PardotField;

  PardotField = _data.Model.extend({
    name: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    type: (0, _data.attr)('type'),
    required: (0, _data.attr)('boolean'),
    createable: (0, _data.attr)('boolean'),
    updateable: (0, _data.attr)('boolean'),
    referenceTo: (0, _data.attr)('raw'),
    notRequired: Ember.computed.not('required'),
    normalizedLabel: function () {
      var label;
      label = this.get('label');
      if (this.get('type') === 'id') {
        label = 'ID';
      } else if (this.get('type') === 'reference') {
        label = label.replace(/\sID$/, '');
      }
      return label;
    }.property('label')
  });

  PardotField.reopenClass({
    resourceName: 'pardot_field'
  });

  exports.default = PardotField;
});
define('presenter/models/automagic-push', ['exports', 'presenter/data', 'presenter/models/salesforce-push'], function (exports, _data, _salesforcePush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AutomagicPush;

  AutomagicPush = _salesforcePush.default.extend({
    isAutomagicPush: true
  });

  AutomagicPush.reopenClass({
    resourceName: 'automagic_push'
  });

  exports.default = AutomagicPush;
});
define('presenter/models/merge-key', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.StandardValueTypes = undefined;
  var MergeKey,
      indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }return -1;
  };

  MergeKey = _data.Model.extend({
    name: (0, _data.attr)('string'),
    valueType: (0, _data.attr)('string'),
    valueTypeLabel: (0, _data.attr)('string'),
    keyPrefix: (0, _data.attr)('string'),
    locked: (0, _data.attr)('boolean', {
      readOnly: true
    }),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    humanValueType: Ember.computed.or('valueTypeLabel', 'valueType'),
    hasSalesforceValueType: Ember.computed('valueType', function () {
      var ref;
      return ref = this.get('valueType'), indexOf.call(StandardValueTypes.mapBy('type'), ref) < 0;
    })
  });

  MergeKey.reopenClass({
    resourceName: 'merge_key'
  });

  var StandardValueTypes = exports.StandardValueTypes = [{
    label: 'Text',
    type: 'text'
  }, {
    label: 'Number',
    type: 'number'
  }, {
    label: 'Email',
    type: 'email'
  }, {
    label: 'Date',
    type: 'date'
  }];

  exports.default = MergeKey;
});
define('presenter/models/average', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Average;

  Average = _data.Model.extend({
    choiceId: (0, _data.attr)('number'),
    avg: (0, _data.attr)('number')
  });

  Average.reopenClass({
    resourceName: 'average'
  });

  exports.default = Average;
});
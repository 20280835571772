define('presenter/models/pardot-object-mapping', ['exports', 'presenter/data', 'presenter/globals'], function (exports, _data, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PardotObjectMapping;

  PardotObjectMapping = _data.Model.extend({
    type: (0, _data.attr)('string'),
    objectName: (0, _data.attr)('string'),
    conditional: (0, _data.attr)('boolean'),
    survey: (0, _data.belongsTo)('survey', {
      serialize: 'id'
    }),
    pardotFieldMappings: (0, _data.hasMany)('pardot_field_mapping', {
      serialize: true
    }),
    init: function init() {
      return this.set('fieldMappingRules', []);
    },
    label: Ember.computed('objectName', function () {
      return this.get('objectName').classify();
    }),
    saveError: null,
    pardotObject: null,
    isDirtyOrNew: Ember.computed.or('isDirty', 'isNew'),
    isValid: function () {
      return this.get('hasValidFieldMappings');
    }.property('hasValidFieldMappings'),
    hasValidFieldMappings: function () {
      return this.get('pardotFieldMappings.length') && this.get('pardotFieldMappings').isEvery('isValid');
    }.property('pardotFieldMappings.@each.isValid'),
    fieldMappingsByFieldName: Ember.computed.indexBy('pardotFieldMappings', 'fieldName'),
    fieldMappingsByChoice: Ember.computed.indexBy('pardotFieldMappings', 'choice'),
    needsSave: function () {
      return this.get('isDirtyOrNew') && this.get('isValid');
    }.property('isDirtyOrNew', 'isValid'),
    cannotSave: Ember.computed.not('needsSave'),
    objectMappingRule: Ember.computed('survey.rules.[]', function () {
      return this.get('survey.rules').find(function (_this) {
        return function (rule) {
          return rule.get('type') === 'exit' && rule.get('actions.firstObject.pardotObjectMapping.id') === _this.get('id');
        };
      }(this));
    }),
    conditions: Ember.computed('survey.rules.@each.conditions.[]', 'id', 'conditional', function () {
      return this.get('conditional') && this.get('objectMappingRule.conditions') || [];
    })
  });

  PardotObjectMapping.reopenForEach(_globals.codeSettings.pardotObjectMappingTypes, function (type, Model) {
    return Model["is" + type.camelize().titleize()] = Ember.computed.equal('type', type);
  });

  PardotObjectMapping.reopenClass({
    resourceName: 'pardot_object_mapping'
  });

  exports.default = PardotObjectMapping;
});
define('presenter/models/invalid-iframe-embed', ['exports', 'presenter/models/default-page'], function (exports, _defaultPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _defaultPage.default.extend({
    isExit: true,
    type: 'InvalidPage',
    isBranded: false,
    title: 'You cannot embed survey iframes',
    description: "It looks like your plan doesn't support multichannel embeds (displaying your survey via an iframe). Please upgrade your plan to use this feature."
  });
});
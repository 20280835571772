define('presenter/controllers/presenter/rank', ['exports', 'i18n-js', 'presenter/controllers/presenter/component', 'presenter/mixins/randomizable-choices'], function (exports, _i18nJs, _component, _randomizableChoices) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_randomizableChoices.default, {
    visibleChoicesCount: null,
    hasChanged: function () {
      return this.set('isChanged', true);
    }.observes('answer.rankedChoices.[]'),
    pipingAnswer: function pipingAnswer(itemIndex) {
      var answer, choice;
      answer = this.get('answer');
      if (!answer) {
        return;
      }
      if (Number.isFinite(itemIndex)) {
        if (choice = answer.get('rankedChoices').objectAt(itemIndex)) {
          return _i18nJs.default.t("choices." + choice.get('id') + ".text");
        } else {}
      } else if (!Ember.isEmpty(this.get('rankedChoices'))) {
        return answer.get('rankedChoices').map(function (choice) {
          return _i18nJs.default.t("choices." + choice.get('id') + ".text");
        }).join(', ');
      }
    },
    clearAnswer: function clearAnswer() {
      if (!this.get('answer')) {
        return;
      }
      return this.get('answer.rankedChoices').clear();
    },
    rankedChoices: Ember.computed('answer.rankedChoices.content', {
      get: function get(key) {
        return this.get('answer.rankedChoices.content');
      },
      set: function set(key, value) {
        var length;
        length = this.get('answer.rankedChoices.length');
        if (length) {
          this.get('answer.rankedChoices').replaceContent(0, length, value);
        } else {
          this.get('answer.rankedChoices').pushObjects(value);
        }
        return value;
      }
    }),
    calculatedDisplayChoices: function () {
      var added, choicesById, rankedChoices;
      if (this.get('rankedChoices.length')) {
        choicesById = this.get('choicesById');
        added = {};
        rankedChoices = this.get('rankedChoices').map(function (_this) {
          return function (choice) {
            added[choice.get('id')] = true;
            return choicesById[choice.get('id')];
          };
        }(this));
        Object.keys(choicesById).map(function (_this) {
          return function (id) {
            var c;
            if (!added[id]) {
              c = choicesById[id];
              return rankedChoices.push(c);
            }
          };
        }(this));
        return rankedChoices;
      } else {
        return this.get('displayChoices');
      }
    }.property('displayChoices', 'rankedChoices.[]'),
    choicesById: function () {
      return this.get('choices').reduce(function (agg, choice) {
        agg["" + choice.get('id')] = choice;
        return agg;
      }, {});
    }.property('choices.@each.id'),
    onComputeFilteredChoices: function onComputeFilteredChoices(filteredChoices) {
      var answerChoices;
      if (this.get('answer.rankedChoices')) {
        answerChoices = this.get('answer.rankedChoices.content');
        if (this.areChoicesDifferent(answerChoices, filteredChoices)) {
          this.set('rankedChoices', filteredChoices);
        }
        return this.set('visibleChoicesCount', filteredChoices.length);
      }
    },
    areChoicesDifferent: function areChoicesDifferent(choices1, choices2) {
      if (choices1.length !== choices2.length) {
        return true;
      }
      return choices1.some(function (choice, index) {
        return choice.get('id') !== choices2[index].get('id');
      });
    },
    skipQuestionIfEmpty: function skipQuestionIfEmpty() {
      this.computeFilteredChoices(this.get('calculatedDisplayChoices'));
      if (this.get('visibleChoicesCount') !== null && this.get('visibleChoicesCount') === 0) {
        if (this.get('components.lastNavigationDirection') === 'next') {
          this.send('forceAutoAdvanceToNextQuestion');
          return true;
        } else if (this.get('components.lastNavigationDirection') === 'previous') {
          this.send('forceAutoAdvanceToPreviousQuestion');
          return true;
        }
      }
      return false;
    }
  });
});
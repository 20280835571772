define('presenter/components/presenter/cs-rating', ['exports', 'i18n-js', 'presenter/mixins/aria'], function (exports, _i18nJs, _aria) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_aria.default, {
    tagName: 'button',
    classNames: ['Cs-rating', 'theme-border-20', 'theme-answer'],
    classNameBindings: ['isSelected:theme-selected:theme-background-screen', 'isSelected:is-selected'],
    layoutName: ['presenter/cs-rating'],
    attributeBindings: ['isSelected:aria-checked'],
    answer: null,
    rating: null,
    translationLabelType: function () {
      if (this.get('answer.component.type') === 'CustomerEffort') {
        return 'ces';
      } else if (this.get('answer.component.type') === 'CustomerSatisfaction') {
        return 'csat';
      }
    }.property('answer.component.type'),
    isSelected: function () {
      return this.get('rating.number') === this.get('answer.number');
    }.property('rating.number', 'answer.number'),
    click: function click() {
      return this.send('select', this.get('rating.number'));
    }
  });
});
define("presenter/templates/svg/_recipes_container_flag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_recipes_container_flag.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "viewBox", "0 0 26 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "d", "M22.073,11.000 L25.843,18.553 C25.998,18.863 25.981,19.231 25.799,19.526 C25.616,19.820 25.296,20.000 24.949,20.000 L4.985,20.000 C4.434,20.000 3.987,19.552 3.987,19.000 L3.987,3.000 C3.987,2.448 4.434,2.000 4.985,2.000 L24.949,2.000 C25.296,2.000 25.616,2.180 25.798,2.474 C25.980,2.769 25.997,3.137 25.842,3.447 L22.073,11.000 ZM0.992,32.000 C0.441,32.000 -0.006,31.552 -0.006,31.000 L-0.006,1.000 C-0.006,0.448 0.441,0.000 0.992,0.000 C1.543,0.000 1.990,0.448 1.990,1.000 L1.990,31.000 C1.990,31.552 1.543,32.000 0.992,32.000 Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
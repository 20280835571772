define('presenter/models/salesforce-report', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceReport;

  SalesforceReport = _data.Model.extend({
    reportId: (0, _data.attr)('string'),
    name: (0, _data.attr)('string'),
    instanceId: (0, _data.attr)('string'),
    disableParent: false
  });

  SalesforceReport.reopenClass({
    resourceName: 'salesforce_report'
  });

  exports.default = SalesforceReport;
});
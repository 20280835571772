define('presenter/components/presenter/date-year', ['exports', 'presenter/components/presenter/date-base'], function (exports, _dateBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateBase.default.extend({
    name: 'year',
    classNames: ['DateField-inputField', 'yyyy'],
    validYearOffset: 250,
    format: 'YYYY',
    maxlength: 4,
    validValue: Ember.computed.alias('dateField.yearValue'),
    dayValue: Ember.computed.oneWay('dateField.dayValue'),
    monthValue: Ember.computed.oneWay('dateField.monthValue'),
    placeholder: Ember.computed.oneWay('dateField.yearPlaceholder'),
    focused: Ember.computed.alias('dateField.yearFocused'),
    validations: true,
    currentYear: function () {
      return new Date().getFullYear();
    }.property(),
    min: function () {
      return this.get('currentYear') - this.get('validYearOffset');
    }.property('currentYear', 'validYearOffset'),
    max: function () {
      return this.get('currentYear') + this.get('validYearOffset');
    }.property('currentYear', 'validYearOffset'),
    _isComplete: function _isComplete() {
      return !Ember.isEmpty(this.get('value')) && this.get('isValid');
    }
  });
});
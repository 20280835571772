define('presenter/mixins/text-insert', ['exports', 'presenter/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    lastCursorPosition: 0,
    textToInsert: null,
    init: function init() {
      this._super();
      return this.on('focusOut', this, this.removeFocus);
    },
    insertText: function () {
      var value;
      if (this.get('textToInsert')) {
        value = this.get('value') || '';
        this.set('value', value.splice(this.get('textToInsert'), this.get('lastCursorPosition')));
        return this.set('textToInsert', null);
      }
    }.observes('textToInsert'),
    removeFocus: function removeFocus() {
      return this.set('lastCursorPosition', (0, _utils.cursorPosition)(this.$()[0]));
    }
  });
});
define('presenter/models/response', ['exports', 'ember-restless', 'presenter/globals', 'presenter/data'], function (exports, _emberRestless, _globals, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Response;

  Response = _data.Model.extend({
    token: (0, _emberRestless.attr)('string'),
    answers: (0, _emberRestless.hasMany)('answer'),
    status: (0, _emberRestless.attr)('string'),
    limited: (0, _emberRestless.attr)('boolean'),
    emailSentAt: (0, _emberRestless.attr)('date', {
      printableName: 'Email Sent At Date'
    }),
    emailOpenedAt: (0, _emberRestless.attr)('date', {
      printableName: 'Email Opened At Date'
    }),
    emailClickedAt: (0, _emberRestless.attr)('date', {
      printableName: 'Email Link Clicked At Date'
    }),
    createdAt: (0, _emberRestless.attr)('date', {
      printableName: 'Response Date'
    }),
    mergeMap: (0, _emberRestless.attr)('raw'),
    financialData: (0, _emberRestless.attr)('raw', {
      printableName: 'Financial Data'
    }),
    embeddedAnswerMap: (0, _emberRestless.attr)('raw', {
      defaultValue: {}
    }),
    retrySalesforcePush: (0, _emberRestless.attr)('boolean'),
    pushStatus: (0, _emberRestless.attr)('string'),
    salesforcePushStatus: (0, _emberRestless.attr)('string'),
    campaignToken: (0, _emberRestless.attr)('string'),
    campaign: (0, _emberRestless.belongsTo)('campaign'),
    activityPushes: (0, _emberRestless.hasMany)('activity_push'),
    objectMappingPushes: (0, _emberRestless.hasMany)('object_mapping_push'),
    automagicPushes: (0, _emberRestless.hasMany)('automagic_push'),
    fieldMappingPushes: (0, _emberRestless.hasMany)('field_mapping_push'),
    pardotPushes: (0, _emberRestless.hasMany)('pardot_push'),
    triggeredActions: (0, _emberRestless.hasMany)('action', {
      serialize: 'id'
    }),
    language: (0, _emberRestless.attr)('string', {
      printableName: 'Language Code'
    }),
    identifier: (0, _emberRestless.attr)('string', {
      readOnly: true
    }),
    ipdata: (0, _emberRestless.attr)('raw', {
      defaultValue: {}
    }),
    ipAddress: (0, _emberRestless.attr)('string', {
      printableName: 'IP Address'
    }),
    countryCode: (0, _emberRestless.attr)('string'),
    countryCodeWithEmoji: (0, _emberRestless.attr)('string', {
      printableName: 'Country Code'
    }),
    regionCode: (0, _emberRestless.attr)('string', {
      printableName: 'Region Code'
    }),
    city: (0, _emberRestless.attr)('string', {
      printableName: 'City'
    }),
    countryName: (0, _emberRestless.attr)('string', {
      printableName: 'Country Name'
    }),
    regionName: (0, _emberRestless.attr)('string', {
      printableName: 'Region Name'
    }),
    postalCode: (0, _emberRestless.attr)('string', {
      printableName: 'Postal Code'
    }),
    latitude: (0, _emberRestless.attr)('string', {
      printableName: 'Latitude'
    }),
    longitude: (0, _emberRestless.attr)('string', {
      printableName: 'Longitude'
    }),
    inEu: (0, _emberRestless.attr)('string', {
      printableName: 'In EU?'
    }),
    getAnswers: function getAnswers(component) {
      if (!this._componentAnswerMap) {
        this._componentAnswerMap = {};
        this.get('answers').forEach(function (_this) {
          return function (answer) {
            var answers;
            if (answers = _this._componentAnswerMap[answer.get('component.id')]) {
              answers.pushObject(answer);
            } else {
              answers = [answer];
              _this._componentAnswerMap[answer.get('component.id')] = answers;
            }
            return answers;
          };
        }(this));
      }
      return this._componentAnswerMap[component.get('id')];
    }
  });

  Response.reopenForEach(_globals.codeSettings.salesforcePushStatuses, function (status, Response) {
    return Response["push" + status.titleize()] = Ember.computed.equal('pushStatus', status);
  });

  Response.reopenClass({
    STATUSES: _globals.codeSettings.responseStatuses,
    resourceName: 'response',
    deleteIds: function deleteIds(ids) {
      return Ember.get(this, 'adapter').request({
        model: Response.create(),
        params: {
          type: 'DELETE'
        },
        key: ids
      });
    },
    resyncIds: function resyncIds(ids) {
      return $.ajax({
        method: 'POST',
        url: "/responses/resync",
        data: {
          ids: ids
        }
      });
    }
  });

  exports.default = Response;
});
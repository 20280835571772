define("presenter/templates/svg/question_types/_net_promoter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_net_promoter.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 48 48");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M24 8a16 16 0 1 0 16 16A16 16 0 0 0 24 8zm5.05 8.42a2.53 2.53 0 1 1-2.53 2.53 2.53 2.53 0 0 1 2.53-2.53zm-10.11 0a2.53 2.53 0 1 1-2.53 2.53 2.53 2.53 0 0 1 2.54-2.53zM24 36.26c-5.57 0-10.11-4.26-10.11-9.26h2.53c0 4 3.4 6.73 7.58 6.73S31.58 31 31.58 27h2.53c0 5-4.54 9.26-10.11 9.26z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
define('presenter/models/pardot-push', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PardotPush;

  PardotPush = _data.Model.extend({
    status: (0, _data.attr)('string'),
    createdRecordId: (0, _data.attr)('string'),
    errorCode: (0, _data.attr)('string'),
    errorMessage: (0, _data.attr)('string'),
    response: (0, _data.belongsTo)('response', {
      serialize: false
    }),
    pardotObjectMapping: (0, _data.belongsTo)('pardot_object_mapping', {
      serialize: false
    }),
    isPardotPush: true
  });

  PardotPush.reopenClass({
    resourceName: 'pardot_push'
  });

  exports.default = PardotPush;
});
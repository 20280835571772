define('presenter/models/dashboard', ['exports', 'presenter/data', 'presenter/mixins/allows-merges', 'presenter/mixins/change-feed-manager'], function (exports, _data, _allowsMerges, _changeFeedManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Dashboard;

  Dashboard = _data.Model.extend(_allowsMerges.default, _changeFeedManager.default, (_Model$extend = {
    name: (0, _data.attr)('string'),
    shareToken: (0, _data.attr)('string'),
    filterToken: (0, _data.attr)('string'),
    cartilePositions: (0, _data.attr)('raw'),
    createdAt: (0, _data.attr)('date'),
    updatedAt: (0, _data.attr)('date'),
    changeFeedToken: (0, _data.attr)('string', {
      readOnly: true,
      deserializeOnPreview: true
    }),
    hmacKey: (0, _data.attr)('string', {
      readOnly: true
    }),
    colorScheme: (0, _data.attr)('string'),
    bundleComponentId: (0, _data.attr)('number'),
    dashboardShares: (0, _data.hasMany)('dashboard_share', {
      serialize: false
    }),
    cartiles: (0, _data.hasMany)('cartile', {
      serialize: false
    }),
    user: (0, _data.belongsTo)('user', {
      serialize: false
    }),
    surveys: (0, _data.hasMany)('survey', {
      serialize: false
    }),
    existingDashboard: (0, _data.belongsTo)('dashboard', {
      serialize: 'id'
    })
  }, _defineProperty(_Model$extend, 'user', (0, _data.belongsTo)('user', {
    serialize: 'id'
  })), _defineProperty(_Model$extend, 'isDayMode', Ember.computed.equal('colorScheme', 'day')), _defineProperty(_Model$extend, 'isNightMode', Ember.computed.equal('colorScheme', 'night')), _defineProperty(_Model$extend, 'positionCartiles', function positionCartiles() {
    var cartilePositions;
    cartilePositions = this.get('cartilePositions');
    return this.get('cartiles').forEach(function (_this) {
      return function (cartile) {
        var cartilePosition;
        cartilePosition = cartilePositions[cartile.get('id')];
        if (cartilePosition && Number.isFinite(cartilePosition['x']) && Number.isFinite(cartilePosition['y'])) {
          cartile.set('xCoordinate', cartilePosition['x']);
          return cartile.set('yCoordinate', cartilePosition['y']);
        }
      };
    }(this));
  }), _Model$extend));

  Dashboard.reopenClass({
    resourceName: 'dashboard'
  });

  exports.default = Dashboard;
});
define('presenter/models/field-mapping-push', ['exports', 'presenter/data', 'presenter/models/salesforce-push'], function (exports, _data, _salesforcePush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FieldMappingPush;

  FieldMappingPush = _salesforcePush.default.extend({
    salesforceMapping: (0, _data.belongsTo)('salesforce_field_mapping', {
      serialize: false
    }),
    isFieldMappingPush: true
  });

  FieldMappingPush.reopenClass({
    resourceName: 'field_mapping_push'
  });

  exports.default = FieldMappingPush;
});
define('presenter/models/salesforce-object', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SalesforceObject;

  SalesforceObject = _data.Model.extend({
    name: (0, _data.attr)('string'),
    label: (0, _data.attr)('string'),
    keyPrefix: (0, _data.attr)('string'),
    createable: (0, _data.attr)('boolean'),
    updateable: (0, _data.attr)('boolean'),
    upsertable: (0, _data.attr)('boolean'),
    assignable: (0, _data.attr)('boolean'),
    hasEvents: (0, _data.attr)('boolean'),
    custom: (0, _data.attr)('boolean'),
    fields: (0, _data.hasMany)('salesforce_field', {
      serialize: false
    }),
    recordTypes: (0, _data.hasMany)('salesforce_record_type', {
      serialize: false
    }),
    fieldsByName: Ember.computed.indexBy('fields', 'name'),
    normalizedLabel: Ember.computed.alias('label')
  });

  SalesforceObject.reopenClass({
    resourceName: 'salesforce_object',
    useModelKey: true,
    outboundMessageOnlyObjects: function outboundMessageOnlyObjects(parent, parentId) {
      this.endpoint = parent + "/" + parentId + "/salesforce_outbound_message_only_objects";
      return this.fetch();
    }
  });

  exports.default = SalesforceObject;
});
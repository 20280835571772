define('presenter/components/video-player', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'media-view-video',
    media: null,
    playbackSwitch: null,
    stopPlayback: Ember.K,
    _stopPlayback: function () {
      return this.stopPlayback();
    }.observes('playbackSwitch')
  });
});
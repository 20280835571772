define('presenter/models/invalid-page', ['exports', 'presenter/globals', 'presenter/models/default-page', 'i18n-js'], function (exports, _globals, _defaultPage, _i18nJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _defaultPage.default.extend({
    type: 'InvalidPage',
    isBranded: false,
    title: function () {
      return _i18nJs.default.t('presenter.default_pages.invalid_page.title');
    }.property(),
    description: _globals.isPreview ? function () {
      return _i18nJs.default.t('presenter.default_pages.invalid_page.description');
    }.property() : void 0
  });
});
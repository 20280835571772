define('presenter/models/action', ['exports', 'presenter/data', 'presenter/utils'], function (exports, _data, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Action;

  Action = _data.Model.extend({
    position: (0, _data.attr)('number'),
    type: (0, _data.attr)('string', {
      defaultValue: function defaultValue() {
        return 'jump';
      }
    }),
    emails: (0, _data.attr)('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    emailSubject: (0, _data.attr)('string'),
    parameter: (0, _data.attr)('string'),
    value: (0, _data.attr)('string'),
    rule: (0, _data.belongsTo)('rule', {
      serialize: false
    }),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    slackConversationId: (0, _data.attr)('string'),
    slackTeamId: (0, _data.attr)('string'),
    slackMessage: (0, _data.attr)('string'),
    includeResponse: (0, _data.attr)('boolean', {
      defaultValue: 'true'
    }),
    pardotObjectMapping: (0, _data.belongsTo)('pardot_object_mapping', {
      serialize: 'id'
    }),
    pardotFieldMapping: (0, _data.belongsTo)('pardot_field_mapping', {
      serialize: 'id'
    }),
    salesforceObjectMapping: (0, _data.belongsTo)('salesforce_object_mapping', {
      serialize: 'id'
    }),
    salesforceFieldMapping: (0, _data.belongsTo)('salesforce_field_mapping', {
      serialize: 'id'
    }),
    sfdcUserCheckError: null,
    fieldMappingToken: (0, _data.attr)('string'),
    isValid: function () {
      var type;
      type = this.get('type');
      switch (type) {
        case null:
          return false;
        case 'jump':
          return this.get('isValidJump');
        case 'send_notification':
          return this.get('isValidSendNotification');
        case 'push_pardot_mapping':
          return true;
        case 'push_salesforce_mapping':
          return this.get('isValidSfMapping');
        case 'send_slack_message':
          return this.get('isValidSlackMapping');
      }
    }.property('type', 'isValidJump', 'isValidSendNotification', 'isValidSfMapping', 'isValidSlackMapping'),
    isValidJump: function () {
      return !this.get('component') || this.get('component.position') > this.get('rule.component.position');
    }.property('component.position', 'rule.component.position'),
    isValidSendNotification: function () {
      return Ember.isPresent(this.get('emailSubject')) && this.get('hasValidEmails');
    }.property('emailSubject', 'hasValidEmails'),
    taskObjectMapping: Ember.computed.alias('salesforceObjectMapping'),
    taskFieldMappings: Ember.computed.alias('taskObjectMapping.salesforceFieldMappings'),
    taskActivityDateFieldMapping: Ember.computed('taskFieldMappings.@each.fieldName', function () {
      var ref;
      return (ref = this.get('taskFieldMappings')) != null ? ref.findBy('fieldName', 'ActivityDate') : void 0;
    }),
    activityDateIntervalIsValid: Ember.computed('taskActivityDateFieldMapping.activityDateInterval', function () {
      var intValue, stringValue;
      stringValue = this.get('taskActivityDateFieldMapping.activityDateInterval');
      if (!(stringValue != null ? stringValue.match(/^\d+$/) : void 0)) {
        return false;
      }
      intValue = parseInt(stringValue);
      return !isNaN(intValue) && intValue > 0;
    }),
    taskOwnerIdFieldMapping: Ember.computed('taskFieldMappings.@each.fieldName', function () {
      var ref;
      return (ref = this.get('taskFieldMappings')) != null ? ref.findBy('fieldName', 'OwnerId') : void 0;
    }),
    taskSubjectFieldMapping: Ember.computed('taskFieldMappings.@each.fieldName', function () {
      var ref;
      return (ref = this.get('taskFieldMappings')) != null ? ref.findBy('fieldName', 'Subject') : void 0;
    }),
    hasUnsavedChanges: Ember.computed('type', 'hasUnsavedTaskMappingChanges', 'hasUnsavedEmailChanges', function () {
      switch (this.get('type')) {
        case null:
          return false;
        case 'send_notification':
          return this.get('hasUnsavedEmailChanges');
        case 'push_salesforce_mapping':
          return this.get('hasUnsavedTaskMappingChanges');
      }
    }),
    hasUnsavedTaskMappingChanges: Ember.computed.or('salesforceFieldMapping.isDirty', 'salesforceObjectMapping.isDirty', 'taskActivityDateFieldMapping.isDirty', 'taskObjectMapping.isDirty', 'taskOwnerIdFieldMapping.isDirty', 'taskSubjectFieldMapping.isDirty'),
    hasUnsavedEmailChanges: Ember.computed.or('emails.isDirty', 'emailSubject.isDirty'),
    commaSeparatedEmails: Ember.computed('emails.[]', {
      get: function get(key) {
        return this.get('emails').join(', ');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isValidSfMapping: Ember.computed('taskActivityDateFieldMapping.activityDateInterval', 'taskOwnerIdFieldMapping.fixedValue', 'taskSubjectFieldMapping.fixedValue', 'activityDateIntervalIsValid', function () {
      if (this.get('rule.purpose') !== 'user_defined_action') {
        return true;
      }
      return this.checkSfIdConstraints() && Ember.isPresent(this.get('taskActivityDateFieldMapping.activityDateInterval')) && Ember.isPresent(this.get('taskOwnerIdFieldMapping.fixedValue')) && Ember.isPresent(this.get('taskSubjectFieldMapping.fixedValue')) && Ember.isEqual(this.get('activityDateIntervalIsValid'), true);
    }),
    isValidSlackMapping: Ember.computed('slackTeamId', 'slackConversationId', 'slackMessage', function () {
      return Ember.isPresent(this.get('slackTeamId')) && Ember.isPresent(this.get('slackConversationId')) && Ember.isPresent(this.get('slackMessage'));
    }),
    checkSfIdConstraints: function checkSfIdConstraints() {
      var _, fallback, fallbackPattern, id, isValid, matchData;
      fallbackPattern = /^\[.+,fallback=(\w+)\]$/;
      id = this.get('taskOwnerIdFieldMapping.fixedValue') || '';
      matchData = id.match(fallbackPattern);
      if (matchData) {
        _ = matchData[0], fallback = matchData[1];
      }
      isValid = this.isValidSfUserIdFormat(fallback || id);
      if (isValid) {
        this.set('sfdcUserCheckError', null);
      } else {
        this.set('sfdcUserCheckError', 'Please enter a valid Salesforce User ID');
      }
      return isValid;
    },
    isValidSfUserIdFormat: function isValidSfUserIdFormat(id) {
      if (id == null) {
        id = '';
      }
      return id.match(/^(005)\w{12,15}$/);
    },
    hasValidEmails: function () {
      return !!this.get('emails').length && this.get('emails').every(function (email) {
        return (0, _utils.isValidEmail)(email);
      });
    }.property('emails.[]'),
    reset: function reset() {
      return this.setProperties({
        emails: [],
        emailSubject: null,
        salesforceObjectMapping: null,
        sfdcUserCheckError: null
      });
    }
  });

  Action.reopenClass({
    resourceName: 'action'
  });

  exports.default = Action;
});
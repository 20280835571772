define("presenter/templates/svg/_email_support", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/_email_support.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 100 100");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3, "cx", "50");
        dom.setAttribute(el3, "cy", "50");
        dom.setAttribute(el3, "r", "40");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M50.896 56.51l13.634-9.343a.3.3 0 0 1 .47.248v12.67a1.7 1.7 0 0 1-1.7 1.7H37.7a1.7 1.7 0 0 1-1.7-1.7V47.394a.3.3 0 0 1 .47-.247l13.634 9.363a.7.7 0 0 0 .792 0zm-.945-6.087l-13.873-9.506A.18.18 0 0 1 36 40.77V39.7a.7.7 0 0 1 .7-.7h27.6a.7.7 0 0 1 .7.7v1.07a.222.222 0 0 1-.097.183l-13.821 9.47a1 1 0 0 1-1.13 0z");
        dom.setAttribute(el3, "fill", "#FFF");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
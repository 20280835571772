define('presenter/routes/presenter/survey', ['exports', 'presenter/models/survey', 'presenter/utils/fonts', 'presenter/globals', 'presenter/mixins/head-data'], function (exports, _survey, _fonts, _globals, _headData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var fontLoaded;

  fontLoaded = false;

  exports.default = Ember.Route.extend(_headData.default, {
    title: function () {
      if (this.modelFor('presenter/survey').get('whitelabeled')) {
        return 'Survey';
      } else {
        return 'GetFeedback';
      }
    }.property(),
    beforeModel: function beforeModel() {
      if (this.controllerFor('presenter/response').get('hasEmbeddedAnswer')) {
        return this.controllerFor('presenter/response').start();
      }
    },
    model: function model() {
      return this.setIpdata(_survey.default.load(window._survey_raw));
    },
    afterModel: function afterModel(model) {
      var response;
      response = this.controllerFor('presenter/response').get('model');
      return response.sideloadRelationships(model.get('identityMap'));
    },
    setupController: function setupController(controller, survey) {
      this._super.apply(this, arguments);
      if (window._merge_field_validation_errors) {
        return controller.set('mergeFieldValidationErrors', window._merge_field_validation_errors);
      }
    },
    activate: function activate() {
      var responseToken;
      if (!(0, _globals.presenterFlag)('isReadOnly') && !!(responseToken = this.controllerFor('presenter/response').get('identifier'))) {
        if (Ember.$.cookie('responseToken') === responseToken) {
          location.reload();
        } else {
          Ember.$.cookie('responseToken', responseToken);
        }
      }
      if (!fontLoaded) {
        _fonts.Fonts.loadFonts([this.modelFor('presenter/survey').get('font')]);
        return fontLoaded = true;
      }
    },
    setIpdata: function setIpdata(survey) {
      var deferred;
      deferred = Ember.RSVP.defer();
      if (!_globals.isPreview && survey.get('hasIpdata') && survey.get('storeIpdata')) {
        Ember.$.ajax({
          method: 'get',
          url: _globals.jsSettings.ipdata.url,
          dataType: 'jsonp',
          error: function (_this) {
            return function (xhr, status, error) {
              Raven.captureMessage(error);
              return deferred.resolve(survey);
            };
          }(this),
          success: function (_this) {
            return function (ipdata) {
              var response;
              response = _this.controllerFor('presenter/response').get('model');
              response.set('ipdata', ipdata);
              return deferred.resolve(survey);
            };
          }(this)
        });
      } else {
        deferred.resolve(survey);
      }
      return deferred.promise;
    },
    actions: {
      inputStarted: function inputStarted() {
        return this.get('container').lookup('eventBus:survey').trigger('inputStarted', this.controllerFor('presenter/response').get('campaignToken'));
      },
      inputEnded: function inputEnded() {
        return this.get('container').lookup('eventBus:survey').trigger('inputEnded', this.controllerFor('presenter/response').get('campaignToken'));
      }
    }
  });
});
define('presenter/components/presenter/question', ['exports', 'presenter/components/presenter/component', 'presenter/mixins/grid-component-helper'], function (exports, _component, _gridComponentHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['is-question'],
    classNameBindings: ['component.hasGridItems'],
    layoutName: 'presenter/layouts/question',
    _initGridView: function () {
      if (this.get('component.hasGridItems')) {
        return this.reopen(_gridComponentHelper.default);
      }
    }.observes('component.hasGridItems').on('init'),
    _animateSelection: function _animateSelection(elements, property) {
      var animate, attrHash, blink, orig, originalCss;
      if (elements != null ? elements.length : void 0) {
        animate = Modernizr.transition ? elements.transition : elements.animate;
        attrHash = function attrHash(property, value) {
          var hash;
          hash = {};
          hash[property] = value;
          return hash;
        };
        originalCss = elements.attr('style') || '';
        orig = attrHash(property, elements.css(property));
        blink = function () {
          switch (property) {
            case 'backgroundColor':
              return attrHash(property, 'transparent');
            case 'opacity':
              return attrHash(property, 0);
            case 'fill':
              return attrHash(property, 'transparent');
          }
        }();
        return Ember.run.later(function (_this) {
          return function () {
            elements.css(blink);
            return Ember.run.later(function () {
              return animate.call(elements, orig, 50, function () {
                Ember.run.later(function () {
                  var base;
                  return typeof (base = _this.get('component.animateSelection')).resolve === "function" ? base.resolve() : void 0;
                }, 50);
                return elements.attr('style', originalCss);
              });
            }, 50);
          };
        }(this), 50);
      }
    }
  });
});
define('presenter/initializers/set-restless-namespace', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'setRestlessNamespace',
    after: 'restless',
    initialize: function initialize() {
      var adapter;
      adapter = _data.Client.get('adapter');
      return adapter.reopen({
        namespace: 'presenter'
      });
    }
  };
});
define("presenter/templates/presenter/campaign-monitor-form-question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 188
            }
          },
          "moduleName": "presenter/templates/presenter/campaign-monitor-form-question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "presenter-form-question-legend");
          var el2 = dom.createTextNode("* = ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'aria-label');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "translated-properties", ["presenter", "alerts", "required_question"], [], ["loc", [null, [1, 45], [1, 111]]]]]]], ["content", "legend", ["loc", [null, [1, 172], [1, 182]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 195
            },
            "end": {
              "line": 1,
              "column": 321
            }
          },
          "moduleName": "presenter/templates/presenter/campaign-monitor-form-question.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "presenter/form-field", [], ["field", ["subexpr", "@mut", [["get", "field", ["loc", [null, [1, 282], [1, 287]]]]], [], []], "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [1, 295], [1, 299]]]]], [], []], "component", ["subexpr", "@mut", [["get", "component", ["loc", [null, [1, 310], [1, 319]]]]], [], []]], ["loc", [null, [1, 253], [1, 321]]]]],
        locals: ["field"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 330
          }
        },
        "moduleName": "presenter/templates/presenter/campaign-monitor-form-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "component.showLegend", ["loc", [null, [1, 6], [1, 26]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 195]]]], ["block", "each", [["get", "component.formFieldsNotHiddenByPiping", ["loc", [null, [1, 203], [1, 240]]]]], [], 1, null, ["loc", [null, [1, 195], [1, 330]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
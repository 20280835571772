define("presenter/templates/svg/question_types/_multiple_choice_grid", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "presenter/templates/svg/question_types/_multiple_choice_grid.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 53 48.1");
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "fill-rule", "evenodd");
        dom.setAttribute(el2, "clip-rule", "evenodd");
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M46 36.3V40h-3.7l-2 2H48v-7.7M20.7 40H2V24h21v9.7l1.5-1.5.5.5V24h21v.7l2-2V4H0v38h22.7l-2-2zM25 6h21v16H25V6zM2 6h21v16H2V6z");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M53 26.5l-3.4-3.4-18.2 18.2-7-7-3.4 3.4 8.3 8.4 2.1 2");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  }());
});
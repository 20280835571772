define('presenter/components/presenter/footer-view', ['exports', 'presenter/globals'], function (exports, _globals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    context: Ember.computed.oneWay('components'),
    submitSameColor: function () {
      return !!(0, _globals.presenterFlag)('isSubmitSameColor');
    }.property().readOnly(),
    preventOverscroll: function () {
      if (!(_globals.isEmbedded && Modernizr.touch)) {
        return;
      }
      return this.$().on('touchmove', function (e) {
        return e.preventDefault();
      });
    }.on('didInsertElement')
  });
});
define('presenter/components/application-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['modal-container', 'modal-mask', 'hidden'],
    classNameBindings: ['modalOptions.fullscreen:transparent-mask'],
    anchor: null,
    didInsertElement: function didInsertElement() {
      this._super();
      if (this.get('showModal')) {
        this._animate();
      }
      return this._keyHandler = function (_this) {
        return function (e) {
          if (e.which === 27) {
            return _this.sendAction('hideModal');
          }
        };
      }(this);
    },
    _toggleKeyHandler: function () {
      if (this.get('showModal') && this.get('modalOptions.dismissable')) {
        return Ember.$(window).on('keyup', this._keyHandler);
      } else {
        return Ember.$(window).off('keyUp', this._keyHandler);
      }
    }.observes('showModal'),
    _animate: function () {
      var $anchor, $modal, hideDelay;
      if (!this.$()) {
        return;
      }
      if (this.hideTimer) {
        Ember.run.cancel(this.hideTimer);
      }
      if (this.showTimer) {
        Ember.run.cancel(this.showTimer);
      }
      if (this.get('showModal')) {
        this.setProperties({
          contentClassName: this.get('modalOptions.className'),
          anchor: this.get('modalOptions.anchor') && Ember.$(this.get('modalOptions.anchor'))
        });
        this.$().show();
        return this.showTimer = Ember.run.next(this, function () {
          return this.$().addClass('shown');
        });
      } else {
        if ($anchor = this.get('anchor')) {
          hideDelay = 500;
          this.$().css({
            background: 'none'
          });
          $modal = this.$('.js-modalContent');
          $modal.transition({
            x: $anchor.offset().left + $anchor.width() / 2 - $modal.offset().left - $modal.outerWidth() / 2,
            y: $anchor.offset().top + $anchor.height() / 2 - $modal.offset().top - $modal.outerHeight() / 2,
            scale: 0,
            duration: hideDelay
          }, function (_this) {
            return function () {
              _this.$('.js-modalContent').css({
                transform: ''
              });
              return _this.$().css({
                background: ''
              });
            };
          }(this));
        } else {
          this.$().removeClass('shown');
          hideDelay = 160;
        }
        return this.hideTimer = Ember.run.later(this, function () {
          this.$().removeClass('shown');
          this.$().hide();
          this.set('contentClassName', null);
          this.sendAction('modalDismissed');
          return this.sendAction('disconnectModal');
        }, hideDelay);
      }
    }.observes('showModal'),
    actions: {
      hideModal: function hideModal() {
        return this.sendAction('hideModal');
      }
    }
  });
});
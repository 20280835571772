define('presenter/components/bourbon-feature-overview', ['exports', 'bourbon/components/bourbon-feature-overview'], function (exports, _bourbonFeatureOverview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bourbonFeatureOverview.default;
    }
  });
});
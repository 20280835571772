define('presenter/models/answer', ['exports', 'presenter/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Answer;

  Answer = _data.Model.extend({
    type: (0, _data.attr)('string'),
    createdAt: (0, _data.attr)('date'),
    embedded: (0, _data.attr)('boolean'),
    skipped: (0, _data.attr)('boolean'),
    component: (0, _data.belongsTo)('component', {
      serialize: 'id'
    }),
    response: (0, _data.belongsTo)('response', {
      serialize: 'id'
    }),
    gridItem: (0, _data.belongsTo)('grid_item', {
      serialize: 'id'
    }),
    text: (0, _data.attr)('string'),
    number: (0, _data.attr)('number'),
    textValue: (0, _data.attr)('string'),
    scale: (0, _data.attr)('number'),
    notApplicable: (0, _data.attr)('boolean'),
    choices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    comment: (0, _data.attr)('string'),
    items: (0, _data.attr)('raw'),
    listItems: (0, _data.hasMany)('list_item', {
      serialize: true
    }),
    formFields: (0, _data.hasMany)('form_field', {
      serialize: true
    }),
    rankedChoices: (0, _data.hasMany)('choice', {
      serialize: 'id'
    }),
    answerAttachments: (0, _data.hasMany)('answer_attachment', {
      serialize: true
    }),
    fileUploadUrls: function () {
      return this.get('answerAttachments').filterBy('url').mapBy('url');
    }.property('answerAttachments.@each.url'),
    displayText: (0, _data.attr)('string'),
    componentDisplayText: (0, _data.attr)('string')
  });

  Answer.reopenClass({
    resourceName: 'answer'
  });

  exports.default = Answer;
});